<template>
  <ClusterEncountersPopper
    :cluster="cluster"
    :show="isPopperOpen"
    @close="isPopperOpen = false"
  >
    <div class="cluster-card"
      :class="{
        'popper-open': isPopperOpen
      }"
      @click="isPopperOpen = !isPopperOpen"
      @close="isPopperOpen = false"

      @mouseover="isHovering=true"
      @mouseleave="isHovering=false"
    >
      <auth-img
        v-if="cluster.referenceEncounter"
        :src="computedReferenceEncounterThumbnail"
        class="best-thumbnail"
        draggable="false"
      />

      <MDBDropdown class="menu" v-show="isHovering || isMenuOpen" v-model="isMenuOpen">
        <RocDropdownToggle @click.stop="isMenuOpen = !isMenuOpen">
          <RocIcon
            size="md"
            icon="kebab"
            style="color: white;"
          />
        </RocDropdownToggle>
        <RocDropdownMenu>
          <RocDropdownItem @click.stop="launchAssociatesMode(); isMenuOpen=false;">
            View Associates
          </RocDropdownItem>
          <RocDropdownItem v-if="isAdminOrPowerUser" @click.stop="isShowingMergeClusters=true; isMenuOpen=false;">
            Merge With...
          </RocDropdownItem>
          <RocDropdownItem v-if="isAdminOrPowerUser" @click.stop="isShowingNotes=true; isMenuOpen=false">
            Edit Notes
          </RocDropdownItem>
        </RocDropdownMenu>
      </MDBDropdown>


      <div class="cluster-info">
        <div class="cluster-name" style="margin-right: var(--spacing-base);"
          :title="cluster.name"
        >
          {{ clusterName }}
          <RocIcon
            color="primary"
            size="sm"
            icon="edit"
            @click.stop="isShowingEditName=true"
          />
          <!-- John Smith and Edit icon-->
        </div>
        <div class="cluster-encounter-count">
          {{ cluster.encounters.length }} {{ cluster.encounters.length === 1 ? 'Encounter' : 'Encounters' }}
        </div>
        <div class="cluster-datetime-tags">
          <!-- Hover Datetime and Tags -->
          <MyPopperWrapper arrow hover class="icon-popper">
            <RocIcon
              color="buttonPrimary"
              size="sm"
              icon="calendar"
            />
            <template #content>
              <div>
                <div>
                  {{ formattedDatetimeString(cluster.startTimestamp) + " - "}}
                </div>
                <div>
                  {{ formattedDatetimeString(cluster.endTimestamp) }}
                </div>
              </div>
            </template>
          </MyPopperWrapper>
          <MyPopperWrapper arrow hover class="icon-popper" style="margin-left: 0;">
            <RocIcon
              color="buttonPrimary"
              size="sm"
              icon="tag"
            />
            <template #content>
              <div>
                {{ computedTagsString }}
              </div>
            </template>
          </MyPopperWrapper>
        </div>
      </div>

      <!-- Right justified Favorite Star -->
      <RocIcon
        class="cluster-favorite"
        :color="
          cluster.isFavorite ?
          'primary' :
          'gray'
        "
        size="md"
        :icon="cluster.isFavorite ? 'starFilled' : 'starEmpty'"
        @click.stop="toggleFavorite()"
      />
      <BaseDialog
        v-if="isShowingEditName"
        show
        hideCloseBtn
        @close="isShowingEditName=false;"
      >
        <ClusterCardEditName
          :name="cluster.name"
          @save="saveEditName"
          @close="isShowingEditName=false"
        />
      </BaseDialog>
      <BaseDialog
        v-if="isShowingMergeClusters"
        @close="isShowingMergeClusters=false"
        show
        hideCloseBtn
        noPadding
      >
        <MoveToExistingCluster
          mergeClusterMode
          @close="isShowingMergeClusters=false"
          :encounters="cluster.encounters"
          :cluster="cluster"
        />
      </BaseDialog>
      <BaseDialog
        v-if="isShowingNotes"
        show
        hideCloseBtn
        noPadding
      >
        <EditClusterNotes
          @close="isShowingNotes=false"
          :cluster="cluster"
        />
      </BaseDialog>

    </div>
  </ClusterEncountersPopper>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import BaseDialog from '@/components/ui/BaseDialog';
import RocButton from '@/components/ui/RocButton';
import RocIcon from '@/components/ui/RocIcon';
import ClusterCardEditName from '@/components/clusters/ClusterCardEditName';
import ClusterEncountersPopper from '@/components/clusters/ClusterEncountersPopper';
import MyPopperWrapper from '@/components/ui/MyPopperWrapper';
import { MDBDropdown } from 'mdb-vue-ui-kit';
import RocDropdownMenu from '@/components/ui/RocDropdownMenu.vue';
import RocDropdownToggle from '@/components/ui/RocDropdownToggle.vue';
import RocDropdownItem from '@/components/ui/RocDropdownItem';
import MoveToExistingCluster from '@/components/clusters/MoveToExistingCluster';
import EditClusterNotes from '@/components/clusters/EditClusterNotes.vue';

import userAccessHelper from "@/js/userAccessHelper";

import moment from 'moment';

export default {
  name: 'ClusterCard',
  components: {
    RocButton,
    RocIcon,
    BaseDialog,
    ClusterCardEditName,
    MyPopperWrapper,
    ClusterEncountersPopper,
    MDBDropdown,
    RocDropdownMenu,
    RocDropdownToggle,
    RocDropdownItem,
    MoveToExistingCluster,
    EditClusterNotes
},
  props: {
    cluster: {
      type: Object,
      required: true
    }
  },
  emits: ['show-associates'],
  setup(props, context) {
    const store = useStore();

    const computedReferenceEncounterThumbnail = computed(() => {
      const faceTemplateId = props.cluster.referenceEncounter.facetemplateId;
      return `/rest/v1/image/face/${faceTemplateId}/tn`;
    });

    const isShowingEditName = ref(false);

    async function saveEditName(name) {
      const response = await store.dispatch("clusters/updateCluster", {
        id: props.cluster._id,
        update: {
          name: name
        }
      });
    }

    async function toggleFavorite() {
      const response = await store.dispatch("clusters/updateCluster", {
        id: props.cluster._id,
        update: {
          isFavorite: !props.cluster.isFavorite
        }
      });
    }

    function formattedDatetimeString(datetime) {
      const date = moment(new Date(datetime));
      const formatted = date.format("MMM DD, yyyy HH:mm:ss");
      return formatted
    }

    const isPopperOpen = ref(false);

    const computedTagsString = computed(() => {
      if (props.cluster.attributes.length > 0) {
        var output = '';
        let i = 0;
        for (let attribute of props.cluster.attributes) {
          output += attribute;
          if (i < props.cluster.attributes.length - 1) {
            output += ', ';
          }
          i++;
        }
        return output;
      } else {
        return 'No tags'
      }
    });

    const clusterName = computed(() => {
      var maxLength = 14;
      if (props.cluster.name.length > maxLength) {
        return props.cluster.name.substring(0, maxLength) + '...';
      }
      return props.cluster.name;
    });

    const isHovering = ref(false);
    const isMenuOpen = ref(false);
    const isShowingMergeClusters = ref(false);

    const isShowingNotes = ref(false);

    function launchAssociatesMode() {
      context.emit('show-associates')
    }

    const isAdminOrPowerUser = computed(() => {
      const helper = new userAccessHelper();
      return helper.isAdminOrPowerUser();
    });

    return {
      computedReferenceEncounterThumbnail,
      isShowingEditName,
      saveEditName,
      toggleFavorite,
      formattedDatetimeString,
      isPopperOpen,
      computedTagsString,
      clusterName,
      isHovering,
      isMenuOpen,
      isShowingMergeClusters,
      isShowingNotes,
      launchAssociatesMode,
      isAdminOrPowerUser
    }
  }
}
</script>

<style scoped lang="scss">

.cluster-card {
  // Card should not change size based on border being set in active mode
  box-sizing: content-box;

  display: flex;
  flex-direction: column;

  height: 320px;
  width: 200px;

  border-radius: 5px;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

  background-color: var(--overwatch-secondary);

  position: relative;

  svg {
    cursor: pointer;
  }

  .best-thumbnail {
    height: 220px;
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
  .cluster-info {
    display: flex;
    flex-direction: column;
    padding: $--spacing-s;

    .cluster-name {
      @include overwatch-body-med;
      color: var(--overwatch-neutral-100);
    }

    .cluster-encounter-count {
      @include overwatch-body-small;
      margin-top: var(--spacing-base);
      color: var(--overwatch-neutral-200);
    }

    .cluster-datetime-tags {
      display: flex;
      gap: $--spacing-base;
    }

  }

  .icon-popper :deep(.popper) {
    background-color: var(--overwatch-primary);
    color: var(--overwatch-button-text);
    border-radius: 5px;
    padding: $--spacing-s $--spacing-m;
    width: fit-content;
    min-width: 240px;
  }

  .icon-popper :deep(.popper #arrow::before) {
    background-color: var(--overwatch-primary);
  }
  .cluster-favorite {
    position: absolute;
    right: $--spacing-s;
    bottom: $--spacing-s;
  }
}

.cluster-card.popper-open {
  // TODO: Change to new colors.
  border: solid 2px var(--overwatch-button-primary);
  background-color: var(--overwatch-button-primary-20);
}


.menu {
  position: absolute;
  top: $--spacing-s;
  right: $--spacing-base;
}
</style>