
export default {
  resetState (state, defaultState) {
    // Merge rather than replace so we don't lose observers
    Object.assign(state, defaultState);
  },
  setToken(state, payload) {
    state.token = payload;
  },
  setRefreshToken(state, payload) {
    state.refreshToken = payload;
  },
  setIsAuthenticated(state, payload) {
    state.isAuthenticated = payload;
  },
  setIsSSO(state, payload) {
    state.isSSO = payload;
  },
  setFirstName(state, payload) {
    state.firstname = payload;
  },
  setLastName(state, payload) {
    state.lastname = payload;
  },
  setEmail(state, payload) {
    state.email = payload;
  },
  setServerId(state, payload) {
    state.server_id = payload;
  },
  setServerName(state, payload) {
    state.server_name = payload;
  },
  setServerProtocol(state, payload) {
    state.server_protocol = payload;
  },
  setServerHost(state, payload) {
    state.server_host = payload;
  },
  setServerPort(state, payload) {
    state.server_port = payload;
  },
  setLastRefreshDelay(state, payload) {
    state.lastRefreshDelay = payload;
  },
  setLastRefreshTimeout(state, payload) {
    state.lastRefreshTimeout = payload;
  },
  setPresetServers(state, payload) {
    state.presetServers = payload;
  },
  setOneTimeToken(state, value) {
    state.oneTimeToken = value;
  },
  setUserConfig(state, payload) {
    state.userConfig = payload;
  },
  setUserSettingsChanged(state, payload){
    state.userSettingsChanged = payload;
  },
  setPreAuthConfig(state, payload){
    state.preAuthConfig = payload;
  }
};
