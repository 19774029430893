<script setup>
import { ref, computed, watchEffect } from 'vue';
import { useStore } from 'vuex';
import RocIcon from "./RocIcon.vue";
import { useRoute } from 'vue-router';

const props = defineProps({
  width: {
    type: String,
    default: '250px'
  },
  height: {
    type: String,
    default: '150px'
  },
  product: {
    type: String,
    default: 'watch',
    validator: (value) => { return ['watch', 'examine', 'enroll', 'powered'].includes(value); }
  }
});

const store = useStore();
const route = useRoute();

const logoSrc = ref(null);
const isWhiteLabelEnabled = ref(false);
const rocLogo = ref('rocLogo');

const preAuthConfig = computed(() => store.getters['auth/preAuthConfig'])

watchEffect(() => {
    const url = route.fullPath.split('/')[1];

    if(props.product === 'examine' || url === 'examine'){
      rocLogo.value = 'ROC_Examine';
    }  else if(props.product === 'enroll' || url === 'rocenroll'){
      rocLogo.value = 'ROC_Enroll'
    } else if(props.product === 'powered'){
      rocLogo.value = 'ROCPowered';
    } else {
      rocLogo.value = 'rocLogo';
    }
})

watchEffect(async () => {
  if(preAuthConfig.value){
    logoSrc.value = await store.dispatch('settings/getLogo', { preAuthConfig: preAuthConfig.value });
    isWhiteLabelEnabled.value = store.getters['auth/preAuthConfig'].isWhiteLabelEnabled;
  }
})

</script>

<template>
  <div class="logoHolder">
    <RocIcon v-if="!isWhiteLabelEnabled"  :icon="rocLogo" color="black" size="match"/>
    <img
        v-else-if="logoSrc !== null && logoSrc !== ''"
        :src='logoSrc'
        style="width: 100%; max-height: 100%; object-fit: contain;"
        alt="Product Logo"
    />
  </div>
</template>

<style scoped>
.logoHolder {
  display: flex;
  width: v-bind(width);
  height: v-bind(height);
  max-height: v-bind(height);
  margin-left: auto;
  margin-right: auto;
}
</style>