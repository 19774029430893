export default {
  resetState (state, defaultState) {
    // Merge rather than replace so we don't lose observers
    Object.assign(state, defaultState);
  },
  setWatchlists(state, payload) {
    state.watchlists = [];
    state.watchlists = payload;
  },
  setWatchlistState(state, payload) {
    const index = state.watchlists.findIndex(
      watchlist => watchlist._id === payload._id
    );
    if (index >= 0) {
      state.watchlists[index].enabled = payload.enabled;
    }
  },
  setWatchlistColor(state,payload){
    const index = state.watchlists.findIndex(
      watchlist => watchlist._id === payload._id
    );
    if (index >= 0) {
      state.watchlists[index].color = payload.color;
    }
  },
  setImporterVisible(state, payload) {
    state.importerVisible = payload;
  },
  setImporterType(state, payload) {
    state.importerType = payload;
  },
  setImporterMode(state, payload) {
    state.importerMode = payload;
  },
  setImporterWatchlistId(state, payload) {
    state.importerWatchlistId = payload;
  },
  setImporterEventId(state, payload) {
    state.importerEventId = payload;
  },
  addNewWatchlist(state, payload) {
    state.watchlists.push(payload);
  },
  deleteWatchlist(state, payload) {
    const index = state.watchlists.findIndex(
      watchlist => watchlist._id === payload._id
    );
    if (index >= 0) {
      state.watchlists.splice(index, 1);
    }
  },
  updateWatchlistMemberSummary(state, payload) {
    const index = state.watchlists.findIndex(
      watchlist => watchlist._id === payload._id
    );
    if (index >= 0) {
      if (!state.watchlists[index].memberSummary) {
        state.watchlists[index].memberSummary = [];
      }
      state.watchlists[index].memberSummary.push(payload.enrollmentPreview);
    }
  },
  updateWatchlistSize(state, payload) {
    const index = state.watchlists.findIndex(
      watchlist => watchlist._id === payload._id
    );
    if (index >= 0) {
      if (!state.watchlists[index].size) {
        state.watchlists[index].size = 0;
      }
      state.watchlists[index].size++;
    }
  },
  setEncounterFilterSelectedWatchlists(state, payload) {
    state.encounterFilterSelectedWatchlists = payload;
  },
  setNoFiles(state, status) {
    state.noFiles = status;
  },
  addEncounterFilterWatchlistId(state, payload) {
    state.encounterFilterSelectedWatchlists.push(payload);
  },
  setMaxParallelEnrollments(state, payload) {
    state.maxParallelEnrollments = payload;
  }
};
