<template>
  <div class="face-card">
    <!--<img class='face-img' :src="imgData" :style="imgStyle"/>-->
    <div class="examineSelect">
      <MDBCheckbox v-if="selectingImages" @change="toggleAddToCase" :disabled="!canSelect"/>
    </div>
    <div v-if="similarityScore" class="similarity">{{similarityScore}}</div>
    <div :style="imgStyle" @click="launchDetailsView">
      <auth-img class='face-img' :src="watchlistedFace.enrollment_image_preview" style="max-height: 160px;"/>
    </div>
    <div v-if="!watchlistedFace.enabled" style="position: absolute; left: calc(50% - 40px); top: 32%;">
    </div>
    <div style="position: absolute; bottom: 40px;" @click="launchDetailsView">
      <div class="face-card-name">{{watchlistedFace.firstname}}</div>
      <div class="face-card-name">{{watchlistedFace.lastname}}</div>
    </div>
    <div v-if='!similarityScore' style="position: absolute; bottom: var(--spacing-xs); right:var(--spacing-base);">
      <div style="width: 30px; height: 25px; cursor: pointer; position: relative;">
        <div>
          <MDBDropdown v-model='dropdownOptions'>
            <MDBDropdownToggle style='color:black; -webkit-appearance: none; -moz-appearance: none; appearance: none; width: 20px; height: 20px;' tag='a'>
              <div  @click="dropdownOptions = true" style="width: 30px; height: 30px;">
                <RocIcon size="sm" icon="kebab" :rotate90="true" style="position: absolute; right: 10px; top: 10px; text-shadow: 1px 1px 1px black;"/>
              </div>
            </MDBDropdownToggle>
            <RocDropdownMenu style="font-size: 12px;" aria-labelledby='dropdownMenuButton'>
              <MDBDropdownItem href="" @click.prevent="moveToWatchlist" >
                <div class="mdbMenuItem">
                  Move to...
                  <RocIcon icon="sort" size="sm" color="black"></RocIcon>
                </div>
              </MDBDropdownItem>
              <MDBDropdownItem href="" @click.prevent = "toggleEnabled">
                <div class="mdbMenuItem">
                  {{ stateText }}
                  <RocIcon v-if="stateText == 'Disable'" icon="nomatch" size="sm"/>
                  <RocIcon v-else icon="circleCheck" size="sm"/>
                </div>
              </MDBDropdownItem>
              <MDBDropdownItem divider v-if="isAdmin" />
              <MDBDropdownItem href="" @click.prevent="deleteFromWatchlist" v-if="isAdmin" >
                <div class="mdbMenuItem">
                  Delete
                  <RocIcon icon="trash" size="sm" style="color:red;"/>
                </div>
              </MDBDropdownItem>
              <MDBDropdownItem divider />
            </RocDropdownMenu>
          </MDBDropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from "vue";
import { MDBDropdown, MDBDropdownItem, MDBDropdownToggle, MDBCheckbox } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu";
import RocIcon from "@/components/ui/RocIcon";

import AuthImg from "@/components/ui/AuthImg";

export default {
  name: "WatchlistedFace.vue",
  emits: ["toggleEnabled", "deleteElement", "showDetails", "moveElement", 'addedToCase', 'removedFromCase',],
  props: {
    watchlistedFace: Object,
    selectingImages: Boolean,
    canSelect: Boolean,
  },
  components: {
    AuthImg,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    MDBCheckbox,
    RocDropdownMenu,
    RocIcon
  },
  setup(props, context) {

    const store = useStore();

    const windowWidth = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });
    });
    const isMobile = computed(() => {
      return windowWidth.value <= 480;
    });

    const imgStyle = ref('');
    const dropdownOptions = ref(false);
    const enabled = ref(props.watchlistedFace.enabled);


    const isAdmin = computed(function() {
      return store.getters['auth/isAdmin'];
    });

    if(!props.watchlistedFace.enabled) {
      setDisabled();
    }
    else {
      setEnabled();
    }

    function setDisabled() {
      imgStyle.value = 'opacity: 0.4;';
    }

    function setEnabled() {
      imgStyle.value  = 'opacity: 1;' ;
    }

    const stateText = computed(function () {
      if(props.watchlistedFace.enabled) {
        return "Disable"
      }
      else
      {
        return "Enable";
      }
    });

    function toggleAddToCase(e){
      console.log(props.canSelect);
      if(e.target.checked){
        context.emit('addedToCase');
      } else {
        context.emit('removedFromCase');
      }
    }

    function toggleEnabled() {
      const modifiedWatchlistedFace = props.watchlistedFace;
      modifiedWatchlistedFace.enabled = !props.watchlistedFace.enabled;
      if(!modifiedWatchlistedFace.enabled) {
        console.log("set disabled");
        setDisabled();
      }
      else {
        console.log("set enabled");
        setEnabled();
      }
      context.emit("toggleEnabled", modifiedWatchlistedFace);
      dropdownOptions.value = false;
    }

    function deleteFromWatchlist() {
      context.emit("deleteElement");
      dropdownOptions.value = false;
    }

    function launchDetailsView() {
      context.emit('showDetails');
    }

    function moveToWatchlist() {
      dropdownOptions.value = false;
      context.emit('moveElement');
    }

    const similarityScore = computed(() => {
      if (props.watchlistedFace.matchDetails) {
        if (props.watchlistedFace.matchDetails.similarity || props.watchlistedFace.matchDetails.similarity == 0) {
          return require('@/js/rocHelper').formatSimilarityScore(props.watchlistedFace.matchDetails.similarity, true);
        }
      }
      return null;
    });

    return {
      isAdmin,
      imgStyle,
      dropdownOptions,
      stateText,
      toggleAddToCase,
      toggleEnabled,
      deleteFromWatchlist,
      enabled,
      launchDetailsView,
      moveToWatchlist,
      similarityScore,
    };
  }
}
</script>

<style scoped lang="scss">
.face-card {
  position: relative;
  box-sizing: border-box;
  height: 250px;
  width: 142px;
  border-radius: var(--spacing-base);
  background-color: var(--overwatch-secondary);
  box-shadow: 0 1px 2px 0px rgba(0,19,58,0.05);
  margin-top: var(--spacing-s);
  margin-right: var(--spacing-s);
  cursor: pointer;
}

.face-card:hover {
  transform: scale(1);
  transition: transform 0.5s;
  z-index:1;
  box-shadow: 0 2px 12px 0 rgba(0,19,58,0.05);
}

.face-card-name {
  height: 20px;
  width: 122px;
  color: var(--overwatch-neutral-100);
  @include overwatch-body-med;
  line-height: 21px;
  margin-left: 10px;
  margin-top: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  user-select: none;
}

/*
.face-card img:hover {
  filter: brightness(50%);
  cursor: pointer;
}*/

.mdbMenuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 15px;
}

.face-img {
  border-radius: 4px 4px 0px 0px;
  width: 140px;
  height: auto;
  margin-bottom: 6px;
  position: absolute;
}

.disabled {
  height: var(--spacing-s);
  width: 83px;
  color: var(--overwatch-error);
  @include overwatch-body-med;
  letter-spacing: 1px;
  line-height: var(--spacing-s);
}

.dropdown-toggle:after {
  display: none;
}

.header-options-dd {
  @include overwatch-body-med;
  color: #000000;
}

.examineSelect {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  margin: 5px;
}

/* input[type="checkbox"]:checked + .similarity {
  color: white;
  background-color: #94bd86;
  border: 1px solid white;
}

.similarity:hover {
  filter: brightness(85%);
} */

.similarity {
  margin: 5px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include overwatch-body-med;
  color: var(--overwatch-neutral-100);
  border: 1px solid var(--overwatch-neutral-100);
  background-color: var(--roc-watch-similarity-90);
  user-select: none;
}

@media (max-width: 480px) {
  .similarity {
    width: var(--spacing-xl);
    height: var(--spacing-xl);
    font-size: 14px;
  }
}

</style>