<template>
        <RocPopper arrow hover>
            <!-- all @click.stop events are to prevent the SavedViewCard.vue parent-div from loading a saved view when a badge or badge popper is clicked -->
            <div class="Badge" v-if="badgeValues && badgeValues.searchText" @click.stop>
                <RocIcon class="Badge-Icon" icon="personSearch" size="sm" color="primary"/>
                <span class="Badge-Text overwatch-body-small">detect match</span>
            </div>
            <template #content v-if="badgeValues && badgeValues.searchText">
                <div class="Popper-Rectangle" @click.stop>
                    <div class="Popper-Header">
                        <span class="Badge-Body-Title overwatch-body-large">Detect Match</span>
                    </div>
                    <div class="Popper-Body">
                        <span class="Badge-Body-Description overwatch-body-med">{{ badgeValues.searchText }}</span>
                    </div>
                </div>
            </template>
        </RocPopper>

        <RocPopper arrow hover :closeOnClick="closePopper">
            <div class="Badge" v-if="badgeValues && badgeValues.faceImage" @click.stop>
                <RocIcon class="Badge-Icon" icon="faceSearch" size="sm" color="primary"/>
                <span class="Badge-Text overwatch-body-med">face image</span>
            </div>
            <template #content="{close}">
                <div @click.stop="close" class="Popper-Rectangle">
                    <div class="Popper-Header">
                        <span class="Badge-Body-Title overwatch-body-large">Face Image</span>
                    </div>
                    <div @click="showExpandedImg=true" style="cursor: pointer;" class="Popper-Body-Face-Image" v-if="badgeValues && badgeValues.faceImage">
                        <span class="d-flex justify-content-center"><auth-img class="Face-Image" :src='badgeValues.faceImage' style='max-width: 115px; height: auto'></auth-img></span>
                        <span class="d-flex justify-content-end"><RocIcon class="Face-Icon" icon="expand" size="sm" color="primary"/></span>
                    </div>
                </div>
            </template>
        </RocPopper>


        <RocPopper arrow hover>
            <div class="Badge" v-if="tagNames && tagNames[0] && tagNames[0].selectedTags && tagNames[0].selectedTags.length > 0" @click.stop>
                <RocIcon class="Badge-Icon" icon="tag" size="sm" color="primary"/>
                <span class="Badge-Text overwatch-body-small">tags</span>
            </div>
            <template #content>
                <div class="Popper-Rectangle" @click.stop>
                    <div class="Popper-Header">
                        <span class="Badge-Body-Title overwatch-body-large">Tags</span>
                    </div>
                    <div class="Popper-Body flex-column" v-if="tagNames && tagNames.length > 1">
                        <div v-for="tagObj in tagNames" :key="tagObj">
                            <div v-if="tagObj.selectedTags && tagObj.selectedTags.length > 0">
                                <div class="Badge-Body-Description overwatch-body-med" style="display: block; text-transform: uppercase;">{{tagObj.andOr}}</div>
                                <div class="d-flex" style="display: block;">
                                    <div class="Popper-Badge" v-for="tag in tagObj.selectedTags" :key="tag">
                                        <span class="Badge-Body-Description overwatch-body-med">{{tag}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="Popper-Body" v-else-if="badgeValues && badgeValues.tags && tagNames && tagNames.length === 1">
                        <div class="Popper-Badge" v-for="tag in tagNames[0].selectedTags" :key="tag">
                            <span class="Badge-Body-Description overwatch-body-med">{{tag}}</span>
                        </div>
                    </div>
                </div>
            </template>
        </RocPopper>

        <RocPopper arrow hover>
            <div class="Badge" v-if="clusterTagNames.length > 0" @click.stop>
                <RocIcon
                    color="primary"
                    icon="tag"
                    size="sm"
                    class="Badge-Icon"
                />

                <span class="Badge-Text overwatch-body-small">tags</span>
            </div>
            <template #content>
                <div class="Popper-Rectangle" @click.stop>
                    <div class="Popper-Header">
                        <span class="Badge-Body-Title overwatch-body-large">Tags</span>
                    </div>
                    <div class="Popper-Body flex-column" v-if="clusterTagNames.length > 0">                        
                        <div class="Badge-Body-Description overwatch-body-med" style="display: block; text-transform: uppercase;">{{clusterTagAndOr}}</div>
                        <div class="d-flex" style="display: block;">
                            <div class="Popper-Badge" v-for="tag in clusterTagNames" :key="tag">
                                <span class="Badge-Body-Description overwatch-body-med">{{tag}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="Popper-Body" v-else-if="badgeValues && badgeValues.tags && tagNames && tagNames.length === 1">
                        <div class="Popper-Badge" v-for="tag in tagNames[0].selectedTags" :key="tag">
                            <span class="Badge-Body-Description overwatch-body-med">{{tag}}</span>
                        </div>
                    </div>
                </div>
            </template>
        </RocPopper>


        <RocPopper arrow hover>
            <div class="Badge" v-if="badgeValues && badgeValues.dateRange && dateRangeDisplayText && dateRangeDisplayText.part1" @click.stop>
                <RocIcon class="Badge-Icon" icon="calendar" size="sm" color="primary"/>
                <span class="Badge-Text overwatch-body-small">date range</span>
            </div>
            <template #content>
                <div class="Popper-Rectangle" @click.stop>
                    <div class="Popper-Header">
                        <span class="Badge-Body-Title overwatch-body-large">Date Range</span>
                    </div>
                    <div class="Popper-Body" v-if="badgeValues && badgeValues.dateRange">
                        <div>
                            <RocIcon class="Calendar-Hover-Icon" icon="calendar" size="sm" color="gray"/>
                            <span class="Badge-Body-Description overwatch-body-med" style="font-size: 16px;">{{ dateRangeDisplayText.part1 }}</span>
                        </div>
                        <!-- splitting dateRangeDisplay into 2 parts to ensure styling is consistent -->
                        <div>
                            <span class="Badge-Body-Description overwatch-body-med" style="font-size: 16px;">{{ dateRangeDisplayText.part2 }}</span>
                        </div>
                    </div>
                </div>
            </template>
        </RocPopper>

        <RocPopper arrow hover>
            <div class="Badge" v-if="badgeValues && badgeValues.cams && badgeValues.cams.length > 0 && getCameraNamesById.length > 0" @click.stop>
                <RocIcon class="Badge-Icon" icon="cameraOn" size="sm" color="primary"/>
                <span class="Badge-Text overwatch-body-small">cameras</span>
            </div>
            <template #content>
                <div class="Popper-Rectangle" @click.stop>
                    <div class="Popper-Header">
                        <span class="Badge-Body-Title overwatch-body-large">Cameras</span>
                    </div>
                    <div class="Popper-Body" v-if="badgeValues && badgeValues.cams">
                        <div class="Popper-Badge" v-for="cam in getCameraNamesById" :key="cam" :value="cam">
                            <span class="Badge-Body-Description overwatch-body-med">{{cam}}</span>
                        </div>
                    </div>
                </div>
            </template>
        </RocPopper>

        <RocPopper arrow hover>
            <div class="Badge" v-if="badgeValues && badgeValues.watchlists && getWatchlistNamesById.length > 0" @click.stop>
                <RocIcon class="Badge-Icon" icon="global" size="sm" color="primary"/>
                <span class="Badge-Text overwatch-body-small">watchlists</span>
            </div>
            <template #content>
                <div class="Popper-Rectangle" @click.stop>
                    <div class="Popper-Header">
                        <span class="Badge-Body-Title overwatch-body-large">Watchlists</span>
                    </div>
                    <div class="Popper-Body" v-if="badgeValues && badgeValues.watchlists">
                        <div class="Popper-Badge" v-for="wl in getWatchlistNamesById" :key="wl" :value="wl">
                            <span class="Badge-Body-Description overwatch-body-med">{{wl}}</span>
                        </div>
                    </div>
                </div>
            </template>
        </RocPopper>

        <RocPopper arrow hover>
            <div class="Badge" v-if="badgeValues && badgeValues.analytics && badgeValues.analytics.length > 0" @click.stop>
                <RocIcon class="Badge-Icon" icon="search" size="sm" color="primary"/>
                <span class="Badge-Text overwatch-body-small">analytics</span>
            </div>
            <template #content>
                <div class="Popper-Rectangle" @click.stop>
                    <div class="Popper-Header">
                        <span class="Badge-Body-Title overwatch-body-large">Analytics</span>
                    </div>
                    <div class="Popper-Body" v-if="badgeValues && badgeValues.analytics">
                        <div class="Popper-Badge" v-for="an in badgeValues.analytics" :key="an" :value="an">
                            <span class="Badge-Body-Description overwatch-body-med">{{an.toLowerCase()}}</span>
                        </div>
                    </div>
                </div>
            </template>
        </RocPopper>

        <RocPopper arrow hover>
            <div class="Badge" v-if="adjudicationSelected" @click.stop>
                <RocIcon class="Badge-Icon" icon="adjudication" size="sm" color="primary"/>
                <span class="Badge-Text overwatch-body-small">adjudication</span>
            </div>
            <template #content>
                <div class="Popper-Rectangle" @click.stop>
                    <div class="Popper-Header">
                        <span class="Badge-Body-Title overwatch-body-large">Adjudication</span>
                    </div>
                    <div class="Popper-Body" v-if="badgeValues && badgeValues.adjudication">
                        <div v-if="badgeValues.adjudication[0].active"
                        class="Popper-Badge">
                            <span class="Badge-Body-Description overwatch-body-med">
                                None
                            </span>
                        </div>
                        <div v-if="badgeValues.adjudication[1].active"
                        style="background-color: var(--overwatch-success);" class="Popper-Badge">
                            <span style="color: var(--overwatch-neutral-100)" class="Badge-Body-Description overwatch-body-med">
                                Confirmed
                            </span>
                        </div>
                        <div v-if="badgeValues.adjudication[2].active"
                        style="background-color: var(--overwatch-error)" class="Popper-Badge">
                            <span class="Badge-Body-Description overwatch-body-med">
                                Rejected
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </RocPopper>

        <RocPopper>
            <div class="Badge" v-if="badgeValues && badgeValues.matchesOnly" @click.stop>
                <RocIcon class="Badge-Icon" icon="match" size="sm" color="primary"/>
                <span class="Badge-Text overwatch-body-small">matches only</span>
            </div>
        </RocPopper>

        <BaseDialog :show="showExpandedImg" @close="showExpandedImg=false;">
            <auth-img :src='badgeValues.faceImage' style='max-width: auto; height: auto'></auth-img>
        </BaseDialog>

</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import Popper from "vue3-popper";
import RocPopper from '../ui/RocPopper.vue';
import { useStore } from "vuex";
import BaseDialog from "../ui/BaseDialog.vue";
import {dateRangeDisplayCalcHelper} from '@/js/dateRangeHelper.js';
import RocIcon from "@/components/ui/RocIcon.vue";

export default {
    name: 'SavedViewBadges',
    props: ['savedView', 'filter', 'popupType', 'mission', 'clusterFilterMode'],
    components: {
    Popper,
    RocPopper,
    BaseDialog,
    RocIcon
},
    setup(props, context) {
        const store = useStore();
        const showExpandedImg = ref(false);
        const badgeValues = computed(() =>{
            if(props.popupType === 'filterUpdate'){
                return props.filter
            }
            else{
                return props.savedView
            }
        })

        const closePopper = ref(true);

        const dateRangeDisplayText = computed(() => {
            let dateRangeDisplayPart1 = "";
            let dateRangeDisplayPart2 = "";

            if (badgeValues.value && badgeValues.value.dateRange && Array.isArray(badgeValues.value.dateRange) && badgeValues.value.dateRange.length) {
                let x = dateRangeDisplayCalcHelper(badgeValues.value.dateRange);

                //x returns a date range with a hyphen in the midddle
                if(x.includes("-")){
                    let split = x.split("-");
                    dateRangeDisplayPart1 = `${split[0] + "-"}`;
                    dateRangeDisplayPart2 = `${split[1]}`;
                }
            }

            let returnObject = {
                part1: dateRangeDisplayPart1,
                part2: dateRangeDisplayPart2
            }

            return returnObject;
        })

        const adjudicationSelected = computed(function () {
            if (badgeValues.value.adjudication?.some(element => element.active)) {
                return true;
            }
            return false;
        });

        const tagNames = computed(()=>{
            let tags = [];
            if(badgeValues.value && badgeValues.value.tags){
                tags = badgeValues.value.tags;
            }
            return tags;
        })

        // Sean: Adding this here because refactoring for clustering is kinda hard :)
        const clusterTagAndOr = ref('and')
        const clusterTagNames = computed(() => {
            if (badgeValues.value?.tags?.andTags) {
                clusterTagAndOr.value = 'and'
                return badgeValues.value.tags.andTags
            } else if (badgeValues.value?.tags?.orTags) {
                clusterTagAndOr.value = 'or'
                return badgeValues.value.tags.orTags;
            }
            return [];
        })

        const getWatchlistNamesById = computed(() => {
            let watchlistNames = [];

            if (badgeValues.value && badgeValues.value.watchlists) {
                //get watchlist objects that contain name and id
                let fetchWatchlist = store.getters['watchlists/watchlists'];
                fetchWatchlist.forEach((element) => {
                    badgeValues.value.watchlists.forEach((wl) => {
                        if (element._id === wl) {
                            watchlistNames.push(element.name)
                        }
                    })
                });
                //sometimes watchlists are sent to this window, but no matches found,
                //so if no matches were found in the above check, return empty array
                if(watchlistNames.length === 0){
                    return [];
                }
            }
            return watchlistNames;
        })

        // clusterFilterMode boolean to grab caseIds
        const caseCameras = ref([]);
        const mission = ref();
        onMounted(async () => {
            if (props.clusterFilterMode) {  
                mission.value = store.getters['clusters/mission'];
                var response = await store.dispatch("cases/getCamerasByCaseId", {caseId: mission.value._id});
                if (response.status === 'success') {
                    caseCameras.value = response.result;
                }
            }
        });

        const getCameraNamesById = computed(() => {
            let cameraNames = [];

            if (badgeValues.value && badgeValues.value.cams) {
                //get camera objects that contain name and GUID
                var fetchCameras;
                if (caseCameras.value.length > 0) {
                    fetchCameras = caseCameras.value;
                } else {
                    fetchCameras = store.getters["cameras/cameras"];
                }
                fetchCameras.forEach((element) => {
                    badgeValues.value.cams.forEach((cam) => {
                        if (element.GUID === cam) {
                            cameraNames.push(element.name)
                        }
                    })
                });
            }
            return cameraNames;
        })

        return {
            adjudicationSelected,
            getWatchlistNamesById,
            getCameraNamesById,
            tagNames,
            showExpandedImg,
            badgeValues,
            closePopper,
            dateRangeDisplayText,
            clusterTagAndOr,
            clusterTagNames
        }
    }
}
</script>

<style scoped lang="scss">
.Badge {
  max-width: fit-content;  
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $--spacing-s;
  margin-left: $--spacing-s;
  padding: $--spacing-s;
  border-radius: 5px;
  gap: $--spacing-base;
  border: solid 1px var(--overwatch-button-primary);
  background-color: var(--overwatch-button-primary-20);
  color: var(--overwatch-button-primary);
}

.Badge-Icon {
  margin-right: 4px;
}

.Calendar-Hover-Icon {
  width: 16px;
  height: 16px;
  margin: 4px;
  object-fit: contain;
}

.Popper-Rectangle{
  width: 340px;
  min-height:fit-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--overwatch-secondary);
}
.Popper-Header{
  width: 100%;
  height: 51px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: var(--overwatch-neutral-200);
  color: var(--overwatch-secondary);
}

.Popper-Body{
  padding: 15px 20px;
  width: 100%;
  flex-wrap: wrap;
  min-height:fit-content;
  display: flex;
  justify-content: center;
  color: var(--overwatch-neutral-100);
}

.Popper-Body-Face-Image{
  padding: 15px 20px;
  width: 100%;
  flex-wrap: wrap;
  min-height:fit-content;
}

.Popper-Badge{
  max-width: 100%;  
  height: 32px;
  margin: 4px;
  padding: 4px 12px;
  border-radius: 5px;
  background-color: var(--overwatch-neutral-400);
  color: var(--overwatch-neutral-100);
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
}

.Face-Image{
    display: flex;
    justify-content: center;
}

.Face-Icon{
    display: flex;
    justify-content: flex-end;
}
</style>