<template>
  <MDBCheckbox class="roc-checkbox" wrapperClass="roc-checkbox-wrapper"
    :class="{
      white: white
    }"
  />
</template>

<script>
import { ref } from 'vue';
import { MDBCheckbox } from 'mdb-vue-ui-kit';

export default {
  name: 'RocCheckbox', 
  props: {
    white: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MDBCheckbox
  }
}

</script>

<style lang="scss">
.roc-checkbox-wrapper {
  display: flex;
  align-items: center;
  padding: 0 !important;
  gap: var(--spacing-s) !important;
  margin: 0;
  min-height: 0;
}

.roc-checkbox {
  border-radius: 2px !important;
  border: solid 1px var(--overwatch-neutral-100) !important;
  cursor: pointer !important;
  background-color: transparent;
  margin: 0 !important;
}

.roc-checkbox:checked {
  border-color: var(--overwatch-primary) !important;
  background-color: var(--overwatch-primary) !important;
}

/* White */
.roc-checkbox.white {
  border: solid 1.5px white !important;
}

.roc-checkbox::before {
  all:revert;
}

.roc-checkbox::after {
  margin-left: 0 !important;

  width: .3rem !important;
  height: .8rem !important;

  /* Eyeballed */
  left: 55%;
  transform: rotate(45deg) translateX(-25%) translateY(25%) !important;
  border-width: 0.125rem !important;
}


.roc-checkbox:focus::after {
  border: 0;
  background: none !important;
}

.roc-checkbox + label {
  @include overwatch-body-med;
  cursor: default !important;
  user-select: none !important;
}
</style>
