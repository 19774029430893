import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

const getDefaultState = () => {
  return {
    lastFetch: null,
    cameras: [],
    encounterFilterSelectedCameras: [],
    isLiveCameraPopupVisible: false,
  }
}

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  getDefaultState
};
