<template>
  <div class='encounter-card'
    :class="{
      'card-expanded': isExpanded,
      'card-collapsed': !isExpanded,
      'encounter-card-showEncounterMatchesMode': showEncounterMatchesMode
    }"
  >
    <div class='encounter-card-row'>
      <div class="encounter-card-main-content">
        <div class="encounter-card-container" :class="{'encounter-card-container-showEncounterMatchesMode': showEncounterMatchesMode }" @click="isMobile ? alarmScoreClicked() : null">
          <!-- Face Section -->

          <!-- Unknown Face -->
          <div v-if="isFaceUnknown" class="face-section">

            <auth-img
              class="disable-select thumbnail"
              :src='probeThumbnail'
              :fade="false"
              @click="!isMobile ? toggleState() : null;"
            />

            <div class="align-self-center" style="margin-left: 10px;" :style="!hasMatchScore() ? 'width: 120px;' : 'width: 80px;'">
              <div v-if="rawObject.spoofScore > spoofThreshold" :title="'Spoof'">
                <RocIcon icon="error" size="md" color="red"/>
              </div>
            </div>

            <RocPopper arrow :popperType="'menu'" placement="right" class="unknown-face-popper">
              <RocIcon size="md" color="primary" icon="error" style="cursor: pointer;"/>
              <template #content>
                <ul>
                  <li @click="searchAction()">
                    Search This Face
                  </li>
                  <li v-if="!isViewOnlyUser" @click="enrollFace">
                    Enroll to Watchlist
                  </li>
                </ul>
              </template>
            </RocPopper>

            <span
              class="disable-select score"
              :style="`text-decoration-color: ${scoreColor}; cursor: default;`"
              v-if="hasMatchScore()"
            >
              {{ getMatchScore() }}
            </span>

            <div class='encounter-heading' style='margin-left: var(--spacing-s)'>{{ encounterTitle }}</div>
          </div>

          <!-- Face Match -->
          <div v-if="isFaceMatch" class="face-section" :class="{'face-section-showEncounterMatchesMode': showEncounterMatchesMode}">
            <auth-img class='thumbnail' :src='probeThumbnail' @click="!isMobile ? toggleState() : null;" :style="showEncounterMatchesMode ? 'cursor: default;' : null"/>
            <auth-img
              v-if="!showEncounterMatchesMode"
              :style="candidateThumbnailStyle"
              class='thumbnail'
              :src='candidateThumbnailComputed'
              @click="!isMobile && !isTaggedFace ? displayCandidateDetails() : null;"
            />
            <div class="score-watchlist-container">
              <div
                class="disable-select score"
                :style="`text-decoration-color: ${scoreColor}; cursor: ${showEncounterMatchesMode ? 'default' : 'pointer'}`"
                @click="!isTaggedFace ? alarmScoreClicked() : null"
              >
                {{ getMatchScore() }}
              </div>
              <div v-if="!showEncounterMatchesMode" class="name-watchlist-container">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-column encounter-heading">
                    <div v-if="rawObject.spoofScore > spoofThreshold" :title="'Spoof'">
                      <RocIcon icon="error" size="md" color="red"/>
                    </div>
                    <div class="name-text overwatch-body-med"
                      :title="fetchFullName().length > 20 ? fetchFullName() : null">
                      {{ fetchFullName() }}
                    </div>
                    <div
                      class="watchlist-name overwatch-body-small"
                      :style="{ 'background-color': watchlistColorComputed }"
                    >
                      <RocIcon size="sm" icon="watchlist" :color="watchlistIconColor" :style="`
                        min-width: 16px; 
                        min-height: 16px;`
                      "/>
                      <span
                        class="watchlist-name-text"
                        style="color:black"
                        :title="watchlistNameComputed.length > 24 ? watchlistNameComputed : null"
                      >
                        {{ watchlistNameComputed }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div v-if="!showEncounterMatchesMode" class="adjudication-btns" @click.stop>
                <AdjudicationButtons
                  :adjudication="adjudication.state"
                  @change="setAdjudication"
                />
              </div>

          </div>

          <!-- Object  -->
          <div v-else-if="isObject" class='face-section align-self-center' :class="{'face-section-showEncounterMatchesMode': showEncounterMatchesMode}">
            <auth-img :src='probeThumbnail' class="thumbnail" @click="!isMobile ? toggleState() : null;"/>
            <div class="align-self-center" style="max-height: 100%; margin-left: 10px; overflow-y: auto; overflow-x: hidden;" :style="!hasMatchScore() ? 'width: 120px;' : 'width: 80px;'">
              <div v-if="isLPRwithText()">
                <div class="overwatch-body-large ocr-text">{{ rawObject.md.Text }}</div>
                <div v-if="rawObject.md.LicensePlateState && rawObject.md.LicensePlateState.toLowerCase() != 'unknown'" class="overwatch-body-xsmall ocr-text">
                  {{rawObject.md.LicensePlateState}}<br>
                </div>
              </div>
            </div>

            <RocPopper arrow
              :popperType="'menu'"
              placement="right"
              class="unknown-face-popper"
              v-if="rawObject.trackFinalized && (objectSearchEnabledModalities.includes(label) || isLPRwithText())"
            >
              <RocIcon size="md" color="primary" icon="error" style="cursor: pointer;"/>
              <template #content>
                <ul>
                  <li @click="searchAction()">
                    Search This {{objectLabel}}
                  </li>
                  <li v-if="!isViewOnlyUser && isLPRwithText()" @click="enrollLPR">
                    Enroll to Watchlist
                  </li>
                </ul>
              </template>
            </RocPopper>

            <span
              class="disable-select score"
              :style="`text-decoration-color: ${scoreColor}  cursor: default;`"
              v-if="hasMatchScore()"
            >
              {{ getMatchScore() }}
            </span>

            <div class='d-flex justify-content-end align-items-center' style='margin-left: var(--spacing-s)'>
              <div class="d-flex flex-column">
                <div class="d-flex align-self-end">
                  <div class='encounter-heading' style="margin-left: 0;">{{ encounterTitle }}</div>
                </div>
              </div>
            </div>
          </div>

          <!-- License Plate Match -->
          <div v-else-if="isObjectLpMatch" class='face-section align-self-center'>
            <div class="d-flex align-items-center" style="height: 100%; width: 150px;">
              <auth-img :src='probeThumbnail' class="thumbnail" @click="!isMobile ? toggleState() : null;"/>
              <div style="max-width: 100%; position: relative; margin-left: var(--spacing-base);">
                <img src="@/assets/img/blank-plate-gray.png" style="width:100%">
                <div class="plateImage">{{rawObject.ocrPlateRead}}</div>
              </div>
            </div>
            <div class="score-watchlist-container">
              <span
                class="disable-select score"
                :style="`text-decoration-color: ${scoreColor};`"
                @click="!isTaggedFace ? alarmScoreClicked() : null"
              >
                {{ getMatchScore() }}
              </span>
              <div v-if="!showEncounterMatchesMode" class="name-watchlist-container">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-column encounter-heading">
                    <div class="name-text overwatch-body-med">
                      <div>{{ rawObject.plateNumber }}</div>
                    </div>
                    <div
                      class="watchlist-name overwatch-body-small"
                      :style="{ 'background-color': watchlistColorComputed }"
                    >
                      <RocIcon :color="watchlistIconColor" size="sm" icon="watchlist" style="min-width: 16px; min-height: 16px;"/>
                      <span
                        class="watchlist-name-text"
                        style="color: black"
                        :title="watchlistNameComputed.length > 24 ? watchlistNameComputed : null"
                      >
                        {{ watchlistNameComputed }} 
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Object Match -->
          <div v-else-if="isObjectMatch" class="face-section" :class="{'face-section-showEncounterMatchesMode': showEncounterMatchesMode}">
            <auth-img class='thumbnail' :src='probeThumbnail' @click="!isMobile ? toggleState() : null;" :style="showEncounterMatchesMode ? 'cursor: default;' : null"/>
            <auth-img
              v-if="!showEncounterMatchesMode"
              :style="candidateThumbnailStyle"
              class='thumbnail'
              :src='candidateThumbnailComputed'
              @click="!isMobile && !isTaggedFace ? displayCandidateDetails() : null;"
            />
            <div class="score-watchlist-container">
              <div
                class="disable-select score"
                :style="`text-decoration-color: ${scoreColor}; cursor: ${showEncounterMatchesMode ? 'default' : 'pointer'}`"
                @click="!isTaggedFace ? alarmScoreClicked() : null"
              >
                {{ getMatchScore() }}
              </div>
              <div v-if="!showEncounterMatchesMode" class="name-watchlist-container">
                <div class="d-flex justify-content-between">
                  <div class="d-flex flex-column encounter-heading">

                    <div class="name-text overwatch-body-med"
                      :title="fetchFullName().length > 20 ? fetchFullName() : null">
                      {{ fetchFullName() }}
                    </div>
                    <div
                      class="watchlist-name overwatch-body-small"
                      :style="{ 'background-color': watchlistColorComputed, 'color': 'black' }"
                    >
                      <RocIcon size="sm" icon="watchlist" :color="watchlistIconColor" :style="`
                        min-width: 16px; 
                        min-height: 16px;`
                      "/>
                      <span
                        class="watchlist-name-text"
                        style="color: black"
                        :title="watchlistNameComputed.length > 24 ? watchlistNameComputed : null"
                      >
                        {{ watchlistNameComputed }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Multi-Select Tag Component -->
          <div v-if="!showEncounterMatchesMode"
            class="tags-multiselect" @click.stop
          >
            <div class='encounter-heading'>
              <TagsMultiSelect
                mode="tags"
                :close-on-select="false"
                :searchable="true"
                :create-option="true"
                :selected-tags="attributes"
                :modality="objectType"
                :encounter-title="encounterTitle"
                :read-only="!rawObject.trackFinalized || isTaggedFace"
                @toggle-tags-active="tagsActive"
                @enroll-face="tagEnrollFace"
              />
            </div>
          </div>

          <div class="camera-datetime-button-section">
            <div class="camera-datetime-grid">
            <div class="grid-camera-graytext">
              <RocPopper  placement="top" :popperType="'tooltip'" hover arrow class="grid-camera-icon" :locked="true" offsetDistance="4">
                <RocIcon size="sm" color="primary" icon="cameraGroup" />
                <template #content>
                  <div v-for="group in cameraGroups" :key="group"> 
                  {{  group }}
                  </div>
                </template>
              </RocPopper>
              Camera
            </div>
            <div class="grid-camera-name" :title="cameraName">{{ computedCameraName }}</div>

            <div class="grid-datetime-graytext">Date</div>
            <div class="grid-datetime-date" v-html="formattedEncounterDate"></div>
            <div class="grid-datetime-time" v-html="formattedEncounterTime"></div>
            </div>

            <div v-if="!showEncounterMatchesMode" class="button-section" @click.stop>
              <router-link v-if="isVideoAvailable" :to="encounterDetails">
                <div class="play pointer"
                  :class="{'video-available': isVideoAvailable}"
                >
                  <RocIcon color="white" customSize="10" icon="play"/>
                </div>
              </router-link>
              <div v-else>
                <div class="play">
                  <RocIcon color="white" customSize="10" icon="play"/>
                </div>
              </div>
              <RocIcon
                v-if="!isMobile"
                class="pointer"
                color="black"
                size="sm"
                icon="downArrow"
                :flip="isExpanded"
                @click="toggleState()"
              />
            </div>
          </div>
        </div>

        <!-- this gets stuck to the top right of the card in mobile + desktop -->
        <MDBDropdown v-if="!showEncounterMatchesMode" v-model="dropOptions" align="end" class="kebab-menu-container">
          <RocDropdownToggle @click="dropOptions = !dropOptions" >
            <RocIcon class='kebab' color="black" customSize="16" icon="kebab" />
          </RocDropdownToggle>
          <RocDropdownMenu aria-labelledby='dropdownMenuButton'>
            <MDBDropdownItem href="#" @click.prevent="downloadContextImage()">
              Download Context Image
            </MDBDropdownItem>
            <MDBDropdownItem :disabled="isVideoAvailable ? false : true" href="#" @click.prevent="downloadVideo()">
              Download Video Clip
            </MDBDropdownItem>
          </RocDropdownMenu>
        </MDBDropdown>

        <!-- watchlist color rectangle -->
        <div :style='rectColor' class='encounter-type-rectangle' v-if="!showEncounterMatchesMode"></div>
      </div>
    </div>

    <div v-if="isExpanded" style="width: 100%; margin-top: 6px; overflow: hidden;">
      <track-analysis class="fade-in" :obj="rawObject" :encounterType="encounterType" />
    </div>
  </div>
</template>

<script setup>
    import { MDBDropdown, MDBDropdownItem } from "mdb-vue-ui-kit";
    import { computed, onMounted, reactive, ref, watch, defineProps, defineEmits } from "vue";
    import { useRoute } from "vue-router";
    import { useStore } from "vuex";
    import AuthImg from "@/components/ui/AuthImg";
    import trackAnalysis from "@/components/tools/trackAnalysis";
    import { get as lodashGet } from "lodash";
    import TagsMultiSelect from "@/components/ui/TagsMultiSelect.vue";
    import { union as lodashUnion } from "lodash";
    import colorHelper from "@/js/colorHelper";
    import RocIcon from "@/components/ui/RocIcon";
    import RocPopper from "@/components/ui/RocPopper";
    import AdjudicationButtons from "@/components/encounters/AdjudicationButtons";
    import RocDropdownMenu from "@/components/ui/RocDropdownMenu";
    import RocDropdownToggle from "@/components/ui/RocDropdownToggle";

    const emit = defineEmits(["show-track-details","enroll-face","alarm-clicked", "show-candidate-details", "show-filter", "enroll-lpr"]);
    const props = defineProps({
      isNew: Boolean,
      cameraName: String,
      objectType: String,
      label: String,
      time: Number,
      probeThumbnail: String,
      candidateThumbnail: String,
      candidateId: String,
      templateId: String,
      cameraGUID: String,
      similarity: Number,
      firstname: String,
      lastname: String,
      personId: String,
      facematch: {
        fullname: String
      },
      watchlistName: String,
      watchlistId: String,
      md: Object,
      notes: String,
      attributes: Array,
      rawObject: Object,
      showEncounterMatchesMode: Boolean
    });

    const isTaggedFace = ref(false);
    const isFaceMatch = computed(() => {
      return props.objectType == 'face_match' || isTaggedFace.value;
    });
    const isFaceUnknown = computed(() => {
      return props.objectType == 'face' && !isTaggedFace.value;
    });
    const isObject = computed(() => {
      return props.objectType === 'object';
    });
    const isObjectMatch = computed(() => {
      return props.objectType === 'object_match';
    });
    const isObjectLpMatch = computed(() => {
      return props.objectType === 'object_lp_match';
    });
    const encounterType = computed(() => {
      if (isFaceMatch.value || isFaceUnknown.value) {
        return 'face';
      } else if (isObject.value || isObjectLpMatch.value || isObjectMatch.value) {
        return 'object';
      } else {
        // default to face for now
        return 'face';
      }
    })
    const watchlistNameComputed = computed(() => {
      if (isTaggedFace.value) {
        const watchlistInfo = store.getters['settings/taggedFacesWatchlistConfig'];
        return watchlistInfo.watchlistName;
      } else {
        return props.watchlistName ?? "";
      }
    });

    const cameraGroups = computed(() => {
      if(props.cameraGUID)
      {
        let camera = store.getters['cameras/findByGUID'](props.cameraGUID);
        if(camera?.cameraGroups?.length > 0)
        {
          return camera?.cameraGroups ?? [];
        }
        else return ["Uncategorized Camera"]
      } else {
        console.warn("Encounter has no camera GUID");
        return  ["Uncategorized Camera"];
      }
    
    });

    const computedCameraName = computed(() => {
      const cameraName = props.cameraName;
      // a deleted camera can trigger empty camera name in encounter
      if (cameraName) {
        if (cameraName.length < 30) {
          return cameraName;
        } else {
          return cameraName.substring(0, 30) + '...';
        }
      } else {
        return "";
      }
    });

    const candidateThumbnailComputed = computed(() => {
      if (isTaggedFace.value) {
        return props.probeThumbnail;
      } else {
        return props.candidateThumbnail;
      }
    });
    const candidateThumbnailStyle = computed(() => {
      const dynamicStyle = {
        'margin-left': '4px',
        'float': 'left'
      }
      const borderEnabled = store.getters['settings/encounterCardUX'].highlightMatchCandidate;
      const defaultColor = store.getters['settings/watchlistDefaultColor'];
      if (borderEnabled && watchlistColorComputed.value && watchlistColorComputed.value != defaultColor) {
        dynamicStyle['border'] = '3px solid ' + watchlistColorComputed.value;
      }
      return dynamicStyle;
    });
    const rectColor = ref(encounterTypeRectangleColor());
    const dropOptions = ref(false);
    const store = useStore();
    const cardStyle = ref();
    const route = useRoute();
    const reactiveObj = reactive(props.rawObject);
    const encounterStyle = ref(
      `border-radius: 25px;
      line-height: 20px;
      margin-top: 4px;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 2px;
      padding-bottom: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 20.5ch;`
    );
    let autoExpanded = false;
    const isExpanded = ref(lodashGet(props.rawObject, 'UXProps.card_expanded', false));

    const colorUtil = new colorHelper();

    const windowWidth = ref(window.innerWidth);

    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });
    });

    const isMobile = computed(() => {
      /**
       * Not an ideal way to check if mobile, but that's what we're going with for most of the app.
       * Revisit later?
       */
      return windowWidth.value <= 480;
    });

    /**
     * Computed colors for different scores.
     */
    const scoreColor = computed(() => {
      const similarity = getMatchScore();
      let color = 'var(--roc-watch-similarity-90)';
      if (similarity) {
        if (similarity >= 88) {
          color = 'var(--roc-watch-similarity-90)';
        }
        else if (similarity < 88 && similarity >= 60) {
          color = 'var(--roc-watch-similarity-70)';
        } else {
          color = 'var(--roc-watch-similarity-0)';
        }
      }
      return color;
    });

    const previousEncountersBgColor = computed(() => {
      let bgStyle;
      if (props.showEncounterMatchesMode) {
        bgStyle = 'background: var(--overwatch-secondary);'
      }
      return bgStyle;
    });

    const previousEncountersDateTimeStyle = computed(() => {
      let dateStyle;
      if (props.showEncounterMatchesMode) {
        dateStyle = 'margin-left: 0rem !important; margin-right: 2rem !important';
      }
      return dateStyle;
    });

    const previousEncountersLeftRectangleStyle = computed(() => {
      let rectangleStyle;
      if (props.showEncounterMatchesMode) {
        rectangleStyle = 'width: 5px !important';
      }
      else{
        rectangleStyle = 'width: 17px'
      }
      return rectangleStyle;
    });

    const adjudication = reactive(lodashGet(props.rawObject, 'adjudication', {id: props.rawObject.id, state: 'unset'}));

    const isViewOnlyUser = computed(() => { return store.getters['auth/isViewOnly']; });

    const darkMode = computed(() => store.getters['settings/getDarkMode']);

    const watchlistColorComputed = computed(() => {
      const watchlistList = store.getters['watchlists/watchlists'];
      let watchlistColor;
      watchlistList.forEach((watchlist) => {
        if(watchlist._id == props.watchlistId) {
          watchlistColor = watchlist.color;
        }
      });
      return watchlistColor;
    });
 
    const watchlistIconColor = computed(() => {
          if(darkMode.value) return 'white';
          return 'black';
    });

    async function setAdjudication(adj) {
      const result = await store.dispatch("watchlists/setWatchlistAdjudicationState", {
        id: props.rawObject.id,
        adjudication: {
          state: adj,
        }
      });

      if(result) {
        adjudication.state = adj;
      }
    }

    function hasMatchScore() {
      if (props.similarity == null) {
        return false;
      }
      return true;
    }

    const isNewCard = computed(function() {
      if(props.isNew) {
        return true;
      } else{
        //handleToggle();
        return false;
      }
    });

    watch(isNewCard, () => {
        if(isExpanded.value && autoExpanded) {
          toggleState();
        }
    });

    function getMatchScore() {
      if (isTaggedFace.value) {
        return 100;
      }
      if (props.similarity != null) {
        return require('@/js/rocHelper').formatSimilarityScore(props.similarity);
      }
      else return null;
    }

    function isLPRwithText() {
      if(lodashGet(props.rawObject, 'md.Text', null))
      {
        return true;
      }
      return false;
    }

    const encounterTitle = computed(() => {
      let title = '';

      if (isFaceUnknown.value) {
        title = 'Unknown Face';
      }
      /*else if (isFaceMatch.value) {
        styleColor += '#EB5D43;';
      }*/
      else if (isObject.value) {
        if(props.label) {
          title = props.label.charAt(0).toUpperCase() + props.label.slice(1) + ' Detected';
          title = title.replace('_',' ');
        }
      }
      else if (isObjectLpMatch.value) {
        title = props.label;
      }

      return title;
    });

    const objectLabel = computed(() => {
      let title = '';
      if (isObject.value) {
        if(props.label) {
          title = props.label.charAt(0).toUpperCase() + props.label.slice(1);
          title = title.replace('_',' ');
        }
      }
      else if (isObjectLpMatch.value) {
        title = props.label;
      }
      return title;
    });

    function setBackgroundColorByScore() {
      const similarity = getMatchScore();
      let bgStyle = 'background: var(--roc-watch-similarity-90);';
      if (similarity) {
        if (similarity < 90 && similarity >= 70) {
          bgStyle = 'background: var(--roc-watch-similarity-70);';
        }
        else if (similarity < 70) {
          bgStyle = 'background: var(--roc-watch-similarity-0);';
        }
      }
      return bgStyle;
    }

    function bold(text) {
      /**
       * For mobile where the default font is FK Grotesk; surround text with a span to bold the text.
       */

      return "<span>" + text + "</span>";
    }

    const formattedEncounterDate = computed(() => {
      const eventDate = new Date(props.time);

      return bold(dayOfWeekAsString(eventDate.getDay())) + '&nbsp' + ('0' + Number(eventDate.getMonth() + 1)).slice(-2) + '-' + ('0' + eventDate.getDate()).slice(-2) + '-' + eventDate.getFullYear();
    });

    const formattedEncounterTime = computed(function() {
      const eventDate = new Date(props.time);

      /* Don't show milliseconds on mobile */
      var milliseconds = '.' + ('0' + eventDate.getMilliseconds()).slice(-3);
      if (windowWidth.value <= 480) {
        milliseconds = '';
      }

      const timeString = eventDate.toLocaleTimeString([], { hour12: true }).split(' ')[0];
      var hour, minute, second;
      [hour, minute, second] = timeString.split(':');

      return `${bold(hour + ':' + minute)}:${second}` + milliseconds + '&nbsp' + bold(((eventDate.getHours() >= 12) ? 'PM' : 'AM'));
    });


    function fetchFullName() {
      if (isTaggedFace.value) {
        return taggedFaceName.value;
      } else {
        return (props.firstname ?? '') + ' ' + (props.lastname ?? '');
      }
    }


    function dayOfWeekAsString(dayIndex) {
      return ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'][dayIndex] || '';
    }

    function encounterTypeRectangleColor() {
      const label = props.rawObject.label;
      let styleColor = 'background-color: ';
      if (isFaceUnknown.value) {
        styleColor += 'var(--modality-color-no-match)';
      }
      else if (isFaceMatch.value) {
        styleColor += 'var(--modality-color-face)';
      }
      else if (isObject.value) {
        if(isLPRwithText()) {
          styleColor += 'var(--modality-color-license);';
        }
        else {
          if (['car', 'truck', 'bus', 'motorcycle', 'bicycle', 'military_vehicle', 'airplane', 'boat'].includes(label)) {
            styleColor += 'var(--modality-color-vehicle);';
          }
          if (label === 'weapon') {
            styleColor += 'var(--modality-color-weapon);';
          }
          if (label === 'person') {
            styleColor += 'var(--modality-color-person);';
          }
        }
      }
      // else if (props.objectType == 'text_match') {
      //   styleColor += '#85C6AA;';
      // }
      else if (isObjectLpMatch.value) {
        styleColor += 'var(--modality-color-license);';
      }

      return styleColor;
    }

    function searchAction() {
      if (isLPRwithText()) {
        const textToSearch = lodashGet(props.rawObject, 'md.Text', '');
        store.commit("encounters/setSearchText", textToSearch);
        emit('show-filter');
        return;
      }
      store.commit('encounters/setSearchTemplateId', props.templateId);
      store.commit('encounters/setSearchImageData', props.probeThumbnail);
      store.commit('encounters/setSearchModality', 'face');
      if (isObject.value) {
        store.commit('encounters/setSearchModality', props.label);
      }
      store.commit('encounters/setShowImageSearchBox', true);

      emit('show-filter');
    }

    function enrollFace() {
      emit("enroll-face", {templateId: props.templateId, thumbnail: props.probeThumbnail, personId: props.personId});
    }

    function enrollLPR() {
      const lpText = lodashGet(props.rawObject, 'md.Text', '');
      const lpState = lodashGet(props.rawObject, 'md.LicensePlateState', 'unknown');
      emit("enroll-lpr", {
        watchlistType: 'text',
        thumbnail: props.probeThumbnail,
        text: lpText,
        state: lpState }
      );
    }

    function displayTrackAnalysis() {
      emit("show-track-details", props.personId);
      dropOptions.value = false;
    }
    function displayCandidateDetails() {
      emit("show-candidate-details", props.rawObject);
    }

    async function toggleState() {
      if (props.showEncounterMatchesMode) {
        return;
      }
      isExpanded.value = !isExpanded.value;
      store.commit("encounters/setUXProperty", {id: props.rawObject.id, prop: {card_expanded: isExpanded.value}});
    }

    const isVideoAvailable = computed(function() {
      if(props.rawObject.video) {
        return true;
      }
      else {
        return false;
      }
    });

    const encounterDetails = computed( function() {
      return { name: 'EncounterDetails', params: { encounter: btoa(JSON.stringify(props.rawObject)) } }
    });

    function alarmScoreClicked() {
      emit("alarm-clicked", props.rawObject);
    }

    const spoofThreshold = computed(function() {
      const st = store.getters["settings/spoofThreshold"];
      return st;
    });

    async function downloadVideo() {
      dropOptions.value = false;
      const mediaId = lodashGet(props.rawObject, 'video.mediaId', '');
      const cameraName = lodashGet(props.rawObject, 'cameraName', 'cameraName');

      const startMS = props.rawObject.video.startMS;

      const httpPayload = {
        url: '/rest/v1/media/video/' + lodashGet(props.rawObject, 'video.mediaId', ''),
        payload: { method: 'GET' },
      };

      const base64Image = await store.dispatch('auth/fetchBinaryResourceAsBase64', httpPayload);
      const videoBytes = base64Image;

      if(mediaId) {
        const startTime = new Date(startMS);
        let formattedTimeStr = startTime.toLocaleTimeString([], { hour12: false }) + '.' + ('0' + startTime.getMilliseconds()).slice(-3);// .toString() + ':' + ('0' + eventDate.getMilliseconds()).slice(-3) + ' ' + (eventDate.getHours() >= 12) ? 'PM' : 'AM'; //+ ':' + eventDate.getMilliseconds();
        formattedTimeStr = formattedTimeStr.replace(/:/g,'');
        const formattedDateTimeStr = startTime.getFullYear() + ('0' + Number(startTime.getMonth() + 1)).slice(-2) + ('0' + startTime.getDate()).slice(-2) + '_' + formattedTimeStr;
        const fileName = `${cameraName}_${formattedDateTimeStr}_${mediaId}.mp4`; //${props.rawObject.cameraName}_${props.rawObject}_
        const a = document.createElement('a');
        a.href = videoBytes;
        a.download = fileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again
      }
    }

    async function downloadContextImage() {
      const cameraName = lodashGet(props.rawObject, 'cameraName', 'cameraName');

      const payload = {
        objectType: props.rawObject.objectType,
        personId: props.rawObject.personId,
        contextImageMediaId: props.rawObject.contextImageMediaId
      };
      var contextImage = await store.dispatch('tools/getContextImage', payload);

      const fileName = `${cameraName}.jpg`
      const a = document.createElement('a');
      a.href = contextImage;
      a.download = fileName;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();  //afterwards we remove the element again

    }

    async function updateEncounter(tagInputValues) {
      if (isFaceMatch.value) {
        await store.dispatch('watchlists/updateWatchlistedFaceMatchAttributes', {
          id: props.rawObject.id,
          attributes: tagInputValues
        });
        updateAllFaceAttributes(tagInputValues);
      }
      else if (isFaceUnknown.value) {
        await store.dispatch('encounters/updateFaceEncounterAttributes', {
          id: props.rawObject.id,
          attributes: tagInputValues
        });
        updateAllFaceAttributes(tagInputValues);
      }
      else if (isObject.value) {
        await store.dispatch('encounters/updateObjectEncounterAttributes', {
          id: props.rawObject.id,
          attributes: tagInputValues
        });
        updateAllObjectAttributes(tagInputValues);
      }
      else if (isObjectLpMatch.value) {
        await store.dispatch('watchlists/updateWatchlistedLicensePlateMatchAttributes', {
          id: props.rawObject.id,
          attributes: tagInputValues
        });
        updateAllObjectAttributes(tagInputValues);
      } else if(isObjectMatch.value){
        await store.dispatch('watchlists/updateWatchlistedImageMatchAttributes', {
          id: props.rawObject.id,
          attributes: tagInputValues
        });
        updateAllObjectAttributes(tagInputValues);
      }
    }

    function updateAllFaceAttributes(tagInputValues) {
      store.commit('encounters/unionAllFaceAttributes', tagInputValues);
    }

    function updateAllObjectAttributes(tagInputValues) {
      store.commit('encounters/unionAllObjectAttributes', tagInputValues);
    }

    const taggingActive = ref(false);

    async function tagsActive(emitObj) {
      if(!emitObj.isActive && emitObj.tagInputValues) {
        updateEncounter(emitObj.tagInputValues);
      }
      taggingActive.value = emitObj.isActive;
    }

    const taggedFaceName = ref('');
    async function tagEnrollFace(nameObject) {
      // trigger enrollment into tagged faces watchlist and kick off historical search to generate watchlistedFaceMatches
      const watchlistInfo = store.getters['settings/taggedFacesWatchlistConfig'];
      let taggedFacesWatchlistId;
      const watchlistList = store.getters["watchlists/watchlists"];
      watchlistList.forEach((watchlist) => {
        if (watchlist.name === watchlistInfo.watchlistName) {
          taggedFacesWatchlistId = watchlist._id;
        }
      });
      if (!taggedFacesWatchlistId) {
        const createResponse = await store.dispatch('watchlists/createWatchlist', {
          info: {
            name: watchlistInfo.watchlistName,
            description: watchlistInfo.watchlistDesc,
            enabled: true,
            type: 'face',
          }
        });
        if (createResponse && createResponse.status === 'success') {
          taggedFacesWatchlistId = createResponse.watchlist._id;
          // add to filters
          store.commit("watchlists/addEncounterFilterWatchlistId", taggedFacesWatchlistId);
        }
      }
      const enrollmentPayload = {
        personId: props.personId,
        watchlistTargets: [taggedFacesWatchlistId],
        firstname: nameObject.firstname,
        lastname: nameObject.lastname,
        tag: nameObject.attributeTag
      };
      const responseData = await store.dispatch("watchlists/enrollFaceToWatchlists", enrollmentPayload);
      if (responseData && responseData.status == 'success') {
        isTaggedFace.value = true;
        taggedFaceName.value = nameObject.firstname + ' ' + nameObject.lastname;
      } else {
        console.error('Unable to enroll face to tagged_faces watchlist');
        isTaggedFace.value = false;
        taggedFaceName.value = '';
      }
    }

    const objectSearchEnabledModalities = computed(()=>{return store.getters['settings/reverseSearchObjModalities'];});

    const truthyRef = ref(true);
</script>

<style scoped lang="scss">
$mainCardHeight: 100px;

.encounter-card {
  display: flex;
  flex-direction: column;

  width: 100%;
  // min-width: 60rem;

  border-radius: 5px 0 0 5px;

  background-color: var(--overwatch-secondary);

  margin-bottom: 5px;
  min-width: 1280px;
}

.encounter-card-showEncounterMatchesMode {
  min-width: 600px;
}

.card-collapsed {
  height: $mainCardHeight;
}

.card-expanded {
  height: auto;
}

// Wrapper containing the card-container (main card area) and encounter type color rect
.encounter-card-row {
  display: flex;
  height: $mainCardHeight;
  width: 100%;
  position: relative;
}

.encounter-card-main-content{
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
}

.encounter-card-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px;
}

.encounter-card-container-showEncounterMatchesMode {
  justify-content: space-between;
}

.face-section {
  position: relative;
  height: 100%;
  width: 480px;
  flex-shrink: 0;

  padding: var(--spacing-base);

  border-radius: 5px;

  background-color: color-mix(in srgb, var(--overwatch-neutral-300), transparent 95%);

  display: flex;
  align-items: center;
}

.face-section-showEncounterMatchesMode {
  display: flex !important;
  width: unset !important;
  max-width: 25% !important;
  background: var(--overwatch-secondary);
}
.thumbnail {
  max-height: 100%;
  width: 73.5px;
  cursor: pointer;
  border-radius: 2px;
}

.tags-multiselect {
  min-width: 400px;
  flex: 1;
}

.camera-datetime-grid {
  width: 350px;
  height: 80px;
  min-height: 0;
  min-width: 0;

  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;

  margin-left: auto;
}

.camera-datetime-grid div {
  // center grid items
  display: flex;
  align-items: center;
  justify-content: center;
}

$cameraColumns: 1;
$datetimeColumns: 2;
.grid-camera-graytext {
  @include overwatch-body-small;
  color: var(--overwatch-neutral-200);
  grid-row: 1;
  grid-column: $cameraColumns;
  position: relative;
}

.grid-camera-name {
  @include overwatch-body-med;
  grid-row: 2 / span 2;
  grid-column: $cameraColumns;
  color: var(--overwatch-neutral-100);

  height: 100%;

  // Take up entire two rows
  align-self: stretch;

  align-items: start !important;
  text-align: center;
  overflow: hidden;
}

.grid-camera-icon {
  position: absolute;
  left: 30px;
  bottom: 2px;
}


.grid-datetime-graytext {
  @include overwatch-body-small;
  color: var(--overwatch-neutral-200);
  grid-row: 1;
  grid-column: $datetimeColumns;
}

.grid-datetime-date {
  @include overwatch-body-med;
  color: var(--overwatch-neutral-100);
  grid-row: 2;
  grid-column: $datetimeColumns;
}

.grid-datetime-time {
  @include overwatch-body-med;
  color: var(--overwatch-neutral-100);
  grid-row: 3;
  grid-column: $datetimeColumns;
}

.button-section {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 50px;
  height: 75px;
  justify-content: space-evenly;
  align-items: center;
}

.button-section .play {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;
  width: 24px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--overwatch-neutral-400);
}

.button-section .play.video-available {
  background-color: var(--overwatch-button-primary);
}

.pointer {
  cursor: pointer;
}

.button-section .kebab {
  transform: rotate(90deg);
}

.encounter-type-rectangle {
  height: 100%;
  width: 10px;
  border-radius: 0 5px 5px 0;
  flex-shrink: 0;
}

.encounter-heading {
  @include overwatch-body-med;
  color: var(--overwatch-neutral-100);
}

.unknown-face-heading {
  flex: 1;
  display: flex;
  justify-content: center;
}

.rotate {
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}

.score-watchlist-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name-watchlist-container {
   height: 72px;
   float: left;
   width: 165px;
   display: flex;
   align-items: center;
}

.score {
  @include overwatch-body-large;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  width: 5ch;

  text-decoration: underline dotted;
  text-decoration-thickness: 2px;

  cursor: pointer;


  margin-right: $--spacing-m;
  margin-left: $--spacing-m;
  color: var(--overwatch-neutral-200);
}

.adjudication-btns {
  margin-left: $--spacing-s;
  margin-right: $--spacing-s;
}

.camera-datetime-button-section{
  display: flex;
  align-items: center;
  justify-content: center;
}

.watchlist-name {
  padding-left: $--spacing-xs;
  padding-right: $--spacing-xs;
  padding-top: $--spacing-base;
  padding-bottom: $--spacing-base;
  border-radius: 20px;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: var(--spacing-base);
  max-width: 18ch;
}

.watchlist-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include overwatch-body-small;
}

.name-text {
  max-width: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
}

.kebab-menu-container{
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 31px;
  margin-top: 5px;
}

.kebab-menu-container .kebab{
  transform: rotate(90deg);
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}


.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: .5s;
}

.unknown-face-popper {
  @include overwatch-body-med;
  margin-left: var(--spacing-base) !important;
  margin-right: 0px !important;
}

.plateImage {
  @include overwatch-body-small;
  color: var(--overwatch-neutral-100);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ocr-text {
  overflow-wrap: break-word;
}




/* IPAD PORTRAIT */
@media only screen and (max-width: 810px) and (orientation: portrait) {

  .encounter-card {
    min-width: 0 !important;
  }

  .face-section {
    width: 320px;
    flex-basis: unset;
    flex-grow: unset;
    flex-shrink: unset;
  }
  .name-watchlist-container {
    width: 130px;
  }

  .name-watchlist-container , .encounter-heading {
    font-size: 12px;
  }
  .kebab-menu-container{
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 30px;
    margin-top: 5px;
  }

  .kebab-menu-container .kebab{
    transform: rotate(90deg);
  }
}

/* MOBILE */
@media (max-width: 480px) {
  .name-watchlist-container , .encounter-heading {
    font-size: 14px;
  }

  .encounter-card-main-content{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .encounter-card {
    min-width: 0 !important;
    width: 100% !important;
    height: fit-content !important;
    display: flex;
    flex-wrap: wrap;

    //position relative so kebab icon can relocate
    position: relative;
  }

  .encounter-card-container {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }

  .encounter-card-row {
    flex-direction: row;
    height: auto;
    width: 100%;
    position: relative;
  }

  .encounter-type-rectangle {
    width: 10px;
    height: auto;
    border-radius: 0 5px 5px 0;
  }

  .face-section {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 0;
    background-color: var(--overwatch-secondary);
  }

  .camera-datetime-grid {
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 1fr 2fr;
    align-items: center;
    justify-content: center;

    margin-left: auto;
  }

  .grid-camera-icon {
    position:unset;
    left: 0px;
    margin-right: 5px;
  }

  .thumbnail {
    height: 80px;
    width: auto;
    cursor: pointer;
  }

  .name-watchlist-container {
    height: 72px;
   float: left;
   width: 165px;
   display: flex;
   align-items: center;
   flex: 1;
   margin-right: auto;
  }

  .adjudication-btns {
    margin-right: 4px;
    margin-top: 40px;
  }

  .score {
  margin-right: auto;
  }

  .tags-multiselect {
    flex: none;
    min-width: 50%;
    margin-right: 0;
    margin-top: 5px;
    scrollbar-width: thin;
  }

  .watchlist-name {
    padding: 3px 9px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: var(--spacing-base);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 20.5ch;
  }

  .score-watchlist-container{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 150px;
  }

  .name-watchlist-container{
    width: 150px;
  }

  .camera-datetime-button-section{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .kebab-menu-container{
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 30px;
    margin-top: 5px;
  }

  .kebab-menu-container .kebab{
    transform: rotate(90deg);
  }

  .grid-camera-graytext {
    @include overwatch-body-xsmall;
  }

  .grid-camera-name {
    @include overwatch-body-small;
  }

  .grid-datetime-graytext {
    @include overwatch-body-xsmall;
  }

  .grid-datetime-date {
    @include overwatch-body-small;
  }

  .grid-datetime-time {
    @include overwatch-body-small;
  }
}

</style>
