<template>
  <div class="title">
    {{ title }}
  </div>
  <div class="mainContainer">
    <div v-if='isCameraCRUD' style="position: absolute; margin-left: 23rem; margin-top: 20rem; z-index: 2;">
      <roc-spinner/>
    </div>
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex flex-row align-items-center" style="gap: var(--spacing-s); cursor: pointer;" @click="collapseDiv = !collapseDiv">
          <div>Video Preview</div>
          <RocIcon size="sm" icon="view" color="primary" style="margin-right: var(--spacing-s);"/>
        </div>
        <RocIcon @click="collapseDiv = !collapseDiv" :rotate270="!collapseDiv" icon="downArrow" size="sm" style="margin-right: var(--spacing-m); cursor: pointer;"/>
      </div>
      <div v-if="collapseDiv" class="d-flex align-content-start" style="padding-top: var(--spacing-m);">
        <div v-if='isLoadingStreamPreview'>
          <roc-spinner/>
        </div>
        <div v-else-if="cameraPreviewSrc && !linkError" class="camera-preview-roi-container">
            <canvas v-if="isCaptureZonesActive" ref="canvasRef" style="height: 200px; margin-bottom: calc(-1 * var(--spacing-xs));"></canvas>
            <img v-else :src='cameraPreviewSrc' @error="noSource = true" style="height: 200px;"/>
            <div class="d-flex flex-column">
            <div class="capture-zones">
              <div
                class="overwatch-body-small"
                style="margin-right: var(--spacing-s)">
                Capture Zones
            </div>
              <div style="margin-right: var(--spacing-base)"
                :class="{ 'activeBubble': isCaptureZonesActive, 'disabledBubble': !isCaptureZonesActive }">
              </div>
              <div
                class="overwatch-body-small"
                :style="{color: isCaptureZonesActive ? 'var(--overwatch-neutral-100)' : 'var(--overwatch-neutral-200)'}">
                  {{ isCaptureZonesActive ? 'Active' : 'Disabled' }}
              </div>
              <div style="margin-left: auto">
                <RocIcon color="primary" size="sm" icon="outsideLink" :disabled="isMobile || !cameraUrl"
                class="capture-zones-new-window"
                @click="openCaptureZonesEditor()"/>
                <RocPopper arrow hover placement="bottom" :popperType="'tooltip'" :locked="true" class="popper">
                  <RocIcon size="sm" color="primary" icon="tooltip" />
                      <template #content>
                        <div class="d-flex flex-column" style="max-width: 250px">
                          <div>{{ captureZonesTooltipText }}</div>
                          <div
                          @click="isShowingLearnMoreText = true"
                          style="cursor: pointer; text-decoration: underline; margin-top: var(--spacing-s)">
                          Learn More
                        </div>
                        </div>
                      </template>
                </RocPopper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div>
      <div class="fieldGroups" style="gap:var(--spacing-m)">
        <div style="flex: 1;">
          <div class='inputTitle'>Camera Name</div>
          <RocInput type='text' v-model="cameraName" :placeholder="'Enter a name for the camera'" :errorMessage="cameraNameError"/>
        </div>
        <div style="flex: 1;">
          <div class='inputTitle'>Camera Group</div>
          <filterMultiSelect
            mode="tags" :close-on-select="false"
            :placeholder-text="'Select Camera Groups'" :available-items="allCameraGroups" :createTags="true"
            :currently-selected="selectedCameraGroup" @selection-changed="updateSelectedGroup" :enableClear="false"
          >
          </filterMultiSelect>
        </div>
      </div>
      <div>
        <div class='inputTitle'>Video Source</div>
        <div class='align-self-center align-items-center overwatch-body-med' style="max-width: 98%;">
          <RocInput :placeholder="'Add the source IP of the video'" type='text' v-model="cameraUrl" :errorMessage="linkError" @blur="gotBlur()">
            <slot>
              <div v-if='isLoadingStreamPreview'>
                <roc-spinner size="sm" style="margin-left: -4px;"/>
              </div>
              <div v-else>
                <RocIcon size="md" icon="cameraOn" color="primary" style="margin-right: var(--spacing-s)"/>
              </div>
            </slot>
          </RocInput>
          <!--Search By Camera Name-->
        </div>
      </div>
      <div class="fieldGroups" style="gap:var(--spacing-m); margin-bottom: var(--spacing-m);">
        <div style="flex: 1;">
          <div class='inputTitle'>Source Username</div>
          <RocInput type='text' v-model="cameraUser" :placeholder="'Enter the username for the source IP'" :errorMessage="credentialError" @blur="gotBlur()" />
        </div>
        <div style="flex: 1;">
          <div class='inputTitle'>Source Password</div>
          <RocInput :type='viewPass' :errorMessage="credentialError" v-model="cameraPass" :placeholder="'Enter the password for the source IP'" @blur="gotBlur()" >
            <template #customIcon>
              <RocIcon size="md" icon="view" color="primary" style="margin-right: var(--spacing-s); cursor: pointer;" @click="flipViewPass"/>
            </template>
          </RocInput>
        </div>
      </div>
    </div>
    <div class='d-flex flex-column justify-content-start flex-grow-1'>
      <div v-if="isAdvancedEditorDisplayed" class="d-flex flex-column flex-grow-1 settingsRectangle" :style="'flex-grow: 1; border: unset;'">
        <JsonEditor v-model="advancedEditorValue"
            @change="isButtonDisabled=false;"
            class="json-editor"
            :style="{'filter': darkMode ? 'invert(1)' : 'invert(0)'}"
            style="flex-grow: 1; width: 100%;"/>
        <div class="d-flex align-items-end justify-content-end" style="margin-top: var(--spacing-l);">
          <div class="d-flex flex-row" style="gap: var(--spacing-s);">
            <RocButton @click="cancelAdvancedSettings" type="secondary">Cancel</RocButton>
            <RocButton @click="okAdvancedSettings">OK</RocButton>
          </div>
        </div>
      </div>
      <div v-else style="width: 100%;">
          <div style="margin-bottom: var(--spacing-xs); margin-top: var(--spacing-s);" class="overwatch-title-small">Settings</div>
          <div class="settings overwatch-body-med">
            <div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="fieldTitle">Enabled</div>
                <div><RocSwitch id='enabled' :isActive="enabled" @switch-toggled="enabled = $event"/></div>
              </div>
              <div class="fieldDesc">Camera is on</div>

              <div class="d-flex align-items-center justify-content-between">
                <div class="fieldTitle">Record Video</div>
                <RocSwitch id='enableRecorder' :isActive="enableRecorder" @switch-toggled="enableRecorder = $event"/>
              </div>
              <div class="fieldDesc">Records Video for playback</div>

              <div class="d-flex align-items-center justify-content-between" style="margin-top: var(--spacing-sx);">
                <div class="fieldTitle">Face Analysis</div>
                <RocSwitch id='faceAnalytics' :isActive="faceAnalytics" @switch-toggled="faceAnalytics = $event"/>
              </div>
              <div class="fieldDesc">Detects and matches faces in real-time</div>

              <div class="d-flex align-items-center justify-content-between" style="margin-top: var(--spacing-sx);">
                <div class="fieldTitle">Use System Timestamps</div>
                <RocSwitch id='systemTimestamps' :isActive="systemTimestamps" @switch-toggled="systemTimestamps = $event"/>
              </div>
              <div class="fieldDesc">Ignore camera reported timestamps</div>

              <div>
                  <div class='d-flex justify-content-between' style="margin-top: var(--spacing-m);">
                    <div class="fieldTitle">Match Threshold Override</div>
                      <RocSwitch id='enableMatchThresholdOverride' :isActive="enableMatchThresholdOverride" @switch-toggled="enableMatchThresholdOverride = $event" style="margin-top:-4px;"/>
                    </div>
                    <RocRange v-model="matchThresholdSlider" :disabled="!enableMatchThresholdOverride"/>
                  <div class="fieldDesc">Allows for Camera Level Match Threshold for Face</div>
                </div>
            </div>
            <div style="display: flex; flex-direction: column; width: 55%">
              <div class="fieldTitle">Object Analysis</div>
              <filterMultiSelect style="margin:4px 0px 2px;"
                mode="tags" :close-on-select="false" no-results-text=""
                placeholder-text="Select Objects" :available-items="analytics"
                :currently-selected="selectedAnalytics" @selection-changed="updateSelectedAnalytics"
              />
              <div class="fieldDesc">Detects objects in real-time.</div>
              <div style="margin-top: var(--spacing-s); margin-bottom: var(--spacing-base);" class="fieldTitle">User Groups</div>
              <filterMultiSelect
                mode="tags" :close-on-select="false"
                placeholder-text="Select User Groups" :available-items="allUserGroups"
                :currently-selected="selectedUserGroupIds" @selection-changed="updateSelectedUserGroups" :enableClear="false"
              />
              <div class="fieldDesc">Assign Camera to User Group(s).</div>
              <div style="margin-top: var(--spacing-s); margin-bottom: var(--spacing-base);" class="fieldTitle">Compute Node</div>
              <RocSelect
                  :availableOptions="allVideoServiceIds"
                  :optionLabel="'text'"
                  :currently-selected="selectedVideoServiceId"
                  @selectionChanged="selectedVideoServiceId = $event"
              />
              <div class="fieldDesc">Assign Camera to Compute Node.</div>
              <div style="margin-top: var(--spacing-s);" class="fieldTitle">Coordinates
                <RocPopper
                  placement="bottom"
                  :popperType="'tooltip'"
                  hover
                  arrow
                >
                    <RocIcon size="sm" icon="tooltip" color="primary" style="margin-top: -3px; margin-left: var(--spacing-base);"/>
                    <template #content>
                      <div>Formats:</div>
                      <div>- Decimal degrees (DD): 41.40338, 2.17403</div>
                      <div>- Degrees, minutes, and seconds (DMS): 41°24'12.2"N,2°10'26.5"E</div>
                      <div>- Degrees and decimal minutes (DMM): 41 24.2028, 2 10.4418</div>
                    </template>
                  </RocPopper>
              </div>
              <RocInput placeholder="ex. 41.40338, 2.17403 " v-model="coordinates" :errorMessage="coordinateError" style="margin:4px 0px 2px;"/>
            </div>
          </div>
          <hr>
      </div>
    </div>
    <div v-if="!isAdvancedEditorDisplayed" class="d-flex align-items-end justify-content-end" style="flex-grow: 1; margin-top: var(--spacing-l);">
      <div style="margin-right: auto">
        <RocButton @click="isAdvancedEditorDisplayed=!isAdvancedEditorDisplayed" type="ghost" >
          {{ isAdvancedEditorDisplayed ? 'General' : 'Advanced'}}
        </RocButton>
      </div>
      <div class="d-flex flex-row" style="gap: var(--spacing-s);">
          <RocButton @click="close()" type="secondary">Cancel</RocButton>
          <RocButton @click="executeCRUDCommand" :disabled="isButtonDisabled">{{getButtonText()}}</RocButton>
      </div>
    </div>
    <base-dialog
      style="width: 420px;"
      :show="isShowingLearnMoreText"
      @close="isShowingLearnMoreText = false"
    >
      <div class="d-flex flex-column" style="margin-top: calc(var(--spacing-base)* -1)">
        <div class="overwatch-title-small d-flex justify-content-center">Capture Zones</div>
        <div style="margin-top: var(--spacing-s)">
          Capture zones are user defined areas that are included/excluded from analytic data processing. Use cases include honing in on areas with stable activity, excluding unwanted noise, etc.
        </div>
      </div>
    </base-dialog>
  </div>
</template>

<script>
import JsonEditor from "json-editor-vue3";
import { get as lodashGet, set as lodashSet, isEqual, merge as LodashMerge } from "lodash";
import filterMultiSelect from "@/components/ui/filterMultiSelect";
import {computed, ref, watch, reactive, onMounted, onBeforeUnmount} from "vue";
import { useStore } from "vuex";
import RocButton from "@/components/ui/RocButton.vue";
import RocSwitch from "@/components/ui/RocSwitch.vue";
import RocRange from "@/components/ui/RocRange.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocInput from "@/components/ui/RocInput.vue";
import RocSelect from "@/components/ui/RocSelect.vue";
import RocPopper from "@/components/ui/RocPopper.vue";

export default {
  name: 'CameraCRUD',
  emits: ["close"],
  components: {
    JsonEditor,
    filterMultiSelect,
    RocButton,
    RocSwitch,
    RocRange,
    RocIcon,
    RocInput,
    RocPopper,
    RocSelect
  },
  props: {
    title: String,
    mode: String,
    cameraId: String
  },
  setup(props, context) {
    const store = useStore();
    const cameraName = ref(null);
    const cameraPass = ref(null);
    const cameraUser = ref(null);
    const cameraUrl = ref(null);
    const enabled = ref(true);
    const faceAnalytics = ref(true);
    const systemTimestamps = ref(false);
    const selectedUserGroupIds = ref([]);
    const selectedVideoServiceId = ref(null);
    const enableRecorder = ref(true);
    const cameraPreview = ref(null);
    let rawPreview;// = ref(null);
    let previewUpdateRequired = false;
    const isCameraCRUD = ref(false);
    const isLoadingStreamPreview = ref(false);
    const noSource = ref(false);
    const editCam = ref(store.getters['cameras/findByGUID'](props.cameraId));
    const selectedCameraGroup = ref([]);
    const isButtonDisabled = ref(true);
    const matchThresholdSlider = ref(80);
    const enableMatchThresholdOverride = ref(false);
    const isShowingLearnMoreText = ref(false);
    const cameraNameError = ref(undefined);
    const linkError = ref(undefined);
    const credentialError = ref(undefined);
    const collapseDiv = ref(false);
    const coordinates = ref(undefined);
    const coordinateError = ref(undefined);
    const viewPass = ref('password');

    const windowWidth = ref(window.innerWidth);

    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });
    });

    const activeMission = computed(() => store.getters['cases/activeMission']);
    onMounted(async () => {
      await store.dispatch('settings/loadAllVideoServiceIds');
      if (props.mode === "add") {
        await store.dispatch('settings/loadLowestLoadVideoServiceId');
        selectedVideoServiceId.value = store.getters['settings/lowestLoadVideoServiceId'];
      }
    });

    onBeforeUnmount(() => {
      //if dialog closes, close capture zones
      closeChildWindow();
      //reset capture zones settings so it doesn't carry over to new camera dialog open
      captureZonesSettings.value = null;
    });

    //watch isMobile, and set the text of the capture zones tooltip based on the screen size
    const captureZonesTooltipText = ref(null);
    const isMobile = computed(() => {
      if(windowWidth.value <= 480){
        captureZonesTooltipText.value = 'Opening the camera in a new page is not available at this screen size. Please use a tablet or desktop device to access this feature.'
      }
      if(!cameraUrl.value || linkError.value){
        captureZonesTooltipText.value = 'Capture Zones are only available for a valid or processed camera.'
      }
      else{
        captureZonesTooltipText.value = 'Click to open the camera in a new window and edit Capture Zones.'
      }
      return windowWidth.value <= 480;
    });

    const isAdvancedEditorDisplayed = ref(false);
    const advancedEditorHeight = computed(() => {
      const rectangle = document.getElementById('settings')
      return rectangle.offsetHeight + 'px';
    });

    const childWindow = ref(null);
    const captureZonesSettings = ref(null); //capture zones settings from the popup window
    function openCaptureZonesEditor() {
      //capture zones not available for mobile or when there is no camera source
      if(isMobile.value || !cameraUrl.value || linkError.value){
        return;
      }

      //open new window w new route
      childWindow.value = window.open(`/capturezoneseditor`, 'Capture Zones', 'width=1200,height=700,left=300,top=200, resizable=yes');

      //Add an event listener to the parent window so when it closes it will close the child window
      window.addEventListener('beforeunload', closeChildWindow);

      //On logout, close the child window
      window.addEventListener('logout', closeChildWindow);

      // Listen for messages from the child window
      window.addEventListener('message', messageEventHandler);
    }

    function closeChildWindow() {
      // Check if the child window is open before trying to close it
      if (childWindow.value && !childWindow.value.closed) {
          childWindow.value.close();
          window.removeEventListener('beforeunload', closeChildWindow);
          window.removeEventListener('message', messageEventHandler);
          window.removeEventListener('logout', closeChildWindow);
      }
    }

   function messageEventHandler(event) {
      try {
        const eventObject = JSON.parse(event.data);
        if (eventObject.type === 'childReady') {
          const payload = {
            type: 'initialData',
            data: {
              cameraGuid: props.cameraId,
              cameraName: cameraName.value,
              cameraPreviewSrc: cameraPreviewSrc.value,
              cameraUrl: cameraUrl.value,
              videoServiceId: selectedVideoServiceId.value,
              firstTimeInstructions: store.getters['settings/getCaptureZonesInstructionsShown'],
              //if user has already set capture zones, send them to the child window for editing since they haven't been officially saved yet in the camera config
              captureZones: captureZonesSettings.value ? JSON.parse(JSON.stringify(captureZonesSettings.value)) : null
              }
          }
          childWindow.value.postMessage(JSON.stringify(payload));
        }
        if(eventObject.type === 'instructions'){
          store.commit("settings/setCaptureZonesInstructionsShown", true);
          store.commit('auth/setUserSettingsChanged', Date.now());
        }
        if(eventObject.type === 'captureZoneSettings'){
          captureZonesSettings.value = eventObject.data;
        }
      } catch (e) { }
    }

    async function updateROI(vsConfig) {
      return new Promise((resolve) => {
        const image = new Image(); //make image to get camera dimensions

        image.onerror = (error) => {
          console.error("Image failed to load causing ROI update issue:", error);
          resolve(null); // Resolve the promise to avoid it hanging indefinitely
        };

        image.onload = () => {
          let formattedROI = [];

          let konvaStageWidth = captureZonesSettings.value.imageData.width;
          let konvaStageHeight = captureZonesSettings.value.imageData.height;
          let cameraWidth = image.width;
          let cameraHeight = image.height;

          const scalingFactorWidth = konvaStageWidth / cameraWidth;
          const scalingFactorHeight = konvaStageHeight / cameraHeight;

          // Loop through each object in the captureZoneSettings, loop through each rect in rects, and format the x, y, width, height values
          captureZonesSettings.value?.coordinates.forEach(zone => {
            zone.rects.forEach(rect => {
              // Scale coordinates and dimensions from canvas to new rectangle size
              //Ex: If rect in canvas was Width = 500, Stage Width = 2000, Camera Image Width = 1000, then scaledWidth = 250
              let scaledX = rect.x / scalingFactorWidth;
              let scaledY = rect.y / scalingFactorHeight;
              let scaledWidth = rect.width / scalingFactorWidth;
              let scaledHeight = rect.height / scalingFactorHeight;

              //Divide by camera width/height to account for resolution change in the SDK (this is advised by SDK docs)
              //SDK Docs:
              // x: Rect X Coordinate (scaledX)/Camera Width (px)
              // y: Rect Y Coordinate (scaledY)/Camera Height (px)
              // height: Rect height/Camera Height
              // width: Rect Width/Camera Width
              let x = scaledX / cameraWidth;
              let y = scaledY / cameraHeight;
              let width = scaledWidth / cameraWidth;
              let height = scaledHeight / cameraHeight;

              formattedROI.push(`${x},${y},${width},${height}`);
            });
          });

          //update camera preview canvas with new capture zones
          drawCanvas();

          //get roi object from advanced editor value if in edit mode, else, get it from the vsConfig since we are adding a cam
          let roi = lodashGet(advancedEditorValue.value, 'vs_config.roc.tracker', null) ?? lodashGet(vsConfig, 'roc.tracker', null);

          //set enabled/disabled
          lodashSet(roi, 'roi.enabled', captureZonesSettings.value.enabled);

          //if no zones are set, set the roi object to null
          if (formattedROI.length === 0) {
            lodashSet(roi, 'roi.include-roi', []);
            lodashSet(roi, 'roi.exclude-roi', []);
          }
          //if zone is set to include, set the include array in the camera config, but leave exclude blank
          else if (formattedROI.length > 0 && captureZonesSettings.value.include === true) {
            //set include array in camera config
            lodashSet(roi, 'roi.include-roi', formattedROI);
            lodashSet(roi, 'roi.exclude-roi', []);
          }
          //if zone is set to exclude, set the exclude array in the camera config, but leave include blank
          else if (formattedROI.length > 0 && captureZonesSettings.value.include === false) {
            //set exclude array in camera config
            lodashSet(roi, 'roi.exclude-roi', formattedROI);
            lodashSet(roi, 'roi.include-roi', []);
          }

          //set the roi object in the advanced editor value
          if(!vsConfig){
            lodashSet(advancedEditorValue.value, 'vs_config.roc.tracker', roi);
            resolve(null); //resolve, but no return value since we set advancedEditorValue directly
          }
          else{
            resolve(roi); //resolve and return value to update vsConfig
          }
        }
        image.src = cameraPreviewSrc.value; //once this image is fully loaded, the image.onload() function will be called
      });
    }

    //computed prop that determines if capture zones are active
    const isCaptureZonesActive = computed(() => {
      const roi = lodashGet(advancedEditorValue.value, 'vs_config.roc.tracker.roi', null);

      //if capture zones is enabled/disabled from the popup, that takes priority of active/inactive. otherwise, use the value from the advanced editor
      return  captureZonesSettings.value?.enabled ?? (roi?.enabled === true);
    });

    const editCamCopy = computed(() => {
        const editCamContent = editCam.value ? JSON.parse(JSON.stringify(editCam.value)) : {};
        // We don't want users to mess with these fields.
        // Hidden from editor for safety.
        const hiddenFields = ['previewImage', '_id', 'GUID',
          'state', 'label', 'createdUTC', 'name', '__v', 'url', 'value', 'userGroups'];

        for (let field of hiddenFields) {
          delete editCamContent[field];
        }

        return editCamContent;
    });
    const advancedEditorValue = ref(editCamCopy.value);

    const analytics = ref([
      'Pedestrian',
      'Gun',
      'License Plate',
      'Vehicle',
      'OCR In-The-Wild',
    ]);

    const originalSelectedAnalytics = ref([]);
    const originalSelectedAlgs = ref([]);

    const selectedAnalytics = ref([]);
    function updateSelectedAnalytics(selectedReps) {
      selectedAnalytics.value = selectedReps.value;
	    isButtonDisabled.value = false;
    }

    const analyticsMap = {
      'Pedestrian': 'pedestrian',
      'Gun': 'gun',
      'License Plate': 'lpr',
      'Vehicle': 'vehicle',
      'OCR In-The-Wild': 'ocr',
    }

    cameraPreview.value = require('@/assets/camera-settings-background.png');
    const cameraPreviewSrc = computed(function() {
      return (cameraPreview.value ? cameraPreview.value : cameraPreview.src);
    });

    const canvasRef = ref(null);
    // Get capture zone values to draw a rectangle on the canvas
    function drawCanvas() {
        const image = new Image();
        image.onload = () => {
          if (isCaptureZonesActive.value === true && canvasRef.value !== null) {
          const canvas = canvasRef.value;
          const ctx = canvas?.getContext('2d');
          const imageData = lodashGet(captureZonesSettings.value, 'imageData', null) ?? lodashGet(advancedEditorValue.value, 'captureZones.imageData', null);
          const roi = lodashGet(captureZonesSettings.value, 'coordinates', null) ?? lodashGet(advancedEditorValue.value, 'captureZones.coordinates', null);
          const color = lodashGet(captureZonesSettings.value, 'color', null) ?? lodashGet(advancedEditorValue.value, 'captureZones.color', 'rgb(0,255,240)');
          const opacity = lodashGet(captureZonesSettings.value, 'opacity', null) ?? lodashGet(advancedEditorValue.value, 'captureZones.opacity', 0.75);

          canvas.width = image.width;
          canvas.height = image.height;

          const scalingFactorWidth = imageData.width / canvas.width;
          const scalingFactorHeight = imageData.height / canvas.height;

          ctx.globalAlpha = 1;
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          if (roi) {
            roi.forEach(zone => {
              zone.rects.forEach(rect => {
                const { x, y, width, height } = rect;
                const scaledX = x / scalingFactorWidth;
                const scaledY = y / scalingFactorHeight;
                const scaledWidth = width / scalingFactorWidth;
                const scaledHeight = height / scalingFactorHeight;
                ctx.beginPath();
                ctx.rect(scaledX, scaledY, scaledWidth, scaledHeight);
                ctx.lineWidth = 0;
                ctx.fillStyle = color;
                ctx.globalAlpha = opacity;
                ctx.fill();
              });
            });
          }
        };
      };
        image.src = cameraPreviewSrc.value;
    }

    //when page is loaded and camera preview is shown, drawCanvas
    watch(canvasRef, () => {
         drawCanvas();
    });

    if(props.mode === 'edit') {
      if(editCam.value) {
        cameraName.value = editCam.value.name;
        cameraUrl.value = editCam.value.url;
        enabled.value = editCam.value.enabled;
        selectedUserGroupIds.value = editCam.value.userGroups;
        selectedCameraGroup.value = editCam.value.cameraGroups;
        selectedVideoServiceId.value = editCam.value.videoServiceId;
        cameraUser.value = editCam.value.user;
        systemTimestamps.value = editCam.value.systemTimestamps;

        coordinates.value = editCam.value.location ?  editCam.value.location?.latitude + ',' + editCam.value.location?.latitude : '';

        const allAnalyticIds = getAllAID(editCam.value);
        if (allAnalyticIds) {
          setAnalyticsByAID(allAnalyticIds);
        } else {
          console.log('No analytics backend found for camera', editCam.value);
          faceAnalytics.value = false;
          selectedAnalytics.value = [];
        }

        setRecorderComponentsByCamera(editCam.value);

        if(editCam.value.previewImage) {
          cameraPreview.value = editCam.value.previewImage;
        }
        else
        {
          const connectionPayload = {url: cameraUrl.value, username: cameraUser.value, password: cameraUser.password, systemTimestamps: systemTimestamps.value, videoServiceId: selectedVideoServiceId.value};
          getCameraStreamPreview(connectionPayload);
        }

        if(editCam.value.matchThresholdOverride) {
          enableMatchThresholdOverride.value = editCam.value.matchThresholdOverride.enabled;
          matchThresholdSlider.value = editCam.value.matchThresholdOverride.threshold * 100;
        }
      }
    }

    function setRecorderComponentsByCamera(camera) {
      try {
        const persistentRecorderConfig = lodashGet(camera, 'vs_config.roc.tracker.video-recordings.persistent-recording', null);
        if(persistentRecorderConfig) {
          if(persistentRecorderConfig.enabled) {
            enableRecorder.value = true;
          }
          else {
            enableRecorder.value = false;
          }
        }
      }

      catch(err) {
        console.log('Error setting recorder components by camera: ', err);
        enableRecorder.value = false;
      }
    }

    function persistentRecorderEnabled(camera) {
      return lodashGet(camera, 'vs_config.roc.tracker.video-recordings.persistent-recording.enabled', false);
    }

    function cameraHasFaceAnalytics(camera) {
      const analyticsId = getCameraAlgorithmId(camera);
      if(analyticsId) {
        return AIDHasFaceAnalytics(analyticsId);
      }
      return false;
    }

    function getCameraAlgorithmId(camera) {
      const analytics_backends = getAnalyticsBackend(camera); //lodashGet(camera, 'vs_config.roc.tracker.analytics-backends[0]', []);
      if(analytics_backends) {
        return analytics_backends["algorithm-id"];
      }

      return null
    }

    function getAnalyticsBackend(camera) {
      return lodashGet(camera, 'vs_config.roc.tracker.analytics-backends[0]', null);
    }

    function getRecorderConfig(camera) {
      return lodashGet(camera, 'vs_config.roc.tracker.video-recordings', null);
    }

    function setRecorderProperties(recorderConfig, enabled = false) {
      lodashSet(recorderConfig, 'enabled', enabled);
      lodashSet(recorderConfig, 'persistent-recording.enabled', enabled);
    }

    function setRecorderConfig(camera, recorderConfig) {
      lodashSet(camera, 'vs_config.roc.tracker.video-recordings', recorderConfig);
    }

    function flipViewPass() {
      viewPass.value == 'password' ? viewPass.value = 'text' : viewPass.value = 'password';
    }

    function setCameraAlgorithmId(camera, analyticsId) {
      const result = lodashSet(camera, 'vs_config.roc.tracker.analytics-backends[0].algorithm-id', analyticsId);
    }

    function setCameraAnalyticsBackend(camera, analyticsBackend) {
      //const result = lodashSet(camera, 'vs_config.roc.tracker.analytics-backends', analyticsBackend);
      return analyticsBackend;
    }

    function arrayIncludesArrayOfValues(analyticsId, analyticsDefinition) {
      try {
        if(analyticsId.some(id => analyticsDefinition.includes(id))) {
          return true;
        }
      }

      catch(err) {
        console.log(err);
      }

      return false;
    }

    function AIDHasFaceAnalytics(analyticsId) {
      if(arrayIncludesArrayOfValues(analyticsId,["ROC_FACE_DETECTION","ROC_FACE_LEGACY_DETECTION"])) {
        return true;
      }
      return false;
    }

    function setAnalyticsByAID(analyticsIds) {
      faceAnalytics.value = analyticsIds.includes('ROC_FACE_DETECTION') ? true : false;

      selectedAnalytics.value = [
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_LICENSE_PLATE_DETECTION']) ? ['License Plate'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_TEXT_DETECTION']) ? ['OCR In-The-Wild'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_VEHICLE_DETECTION']) ? ['Vehicle'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_PERSON_DETECTION']) ? ['Pedestrian'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_GUN_DETECTION']) ? ['Gun'] : [],
      ]

      //these are helper values for analytics handling/editing in UI and Advanced Editor
      originalSelectedAnalytics.value = selectedAnalytics.value;
      originalSelectedAnalytics.value.push(faceAnalytics.value ? 'Face' : [])

      originalSelectedAlgs.value = [
        ...analyticsIds.includes('ROC_FACE_DETECTION') ? ['ROC_FACE_DETECTION'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_LICENSE_PLATE_DETECTION']) ? ['ROC_LICENSE_PLATE_DETECTION'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_TEXT_DETECTION']) ? ['ROC_TEXT_DETECTION'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_VEHICLE_DETECTION']) ? ['ROC_VEHICLE_DETECTION'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_PERSON_DETECTION']) ? ['ROC_PERSON_DETECTION'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_GUN_DETECTION']) ? ['ROC_GUN_DETECTION'] : [],
      ]
    }

    function getAllAID(camera) {
      const backends = lodashGet(camera, 'vs_config.roc.tracker.analytics-backends', null);
      const analyticIds = [];
      for (let backend of backends) {
        analyticIds.push( ...backend['algorithm-id'] );
      }
      return analyticIds;
    }

    function getButtonText() {
      let buttonCaption;

      if(props.mode === 'add') {
        buttonCaption = 'Save';
      }
      else if(props.mode === 'edit') {
        buttonCaption = 'Update';
      }

      return buttonCaption;
    }

    function close(){
      context.emit("close");
    }

    async function executeCRUDCommand() {
      if(props.mode === 'add') {
        const newDoc = await getCamera();
        if(Object.keys(newDoc).length !== 0) {
          isButtonDisabled.value = true;
          isCameraCRUD.value = true;
          const result = await store.dispatch("cameras/addCamera", newDoc);
          store.commit('cameras/setEncounterFilterSelectedCameras', []);
          isCameraCRUD.value = false;
          isButtonDisabled.value = false;
          if(result === 0) {
            close();
          }
        }
      }
      else if(props.mode === 'edit') {
        let payload = { GUID: props.cameraId };

        let advancedDelta = getAdvancedSettingChanges();
        let normalDelta = await getCameraChanges();

        //Evan - used to be {...advancedDelta, ...normalDelta} - this prevented new changes from being applied
        //because the last param in merging objects using the spread operator gets priority over which value goes into final object
        //so this would have the original value overwrite any advanced editor values, and advanced editor should have priority

        payload.updates = {};
        LodashMerge(payload.updates, normalDelta, advancedDelta);

        //merge doesn't cover condition where arrays get blanked out, so set include/exclude to [] capture zones rects get blanked out
        if(captureZonesSettings.value && captureZonesSettings.value.coordinates.length === 0){
            lodashSet(payload.updates, 'vs_config.roc.tracker.roi.exclude-roi', []);
            lodashSet(payload.updates, 'vs_config.roc.tracker.roi.include-roi', []);
          }

        isButtonDisabled.value = true;
        isCameraCRUD.value = true;
        const result = await store.dispatch("cameras/updateCameraByUUID", payload);
        store.commit('cameras/setEncounterFilterSelectedCameras', []);
        isCameraCRUD.value = false;
        isButtonDisabled.value = false;

        if(result === 0) {
          close();
        }
      }
    }

    function cancelAdvancedSettings() {
      /**
       * Reset the advanced settings form and toggle Displayed.
       */
      advancedEditorValue.value = editCamCopy.value;
      isAdvancedEditorDisplayed.value = !isAdvancedEditorDisplayed.value;
    }

    function okAdvancedSettings() {
      /**
       * Maintain the form.
       */
      isAdvancedEditorDisplayed.value = !isAdvancedEditorDisplayed.value;
    }

    function getAdvancedSettingChanges() {
      /**
       * Obtain setting changes made in advanced setting editor.
       * Recursively compare key values of advancedEditorValue with editCam.
       */
      let updateDoc = {};

      for (let [key, value] of Object.entries(advancedEditorValue.value)) {
        if ((editCam.value[key] === null) || (editCam.value[key] === undefined)) {
          // new key, add full entry to updatedDoc
          updateDoc[key] = advancedEditorValue.value[key];
        } else {
          getAdvancedSettingChangesHelper(updateDoc, key, advancedEditorValue.value[key], editCam.value[key]);
        }
      }
      return updateDoc;
    }

    function getAdvancedSettingChangesHelper(update, key, advancedEditorVal, editCamVal) {
      /**
       * Recursion helper.
       *
       * @param {Object} update             - updateDoc
       * @param {String} key                - Current key
       * @param          advancedEditorVal  - advancedEditorValue object value for given key
       * @param          editCamVal         - editCam object value for given key
       *
       * @return {Boolean}      - Return whether children have at least one delta.
       */

       // This fixes a bug in the advanced camera editor where sometimes editing the vs_config object removes the tracker object
       // If the array contains objects, continue recursion as normal. If the array contains values, add it to updateDoc.
       if(Array.isArray(advancedEditorVal)) {
        for(let i = 0; i < advancedEditorVal.length; i++) {
          if(typeof(advancedEditorVal[i] !== typeof({}))) {
            if (!isEqual(advancedEditorVal, editCamVal)) {
              update[key] = advancedEditorVal;
              return true;
            }
            else {
              return false;
            }
          }
        }
      }

      if (typeof(advancedEditorVal) !== typeof({})) {
        if (!isEqual(advancedEditorVal, editCamVal)) {
          update[key] = advancedEditorVal;
          return true;
        } else {
          return false;
        }
      }

      update[key] = {};

      let hasDelta = [];
      for (let [nextKey, nextValue] of Object.entries(advancedEditorVal)) {
        let result = getAdvancedSettingChangesHelper(update[key], nextKey, advancedEditorVal[nextKey], editCamVal[nextKey]);
        hasDelta.push(result);
      }
      if (hasDelta.some(e=>e)) {    // If at least one delta (one true) among children
        return true;
      } else {
        delete update[key];
        return false;               // If not at least one delta, delete key
      }
    }

    const selectedAnalyticsDiff = computed(() => {
      let difference = selectedAnalytics.value.filter(function (item) {
        return !originalSelectedAnalytics.value.includes(item);
      })
      if(faceAnalytics.value && !originalSelectedAnalytics.value.includes('Face')){
        difference.push('Face');
      }
      return difference;
    })

    async function getCameraChanges() {
      let updateDoc = {};

      if(cameraName.value !== editCam.value.name) {
        updateDoc.name = cameraName.value;
      }
      if(cameraUrl.value !== editCam.value.url) {
        updateDoc.url = cameraUrl.value;
      }
      if(enabled.value !== editCam.value.enabled) {
        updateDoc.enabled = enabled.value;
      }
      if(cameraUser.value !== editCam.value.user)
      {
        updateDoc.user = cameraUser.value;
      }
      if(cameraPass.value?.length > 0)
      {
        updateDoc.password = cameraPass.value;
      }
      updateDoc.systemTimestamps = systemTimestamps.value;
      updateDoc.location = getCameraCoordinates();
      updateDoc.userGroups = selectedUserGroupIds.value;
      updateDoc.cameraGroups = selectedCameraGroup.value;
      updateDoc.videoServiceId = selectedVideoServiceId.value;

      /*
      Evan - update `analytics-backend`in advanced editor with values from UI.
      If there were already analytics saved from existing cam settings,
      leave them alone so custom settings for these analytics that have already been applied can stay.
      */

      let advancedArray = [];

      let selectedAlgs =
        [
          ...faceAnalytics.value ? ['ROC_FACE_DETECTION'] : [],
          ...selectedAnalytics.value.includes('License Plate') ? ['ROC_LICENSE_PLATE_DETECTION'] : [],
          ...selectedAnalytics.value.includes('OCR In-The-Wild') ? ['ROC_TEXT_DETECTION'] : [],
          ...selectedAnalytics.value.includes('Vehicle') ? ['ROC_VEHICLE_DETECTION'] : [],
          ...selectedAnalytics.value.includes('Pedestrian') ? ['ROC_PERSON_DETECTION'] : [],
          ...selectedAnalytics.value.includes('Gun') ? ['ROC_GUN_DETECTION'] : [],
        ]

      //filter `editCam` for values that were previously saved so custom settings do not get overwritten during edit
      let trackerVar = lodashGet(editCam.value, 'vs_config.roc.tracker.analytics-backends', null);
      for (let i = 0; i < trackerVar.length; i++) {
        //if currently selected analytics and analytics selected from previous session match, add them to `advancedArray`
        if(trackerVar[i]['algorithm-id'].some(id => originalSelectedAlgs.value.includes(id) && selectedAlgs.includes(id))) {
          //push original value instead of default algorithm modality
          advancedArray.push(trackerVar[i])
        }
      }

      //if there analytics added that weren't here in the previous session, pull the default algorithm into `analytics-backend`
      if (selectedAnalyticsDiff.value && selectedAnalyticsDiff.value.length > 0) {
        let x = setCameraAnalyticsBackend(advancedArray,
          [
            ...selectedAnalyticsDiff.value.includes('Face') ? [await getDefaultAlgorithmIdByModality('face')] : [],
            ...selectedAnalyticsDiff.value.includes('License Plate') ? [await getDefaultAlgorithmIdByModality('lpr')] : [],
            ...selectedAnalyticsDiff.value.includes('OCR In-The-Wild') ? [await getDefaultAlgorithmIdByModality('ocr')] : [],
            ...selectedAnalyticsDiff.value.includes('Vehicle') ? [await getDefaultAlgorithmIdByModality('vehicle')] : [],
            ...selectedAnalyticsDiff.value.includes('Pedestrian') ? [await getDefaultAlgorithmIdByModality('pedestrian')] : [],
            ...selectedAnalyticsDiff.value.includes('Gun') ? [await getDefaultAlgorithmIdByModality('gun')] : [],
          ]
        );

        for (let i = 0; i < x.length; i++) {
          advancedArray.push(x[i])
        }
      }

      //set the advanced editor value with values collected from above
      lodashSet(advancedEditorValue.value, 'vs_config.roc.tracker.analytics-backends', advancedArray);

      if(enableRecorder.value !== persistentRecorderEnabled(editCam.value)) {
        const result = await store.dispatch("cameras/getVSConfigDefaultByModality", "face");
        if(result.status === 'success') {
          const defaultConfig = result.value;
          let emulateCamera = {};
          lodashSet(emulateCamera, 'vs_config', result.value);
          const recorderConfig = getRecorderConfig(emulateCamera);
          if(recorderConfig) {
            if(enableRecorder.value) {
              setRecorderProperties(recorderConfig, true);
              setRecorderConfig(updateDoc, recorderConfig);
            }
            else {
              setRecorderProperties(recorderConfig, false);
              setRecorderConfig(updateDoc, recorderConfig);
            }
          }
        }
      }

      if(enableMatchThresholdOverride.value !== editCam.value.matchThresholdOverride.enabled || matchThresholdSlider.value !== (editCam.value.matchThresholdOverride.threshold * 100)) {
        updateDoc.matchThresholdOverride = {};
        updateDoc.matchThresholdOverride.enabled = enableMatchThresholdOverride.value;
        updateDoc.matchThresholdOverride.threshold = matchThresholdSlider.value / 100;
      }

      //set roi values from capture zones
      if(captureZonesSettings.value) {
        await updateROI();
        updateDoc.captureZones = captureZonesSettings.value;
      }

      return updateDoc;
    }

    async function getDefaultAlgorithmIdByModality(modality) {
      try {
        const result = await store.dispatch("cameras/getVSConfigDefaultByModality", modality);
        if(result.status === 'success') {
          if(result.value.roc) {
            let emulateCamera = {};
            lodashSet(emulateCamera, 'vs_config.roc', result.value.roc)
            return getAnalyticsBackend(emulateCamera);
          }
          else {
            return null;
          }
        }
      }

      catch(err) {
        console.log(err);
      }

      return null;
    }

    async function getCamera() {
      let newDoc = {};
      let vsConfig;
      newDoc.name = cameraName.value;
      newDoc.url = cameraUrl.value;
      newDoc.enabled = enabled.value;
      newDoc.userGroups = selectedUserGroupIds.value;
      newDoc.cameraGroups = selectedCameraGroup.value;
      newDoc.videoServiceId = selectedVideoServiceId.value;
      newDoc.user = cameraUser.value;
      newDoc.password = cameraPass.value;
      newDoc.systemTimestamps = systemTimestamps.value;
      newDoc.location = getCameraCoordinates();
      newDoc.caseId = activeMission.value._id;

      /**
       * Sean: We really only need one vs_config but multiple backends, but I added multiple vs_config presets in the
       * database since that was the pattern before. We'll only use one vs_config, but extract the multiple backends from
       * the other configs
       */

      if(faceAnalytics.value) {
        const result = await store.dispatch("cameras/getVSConfigDefaultByModality", "face");
        if(result.status === 'success') {
          vsConfig = result.value;
        }
      }

      for (let analytic of selectedAnalytics.value) {
        if (!vsConfig) {
          const result = await store.dispatch("cameras/getVSConfigDefaultByModality", analyticsMap[analytic]);
          if(result.status === 'success') {
            vsConfig = result.value;
          }
        } else {
          const result = await store.dispatch("cameras/getVSConfigDefaultByModality", analyticsMap[analytic]);
          // get just the analytics backend of this result.
          const analyticsBackend = result.value.roc.tracker['analytics-backends'][0];

          vsConfig.roc.tracker['analytics-backends'].push(
            analyticsBackend
          );
        }
      }

      if(enableRecorder.value == false) {
        vsConfig.roc.tracker["video-recordings"].enabled = false;
        vsConfig.roc.tracker["video-recordings"]["persistent-recording"].enabled = false;
      }

      if(captureZonesSettings.value){
        const roi = await updateROI(vsConfig); //update vsConfig with capture zones roi values
        lodashSet(vsConfig, 'roc.tracker', roi);
        newDoc.captureZones = captureZonesSettings.value;
      }

      newDoc.vs_config = vsConfig;

      newDoc.previewImage = rawPreview;
      newDoc.matchThresholdOverride = {
        enabled: enableMatchThresholdOverride.value,
        threshold: (matchThresholdSlider.value / 100),
      }

      return newDoc;
    }

    async function gotBlur() {
      if(previewUpdateRequired) {
        const connectionPayload = {url: cameraUrl.value, username: cameraUser.value, password: cameraPass.value, systemTimestamps: systemTimestamps.value, videoServiceId: selectedVideoServiceId.value};
        await getCameraStreamPreview(connectionPayload);
      }
    }

    async function getCameraStreamPreview(connectionParams) {
      isLoadingStreamPreview.value = true;
      try {
        const connectionTestResults = await store.dispatch("cameras/testCameraConnection", connectionParams);
        if (connectionTestResults.status === 'success') {
          rawPreview = connectionTestResults.preview;
          cameraPreview.value = 'data:image/jpg;base64, ' + connectionTestResults.preview;
          credentialError.value = undefined;
          linkError.value = undefined;
          noSource.value = false;
        }
        else
        {
          cameraPreview.value = require('@/assets/no-camera-preview.jpg');
          if(connectionTestResults.message.includes('Incorrect username or password')) {
            credentialError.value = connectionTestResults.message;
            console.error(`Camera credentials are incorrect: ${JSON.stringify(connectionParams)}`);
          } else {
            linkError.value = connectionTestResults.message;
            console.error(`No camera available: ${JSON.stringify(connectionParams)}`);
          }
        }
        previewUpdateRequired = false;
      } catch (error) {
        error.value = error.message || 'Something went wrong!';
      }
      isLoadingStreamPreview.value = false;
    }

    async function checkFormState() {
      if(props.mode === 'edit') {
        const updateDoc = await getCameraChanges();
        if (Object.keys(updateDoc).length !== 0) {
          isButtonDisabled.value = false;
        } else {
          isButtonDisabled.value = true;
        }
      } else {
        isButtonDisabled.value = false;
      }
    }

    watch([
      enabled, cameraName, cameraUrl, faceAnalytics, systemTimestamps, selectedAnalytics,
      enableRecorder, enableMatchThresholdOverride, matchThresholdSlider, selectedUserGroupIds, selectedCameraGroup, cameraPass, cameraUser, selectedVideoServiceId, coordinates, captureZonesSettings
    ], () => {
      if (!cameraUrl.value || !cameraName.value || selectedUserGroupIds.value.length == 0 || (!faceAnalytics.value && selectedAnalytics.value.length == 0)) {
        isButtonDisabled.value = true;
      } else {
        checkFormState();
      }
    });

    watch(cameraUrl, () => {
      if (cameraUrl.value) {
        previewUpdateRequired = true;
      }
    });

    watch(cameraName, () => {
      if(cameraName.value.trim() == '')
      {
        cameraNameError.value = 'Enter a name for this camera.'
      } else {
        cameraNameError.value = undefined;
      }
    });

    watch([cameraPass,cameraUser] ,() => {

      if(cameraPass.value && cameraUser.value)
      {
        previewUpdateRequired = true;
      }
    });

    function missingPreview(e) {
      //e.target.value = require('@/assets/camera-settings-background.png');
      cameraPreview.value = require('@/assets/camera-settings-background.png');
    }

    function getCameraCoordinates()
    {
      if(coordinates.value?.length > 0)
      {
        let DD = new RegExp(/^(([NnSs+-])?([0-8]?\d(\.\d*)?|90(\.0*)?)([°˚º^~*NnSs+-])*)([,:;\s|\/\\-]+)(([EeWw+-])*([0]?\d?\d(\.\d*)?|1[0-7]\d(\.\d*)?|180(\.0*)?)[°˚º^~*]*([EeWw+-])*)[\s]*$/mg);
        let DMS = new RegExp(/([0-8]?\d(°|\s)[0-5]?\d('|\s)[0-5]?\d(\.\d{1,6})?"?|90(°|\s)0?0('|\s)0?0"?)\s{0,}[NnSs]([,:;\s|\/\\-]+)([0-1]?[0-7]?\d(°|\s)[0-5]?\d('|\s)[0-5]?\d(\.\d{1,6})?"?|180(°|\s)0?0('|\s)0?0"?)\s{0,}[EeOoWw]/);
        let DMM = new RegExp(/^(([\+\-NnSs])?([0-8]?\d|90)[°˚º^~*\s\-_]+([0-5]?\d|\d)([.]\d*)?['′\s\-_]*([\+\-NnSs])?)([,:;\s|\/\\-]+)(([\+\-EeWw])?([0]?\d?\d|1[0-7]\d|180)[°˚º^~*\s\-_]+([0-5]\d|\d)([.]\d*)?['′\s_]*([\+\-EeWw])?)[\s]*$/gm);

        let validCoords = false;

        validCoords = (DD.test(coordinates.value) || DMS.test(coordinates.value) || DMM.test(coordinates.value));

        if(validCoords) {
          coordinateError.value = undefined;
          let splitCoords = coordinates.value.split(',');
           return {latitude: splitCoords[0], longitude: splitCoords[1]}
        } else {
           coordinateError.value = 'These Coordinates are not valid!';
        }
      } else {
        coordinateError.value = undefined;
        return undefined;
      }
    }

    const allUserGroups = ref([]);

    const allCameraGroups = computed(() => {
      return Array.from(store.getters['cameras/getAllCameraGroups']);
    });

    const allVideoServiceIds = computed(() => {
      return store.getters['settings/allVideoServiceIds'];
    });

    initUserGroups();
    function initUserGroups() {
      try {
        allUserGroups.value = store.getters['settings/userGroups'];
        const systemUserGroup = allUserGroups.value.find(g => g.isSysAdmin === true);
        if (systemUserGroup) {
          const missionInSystem = activeMission.value.userGroups.find(g => g === systemUserGroup._id);
          if (!missionInSystem) {
            // if the active mission isn't in the system group, we have to filter available userGroups to the mission's userGroups
            // to maintain the hierarchy of mission->camera
            allUserGroups.value = allUserGroups.value.filter(g => activeMission.value.userGroups.includes(g._id));
          }
        }
        // iterate available usergroups to check if selected or readonly
        for (let i=0; i < allUserGroups.value.length; i++) {
          allUserGroups.value[i].value = allUserGroups.value[i]._id;
          // if usergroup is system and user not in system group, mark it disabled
          if (allUserGroups.value[i].isSysAdmin && !userGroupContainsCurrentUser(allUserGroups.value[i])) {
            allUserGroups.value[i].disabled = true;
          } else {
            allUserGroups.value[i].disabled = false;
          }
          if (props.mode === 'add') {
            // if creating, auto select all available usergroups
            selectedUserGroupIds.value.push(allUserGroups.value[i].value);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    // check if currently logged in user is a part of provided usergroup
    function userGroupContainsCurrentUser(userGroup) {
      return userGroup.users.find((user) => {
        if (user.email === store.getters['auth/email']) {
          return true;
        }
      });
    }

    function updateSelectedUserGroups(selectedUserGroups) {
      selectedUserGroupIds.value = selectedUserGroups.value;
    }

    function updateSelectedGroup(selectedCameraGroups) {
      selectedCameraGroup.value = selectedCameraGroups.value;
    }

    const darkMode = computed(() => store.getters['settings/getDarkMode']);

    return {
      cameraName,
      cameraUrl,
      enabled,
      faceAnalytics,
      enableRecorder,
      systemTimestamps,
      gotBlur,
      cameraPreviewSrc,
      isLoadingStreamPreview,
      isCameraCRUD,
      getButtonText,
      executeCRUDCommand,
      isButtonDisabled,
      matchThresholdSlider,
      enableMatchThresholdOverride,
      isAdvancedEditorDisplayed,
      editCamCopy,
      advancedEditorHeight,
      checkFormState,
      advancedEditorValue,
      cancelAdvancedSettings,
      okAdvancedSettings,
      analytics,
      selectedAnalytics,
      updateSelectedAnalytics,
      missingPreview,
      allUserGroups,
      selectedUserGroupIds,
      updateSelectedUserGroups,
      allVideoServiceIds,
      selectedVideoServiceId,
      darkMode,
      isShowingLearnMoreText,
      openCaptureZonesEditor,
      isCaptureZonesActive,
      allCameraGroups,
      selectedCameraGroup,
      updateSelectedGroup,
      cameraNameError,
      cameraPass,
      cameraUser,
      linkError,
      credentialError,
      noSource,
      collapseDiv,
      coordinates,
      coordinateError,
      flipViewPass,
      viewPass,
      isMobile,
      captureZonesTooltipText,
      canvasRef,
      close
    };
  }
};
</script>

<style scoped lang="scss">

.mainContainer {
  height: clamp(800px, 80vh, 900px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: var(--spacing-m);
}

.title{
  @include overwatch-title-med;
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-l);
}

.fieldTitle{
  @include overwatch-body-large;
}

.fieldDesc {
  color: var(--overwatch-neutral-200);
  @include overwatch-body-small;

}

.capture-zones {
  display: flex;
  align-items: center;
  background-color: var(--overwatch-background);
  border: 1px solid var(--overwatch-neutral-400);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: var(--spacing-xs);
}

.activeBubble {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 var(--overwatch-light-success);
  background-image: linear-gradient(to bottom, #e7f0e6 0%, #7ec778 39%, var(--overwatch-light-success) 100%);
}

/* This is the green bubble used in capture zones/events page */
.disabledBubble{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-image: radial-gradient(circle at 50% 50%, #c9c9c9, #c5c5c5 0%, #b8b8b8 32%, var(--overwatch-light-neutral-300) 71%);
}

.capture-zones-new-window{
  margin-right: var(--spacing-m);
  cursor: pointer;
}

.capture-zones-new-window.disabled{
  cursor: unset;
}

.divider {
  margin: 0;
  line-height: 0;
  border-top: 1px solid var(--overwatch-accent) !important;
  margin-top: var(--spacing-m);
}

.settingsRectangle {
  min-width: 55rem;
  border: 1px solid var(--overwatch-neutral-300);
  @include overwatch-body-med;
}

.inputTitle {
  float: top;
  @include overwatch-body-large;
  margin-bottom: var(--spacing-base);
  margin-top: var(--spacing-m);
}

.fieldGroups {
  display: flex;
  max-width: 98%;
}

.settings {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap:var(--spacing-xl);
  margin-bottom: var(--spacing-m);
  width: 98%;
}

.settingsSectionHeader {
  height: 20px;
  width: 299px;
  @include overwatch-body-large;
  line-height: 21px;
}

.settingsSectionDesc {
  width: 343px;
  @include overwatch-body-med;
}

.json-editor :deep(.json-editor-vue),
.json-editor :deep(.json-editor-vue) * {
  font-family: consolas, menlo, monaco, "Ubuntu Mono", source-code-pro, monospace;
  flex: 1;
}

/* IPAD PORTRAIT and MOBILE */
@media (max-width: 810px) {

  .mainContainer {
    height: 700px;
    width: 100%;
  }
  .settingsRectangle {
    all: revert;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .inputTitle {
    width: 100%;
  }

  .fieldGroups {
    flex-direction: column;
  }

  .settings {
    flex-direction: column;
  }

  .capture-zones-new-window{
    color: var(--overwatch-neutral-300);
    cursor: unset;
  }

  .camera-preview-roi-container {
    width: 100%; //mobile takes whole div, desktop does half
  }
}

</style>
