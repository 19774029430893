<template>
  <div class="wrapper">
    <!-- Filter input -->
    <RocInput
      class="filter-input"
      @click="isInputExpanded = true"
      @blur="collapseInputOnBlur"
      @input="changeTextFilter"
      :class="{
        'collapsed': !isInputExpanded
      }"
      v-model="inputText"
    >
      <RocIcon
        icon="search"
        color="black"
        size="sm"
      />
    </RocInput>

    <!-- Sort -->
    <MDBDropdown
      v-model="isSortOpen"
    >
      <RocDropdownToggle>
        <RocButton
          class="filter-button"
          :type="
            currentlySelectedSort.length > 0 ?
            'primary' :
            'white'
          "
          @click="isSortOpen = !isSortOpen"
          style="cursor: default; height: 48px;"
        >
          <div class="d-flex align-items-center">
            <RocIcon
              :color="
                currentlySelectedSort.length > 0 ?
                'white' :
                'black'
              "
              size="sm"
              icon="sort"
            />
            <span
              :class="{'sort-text': currentlySelectedSort.length > 0}"
            >
              {{ currentlySelectedSortText }}
            </span>
            <RocIcon
              v-if="currentlySelectedSort.length > 0"
              class="sort-text"
              color="white"
              size="xs"
              icon="exit"
              @click.stop="selectSort('')"
            />
          </div>
        </RocButton>
      </RocDropdownToggle>
      <RocDropdownMenu>
        <RocDropdownItem @click="selectSort('most')" :active="currentlySelectedSort==='most'">
          Most Encounters
        </RocDropdownItem>
        <RocDropdownItem @click="selectSort('least')" :active="currentlySelectedSort==='least'">
          Least Encounters
        </RocDropdownItem>
        <RocDropdownItem @click="selectSort('newest')" :active="currentlySelectedSort==='newest'">
          Newest to Oldest
        </RocDropdownItem>
        <RocDropdownItem @click="selectSort('oldest')" :active="currentlySelectedSort==='oldest'">
          Oldest to Newest
        </RocDropdownItem>
        <RocDropdownItem @click="selectSort('alpha')" :active="currentlySelectedSort==='alpha'">
          Name A-Z
        </RocDropdownItem>
        <RocDropdownItem @click="selectSort('reverse-alpha')" :active="currentlySelectedSort==='reverse-alpha'">
          Name Z-A
        </RocDropdownItem>
      </RocDropdownMenu>
    </MDBDropdown>

    <!-- Date Range -->
    <RocCalendar clusterPageMode
      @time-filter-change="handleTimeFilterChange"
      @clear-date-selection="removeTimeFilterRange"
      :dateValue="dateRange"
    />

    <!-- Basic filters -->
    <RocPopper popperType="menu" @open:popper="isPopperOpen = true" @close:popper="isPopperOpen = false">
      <RocButton
        class="filter-button"
        :type="filterCount > 0 ? 'primary' : 'white'"
        style="cursor: default; height: 48px;"
      >
        <div class="d-flex align-items-center">
          <RocIcon
            :color="
              filterCount > 0 ? 'white' : 'black'
            "
            size="xs"
            icon="filter1"
            style="margin-right: var(--spacing-s)"
          />
          <span :style="buttonTextColor(filterCount > 0)">Filters</span>
          <div v-show="filterCount > 0" class="filter-count-badge">
            {{ filterCount }}
          </div>
          <RocIcon
            v-if="filterCount > 0"
            class="sort-text"
            color="white"
            size="xs"
            icon="exit"
            style="cursor: pointer;"
            @click.stop="clearFilters"
          />
        </div>
      </RocButton>
      <template #content>
        <ClusteringFiltersPopperContent
          @tags-filter-change="$emit('filter-change')"
          @camera-filter-change="$emit('filter-change')"
          @watchlist-filter-change="$emit('filter-change')"
          @clear="clearFilters"
          :isOpen="isPopperOpen"
        />
      </template>
    </RocPopper>

    <a @click="isShowingSaveFilterPopup=true">Save Filters</a>

    <BaseDialog v-if="isShowingSaveFilterPopup" show @close="isShowingSaveFilterPopup = false" hideCloseBtn>
      <SavedClusterFiltersPopup
        @close="isShowingSaveFilterPopup = false"
        @save="showToast()"
      />
    </BaseDialog>

    <BaseDialog :show="isShowingToast" @close="isShowingToast=false" :hideCloseBtn="true" style="background-color: var(--overwatch-button-primary)">
      <div style="width: 100%; padding-top: var(--spacing-l)" class="d-flex flex-row justify-content-center align-items-center">
        <div style="display: flex; align-items: center; justify-content: center;">
          <span style="display: flex; align-items: center; justify-content: center;" class="Check-Circle-Icon">
            <RocIcon size="lg" icon="check" style="color: var(--overwatch-button-primary)"/></span>
        </div>
        <div style="color:#FFFF" class="overwatch-title-small">Your changes have been saved!</div>
      </div>
    </BaseDialog>

  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import RocButton from '@/components/ui/RocButton';
import RocInput from '@/components/ui/RocInput';
import RocIcon from '@/components/ui/RocIcon';
import RocPopper from '@/components/ui/RocPopper';

import { MDBDropdown } from 'mdb-vue-ui-kit';
import RocDropdownMenu from '@/components/ui/RocDropdownMenu';
import RocDropdownToggle from '@/components/ui/RocDropdownToggle';
import RocDropdownItem from '@/components/ui/RocDropdownItem';

import RocCalendar from "@/components/ui/RocCalendar.vue";

import ClusteringFiltersPopperContent from '@/components/clusters/ClusteringFiltersPopperContent';
import SavedClusterFiltersPopup from '@/components/clusters/SavedClusterFiltersPopup'

import BaseDialog from '@/components/ui/BaseDialog.vue';

export default {
  name: 'ClusteringFilters',
  components: {
    RocButton,
    RocInput,
    RocIcon,
    RocPopper,
    MDBDropdown,
    RocDropdownMenu,
    RocDropdownToggle,
    RocDropdownItem,
    ClusteringFiltersPopperContent,
    RocCalendar,
    BaseDialog,
    SavedClusterFiltersPopup
  },
  emits: ['filter-change', 'sort-change', 'text-filter-change'],
  setup(props, context) {
    const store = useStore();

    const isInputExpanded = ref(false);

    const inputText = ref('');

    function collapseInputOnBlur() {
      if (inputText.value === '') {
        isInputExpanded.value = false;
      }
    }

    var textFilterTimeout;
    const textFilterEmitTime = 300;
    function changeTextFilter() {
      clearTimeout(textFilterTimeout);

      textFilterTimeout = setTimeout(() => {
        store.commit('clusters/setNameFilter', inputText.value);
        context.emit('text-filter-change');
      }, textFilterEmitTime);
    }

    const isSortOpen = ref(false);
    const currentlySelectedSort = computed(() => {
      return store.getters['clusters/sorting'];
    })
    function selectSort(sort) {
      store.commit('clusters/setSorting', sort);
      isSortOpen.value = false;
      context.emit('sort-change');
    }

    const currentlySelectedSortText = computed(() => {
      switch (currentlySelectedSort.value) {
        case 'most':
          return 'Most Encounters';
        case 'least':
          return 'Least Encounters';
        case 'newest':
          return 'Newest to Oldest';
        case 'oldest':
          return 'Oldest to Newest';
        case 'alpha':
          return 'Name A-Z';
        case 'reverse-alpha':
          return 'Name Z-A';
        default:
          return '';
      }
    });

    const isFilterOpen = ref(false);
    const currentlySelectedFilter = ref('all');

    const dateRange = computed(() => {
      return store.getters['clusters/dtRangeFilter']
    })

    function handleTimeFilterChange(filter) {
      store.commit('clusters/setDtRangeFilter', filter);
      context.emit('filter-change');
    }
    function removeTimeFilterRange() {
      store.commit('clusters/setDtRangeFilter', []);
      context.emit('filter-change');
    }

    const filterCount = computed(() => {
      var count = 0;
      // Count attribute filters
      const attributeFilters = store.getters['clusters/attributeFilters'];
      count += attributeFilters?.orTags ? attributeFilters.orTags.length : 0;
      count += attributeFilters?.andTags ? attributeFilters.andTags.length : 0;

      // Count camera filters
      const cameraFilters = store.getters['clusters/cameraFilters'];
      count += cameraFilters ? cameraFilters.length : 0;

      if(store.getters['clusters/watchlistMatchFilter']) {
        count += 1;
      }

      return count;
    })

    function clearFilters() {
      store.commit('clusters/setAttributeFilters', {});
      store.commit('clusters/setCameraFilters', []);
      store.commit('clusters/setWatchlistMatchFilter', false);
      context.emit('filter-change');
    }

    const isShowingSaveFilterPopup = ref(false);

    const isShowingToast = ref(false);
    function showToast(){
      isShowingSaveFilterPopup.value = false;

      isShowingToast.value = true;
      setTimeout(() => {
        isShowingToast.value = false;
      }, 800)
    };

    function buttonTextColor(condition) {
      if (condition) {
        return 'color: var(--overwatch-button-text);';
      } else {
        return 'color: var(--overwatch-neutral-100);';
      }
    }

    const isPopperOpen = ref(false);

    return {
      isInputExpanded,
      inputText,
      collapseInputOnBlur,
      currentlySelectedSort,
      currentlySelectedSortText,
      currentlySelectedFilter,
      isSortOpen,
      isFilterOpen,
      selectSort,
      changeTextFilter,
      handleTimeFilterChange,
      dateRange,
      removeTimeFilterRange,
      filterCount,
      clearFilters,
      isShowingSaveFilterPopup,
      isShowingToast,
      showToast,
      buttonTextColor,
      isPopperOpen
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  gap: $--spacing-s;
}

span {
  color: var(--overwatch-button-text);
}

// Text filter
.filter-input {
  width: 250px;
  transition: width .3s;

  display: flex;
  justify-content: center;
}

// Expanded
.filter-input :deep(svg) {
  margin-right: $--spacing-s;
}

// Collapsed
$animation-time: .3s;
.filter-input.collapsed {
  width: 45px;
  padding: 0;
  transition: all $animation-time;

}
.filter-input.collapsed :deep(svg){
  margin-right: 0;
}
.filter-input.collapsed :deep(input) {
  width: 0;
  transition: width $animation-time;
  // Input size needs to transition with the filter-input,
  // or else the icon jumps around.
}

// buttons
.filter-button {
  @include overwatch-body-small;
  height: 45px;
  text-transform: capitalize;
  padding-left: $--spacing-s;
  padding-right: $--spacing-s;
}

.square {
  // Keep buttons square at 45px.
  height: 45px;
  width: auto;
  padding: 0;
  aspect-ratio: 1/1;
}

.filter-caret{
  margin-left: $--spacing-s;
}

.sort-text {
  margin-left: $--spacing-s;
}

.filter-count-badge {
  @include overwatch-body-xsmall;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: var(--overwatch-button-text);
  color: var(--overwatch-button-primary);
  margin-left: var(--spacing-s);
}

.wrapper a {
  text-decoration: underline;
  color: var(--overwatch-primary);
  @include overwatch-body-small;
  cursor: pointer;
  align-self: center;
}

.wrapper a:hover {
  text-decoration: underline;
  color: var(--overwatch-button-primary);
}

// Toast

.Check-Circle-Icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  display: inline-block;
  background-color: white;
}

//

</style>