<template>
  <div>
    <RocInput
      placeholder="Search Users"
      v-model="filterText"
    />
    <div style="margin-top: var(--spacing-m);">
      <div v-for="user of filteredUsers" class="user-row" :key="user.email">
        <RocCheckbox :model-value="modelValue.map(u => u.email).includes(user.email)"  @update:model-value="c => handleCheck(c, user)"/>
        {{ user.lastname + ', ' + user.firstname }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import RocInput from '@/components/ui/RocInput';
import RocCheckbox from '@/components/ui/RocCheckbox';

export default {
  name: 'UserFilter', 
  emits: ['change', 'update:modelValue'],
  props: ['users', 'modelValue'],
  components: {
    RocInput,
    RocCheckbox
  },
  setup(props, context) {
    const filterText = ref('');

    const users = computed(() => props.users);

    const filteredUsers = computed(() => {
      if (users.value) {
        return users.value.filter(u => {
          const fullname = u.firstname + ' ' + u.lastname;
          return fullname.toLowerCase().includes(filterText.value.toLowerCase())  
        });
      }
    })
    
    const selectedUsers = computed({
      get() {
        return props.modelValue
      },
      set() {
        context.emit('update:modelValue', selectedUsers.value);
      }
    });

    function handleCheck(check, user) {
      if (check) {
        selectedUsers.value.push(user);
      } else {
        const userToDeleteIndex = selectedUsers.value.findIndex(u => u.email === user.email)
        selectedUsers.value.splice(userToDeleteIndex, 1);
      }
    }
    
    return {
      filterText,
      filteredUsers,
      handleCheck
    }
  }
}
</script>

<style scoped>
.user-row {
  display: flex;
  gap: var(--spacing-s);
}
</style>
