<template>
  <div>
    <search-header 
      class="stick-to-top" 
      button-title='Filter'
      @button-clicked="createEvent"
      @search-filter-change='setSearchFilter'
      :transparentBackground="true"
      :use-dropdown="true" 
      searchTextPlaceHolder="Search Events"
      :keepButtonTitle="true"
      >
      <template v-slot:leftnav>
        <div class="title">
          <div style="display: flex; align-items: center;">
            Events
            </div> 
          <RocButton
          size="dialogButton"
          type="primary"
          @click="createEvent"
          >
          Create
          </RocButton>
      </div>
      </template>
      <template v-slot:dropdown-slot>
        <MDBDropdownItem href="" @click.prevent="() => {}">
          <div class="d-flex flex-row">
            <RocCheckbox v-model="filterActive" @click.stop="() => {}"/>
            <span style="margin-top: 2px; margin-left: var(--spacing-m);">Active</span>
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem href="" @click.prevent="() =>{}">
          <div class="d-flex flex-row">
            <RocCheckbox v-model="filterUpcoming" @click.stop="() => {}"/>
            <span style="margin-top: 2px; margin-left: var(--spacing-m);">Upcoming</span>
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem href="" @click.prevent="() =>{}">
          <div class="d-flex flex-row">
            <RocCheckbox v-model="filterEnded" @click.stop="() => {}"/>
            <span style="margin-top: 2px; margin-left: var(--spacing-m);">Ended</span>
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem href="" @click.prevent="() =>{}">
          <div class="d-flex flex-row">
            <RocCheckbox v-model="filterDisabled" @click.stop="() => {}"/>
            <span style="margin-top: 2px; margin-left: var(--spacing-m);">Disabled</span>
          </div>
        </MDBDropdownItem>
      </template>
    </search-header>
    <div v-if='isLoading'>
      <roc-spinner/>
    </div>
    <div v-else-if='hasEvents' style="padding-left: 12px;">
      <InfiniteScroll class='event-container d-flex flex-wrap justify-content-start'
          :enabled=true 
          :items="events"
          :page="currentPage"
          :max-pages="totalPages"
          @setPage="setCurrentPage"
          @refetch="loadEvents"
        >
          <template v-slot:item="{ item }">
            <EventView 
              :event="item" 
              @edit="editEvent(item)"
              @delete="deleteEvent(item)"
              @toggleEnabled="toggleEnabled(item)"
              :isSaving="isEventToggling(item._id)"/>
          </template>
        </InfiniteScroll>
    </div>
    <div v-else style="flex: 1; margin-top: 100px;">
      <RocLogo />
      <div class="no-data-bold">
        There are no events to display.
      </div>
    </div>
    <base-dialog v-if="showCrudDialog" :show="true" :title="eventCrudTitle" @close="showCrudDialog = false;" :style="crudDialogStyle">
      <EventCRUD 
        @close='showCrudDialog=false' 
        @save="saveEvent"
        :saving="isEventSaving"
        :rawObject="eventToEdit"
        :inviteTimeoutDays="eventToEdit.inviteConfig ? Number(eventToEdit.inviteConfig.timeoutDays) : 0"
        :inviteMessage="eventToEdit.inviteConfig ? eventToEdit.inviteConfig.message : ''"
        :eventName="eventToEdit.eventName"
        :notes="eventToEdit.notes"
        :startDate="eventToEdit.startDate"
        :endDate="eventToEdit.endDate"
        :errorMsg="eventCrudError"
      />
    </base-dialog>
    <RocToast v-if="displayToast" :message=message @autoClose="displayToast = !displayToast"/>
  </div>
</template>

<script>
import SearchHeader from "@/components/ui/SearchHeader";
import EventView from "@/components/events/EventView";
import EventCRUD from "@/components/events/EventCRUD";
import InfiniteScroll from '@/components/ui/InfiniteScroll';
import RocLogo from '@/components/ui/RocLogo';
import {  MDBDropdownItem } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import RocIcon from '@/components/ui/RocIcon';
import RocButton from '@/components/ui/RocButton';
import RocCheckbox from '@/components/ui//RocCheckbox.vue';
import RocToast from "@/components/ui/RocToast.vue";
import dateHelper from "@/js/dateHelper";

export default {
  name: 'EventList',
  components: {
    EventView,
    EventCRUD,
    InfiniteScroll,
    SearchHeader,
    MDBDropdownItem,
    RocLogo,
    RocIcon,
    RocButton,
    RocCheckbox,
    RocToast
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const searchFilterText = ref('');
    const showCrudDialog = ref(false);
    const eventCrudTitle = ref('Create Event');
    const eventToEdit = ref({});
    const isEditing = ref(false);
    const isEventSaving = ref(false);
    const eventToggling = ref(false);
    const togglingId = ref(null);
    const currentPage = ref(1);
    const totalPages = ref(1);
    const totalEvents = ref(0);
    const eventCrudError = ref('');
    const filterActive = ref(false);
    const filterUpcoming = ref(false);
    const filterEnded = ref(false);
    const filterDisabled = ref(false);
    const dateTool = new dateHelper();
    const displayToast = ref(false);
    const message = ref("Event has been deleted");

    let timeoutId;
    function setSearchFilter(filterText) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        searchFilterText.value = filterText;
        resetEvents();
        loadEvents(1);
      }, 500);
    }
    
    const events = computed(() => {
      let events = store.getters['events/events'];
      const today = new Date();
      today.setHours(0,0,0,0);

      if(filterActive.value || filterDisabled.value || filterEnded.value || filterUpcoming.value)
      {
       return events.filter((eventItem) => {
          if(!eventItem.enabled){
            return filterDisabled.value
          }
          else {
            const startDate = dateTool.getDateFromDateOnlyString(eventItem.startDate);
            const endDate = dateTool.getDateFromDateOnlyString(eventItem.endDate);

            if(today < startDate)
            {
              return filterUpcoming.value;
            }
            else if(today > endDate)
            {
              return filterEnded.value;
            }
            else return filterActive.value;
          }
          
        });
      }
      else return events;
    });
    const hasEvents = computed(function() {
      return !isLoading.value && events.value.length > 0;
    });

    onMounted(() => {
      // we may have routed straight to Events Workflow, load config here
      resetEvents();
      loadEvents(1);
    });

    async function loadEvents(reload) {
      isLoading.value = true;
      const payload = {
        currentPage: currentPage.value
      };
      if (searchFilterText.value) {
        payload.filter = searchFilterText.value;
      }
      const result = await store.dispatch('events/getEventsPaged', payload);
      if (result) {
        totalEvents.value = result.totalEvents;
        totalPages.value = result.totalPages;
        if (result.events) {
          for (let event of result.events) {
            store.commit('events/appendEvent', event);
          }
        }
      }
      reload.status ? reload.status.value = false : 0;
      isLoading.value = false;
    }

    function resetEvents() {
      currentPage.value = 1;
      totalPages.value = 1;
      totalEvents.value = 0;
      store.commit('events/clearEvents');
    }

    function editEvent(event) {
      eventCrudTitle.value = 'Edit Event';
      isEditing.value = true;
      eventToEdit.value = event;
      showCrudDialog.value = true;
    }

    async function deleteEvent(event) {
      isLoading.value = true;
      const response = await store.dispatch('events/deleteEvent', event._id);
      resetEvents();
      await loadEvents(1);
      isLoading.value = false;
      displayToast.value = true; 
    }

    function createEvent() {
      eventCrudTitle.value = 'Create Event';
      isEditing.value = false;
      eventToEdit.value = {};
      showCrudDialog.value = true;
    }

    async function saveEvent(event) {
      isEventSaving.value = true;
      if (isEditing.value) {
        const response = await store.dispatch('events/updateEvent', event);
        if (response && response.status === 'failed') {
          showEventCrudError(response.message ?? 'Failed to edit Event, check server');
        } else {
          resetEvents();
          await loadEvents(1);
          showCrudDialog.value = false;
        }
      } else {
        const response = await store.dispatch('events/createEvent', event);
        console.log(response);
        if (response && response.status === 'failed') {
          showEventCrudError(response.message ?? 'Failed to create Event, check server');
        } else {
          resetEvents();
          await loadEvents(1);
          showCrudDialog.value = false;
        }
      }
      isEventSaving.value = false;
    }

    async function toggleEnabled(event) {
      eventToggling.value = true;
      togglingId.value = event._id;
      const eventUpdate = {
        _id: event._id,
        enabled: !event.enabled
      }
      const response = await store.dispatch('events/updateEvent', eventUpdate);
      resetEvents();
      await loadEvents(1);
      togglingId.value = null;
      eventToggling.value = false;
    }

    function setCurrentPage(pageNum) {
      currentPage.value = pageNum;
    }

    function showEventCrudError(errorMsg) {
      eventCrudError.value = errorMsg;
      setTimeout(()=> {
        eventCrudError.value = '';
      }, 3000);
    }

    const crudDialogStyle = computed(() => {
      if(window.innerWidth <= 480) {
        return {
          'width': '90%',
        }
      } else {
        return {
          'width': '700px',
        }
      }
    });

    function isEventToggling(id) {
      return eventToggling.value && togglingId.value === id;
    }

    return {
      isLoading,
      setSearchFilter,
      createEvent,
      events,
      currentPage,
      totalPages,
      totalEvents,
      setCurrentPage,
      hasEvents,
      loadEvents,
      showCrudDialog,
      crudDialogStyle,
      saveEvent,
      isEditing,
      eventCrudTitle,
      editEvent,
      eventToEdit,
      isEventSaving,
      deleteEvent,
      toggleEnabled,
      eventCrudError,
      isEventToggling,
      filterActive,
      filterUpcoming,
      filterEnded,
      filterDisabled,
      displayToast,
      message
    }
  }
};

</script>

<style scoped>

.title{
    gap:32px; 
    padding-left: 10px;
    display: flex;
  }

.event-container {
  margin: var(--spacing-l);
}

.no-data-bold {
  display: flex; 
  justify-content: center; 
  font-size: 30px; 
  margin-top: 30px;
}

.stick-to-top {
  position: sticky;
  top: 0;
  z-index: 50;
}


@media only screen and (max-width: 810px) and (orientation: portrait) { 
  
}

/* MOBILE */
@media (max-width: 480px) {
  .event-container {
    margin: 0;  
  }
  .title{ 
    padding-left: var(--spacing-s);
    padding-bottom: var( --spacing-s);
    display: flow;
  }
}

</style>
