import mutations from "./mutations.js";
import getters from "./getters.js";
import actions from "./actions.js";

const getDefaultState = () => {
  return {
    clusters: [],
    page: 0,
    moreToFetch: false,
    mission: null,
    videoPlaybackEncounter: null,
    attributeFilters: {},
    cameraFilters: [],
    watchlistMatchFilter: false,
    dtRangeFilter: [],
    allFaceAttributes: [],
    nameFilter: '',
    sorting: '',
    abortController: null,
    associatesTargetCluster: null,
    associatesSameFrameFilter: [],
    associatesTimeRangeFilter: [],

    // Associates filter popup fields
    associatesFilterPopupFields: {
      isSameFrameOnly: false,
      isBeforeTargetEnabled: false,
      isAfterTargetEnabled: false,
      beforeTargetUnit: 'hrs',
      afterTargetUnit: 'hrs',
      beforeTarget: 0,
      afterTarget: 0
    },

    // Popup window video playback
    isVideoPopupVisible: false,
  }
}

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  getDefaultState
};
