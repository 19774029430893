<template>
  <div>
    <div class="parent-div">
        <div class="Name-Desc-Container">
          <div>
            <div class="Card-Title">
              <span class="overwatch-title-small">{{name}}</span>
              <div style="margin-left: auto; padding: 4px; margin-top: -4px;" class="Edit-Pencil-Div">
                <RocIcon v-if="!isMobile" size="sm" color="gray" icon="edit" @click.stop="openPopup('cardUpdate')"/>
               </div>
            </div>
              <div class="Card-Description overwatch-body-med">
              <div v-if="desc">{{desc}}</div>
            </div>
          </div>
            <!-- adding padding to allow a larger clickable area within the edit button-->
            <div class="Date-Added overwatch-body-small">
              <div>{{"Added:" + " " + filterCreatedTime}}</div>
              <RocIcon v-if="isMobile" size="sm" color="gray" icon="edit" @click.stop="openPopup('cardUpdate')"/>
            </div>
        </div>
        <div style="display: flex; flex-wrap: wrap; width: 85%; margin-top: var(--spacing-m);">
            <SavedViewBadges :savedView="rawObject"></SavedViewBadges>
        </div>
      <div class="buttonsOverlay">
          <div style="margin-left: auto;">
              <span class="Priority-Star-Spacing" style="margin-right: 12px;">
                <RocIcon v-if="isPriority" size="md" color="primary" icon="starFilled" @click="flipPriority"/>
                <RocIcon v-else size="md" color="primary" icon="starEmpty" @click="flipPriority"/>
              </span>
          </div>
          <div class="dropdown" style="margin-left: auto;" @click.stop>
            <MDBDropdown v-model='dropdownOptions' align='end'>
              <MDBDropdownToggle
                style='color:black;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;'
                tag='a'
               >
                <div>
                <span @click="dropdownOptions = !dropdownOptions">
                  <RocIcon color="black" size="sm" icon="kebab"/>
                </span>
              </div>
              </MDBDropdownToggle>
              <RocDropdownMenu aria-labelledby='dropdownMenuButton' class="dropdown">
                <MDBDropdownItem href="" @click.prevent="openPopup('delete')" class="d-flex" style="padding: var(--spacing-s);">
                  <div class="overwatch-body-small" style="color:var(--overwatch-error);">
                    Delete
                  </div>
                  <div class="d-flex justify-content-end" style="margin-left: auto;">
                    <RocIcon color="red" size="sm" icon="trash"/>
                  </div>
                </MDBDropdownItem>
              </RocDropdownMenu>
            </MDBDropdown>
          </div>
          <div @click="loadView()" class="d-flex justify-content-end" style="margin-left: auto; width: 100%">
            <RocIcon color="gray" size="md" icon="downArrow"
             class="Card-Arrow"
             style="transform: rotate(-90deg)"/>
          </div>
      </div>
    </div>
    <BaseDialog v-if="showPopup && popupType === 'cardUpdate'" :show="showPopup" :hideCloseBtn="true" @close="showPopup=false" style="padding: var(--spacing-xl) 20px 10px 20px;">
      <SavedViewPopup :filter-values="rawObject" :popup-type="popupType" @close="closePopup"></SavedViewPopup>
    </BaseDialog>
    <BaseDialog v-if="showPopup && popupType === 'delete'" :hideCloseBtn="true" :show="showPopup" @close="showPopup=false;">
      <Confirmation @yes="deleteView()" @no="closePopup()">
        <div class="d-flex flex-row" style="margin-top:30px">
            <div style="margin-right: var(--spacing-s); margin-top: 2px">
              <RocIcon color="red" size="sm" icon="trash"/>
            </div>
            <div class="overwatch-body-large" style="width:300px; flex-wrap: wrap; margin-bottom: var(--spacing-s)">
              Are you sure you want to delete <span style="text-decoration: underline;">{{ name }} </span> ? 
            </div>
        </div>
      </Confirmation>
     </BaseDialog>
    <RocToast v-if="showUpdateConfirmation" :message="'Your Changes have been saved!'" @autoClose="() => {showUpdateConfirmation = !showUpdateConfirmation}"/>
  </div>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { MDBDropdown, MDBDropdownItem, MDBDropdownToggle } from "mdb-vue-ui-kit";
import BaseDialog from "../ui/BaseDialog.vue";
import RocIcon from "@/components/ui/RocIcon";
import SavedViewPopup from "./SavedViewPopup.vue";
import Confirmation from "../settings/Confirmation.vue";
import SavedViewBadges from "./SavedViewBadges.vue";
import RocDropdownMenu from "../ui/RocDropdownMenu.vue";
import RocToast from "@/components/ui/RocToast.vue";

export default{
  name: 'SavedViewCard',
  emits: ['delete','cardUpdate'],
  components: {
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownItem,
    RocIcon,
    BaseDialog,
    SavedViewPopup,
    Confirmation,
    SavedViewBadges,
    RocDropdownMenu,
    RocToast
  },
  props: {
    rawObject: Object,
    name: String,
    desc: String,
    dateRange: Object,
    img: String,
    cameras: Array,
    matchesOnly: Boolean,
    tags: Object,
    watchlists: Array,
    filterCreatedTime: String,
    adjudication: Array,
    isPriority: Boolean
  },

    setup(props, context){
    const router = useRouter();
    const store = useStore();
    const dropdownOptions = ref(false);
    const showPopup = ref(false);
    const popupType = ref('');
    const showUpdateConfirmation = ref(false);
    const isPriority = ref(props.isPriority);
    const userConfig = ref(store.getters['auth/userConfig'] ? store.getters['auth/userConfig'] : {});

    function closePopup(payload){
      popupType.value = '';
      showPopup.value = false;
      if(payload ==='cardUpdate'){
        showUpdateConfirmation.value = true;
        setTimeout(() => {
          showUpdateConfirmation.value = false;
      }, 800)
      }
    }

    function openPopup(type){
      popupType.value = type;
      showPopup.value = true;
      dropdownOptions.value = false;
    }

    const isMobile = computed(()=> {
      if(window.innerWidth <= 480){
        return true;
      }
      else{
        return false;
      }
    });

    const windowWidth = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });
    });

    const updateConfirmationStyle = computed(() => {
      if (windowWidth.value <= 480) {
        // Mobile style
        return {
          width: '90%',
          'background-color': 'var(--overwatch-secondary)',
          'border-radius': '4px'
        };
      }
      else {
        return {
          'background-color': 'var(--overwatch-secondary)',
          'border-radius': '4px'
        }
      }
    });

   async function flipPriority() {
    if(userConfig.value)
    {
       const indx = userConfig.value.savedViews.findIndex(x => x.name === props.name);
       isPriority.value = !isPriority.value;
       userConfig.value.savedViews[indx].priority = isPriority.value;
       await store.dispatch('auth/updateUserConfig', userConfig.value);
    }
    }

    async function loadView(){
      await store.dispatch('auth/applySavedView', props.rawObject);
      router.push({ path: "/encounters"})
    };

    async function deleteView(){
      await context.emit('delete', props.name)
      popupType.value = '';
      showPopup.value = false;
      dropdownOptions.value = false;
    };

    return {
      loadView,
      deleteView,
      dropdownOptions,
      showPopup,
      closePopup,
      openPopup,
      isMobile,
      popupType,
      showUpdateConfirmation,
      updateConfirmationStyle,
      flipPriority,
      isPriority
    };
  },
}
</script>

<style scoped lang="scss">
.parent-div {
  width: 100%;
  height: auto;
  margin-bottom: var(--spacing-s);
  padding-left: var(--spacing-l);
  padding-right: var(--spacing-l);
  padding-top: var(--spacing-m);
  padding-bottom: var(--spacing-m);
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(215, 212, 212, 0.05);
  background-color: var(--overwatch-secondary);
  display: flex;
  position: relative;
  min-height: 136px;
}
.Name-Desc-Container{
  display: flex;
  flex-wrap: wrap;
  width: 50%;
}
.Card-Title {
  min-width: fit-content;
  display: flex;
  justify-content: space-between;
  width:100%;
 @include overwatch-title-med;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--overwatch-neutral-100);
}

.Card-Description {
  width: 95%;
  max-height: 80px;
  margin-top: var(--spacing-s);
  margin-bottom: 5px;
  @include overwatch-body-med;
  letter-spacing: normal;
  text-align: left;
  color: var(--overwatch-neutral-100);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Date-Added{
  display:flex;
  justify-content: space-between;
  width: 100%;
  height: 24px;
  margin-top: 40px;
  @include overwatch-body-xsmall;
  letter-spacing: normal;
  text-align: left;
  color: var(--overwatch-neutral-100);
}

.buttonsOverlay {
  display:flex;
  flex-wrap: wrap; 
}

.Card-Arrow{
  margin-top: auto;
  margin-bottom: var(--spacing-s);
  cursor: pointer;
}

.dropdown {
    align-items: center;
  }


.Edit-Pencil-Div {
  width: 32px;
  height: 32px;
  margin: 6px 6px 0 0;
  background-color: var(--overwatch-secondary);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .parent-div {
  width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.Name-Desc-Container{
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.Card-Title {
  @include overwatch-title-med;
  text-align: left;
  width: 100px;
}

.Card-Arrow{
  margin-top: 20px
}

.Card-Description {
  margin-top: 5px;
  margin-bottom: 5px;
@include overwatch-body-med;
  text-align: left;
}
.Date-Added{
  margin-top: 40px;
 @include overwatch-body-small;
  text-align: left;
  justify-content: flex-start;
  gap:var(--spacing-m);

}

.buttonsOverlay{
  position: absolute;
  right: 10px;
}

}


</style>