import { get as lodashGet } from "lodash";

const MODE_ENROLL_FACE = "enrollFace";
const MODE_ENROLL_ID_PROOF = "enrollIdProof";
const MODE_VERIFY = "verify";
const MODE_IDENTIFY = "identify";
const MODE_DEFAULT = MODE_ENROLL_FACE;

const defaultRegulaConfig = {
  recognizer: {
    processParam: {
      returnUncroppedImage: false,
      scenario: 'Locate',
      multipageProcessing: true,
      returnPackageForReprocess: false,
      timeout: 60000,
      timeoutFromFirstDetect: 30000,
      timeoutFromFirstDocType: 20000,
      resultTypeOutput: [],
      imageQa: {
        expectedPass: ['dpiThreshold', 'glaresCheck', 'focusCheck'],
        dpiThreshold: 150,
        glaresCheck: true,
        focusCheck: true,
        glaresCheckParams: {
          imgMarginPart: 0.05,
          maxGlaringPart: 0.01,
        },
      }
    }
  },
  imageProcessor: {
    processParam: {
      scenario: 'MrzOrBarcode',
      returnUncroppedImage: false,
      returnPackageForReprocess: false
    }
  }
};

export default {
    faceComplete: (state) => {
        return Boolean(state.capturedFaceData?.faceImage);
    },
    /*
    * returns true if:
    *   ID Proof Mode, ID first: ID capture complete & verified
    *   ID Proof Mode: face and ID capture complete and verified
    *   Enroll Only Mode: ID capture complete
    */
    idComplete: (state, getters) => {
      const identityDocData = getters.capturedIdentityDocData;
      const captured = Boolean(identityDocData.identityDocPageOne);
      if (getters.isIdUseRegulaCapture && captured) {
        return true;
      }
      const captureConfig = getters.captureConfigs;

      // const verified = state.verifyScore >= getters.verificationThreshold;
      const validated = identityDocData.identityDocMetrics &&
                        ((!captureConfig || !captureConfig.isIdAuthenticityEnforced()) || (getters.capturedIdentityDocPage1Timeout || identityDocData.identityDocMetrics.authentic)) &&
                        ((!captureConfig || !captureConfig.isIdOcrResultEnforced()) || (identityDocData.identityDocMetrics.ocrStatus && identityDocData.identityDocMetrics.ocrStatus.ocrSuccess)) &&
                        (identityDocData.identityDocBarcodeMrzSkipped ||
                          (identityDocData.identityDocBarcodeMrz &&
                            identityDocData.identityDocBarcodeMrz.barcodeStatus && identityDocData.identityDocBarcodeMrz.barcodeStatus.barcodeSuccess));

      if (getters.isIdProofMode && getters.isIdFirst) {
        return captured && validated;
      } else if (getters.isIdProofMode && !getters.isDocumentProcessingDisabled) {
        return captured /*&& verified*/ && validated;
      } else if (getters.isIdProofMode) {
        return captured;
      } else {
        return captured;
      }
    },
    /*
    * returns true if:
    *   ID Proof Mode: face and ID capture complete and verified, RWW enrolled (if configured)
    *   Enroll Only Mode: face capture complete, RWW enrolled (if configured)
    */
    enrollComplete: (state, getters) => {
      if (getters.verifyOverride) {
        return getters.faceComplete;
      }
      if (getters.isIdProofMode) {
        if (getters.appFlavorConfig.workflowFlags.enrollIntoRww) {
          return getters.faceComplete && getters.idComplete && state.rwwEnrolled;
        } else {
          return getters.faceComplete && getters.idComplete;
        }
      } else {
        if (getters.appFlavorConfig.workflowFlags.enrollIntoRww) {
          return getters.faceComplete && state.rwwEnrolled;
        } else {
          return getters.faceComplete;
        }
      }
    },
    captureConfigs: (state) => {
        return state.captureConfigs;
    },
    userSelectedCaptureConfig: (state) => {
      return lodashGet(state, 'userConfig.userSelectedCaptureConfig', null);
    },
    selectedCaptureConfig: (state) => {
      return lodashGet(state, 'userConfig.selectedCaptureConfig', null);
    },
    headerShowHome: (state, getters) => {
        try {
            return getters.appFlavorConfig.workflowFlags.showHomeButton;
        } catch {
            // default if not configured by flavor
            return true;
        }
    },
    headerShowCancel: (state, getters) => {
        try {
            return getters.appFlavorConfig.workflowFlags.showCloseButton;
        } catch {
            // default if not configured by flavor
            return false;
        }
    },
    headerhideSettings: (state, getters) => {
        try {
            return getters.appFlavorConfig.workflowFlags.hideSettings;
        } catch {
            // default if not configured by flavor
            return true;
        }
    },
    iframeConfigured: (state, getters) => {
        try {
            return typeof getters.appFlavorConfig.iframeConfig !== 'undefined' ? true : false;
        } catch {
            // default if not configured by flavor
            return false;
        }
    },
    iframeEnabled: (state, getters) => {
        try {
            return getters.appFlavorConfig.iframeConfig &&
                    getters.appFlavorConfig.iframeConfig.enabled;
        } catch {
            // default if not configured by flavor
            return false;
        }
    },
    iframeExportOrigins: (state, getters) => {
      try {
        const iframeConfig = getters.appFlavorConfig ? getters.appFlavorConfig.iframeConfig : null;
        if (iframeConfig) {
          return iframeConfig.iframeOrigin ?? [];
        }
        return [];
      } catch {
        return [];
      }
    },
    iframeCancelPayload: (state, getters) => {
        return {
            integrator: getters.appFlavorConfig.iframeConfig.integrator,
            result: {
                status: 'canceled'
            }
        }
    },
    iframeFinishedPayload: (state, getters) => {
        return {
            integrator: getters.appFlavorConfig.iframeConfig.integrator,
            result: {
                status: 'finished'
            }
        }
    },
    verificationThreshold: (state, getters) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.verificationThreshold', 55);
    },
    ocrFieldsList: (state, getters) => {
        try {
            return getters.appFlavorConfig.workflowConfig.ocrFields;
        } catch {
            // default if not configured by flavor
            return [];
        }
    },
    capturedFaceImage: (state) => {
      return lodashGet(state, 'capturedFaceData.faceImage', null);
    },
    capturedFaceThumbnail: (state) => {
      return lodashGet(state, 'capturedFaceData.faceThumbnail', null);
    },
    capturedFaceThumbnailNoBackground: (state) => {
      return lodashGet(state, 'capturedFaceData.faceThumbnailNoBackground', null);
    },
    themePrimaryColor: (state, getters) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.uiTheme.primaryColor', '#000000');
    },
    themeSpinnerColor: (state, getters) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.uiTheme.primaryColor', '#000000');
    },
    themeCompleteColor: (state, getters) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.uiTheme.stepperCompleteColor', '#1C1E4D');
    },
    themeUi: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.uiTheme', {});
    },
    themeUiFlags: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.uiFlags', {});
    },
    themeUiText: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.uiText', {});
    },
    themeUiAssets: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.uiAssets', {});
    },
    isUserReady: (state) => {
      const userReady = lodashGet(state, 'userConfig.userReadyFlag', false);
      console.debug(`User Ready: ${userReady}`);
      return lodashGet(state, 'userConfig.userReadyFlag', false);
    },
    allowManualCaptureOverride: (state, getters) => {
        try {
            return getters.appFlavorConfig.workflowFlags.allowManualCaptureOverride;
        } catch {
            return false;
        }
    },
    allowPhotoUpload: (state, getters) => {
        try {
            return getters.appFlavorConfig.workflowFlags.allowPhotoUpload;
        } catch {
            return false;
        }
    },
    enrollIntoRww: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.enrollIntoRww;
      } catch {
          return false;
      }
    },
    rwwSelectedWatchlist: (state) => {
      return state.userConfig.rwwSelectedWatchlist;
    },
    appFlavorConfig: (state) => {
      try {
        return state.sessionConfig.appFlavorConfig;
      } catch {
        return null;
      }
    },
    appFlavorTag: (state) => {
      try {
        return state.userConfig.appFlavorTag;
      } catch {
        return '';
      }
    },
    identityData: (state) => {
      return state.identityData;
    },
    vehicleData: (state) => {
      return state.vehicleData;
    },
    themeUiFieldsCollectedIdentityData: (state, getters) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.uiFields.collectedIdentityData', []);
    },
    themeUiFieldsAllowEditing: (state, getters) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.uiFields.allowEditing', true);
    },
    isDevMode: (state) => {
      return state.userConfig.devMode;
    },
    verifyScore: (state) => {
      return state.verifyScore;
    },
    isFlagDeleteEnrollment: (state) => {
      try {
        return state.userConfig.flagDeleteEnrollment;
      } catch {
        return false;
      }
    },
    isEnableDeDupe1N: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowFlags.enableDeDupe1N', false);
    },
    rocSdkAlgoOptions: () => {
      return {
        ROC_NO_ALGORITHM_OPTIONS: 0,
        ROC_FACE_BALANCED_REPRESENTATION: 1,
        ROC_FACE_FAST_REPRESENTATION: 2,
        ROC_FACE_ACCURATE_REPRESENTATION: 8,
        ROC_TATTOO_REPRESENTATION: 16,
        ROC_COLOR_REPRESENTATION: 32,
        ROC_TEXT_REPRESENTATION: 64,
        ROC_FILTER_TEXT: 128,
        ROC_FACE_DETECTION: 256,
        ROC_FACE_LEGACY_DETECTION: 512,
        ROC_TATTOO_DETECTION: 1024,
        ROC_TEXT_DETECTION: 2048,
        ROC_MANUAL_DETECTION: 4096,
        ROC_ENHANCE_CONTRAST: 8192,
        ROC_IGNORE_PARTIAL: 16384,
        ROC_ANALYTICS: 65536,
        ROC_IGNORE_ARTWORK: 131072,
        ROC_LANDMARKS: 262144,
        ROC_PITCH_YAW: 524288,
        ROC_ICAO_METRICS: 1048576,
        ROC_ICAO_BACKGROUND: 4194304,
        ROC_SPOOF: 2097152,
        ROC_THUMBNAIL: 16777216,
        ROC_SERIAL: 33554432
      };
    },
    isEnrollPhotoIdImages: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.enrollPhotoIdImages;
      } catch {
          return false;
      }
    },
    isEnrollIdentityDetails: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.enrollIdentityDetails;
      } catch {
          return false;
      }
    },
    defaultCaptureConfig: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.defaultCaptureConfig', 'Verify');
    },
    prevIdentityData: (state) => {
      return state.prevIdentityData;
    },
    prevIdentityFound: (state) => {
      return state.prevIdentityFound;
    },
    defaultRwwWatchlist: (state) => {
      try {
        return getters.appFlavorConfig.workflowConfig.defaultEnrollWatchlist;
      } catch {
          // default if not configured by flavor
          return '';
      }
    },
    selectedCameraIndexFace: (state) => {
      try {
        return state.userConfig.selectedCameraIndexFace;
      } catch {
        return 0;
      }
    },
    selectedCameraIndexID: (state) => {
      try {
        return state.userConfig.selectedCameraIndexID;
      } catch {
        return 0;
      }
    },
    selectedCameraIndexBarcode: (state) => {
      try {
        return state.userConfig.selectedCameraIndexBarcode;
      } catch {
        return 0;
      }
    },
    deviceSettings: (state) => {
      try {
        return state.deviceSettings;
      } catch {
        return {};
      }
    },
    webcamList: (state) => {
      return state.webcamList ?? [];
    },
    isIOS: (state) => {
      return state.platform == 'iOS';
    },
    platform: (state) => {
      return state.platform;
    },
    metricsFace: (state) => {
      return state.metricsJsonFace;
    },
    capturedFaceUploadFlag: (state) => {
      return state.capturedFaceUploadFlag;
    },
    scannedCacData: (state) => {
      return state.scannedCacData;
    },
    scannedDlData: (state) => {
      return state.scannedDlData;
    },
    isIcaoFallbackEnabled: (state) => {
      return lodashGet(state, 'userConfig.icaoFallback', false);
    },
    isIcaoFallbackAllowed: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowFlags.allowIcaoCaptureFallback', false);
    },
    isIdFirst: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.idFirst;
      } catch {
          return false;
      }
    },
    isEnforceFaceLiveness: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.enforceFaceLiveness;
      } catch {
          return false;
      }
    },
    capturedFaceMetrics: (state) => {
      return lodashGet(state, 'capturedFaceData.faceMetrics', {});
    },
    hideFinishedButton: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.hideFinishedButton;
      } catch {
          // default if not configured by flavor
          return true;
      }
    },
    hideNewEnrollmentButton: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.hideNewEnrollmentButton ?? false;
      } catch {
          // default if not configured by flavor
          return false;
      }
    },
    hideSummary: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.hideSummary ?? false;
      } catch {
          // default if not configured by flavor
          return false;
      }
    },
    isStageTwoFaceCapture: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowFlags.stageTwoFaceCapture', false);
    },
    isIdTwoPageCapture: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.idTwoPageCapture;
        } catch {
          return false;
      }
    },
    isAllowAdditionalDocCapture: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.allowAdditionalDocCapture;
        } catch {
          return false;
      }
    },
    capturedAdditionalDocs: (state) => {
      return state.capturedDocs;
    },
    isWorkflowDemoMode: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.demoMode;
        } catch {
          return false;
      }
    },
    capturedIdentityDocData: (state) => {
      return state.capturedIdentityDocData;
    },
    capturedIdentityDocFaceMetrics: (state) => {
      return state.capturedIdentityDocData.identityDocFaceMetrics;
    },
    capturedIdentityDocType: (state) => {
      return state.capturedIdentityDocData.identityDocType;
    },
    capturedIdentityDocMetrics: (state) => {
      return state.capturedIdentityDocData.identityDocMetrics;
    },
    capturedIdentityDocPageOne: (state) => {
      return state.capturedIdentityDocData.identityDocPageOne;
    },
    capturedIdentityDocPageTwo: (state) => {
        return state.capturedIdentityDocData.identityDocPageTwo;
    },
    capturedIdentityDocFaceThumbnail: (state) => {
        return state.capturedIdentityDocData.identityDocFaceThumbnail;
    },
    capturedIdentityDocBarcodeMrz: (state) => {
      return state.capturedIdentityDocData.identityDocBarcodeMrz;
    },
    capturedIdentityDocBarcodeMrzSkipped: (state) => {
      return state.capturedIdentityDocData.identityDocBarcodeMrzSkipped;
    },
    capturedIdentityDocImageSegments: (state) => {
      return state.capturedIdentityDocData.identityDocImageSegments;
    },
    overrideDocScenario: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowConfig.overrideDocScenario ?? null;
      } catch {
          return null;
      }
    },
    capturedIdentityDocPage1Timeout: (state) => {
      return state.capturedIdentityDocData.identityDocPage1Timeout;
    },
    captureMetrics: (state) => {
      return state.captureMetrics;
    },
    captureStartTime: (state) => {
      return state.startTime;
    },
    isDocumentProcessingDisabled: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.disableDocumentProcessing;
        } catch {
          return false;
      }
    },
    initialCaptureDelaySeconds: (state, getters) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.initialCaptureDelaySeconds', 3);
    },
    isPrivacyPolicyAccepted: (state) => {
      return state.userConfig.privacyPolicyAccepted;
    },
    themeBackgroundColor: (state, getters) => {
      try {
          return getters.appFlavorConfig.uiTheme.primaryBackgroundColor ?? '#D9D8D6';
      } catch {
          // default if not configured by flavor
          return '#D9D8D6';
      }
    },
    themeBackgroundColorHeader: (state, getters) => {
      try {
          return getters.appFlavorConfig.uiTheme.headerBackgroundColor ?? getters.themeBackgroundColor;
      } catch {
          // default if not configured by flavor
          return getters.themeBackgroundColor;
      }
    },
    themePrimaryFontColor: (state) => {
      return lodashGet(state.sessionConfig.appFlavorConfig, 'uiTheme.primaryFontColor', '#000000');
    },
    themeFontFamily: (state, getters) => {
      try {
          return getters.appFlavorConfig.uiTheme.fontFamily ?? getComputedStyle(document.documentElement)
          .getPropertyValue('--font-family');
      } catch {
          // default if not configured by flavor
          return 'sans-serif';
      }
    },
    themeButtonFontColor: (state) => {
      return lodashGet(state.sessionConfig.appFlavorConfig, 'uiTheme.buttonColorText', '#D9D8D6');
    },
    themeButtonBackgroundColor: (state) => {
      return lodashGet(state.sessionConfig.appFlavorConfig, 'uiTheme.buttonColorBackground', '#1C1E4D');
    },
    themeIsVerticalStackButtons: (state) => {
      return lodashGet(state.sessionConfig.appFlavorConfig, 'uiTheme.verticalStackButtons', false);
    },
    themeFontFamilyHeader: (state) => {
      return lodashGet(state.sessionConfig.appFlavorConfig, 'uiTheme.fontFamilyHeader', getComputedStyle(document.documentElement)
      .getPropertyValue('--font-family'));
    },
    topLogo: (state, getters) => {
      let flavorValue;
      try {
        flavorValue = getters.appFlavorConfig.uiAssets.logoHeader;
        if (typeof flavorValue === 'undefined') {
          flavorValue = 'logo_roc.png';
        }
      } catch (err) {
        flavorValue = 'logo_roc.png';
      }
      if (flavorValue === '') {
        return '';
      }
      if (require('@/js/base64Helper').isBase64DataUri(flavorValue)) {
        return flavorValue;
      } else {
        const images = require.context('@/assets/rocenroll/');
        return images('./' + flavorValue);
      }
    },
    bottomLogo: (state, getters) => {
      let flavorValue;
      try {
        flavorValue = getters.appFlavorConfig.uiAssets.logoFooter;
        if (typeof flavorValue === 'undefined') {
          flavorValue = 'logo_roc.png';
        }
      } catch (err) {
        flavorValue = 'logo_roc.png';
      }
      if (flavorValue === '') {
        return '';
      }
      if (require('@/js/base64Helper').isBase64DataUri(flavorValue)) {
        return flavorValue;
      } else {
        const images = require.context('@/assets/rocenroll/');
        return images('./' + flavorValue);
      }
    },
    isEntitlement: (state) => {
      return state.isEntitlement;
    },
    entitlementData: (state) => {
      return state.entitlementData;
    },
    captureSplashFace: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.captureSplashFace', { enabled: false });
    },
    captureSplashID: (state, getters) => {
      try {
        return getters.appFlavorConfig.workflowConfig.captureSplashID ?? { enabled: false };
      } catch {
        // default if not configured by flavor
        return { enabled: false };
      }
    },
    enrollConfig: (state) => {
      return state.enrollConfig;
    },
    enrollDefaultFlavor: (state) => {
      try {
        return state.enrollConfig.flavor.allowDefault ? state.enrollConfig.flavor.default : null;
      } catch (err) {
        return null;
      }
    },
    isEventWorkflowEnabled: (state) => {
      if (!state.enrollConfig) {
        return true;
      }
      return state.eventWorkflowEnabled;
    },
    isSkipToCapture: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.skipToCapture ?? false;
      } catch {
          return false;
      }
    },
    isHideCameraButtons: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.hideCameraButtons ?? false;
      } catch {
          return false;
      }
    },
    isHideStepper: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.hideStepper ?? false;
      } catch {
          return false;
      }
    },
    isHideHeader: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.hideHeader ?? false;
      } catch {
          return false;
      }
    },
    isHideDocumentProgress: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.hideDocumentProgress ?? false;
      } catch {
          return false;
      }
    },
    isHideIdentityDetails: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.hideIdentityDetails ?? false;
      } catch {
          return false;
      }
    },
    isStageTwoIdCapture: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.stageTwoIdCapture ?? false;
      } catch {
          return false;
      }
    },
    cameraOverlayFace: (state, getters) => {
      try {
          return getters.appFlavorConfig.uiAssets.cameraOverlayFace ?? null;
      } catch {
          return null;
      }
    },
    cameraOverlayId: (state, getters) => {
      try {
          return getters.appFlavorConfig.uiAssets.cameraOverlayId ?? null;
      } catch {
          return null;
      }
    },
    isPermanentOverlay: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.permanentOverlay ?? false;
      } catch {
          return false;
      }
    },
    allowRetry: (state, getters) => {
      try {
          return getters.appFlavorConfig.workflowFlags.allowRetry ?? false;
        } catch {
          return false;
      }
    },
    isUsePortal: (state, getters) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.portal.enabled', false);
    },
    customerId: (state, getters) => {
      return state.customerId;
    },
    isWatchlistSelect: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowFlags.showWatchlistSelect', false);
    },
    isEventSelect: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowFlags.showEventSelect', false);
    },
    portalConfig: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.portal', {});
    },
    rwwEnrollResult: (state) => {
      return state.rwwEnrollResult;
    },
    isFingerprintMode: (state) => {
      return state.sessionConfig.fingerprintMode;
    },
    capturedFingerprintData: (state) => {
      return state.capturedFingerprintData;
    },
    fingerprintCaptureQualityThreshold: (state) => {
      return lodashGet(state, 'enrollConfig.fingerprint.thresholds.quality', 1.5);
    },
    fingerprintCaptureMaxDistanceFromCenter: (state) => {
      return lodashGet(state, 'enrollConfig.fingerprint.thresholds.distanceFromCenter', 0.5);
    },
    themeTextAlign: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.uiTheme.textAlign', 'center');
    },
    defaultName: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.defaultName', 'unknown');
    },
    isEnableLiveStatus: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowFlags.enableLiveStatus', false);
    },
    regionIdCredential: (state) => {
      // return override regionId if set, or flavor config regionId if set, or empty
      if (state.regionIdCredential) {
        return state.regionIdCredential;
      }
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.regionIdCredential', '');
    },
    regionIdBarcode: (state) => {
      // return override regionId if set, or flavor config regionId if set, or empty
      if (state.regionIdBarcode) {
        return state.regionIdBarcode;
      }
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.regionIdBarcode', '');
    },
    themeTextAlignCapture: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.uiTheme.textAlignCapture', 'center');
    },
    flavorIcaoBackgroundEnabled: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.enableIcaoBackground', false);
    },
    flavorIcaoBackgroundRemovalEnabled: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.enableIcaoBackgroundRemoval', false);
    },
    isCitizen: (state) => {
      return state.isCitizen;
    },
    documentTypeRegex: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.documentTypeRegex', '');
    },
    isIdUseRegulaCapture: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowFlags.idUseRegulaCapture', false);
    },
    regulaCaptureRecognizer: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.regulaCapture.recognizer', defaultRegulaConfig.recognizer);
    },
    regulaCaptureImageProcessor: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.regulaCapture.imageProcessor', defaultRegulaConfig.imageProcessor);
    },
    regulaCaptureMrzBarcode: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.regulaCapture.enableMrzOrBarcodeParsing', false);
    },
    regulaCaptureServerProcessingEnabled: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.regulaCapture.serverProcessing.enabled', false);
    },
    regulaCaptureServerProcessingRetries: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.regulaCapture.serverProcessing.maxRetries', 1);
    },
    regulaCaptureServerProcessingRetryMsg: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.regulaCapture.serverProcessing.msgRetry', 'Failed to validate document, try again.');
    },
    isMpgsEnroll: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.mpgs.enroll', false);
    },
    isMpgsPay: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.mpgs.pay', false);
    },
    isMpgsAuthenticate: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.mpgs.authenticate', false);
    },
    isMpgsVerify: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.mpgs.verify', false);
    },
    mode: (state) => {
      const flavorMode = lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.mode', null);
      if (!flavorMode) {
        const legacyEnrollOnlyMode = lodashGet(state, 'sessionConfig.appFlavorConfig.workflowFlags.enrollOnlyMode', false);
        const legacyIdProofOnlyMode = lodashGet(state, 'sessionConfig.appFlavorConfig.workflowFlags.idProofMode', false);
        if (legacyEnrollOnlyMode) {
          return MODE_ENROLL_FACE;
        } else if (legacyIdProofOnlyMode) {
          return MODE_ENROLL_ID_PROOF;
        } else {
          return MODE_DEFAULT;
        }
      } else {
        return flavorMode;
      }
    },
    isEnrollOnlyMode: (state, getters) => {
      return getters.mode === MODE_ENROLL_FACE;
    },
    isIdProofMode: (state, getters) => {
      return getters.mode === MODE_ENROLL_ID_PROOF;
    },
    verifyOverride: (state, getters) => {
      return state.verifyOverride || getters.mode === MODE_VERIFY;
    },
    isIdentifyMode: (state, getters) => {
      return getters.mode === MODE_IDENTIFY;
    },
    paymentAmount: (state) => {
      return state.paymentAmount;
    },
    customerPin: (state) => {
      return state.customerPin;
    },
    customerPinConfig: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.customerPin', {});
    },
    autoFinishTimeout: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.autoFinishTimeout', 0);
    },
    privacyPolicyMode: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.privacyPolicy.mode', 'link');
    },
    privacyPolicyUrl: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.privacyPolicy.url', 'https://roc.ai/privacy-policy-online-services/');
    },
    privacyPolicyAcceptMsg: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.privacyPolicy.acceptMsg', 'I acknowledge that I have read and understand the terms of the privacy policy available above.');
    },
    privacyPolicyLinkMsg: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.privacyPolicy.linkMsg', 'Please review our privacy policy before continuing');
    },
    fingerprintBioMiniUrl: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.fingerprint.bioMiniUrl', null);
    },
    returnUrl: (state) => {
      return state.returnUrl;
    },
    externalIdentityCheckSource: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.externalIdentityCheckSource', null);
    },
    barcodeCustomerId: (state) => {
      return lodashGet(state, 'sessionConfig.appFlavorConfig.workflowConfig.barcodeCustomerId', { enabled: false });
    }
}
