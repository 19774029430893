<template>
  <div class="watchlist">
    <search-header 
      @search-filter-change='setSearchFilter'
      searchTextPlaceHolder="Search Watchlists"
      :hideButton="true"
      :transparentBackground="true" 
    >
      <template v-slot:leftnav>
        <div class="title">
          <div style="display: flex; align-items: center;">
            Watchlists
          </div>
            <MDBDropdown v-model='dropdownOptions'>
              <MDBDropdownToggle
                style='color:black;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;'
                tag='a'
                @click="dropdownOptions = !dropdownOptions">
                <RocButton class="createButton" >
                  Create
                </RocButton>
              </MDBDropdownToggle>
              <RocDropdownMenu @click="dropdownOptions=false" aria-labelledby='dropdownMenuButton' style="margin-top: 20px; box-shadow: 4px 4px 15px 4px rgba(0, 19, 58, 0.3);">
                <MDBDropdownItem href="" @click.prevent="addWatchlistClicked('face')">
                <div class="d-flex justify-content-between">
                      <div class="">Faces</div>
                      <RocIcon class="Badge-Icon" icon="faceSearch" size="sm" color="primary"/>
                </div>
                </MDBDropdownItem>
              <hr class='dropdown-divider' @click.stop style="margin-top: 0px !important; margin-bottom: 0px !important;"/>
              <MDBDropdownItem href="" @click.prevent="addWatchlistClicked('text')">
                    <div class="d-flex justify-content-between">
                      <div class="">License Plates</div>
                     <RocIcon icon="vehicle" size="sm" color="black" />
                    </div>
              </MDBDropdownItem>
              <hr class='dropdown-divider' @click.stop style="margin-top: 0px !important; margin-bottom: 0px !important;"/>
              <MDBDropdownItem href="" @click.prevent="addWatchlistClicked('tattoo')">
                <div class="d-flex justify-content-between">
                      <div class="">Tattoos</div>
                      <RocIcon class="Badge-Icon" icon="gallery" size="sm" color="primary"/>
                </div>
              </MDBDropdownItem>
              </RocDropdownMenu>
            </MDBDropdown>
      </div>
      </template>
    </search-header>
    <div v-if='isLoading'>
      <roc-spinner/>
    </div>
    <div v-if='hasWatchlists'>
      <div class='watchlist-container d-flex flex-wrap justify-content-start'>
        <watchlist-view v-for='watchlist in watchlists' :key='watchlist._id'
        :id="watchlist._id"
        :name="watchlist.name"
        :size="watchlist.size"
        :enabled="watchlist.enabled"
        :member-summary="watchlist.memberSummary"
        :match-threshold="watchlist.matchThreshold"
        :audible-alarm="watchlist.audibleAlarmEnabled"
        :wl-type="watchlist.type"
        :color="watchlist.color"
        :watchlist="watchlist"
        @save="loadWatchlists"></watchlist-view>
      </div>
    </div>
  </div>
</template>

<script>
import SearchHeader from "@/components/ui/SearchHeader";
import WatchlistView from "@/components/watchlists/WatchlistView";
import { MDBDropdown, MDBDropdownItem, MDBDropdownToggle } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { computed, onBeforeUnmount, ref } from "vue";
import RocButton from "@/components/ui/RocButton.vue";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import WatchListDetails from "@/components/watchlists/WatchListDetails.vue";

export default {
  name: 'WatchlistsList',
  components: {
    WatchlistView,
    SearchHeader,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    RocButton,
    RocDropdownMenu,
    RocIcon,
    WatchListDetails
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const searchFilterText = ref('');
    const watchlistCRUDMode = ref('edit');
    const crudTitle = ref('Add Watchlist');
    const showDialog = ref(true);
    const watchlistEditGUID = ref(null);
    const error = ref(null);
    const isAddDropdownShowing = ref(false);
    const dropdownOptions = ref(false);

    function dropdownSelected(selected) {
      isAddDropdownShowing.value = false;
      context.emit("dropdown-selected", selected);
    }

    /**
    * @async
    * @function addWatchlistClicked
    * @param {string} type 
    */
    async function addWatchlistClicked(type) {
      store.dispatch("watchlists/initWatchlistImporter", {
        type: type,
        mode: 'create',
        watchlistId: '',
        visible: true
      });
    }

    function setSearchFilter(filterText) {
      searchFilterText.value = filterText;
    }

    onBeforeUnmount( () => {
      store.dispatch("watchlists/toggleWatchlistImporter", false);
    });


    const watchlists = computed(function() {
      const watchLists = store.getters['watchlists/watchlists'];
      return searchFilterText.value != '' ? watchLists.filter((watchlist) => {
        if (watchlist.name.toLowerCase().includes(searchFilterText.value.toLowerCase())) {
          return true;
        }
      }) : watchLists;
    });

    const hasWatchlists = computed(function() {
      return !isLoading.value && store.getters['watchlists/watchlists'];
    });

    async function loadWatchlists(refresh = false) {
      isLoading.value = true;
      try {
        await store.dispatch('watchlists/loadWatchlists', {
          forceRefresh: refresh
        });

      } catch (error) {
        error.value = error.message || 'Something went wrong!';
      }
      isLoading.value = false;
    }

    loadWatchlists();
    function handleError() {
      error.value = null;
      showDialog.value = false;
    }

    return {
      isLoading,
      setSearchFilter,
      addWatchlistClicked,
      watchlists,
      hasWatchlists,
      watchlistCRUDMode,
      watchlistEditGUID,
      crudTitle,
      handleError,
      showDialog,
      loadWatchlists,
      isAddDropdownShowing,
      dropdownSelected,
      dropdownOptions
    }
  }
};
</script>

<style scoped>
.watchlist {
  min-width: 55rem;
}

.createButton{
  background: var(--overwatch-primary);
  color: var(--overwatch-button-text) !important;
  font-size: 14px
}

.title{
  gap: var(--spacing-l);
  padding-left: var(--spacing-base);
  display: flex;
}

.watchlist-container {
  margin: 2rem;
}

.stick-to-top {
  position: sticky;
  top: 0;
  z-index: 50;
}

@media only screen and (max-width: 810px) and (orientation: portrait) { 
  .watchlist {
    min-width: 0;
  }  
}

/* MOBILE */
@media (max-width: 480px) {
  .watchlist {
    all: revert;
  }
   .title{ 
    padding-left: var(--spacing-s);
    padding-bottom: var( --spacing-s);
    display: flow;
  }

  .watchlist-container {
    min-width: 400px;
    max-width: 400px;
    margin:0;
  }
}

</style>
