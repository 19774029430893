<template>
  <div class="workflow-dashboard-card">
    <div style="flex: 3">
      <b @click="viewDetails">
        {{ workflow.name }}
      </b>
      <div class="description">
        {{ workflow.description }}
      </div>
    </div>
    <span 
      v-if="!workflow.enabled"
      class="disabled-text" 
      style="flex: 1"
    >
      DISABLED
    </span>
    <MDBDropdown class='dropdown' :class="{open: isMenuShowing}" v-model="isMenuShowing">
      <MDBDropdownToggle class="toggle" tag='div' @click="isMenuShowing = !isMenuShowing">
        <RocIcon color="black" size="sm" icon="kebab"/>
      </MDBDropdownToggle>
      <RocDropdownMenu> 
        <MDBDropdownItem href="" @click.prevent>
          <div class="dropDownItems" @click="toggleEnabled">
            {{ stateText }}
            <RocIcon v-if="stateText == 'Disable'" icon="nomatch" color="black" size="sm"/>
            <RocIcon v-else icon="circleCheck" color="black" size="sm"/>
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem tag="button" @click="viewDetails">
          <div class="dropDownItems">
            View Details
            <RocIcon icon="description" color="black" size="sm"/>
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem tag="button" @click="editWorkflow">
          <div class="dropDownItems">
            Edit
            <RocIcon icon="edit" color="black" size="sm"/>
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem tag="button" @click="exportWorkflow">
          <div class="dropDownItems">
            Export
            <RocIcon icon="upload" color="black" size="sm"/>
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem tag="button" style="color: var(--overwatch-error);" @click="deleteWorkflow">
          <div class="dropDownItems">
            Delete
            <RocIcon icon="trash" color="red" size="sm"/>
          </div>
        </MDBDropdownItem>
      </RocDropdownMenu>
    </MDBDropdown>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownToggle,
} from 'mdb-vue-ui-kit';
import RocIcon from '@/components/ui/RocIcon';
import RocDropdownMenu from '../ui/RocDropdownMenu.vue';

export default {
  name: 'WorkflowDashboardCard',
  components: {
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    RocIcon,
    RocDropdownMenu,
  },
  props: ['workflow'],
  setup(props, context) {
    const store = useStore();

    const workflow = computed(() => { return props.workflow; });

    const isMenuShowing = ref(false);

    const isEnabled = computed(() => { return props.workflow.enabled; });
    
    function editWorkflow() { 
      context.emit('edit');
      isMenuShowing.value = false;
    }

    function deleteWorkflow() {
      context.emit('delete');
      isMenuShowing.value = false;
    }

    async function saveFile() {
      try {
        if ('showSaveFilePicker' in window) {
          // Create a new handle
          const handle = await window.showSaveFilePicker({
            suggestedName: `rfworkflow-${sanitizeFilename(workflow.value.name)}.json`,
            types: [{
              description: 'JSON Files',
              accept: {'application/json': ['.json']},
            }],
          });

          // Create a FileSystemWritableFileStream to write to
          const writableStream = await handle.createWritable();

          // Write the contents of the file to the stream
          await writableStream.write(JSON.stringify(workflow.value, null, 2));

          // Close the file and write the contents to disk
          await writableStream.close();
        }
      else {
        // Fallback method using Blob and data URL
        const json = JSON.stringify(workflow.value, null, 2);
        const blob = new Blob([json], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        // Create a temporary link element and download the file
        const a = document.createElement('a');
        a.href = url;
        a.download = `rfworkflow-${sanitizeFilename(workflow.value.name)}.json`;
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }

      } catch (e) {
        console.error(e);
      }
    }

    function sanitizeFilename(filename, maxLength = 50) {
      // Remove invalid filename characters
      let sanitized = filename.replace(/[<>:"/\\|?*\x00-\x1F]/g, "");

      // Capitalize the first character of each word and remove spaces
      sanitized = sanitized.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');

      // Truncate to the specified maxLength
      if (sanitized.length > maxLength) {
        sanitized = sanitized.substring(0, maxLength);
      }

      return sanitized;
    }

    const exportWorkflow = async () => {
      isMenuShowing.value = false;
      await saveFile();
    };

    function viewDetails() {
      context.emit('show');
      isMenuShowing.value = false;
    }

    const stateText = computed(() => {
      if (isEnabled.value) return 'Disable';
      else return 'Enable';
    })

    async function toggleEnabled() {
      const response = await store.dispatch('workflows/updateWorkflow', {
        id: props.workflow._id,
        enabled: !(isEnabled.value)
      });
      isMenuShowing.value = false;
    }

    return {
      workflow,
      isMenuShowing,
      editWorkflow,
      deleteWorkflow,
      exportWorkflow,
      viewDetails,
      toggleEnabled,
      stateText
    }
  }
}

</script>

<style scoped>
.workflow-dashboard-card {
  position: relative;
  background-color: var(--overwatch-secondary);
  padding: 20px;
  margin: 12px auto 12px auto;
  width: 90%;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.05);
  display: flex;
  align-items: center;
}

b {
  font-size: 18px;
  margin-bottom: 16px;

  cursor: pointer;
}

b:hover {
  text-decoration: underline;
}

.description {
  width: 40%;
}

.dropdown {
  position: absolute; 
  top: 50%; 
  right: 32px;
  transform: translateY(-50%);
  z-index: 10;
}

/* Need this to fix a z-index issue with dropdowns, 
  something related to the transform: property messing with orders.
*/
.dropdown.open {
  z-index: 11;
}

.dropDownItems {
  display: flex;
  justify-content: space-between;
}

.disabled-text {
  color: var(--overwatch-error);
}

</style>