<template>
  <MDBDropdown>
    <slot class="click-priority">
    </slot>
    <div v-if="show" class="backdrop" @touchstart.passive.stop ref="backdrop"></div>
  </MDBDropdown>

</template>

<script>
/**
 * Wrapper for MDBDropdown with backdrop to prevent touch from propogating 
 * when clicking outside the menu to close it. 
 * 
 * When using this component, make sure to set both v-model and :show with the same variable
 * 
 * e.g. <BaseComponent v-model="varA" :show="varA" > ...
 * 
 * v-model is being inherited by MDBDropdown as a top level element 
 * from the file where BaseDropdown is being utilized.
 * 
 * props.show is necessary to get the v-model value for the backdrop div.
 * 
 * Trying to refactor v-model into this file where v-model is passed with a prop in this file doesn't behave well 
 * as the rest of the MDBDropdown items in the slot are all interacting with the variable in the file where BaseDropdown
 * is called.
 * 
 * Could look into this further but considering we only use this in a few places I'll leave as is for now.
 * 
 * - Sean C. 11/09/22
 */

import { MDBDropdown } from 'mdb-vue-ui-kit'; 

export default {
  components: {
    MDBDropdown
  }, 
  props: ['show'],
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: env(safe-area-inset-top);
  left: 0;
  height: calc(100vh - env(safe-area-inset-top));
  width: 100vw;
  z-index: 100;
}

.click-priority {
  z-index: 99999;
}

</style>