<template>
  <div>
    <div class="input-wrapper"  :class="{'inputError': errorMessage}">
      <slot></slot>
      <textarea 
          ref="inputRef"
          :rows="rows" 
          :value="modelValue" 
          :maxlength="maxLength"
          :disabled="disabled"
          :placeholder="placeholder" 
          @blur="$emit('blur',$event.target.value)"
          @input="$emit('update:modelValue', $event.target.value)"/>
      <RocIcon v-if="errorMessage" icon="error" size="md" color="red" style="margin-bottom: 35px; margin-right: var(--spacing-s);"/>
      <div class='clear-button' v-if="clearButton" @click="clearInput()">
        <RocIcon color="black" size="xs" icon="exit"/>
      </div>
    </div>
    <span v-if="errorMessage" style="color: var(--overwatch-light-error);">{{ errorMessage }}</span>
  </div>
</template>

<script>
import { ref } from 'vue';
import RocIcon from '@/components/ui/RocIcon';

export default {
  name: 'RocTextArea',
  components: {
    RocIcon
  },
  props: {
    modelValue: {
      type: String
    },
    placeholder: {
      type: String,
      required: false
    },
    clearButton: {
      type: Boolean,
      default: false
    },
    disabled :{
      type: Boolean,
      required: false
    },
    maxLength :{
        length: Number,
        required: false,
        default: 200
    },
    rows:{
        rows: Number,
        required: false,
        default: 3
    },
    errorMessage:{
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue','blur'],
  setup(props, context) {
    const inputRef = ref();
    function focusOnInput() {
      inputRef.value.focus();
    }

    function clearInput() {
      inputRef.value.value = '';
      context.emit('update:modelValue', '');
    }

    return {
      inputRef,
      focusOnInput,
      clearInput
    }
  }
}
</script>

<style scoped lang="scss">
.input-wrapper {
  border-radius: 5px;
  border: solid 1px var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  cursor: text;
  display: flex;
  align-items: center;
  width: 100%;
}
.input-wrapper-disabled {
  background-color: var(--overwatch-neutral-400);
}
.input-wrapper > textarea:disabled {
  background-color: var(--overwatch-neutral-400);
}
textarea {
  all: unset;
  @include overwatch-body-med;
  width: 100%;
  background-color: var(--overwatch-neutral-500);
  padding: var(--spacing-s);
  border-radius: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
textarea::placeholder{
  color: var(--overwatch-neutral-300);
  @include overwatch-body-small;
}

.inputError {
  border-radius: 5px;
  border: solid 1px var(--overwatch-light-error);
  background-color: var(--overwatch-neutral-500);
  cursor: v-bind(cursor);
  display: flex;
  align-items: center;
}

.clear-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px;
}
</style>