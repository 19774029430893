import { createRouter, createWebHistory } from "vue-router";

import CamerasList from "./pages/cameras/CamerasList.vue";
import EncounterDetail from "./pages/encounters/EncounterDetail.vue";
import EncountersList from "./pages/encounters/EncountersList.vue";
import RequestsReceived from "./pages/requests/RequestsReceived.vue";
import WatchlistsList from "./pages/watchlists/WatchlistsList.vue";
import WatchlistEditor from "./pages/watchlists/WatchlistEditor.vue";
import CameraLiveFeedPage from "./pages/cameras/CameraLiveFeedPage.vue";
import MissionDashboard from './pages/cases/MissionDashboard.vue';
import CameraLiveFeedPopup from "./pages/cameras/CameraLiveFeedPopup.vue";
import CaptureZonesEditor from "./pages/cameras/CaptureZonesEditor.vue";
import EncounterWatchlistMatch from "./pages/encounters/EncounterWatchlistMatch.vue";
import SavedViews from './pages/encounters/SavedViews.vue';
import NotFound from "./pages/NotFound.vue";
import login from "./pages/auth/login.vue";
import logout from "./pages/auth/logout.vue";
import store from "./store/index.js";
import UsersList from "@/pages/settings/UsersList";
import UserGroupsList from "@/pages/settings/UserGroupsList"
import EventDashboard from "@/pages/events/EventDashboard.vue";
import EventList from "@/pages/events/EventList.vue";
import WorkflowDashboard from "@/pages/workflows/WorkflowDashboard";
import WorkflowCRUD from "@/pages/workflows/WorkflowCRUD";
import InvestigativeCaseManagement from "@/pages/cases/InvestigativeCaseManagement";
import InvestigativeCaseImportWizard from "@/pages/cases/InvestigativeCaseImportWizard";
import InvestigativeCaseRecords from "@/pages/cases/InvestigativeCaseRecords";
import ClusteringHome from "@/pages/clusters/ClusteringHome";
import ClusterEncounterVideoPopup from "@/components/clusters/ClusterEncounterVideoPopup";
import userAccessHelper from "@/js/userAccessHelper";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/', redirect: (to) => {
        return { path: new userAccessHelper().getStartPage(), query: to.query };
      }
    },
    { path: '/cameras', component: CamerasList },
    { path: '/missions', component: MissionDashboard },
    { path: '/encounters', component: EncountersList },
    { path: '/clusters/:caseId', component: ClusteringHome, props: true },
    { path: '/clusters/:caseId/clustervideopopup', name: 'ClusterEncounterVideoPopup', component: ClusterEncounterVideoPopup, props: true },
    {
      path: '/eDetails/:encounter',
      name: 'EncounterDetails',
      component: EncounterDetail,
      props: true,
    },
    {
      path: '/encounters/facematch/:id',
      name: 'EncounterWatchlistMatch',
      component: EncounterWatchlistMatch,
      props: true
    },
    {
      path: '/views',
      name: 'SavedViews',
      component: SavedViews
    },
    { path: '/requests', component: RequestsReceived },
    { path: '/watchlists', component: WatchlistsList },
    {
      path: '/watchlist/:id/:wltype',
      name: 'WatchlistEditor',
      component: WatchlistEditor,
      props: true,
    },
    { path: '/livefeed', component: CameraLiveFeedPage },
    { path: '/livefeedpopup', component: CameraLiveFeedPopup },
    { path: '/capturezoneseditor', component: CaptureZonesEditor },
    {
      path: '/settings/users',
      component: UsersList,
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/isAdmin']) {
          next();
        }
        else {
          next('/notfound');
        }
      }
    },
    {
      path: '/settings/user-groups',
      component: UserGroupsList,
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/isAdmin']) {
          next();
        }
        else {
          next('/notfound');
        }
      }
    },
    { path: '/:notFound(.*)', component: NotFound },
    { path: '/login', component: login, name: 'login' },
    { path: '/logout', component: logout, name: 'logout' },
    // roc enroll pages
    { path: '/rocenroll/home', name: 'ROCEnrollHome', component: () => import('./pages/rocenroll/ROCEnrollHome.vue') },
    { path: '/rocenroll/cameraCaptureFace', name: 'CameraCaptureFace', component: () => import('./pages/rocenroll/CameraCaptureFace.vue') },
    { path: '/rocenroll/cameraCaptureID', name: 'CameraCaptureID', component: () => import('./pages/rocenroll/CameraCaptureID.vue') },
    { path: '/rocenroll/cameraCaptureBarcode', name: 'CameraCaptureBarcode', component: () => import('./pages/rocenroll/CameraCaptureBarcode.vue') },
    { path: '/rocenroll/captured', name: 'Captured', component: () => import('./pages/rocenroll/Captured.vue') },
    { path: '/rocenroll/captureIdentityData', name: 'CaptureIdentityData', component: () => import('./pages/rocenroll/CaptureIdentityData.vue') },
    { path: '/rocenroll/cameraCaptureDocument', name: 'cameraCaptureDocument', component: () => import('./pages/rocenroll/CameraCaptureDocument.vue') },
    {
      path: '/rocenroll/captureFingerprint',
      name: 'CaptureFingerprint',
      component: () => import('./pages/rocenroll/CaptureFingerprint.vue'),
      props: ({params}) => ({fingerPosition: Number.parseInt(params.fingerPosition, 10) || 1})
    },
    { path: '/rocenroll/cameraCaptureRegula', name: 'CameraCaptureRegula', component: () => import('./pages/rocenroll/CameraCaptureRegula.vue') },
    // events
    {
      path: '/events', name: 'EventList', component: EventList,
      beforeEnter: (to, from) => {
        return store.getters['rocenroll/isEventWorkflowEnabled'];
      }
    },
    {
      path: '/events/:id', name: 'EventDashboard', component: EventDashboard, props: true,
      beforeEnter: (to, from) => {
        return store.getters['rocenroll/isEventWorkflowEnabled'];
      }
    },
    {
      path: '/workflows', name: 'WorkflowDashboard', component: WorkflowDashboard
    },
    {
      path: '/workflow', name: 'WorkflowCRUD', component: WorkflowCRUD
    },
    {
      path: '/investigations', name: 'InvestigativeCaseManagement', component: InvestigativeCaseManagement
    },
    {
      path: '/investigation/:id', name: 'InvestigativeCaseImportWizard', component: InvestigativeCaseImportWizard, props: true
    },
    {
      path: '/investigation/:caseId/records',
      name: 'InvestigativeCaseRecords',
      component: InvestigativeCaseRecords,
      props: true
    },

    //examine pages
    {
      path: '/examine',
      name: 'Examine',
      props: true,
      component: () => import('./pages/tools/Examine.vue'),
    },
    {
      path: '/examine/queue',
      name: 'ExamineQueue',
      props: true,
      component: () => import('./pages/tools/ExamineQueue.vue'),
    },
    {
      path: '/examine/examination/:caseName/:examId',
      name: 'Examination',
      props: true,
      component: () => import('./pages/tools/Examination.vue'),
    },
    {
      path: '/examine/imagedash/:caseName/:examId',
      name: 'ImageDash',
      props: true,
      component: () => import('./pages/tools/ImageDash.vue'),
    },
    {
      path: '/examine/casenotes/:caseName/:examId/:complete',
      name: 'CompleteExam',
      props: true,
      component: () => import('./pages/tools/CompleteExam.vue'),
    },
    {
      path: '/examine/annotate/:caseName/:examId',
      name: 'Annotate',
      props: true,
      component: () => import('./pages/tools/Annotate.vue'),
    },
    {
      path: '/examine/adjustsegment',
      name: 'AdjustCrop',
      props: true,
      component: () => import('./components/tools/AdjustCrop.vue'),
    },
    {
      path: '/examine/segmentdash/:caseName/:examId',
      name: 'FacialSegmentDash',
      props: true,
      component: () => import('./pages/tools/SegmentDash.vue'),
    },
    {
      path: '/examine/options',
      name: 'Options',
      props: true,
      component: () => import('./pages/tools/Options.vue'),
    },
    {
      path: '/examine/annotatedash/:caseName/:examId',
      name: 'AnnotateDash',
      component: () => import('./pages/tools/AnnotateDash'),
    },
    {
      path: '/examine/activitylog/:caseName/:examId',
      name: 'ActivityLog',
      component: () => import('./pages/tools/ActivityLog'),
    },
    {
      path: '/examine/docs/:url',
      name: 'Docs',
      component: () => import('./pages/tools/Docs'),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  let publicPages = [
    '/login',
    '/logout',
    '/notFound',
  ];
  let authRequired = !publicPages.includes(to.path);
  let loggedIn = store.getters['auth/isAuthenticated'];
  // set one time token to vuex here, if provided.
  // this ensures URL param access will be using token it intends to and not a cached/expired token
  if (to.query.otp) {
    store.commit('auth/setOneTimeToken', to.query.otp);
  }
  if (authRequired && !loggedIn && to.query.otp) {
    await store.dispatch("auth/isLoggedIn");
    loggedIn = store.getters['auth/isAuthenticated'];
  }
  const userAccessClass = new userAccessHelper();
  console.debug("router logged in: ", loggedIn);
  setDocumentProperties(to.path);

  if (process.env.VUE_APP_NO_LOGIN === 'true') {
    if (to.path === '/login') {
      return next({ path: '/notFound', query: to.query });
    }
  }

  if (authRequired && !loggedIn) {
    return next({ path: '/login', query: to.query });
  } else {
    if (!authRequired) {
      return next();
    } else {
      // auth required, not logged in
      const userAccess = userAccessClass.parseUserAccessCookie();
      if (!userAccess) {
        // no userAccess cookie, we'll just proceed for now
        return next();
      }
      if (userAccess.clientStartPage && to.path === userAccess.clientStartPage) {
        // path is start page, proceed
        // note - do this before checking allowed pages - if misconfigured and start page isn't in allowed pages, causes cicular redirect
        return next();
      }
      const pageAllowed = userAccessClass.isPageAllowed(to.path);
      if (pageAllowed) {
        return next();
      } else {
        // destination page not allowed either go back to 'from', redirect to startingPage, or redirect to login
        if (from && from.path && userAccessClass.isPageAllowed(from.path)) {
          setDocumentProperties(from.path);
          return next({ path: from.path, query: from.query });
        } else if (userAccess.clientStartPage) {
          setDocumentProperties(userAccess.clientStartPage);
          return next({ path: userAccess.clientStartPage, query: to.query });
        } else {
          return next({ path: '/login', query: to.query });
        }
      }
    }
  }
});

function setDocumentProperties(path) {
  const preAuthConfig = store.getters['auth/preAuthConfig'];
  const isRocEnrollPage = path.startsWith('/rocenroll');
  if (!preAuthConfig?.documentTitle) {
    if (isRocEnrollPage) {
      document.title = 'ROC Enroll';
    } else {
      document.title = 'ROC Watch Web';
    }
  } else {
    document.title = preAuthConfig?.documentTitle;
  }
  if (preAuthConfig?.favoriteIconBase64) {
    const favicon = document.getElementById("favicon");
    favicon.href = preAuthConfig.favoriteIconBase64;
  }
  const isRocWatchDarkMode = store.getters['settings/getDarkMode'];
  const isCurrentThemeDarkMode =
    (document.documentElement.getAttribute('data-theme') === 'darkMode') ||
    (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

  if (isRocEnrollPage) {
    if (isCurrentThemeDarkMode) {
      // ROC Enroll currently uses light mode only
      document.documentElement.setAttribute('data-theme', 'lightMode');
    }
  } else {
    const isRocWatchDarkModeSet = document.documentElement.getAttribute('data-theme') === 'darkMode';
    const isRocWatchLightModeSet = document.documentElement.getAttribute('data-theme') === 'lightMode';
    // if we're not in ROC Enroll, ensure page attribute is set correctly
    if (isRocWatchDarkMode && !isRocWatchDarkModeSet) {
      document.documentElement.setAttribute('data-theme', 'darkMode');
    } else if (!isRocWatchDarkMode && !isRocWatchLightModeSet) {
      document.documentElement.setAttribute('data-theme', 'lightMode');
    }
  }
}

export default router;
