<template>
  <filterMultiSelect class="large-multiselect" v-bind="$props"/>
</template>

<script>
import filterMultiSelect from '@/components/ui/filterMultiSelect.vue';

export default {
  name: 'LargeFilterMultiSelect',
  components: {
    filterMultiSelect
  },
  setup(props, context) {

  }
}

</script>

<style scoped lang="scss">
.large-multiselect :deep(.multiselect) {
  border: solid 1px var(--overwatch-neutral-300);
  border-radius: 5px !important;
  padding: 0 0 4px 4px;
  max-height: 150px !important;
  display: flex; //get tags to align at the beginning of the multiselect
  align-items: flex-start;
  width: 100%;
}
.large-multiselect :deep(.multiselect-wrapper) {
  align-items: start;
  background-color: var(--overwatch-neutral-500);
}

.large-multiselect :deep(.multiselect-tags) {
  height: 100%;
  max-height: 142px !important;
  overflow-y: scroll;
  padding: 4px;
}

.large-multiselect :deep(.multiselect-tags-search){
  background-color: var(--overwatch-neutral-500);
  border: none;
}

.large-multiselect :deep(.multiselect-clear-icon) {
  display: none;
}
.large-multiselect :deep(.multiselect-caret){
  background-color: var(--overwatch-neutral-300);
}

.large-multiselect :deep(.multiselect-tag) {
  @include overwatch-body-small;
  color: var(--overwatch-button-primary); // Font color
  border: solid 1px var(--overwatch-button-primary); // Tag border
}

.large-multiselect :deep(.multiselect-tag span) {
  max-width: 25ch; 
  overflow: hidden; 
  text-overflow: ellipsis;
}

.large-multiselect :deep(.multiselect-dropdown) {
  color: var(--overwatch-neutral-100) !important;
  background-color: var(--overwatch-secondary);
  border: none;
  filter: drop-shadow(0px 4px 8px rgba(0,0,0,.25));
}

//hide scrollbar, badges are half shown which implies scroll exists
.large-multiselect :deep(.multiselect-tags)::-webkit-scrollbar-thumb {
  background: transparent; /* make scrollbar transparent */
  display: none;
  width: 0;
}
.large-multiselect :deep(.multiselect-tags)::-webkit-scrollbar {
  background: transparent; /* make scrollbar transparent */
  display: none;
  width: 0;
}
.large-multiselect :deep(.multiselect-tags)::-webkit-scrollbar-track {
  background: transparent; /* make scrollbar transparent */
  display: none;
  width: 0;
}

.large-multiselect :deep(.multiselect-placeholder) {
  align-items: flex-start;
  padding: $--spacing-base;
  padding-top: 6px;
  margin: var(--ms-tag-my, .25rem) 4px 0;
  height: auto;
}
</style>