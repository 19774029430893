<template>
  <MDBDropdownToggle
    style='color:black;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;'
    tag='div'
    class="toggle"
  >
    <slot />
  </MDBDropdownToggle>

</template>

<script>
/**
 * Component that is identical to MDBDropdownToggle,
 * but styled to remove that button styling from the toggle button.
 */

import {
  MDBDropdownToggle
} from 'mdb-vue-ui-kit'

export default {
  name: 'RocDropdownToggle',
  components: {
    MDBDropdownToggle
  }
}
</script>

<style scoped>
.toggle {
  line-height: 0;
}

</style>