<template>
  <div class="outer">
    <div class="inner" :style="computedInnerStyle">
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'ProgressBar',
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  setup(props, context) {
    const percentValue = computed(() => props.value);
    const computedInnerStyle = computed(() => {
      return `width: ${percentValue.value}%;`
    })

    return {
      computedInnerStyle
    }
  }
}
</script>

<style scoped lang="scss">
.outer {
  height: 10px;
  border-radius: 5px;
  border: 1px solid var(--overwatch-button-primary);
  background-color: var(--overwatch-accent);
}

.inner {
  height: 100%;
  background-color: var(--overwatch-button-primary);

  transition: width .5s;
}
</style>