<template>
  <MDBBadge pill :class="type">
    {{ text }}
  </MDBBadge>
</template>

<script>
import {MDBBadge} from "mdb-vue-ui-kit";

export default {
  components: { MDBBadge },
  props: ['type', 'title'],
  computed: {
    text() {
      return this.title.toUpperCase();
    }
  }
}
</script>

<style scoped>
.badge {
  background-color: var(--overwatch-button-primary-20);
  color: var(--overwatch-button-primary);
  border: 1px solid var(--overwatch-button-primary);
  border-radius: 30px;
  padding: 0.5rem 1.5rem;
  display: inline-block;
  margin-right: 0.5rem;
}

.frontend {
  background-color: var(--overwatch-button-primary-20);
  color: var(--overwatch-button-primary);
  border: 1px solid var(--overwatch-button-primary);
}

.backend {
  background-color: var(--overwatch-button-primary-20);
  color: var(--overwatch-button-primary);
  border: 1px solid var(--overwatch-button-primary);
}

.career {
  background-color: var(--overwatch-button-primary-20);
  color: var(--overwatch-button-primary);
  border: 1px solid var(--overwatch-button-primary);
}
</style>