<template>
  <div class="wrapper">
    <div v-for="option of options"
      class="option"
      :class="{
        selected: model === option.value
      }"
      @click="model = option.value"
    >
      {{ option[optionLabel] }}
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  optionLabel: {
    type: String,
    default: 'label'
  },
  optionValue: {
    type: String,
    default: 'value'
  }
});
const model = defineModel();

</script>

<style scoped lang="scss">
.wrapper {
  // Should span the entire width... but maybe I should allow customization?
  width: 100%;
  border-bottom: 1px solid var(--overwatch-accent);

  display: flex;
  gap: var(--spacing-s);
}

.option {
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  padding: var(--spacing-base);
  border-bottom: 4px solid transparent;
}

.option.selected {
  border-bottom: 4px solid var(--overwatch-primary);
}
</style>