<template>
  <ProgressCircle :size="34">
    <RocIcon 
      color="black"
      size="xs"
      icon="cameraOn"
      :x="11"
      :y="11"
    /> 
  </ProgressCircle>

</template>

<script>
import RocIcon from '@/components/ui/RocIcon';
import ProgressCircle from '@/components/ui/ProgressCircle.vue';

export default {
  name: 'CaseUploadProgressCircle',
  components: {
    RocIcon,
    ProgressCircle
  },
  setup(props, context) {

  }
}
</script>

<style scoped lang="scss">
.icon {

}
</style>