<script setup>
import { ref, defineProps, defineEmits } from "vue";
import RocCheckbox from "@/components/ui/RocCheckbox.vue";
import RocButton from "@/components/ui/RocButton.vue";

const emits = defineEmits(['close', 'confirm']);
const props = defineProps(['showAcknowledge']);

function close() {
      emits('close');
}

function confirm() {
    if(!acknowledged.value){
        return;
    }
    emits('confirm');
}

const acknowledged = ref(false);

</script>

<template>
    <base-dialog
    style="max-width: 430px"
    :hideCloseBtn="true"
    :show="true"
    :preventCloseOnClickAway="true"
    @close="close()"
    >

        <div class="title">
            Acknowledgement
        </div>

        <div class="description">
            <slot></slot>
            <div class="i-understand">
                <RocCheckbox v-model="acknowledged" />
                <div >I Understand</div>
            </div>
        </div>

        <div class="buttons">
            <div
            @click="close()"
            class="leave-page"
            >
                Leave Page
            </div>
            <RocButton @click="confirm()"
            :disabled="!acknowledged"
            type="primary">
                Confirm
            </RocButton>
        </div>
    </base-dialog>
</template>

<style scoped lang="scss">
.title {
    @include overwatch-title-small;
    margin: var(--spacing-l) 0;
}

.description {
    @include overwatch-body-med;
}

.i-understand {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-m);
    gap: var(--spacing-s)
}

.leave-page{
    cursor: pointer;
    text-decoration: underline;
    color: var(--overwatch-primary);
    margin-top: var(--spacing-s)
}

.buttons {
  @include overwatch-body-med;
  display: flex;
  gap: var(--spacing-l);
  justify-content: end;
  margin-top: var(--spacing-m);
  align-items: center;
}

@media (max-width: 480px) {
  .buttons {
    display: flex;
  }
  .buttons button {
    width: 0;
    flex: 1;
  }
}
</style>