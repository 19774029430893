<template>
  <div v-if="showColorPicker" class="d-flex flex-row flex-wrap">
    <div v-for="(color, index) in allColors" :key="index">
      <div @click.stop class="colorChoice" :style="getWatchlistColorStyle(color, true)" @click="setWatchlistColor(color)"></div>
    </div>
  </div>
  <div v-else class="d-flex" style="padding-left: 6px;">
    <div @click.stop class="colorChoice" :style="getWatchlistColorStyle(selectedColor)" @click="showPicker()" title="Change Color"></div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  emits: ['color-picked', 'showing-picker'],
  props: {
    selectedColor: String,
    showPicker: Boolean
  },
  setup(props, context) {
    const store = useStore();

    const allColors = computed(() => {
      return store.getters['settings/watchlistColors'];
    });
    
    const showColorPicker = ref(props.showPicker);

    function getWatchlistColorStyle(colorCode, picker=false) {
      const dynamicStyle = {
        'background-color': colorCode
      };

      if (picker) {
        dynamicStyle['margin-right'] = '8px';
      }

      return dynamicStyle;
    }

    function setWatchlistColor(colorCode) {
      showColorPicker.value = false;
      context.emit("color-picked", colorCode);
    }

    function showPicker() {
      showColorPicker.value = true;
      context.emit("showing-picker", true);
    }

    return {
      allColors,
      getWatchlistColorStyle,
      setWatchlistColor,
      showColorPicker,
      showPicker
    };
  }
}
</script>
<style scoped>
.colorChoice {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid var(--overwatch-neutral-300);
  cursor: pointer;
}
</style>