<template>
  <div v-if="newKeyReady">
    <div style="padding: 0px 8px 0px 8px;">
      New API Key generated - PLEASE SAVE THIS DATA
    </div>
    <div style="padding: 4px 8px 8px 8px;">
      For security purposes, these keys are not retrievable in the future
    </div>
    <div class="d-flex flex-row inputSection" style="width: 100%;">
      <div class="flex-grow-1">
        <RocInput placeholder="ID" v-model="newApiKeyId"/>
      </div>
      <div class="d-flex justify-content-end align-items-center" 
        style="margin-left: 12px; cursor: pointer;"
        @click="copyStringToClipboard(newApiKeyId, 'API Key ID')">
        <RocIcon icon="copy"/>
      </div>
    </div>
    <div class="d-flex flex-row inputSection" style="width: 100%;">
      <div class="flex-grow-1">
        <div v-if="isShowingSecret">
          <RocInput placeholder="Secret" v-model="newApiKeySecret"/>
        </div>
        <div v-else style="cursor: pointer;" @click="isShowingSecret = true;">
          <RocInput placeholder="Secret" v-model="clickToReveal"/>
        </div>
      </div>
      <div class="d-flex justify-content-end  align-items-center" 
        style="margin-left: 12px;cursor: pointer;"
        @click="copyStringToClipboard(newApiKeySecret, 'API Key Secret')">
        <RocIcon icon="copy"/>
      </div>
    </div>
  </div>
  <div v-else-if="isSaving">
    <roc-spinner/>
  </div>
  <div v-else>
    <div class="inputSection">
      <RocInput placeholder="ID" v-model="apikeyId" disabled/>
    </div>
    <div class="inputSection">
      <RocInput placeholder="Secret" v-model="hidden" disabled/>
    </div>
    <div class="inputSection">
      <RocInput placeholder="Label" v-model="label"/>
    </div>
    <div class="inputSection">
      <RocTextArea placeholder="Notes" v-model="notes"/>
    </div>
    <div class="d-flex flex-row inputSection">
      <div class="d-flex flex-row" style="flex: 1; margin-right: 20px; align-items: center;">
        <div style="margin-right: 20px;">Role</div>
        <RocSelect 
            style="width: 100%"
            :availableOptions="roles"
            :optionLabel="'label'"
            :optionValue="'_id'"
            :placeholder="'Select Role'"
            :currently-selected="selectedRoleId"
            @selection-changed="selectedRoleId = $event"
          />
      </div>
      <div class="d-flex flex-row align-items-center justify-content-end" style="flex: 1; margin-right: 20px;">
        <div class="flex-grow-1" style="margin-right: 40px; text-align: end;">Enabled</div>
        <div class="d-flex justify-content-end"><RocSwitch :isActive="enabled" @switch-toggled="enabled = $event"/></div>
      </div>
    </div>
  </div>
  <div v-if="!isSaving" class="d-flex flex-row justify-content-end align-items-center" style="padding-top: 1rem; gap: var(--spacing-base)">
    <div class="d-flex statusText">{{statusText}}</div>
    <RocButton v-if="!newKeyReady" type="secondary" size="dialogButton" @click="closeDialog">Cancel</RocButton>
    <div class="d-flex">
      <RocButton type="primary" size="dialogButton" @click="actionClicked">{{ actionButtonLabel }}</RocButton>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from 'vuex';
import RocButton from "@/components/ui/RocButton.vue";
import RocSelect from "@/components/ui/RocSelect.vue";
import RocSwitch from "@/components/ui/RocSwitch.vue";
import RocInput from '@/components/ui/RocInput.vue';
import RocIcon from "@/components/ui/RocIcon.vue";
import RocTextArea from "@/components/ui/RocTextArea.vue";

export default {
  name: "ApiKeySettings",
  emits: ['save', 'close'],
  props: {
    mode: {
      type: String,
      default: 'create'
    },
    apiKeyEditing: Object
  },
  components: {
   RocButton, RocSelect, RocSwitch, RocInput, RocIcon, RocTextArea
  },
  setup(props, context) {
    const store = useStore();
    const label = ref(props.apiKeyEditing ? props.apiKeyEditing.label : '');
    const notes = ref(props.apiKeyEditing ? props.apiKeyEditing.notes : '');
    const selectedRoleId = ref(props.apiKeyEditing ? props.apiKeyEditing._userAccessId : '');
    const enabled = ref(props.apiKeyEditing ? props.apiKeyEditing.enabled : false);
    const roles = ref([]);
    const isSaving = ref(false);
    const statusText = ref('');
    const apikeyId = ref(props.apiKeyEditing ? props.apiKeyEditing.idkey : '');
    const newKeyReady = ref(false);
    const newApiKeyId = ref('');
    const newApiKeySecret = ref('');
    const isShowingSecret = ref(false);
    const clickToReveal = ref('<Click to Reveal>');
    const hidden = computed(() => {
      return props.mode === 'edit' ? '<hidden>' : '';
    });
    const isValid = computed(() => {
      return selectedRoleId.value;
    });
    const actionButtonLabel = computed(() => {
      if (newKeyReady.value) {
        return "Done";
      }
      return props.mode === 'create' ? 'Generate API Key' : 'Save Changes';
    });

    loadUserAccess();
    async function loadUserAccess() {
      isSaving.value = true;
      const response = await store.dispatch('settings/getUserAccess', 'apikey');
      if (response && response.userAccess) {
        roles.value = response.userAccess;
      }
      isSaving.value = false;
    }

    async function actionClicked() {
      if (newKeyReady.value) {
        context.emit('close');
      } else if (props.mode === 'edit') {
        await saveKey();
        context.emit('close');
      } else if (props.mode === 'create') {
        await generateKey();
      }
    }

    async function generateKey() {
      isSaving.value = true;
      try {
        const newKeyPayload = {
          label: label.value,
          notes: notes.value,
          _userAccessId: selectedRoleId.value,
          enabled: enabled.value
        }
        const response = await store.dispatch('settings/createApiKey', newKeyPayload);
        if (response && response.status === 'success') {
          newKeyReady.value = true;
          console.log(response.result);
          newApiKeyId.value = response.result.idkey;
          newApiKeySecret.value = response.result.secretkey;
        } else {
          newApiKeyId.value = '';
          newApiKeySecret.value = '';
          statusText.value = response ? response.message : 'Unable to Generate API Key';
          // clear status text after a few seconds sort of like a Toast
          setTimeout(()=> {
            statusText.value = "";
          }, 3000);
        }
      } catch(err) {
        console.log(err);
      }
      context.emit('save');
      isSaving.value = false;
    }

    async function saveKey() {
      isSaving.value = true;
      try {
        const updatedKeyPayload = {
          _id: props.apiKeyEditing._id,
          enabled: enabled.value
        };
          updatedKeyPayload.label = label.value;
          updatedKeyPayload.notes = notes.value;
        if (selectedRoleId.value) {
          updatedKeyPayload._userAccessId = selectedRoleId.value;
        }
        const updatedKeyResponse = await store.dispatch('settings/updateApiKey', updatedKeyPayload);
      } catch(err) {
        console.log(err);
      }
      context.emit('save');
      isSaving.value = false;
    }

    function copyStringToClipboard(value, type) {
      if (navigator.clipboard) { 
        // If normal copy method available, use it
        navigator.clipboard.writeText(value);
      } else { 
        unsecuredCopyToClipboard(value);
      }
      statusText.value = type + ' Copied!';
      setTimeout(()=> {
        statusText.value = "";
      }, 10000);
    }

    // no access to navigator when not running https
    // this uses an old method to copy to clipboard by adding an element and executing a command
    function unsecuredCopyToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus({preventScroll:true});
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error(err);
      }
      document.body.removeChild(textArea);
    }
    function closeDialog() {
      context.emit('close');
    }

    return {
      label,
      notes,
      selectedRoleId,
      roles,
      newKeyReady,
      actionClicked,
      statusText,
      isSaving,
      actionButtonLabel,
      enabled,
      apikeyId,
      hidden,
      newApiKeyId,
      newApiKeySecret,
      isValid,
      copyStringToClipboard,
      isShowingSecret,
      clickToReveal,
      closeDialog
    }
  }
}
</script>

<style scoped lang="scss">
.inputSection {
  padding: 12px;
}

.buttonRow {
  display: flex;
  padding-top: 1rem;
  justify-content: right;
  align-items: center;
}

.statusText {
  color: var(--overwatch-error);
  margin-right: 8px;
}
</style>