import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

export default class oidcAuthHelper {

    constructor(oidcConfig) {
        let external_url = `${oidcConfig?.url}${process.env.BASE_URL}`;

        const settings = {
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: oidcConfig?.iss,
            client_id: oidcConfig?.aud,
            scope: `openid profile email offline_access ${oidcConfig?.scp}`,
            redirect_uri: `${external_url}oidc-callback.html`,
            silent_redirect_uri: `${external_url}oidc-silent.html`,
            post_logout_redirect_uri: external_url,
        };

        this.userManager = new UserManager(settings);
    }

    async getUser() {
        return this.userManager.getUser();
    }

    async login() {
        return this.userManager.signinRedirect({ state: process.env.BASE_URL });
    }

    async logout() {
        this.userManager.removeUser();
        return this.userManager.signoutRedirect();
    }

}
