<template>
  <div v-if='isLoading'>
    <roc-spinner/>
  </div>
  <div class="container d-flex flex-column justify-content-center" style="height:90%">
    <JsonEditor
        v-model="editorValue"
        style="width: 100%; height: 100%; flex:1; margin:0px; padding: 0px;" 
        :style="{'filter': darkMode ? 'invert(1)' : 'invert(0)'}"
        class="json-editor"
        />
    <div class="d-flex justify-content-end" style="margin-top: 20px; gap: var(--spacing-s)">
      <RocButton  @click="$emit('close')" type="secondary">Close</RocButton>
      <RocButton @click="postSave">Save Settings</RocButton>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed  } from "vue";
import { useStore } from "vuex";
import JsonEditor from "json-editor-vue3";
import RocButton from "../ui/RocButton.vue";

export default {
  name: "WatchlistSettings",
  props: {
    watchlist: Object,
  },
  emits: ["close", "save"],
  components: {
    JsonEditor,
    RocButton
  },
  setup(props, context) {
    const store = useStore();

    const isLoading = ref(false);
    const originalConfig = ref(props.watchlist);

    const originalConfigCopy = computed(() => {
      const copy = originalConfig.value ? JSON.parse(JSON.stringify(originalConfig.value)) : {};
      // We don't want users to mess with these fields.
      // Hidden from editor for safety.
      const hiddenFields = ['_id', 'createdUTC', '__v', 'value', 'size', 'memberSummary'];

      for (let field of hiddenFields) {
        delete copy[field];
      }

      return copy;
    });
    const editorValue = ref(originalConfigCopy.value);


    function getAdvancedSettingChanges() {
      /**
       * Obtain setting changes made in advanced setting editor.
       * Recursively compare key values of advancedEditorValue with editCam.
       */
      let updateDoc = {};

      for (let [key, value] of Object.entries(editorValue.value)) {
        if (!editorValue.value[key]) {
          // user nulled this entry
          editorValue.value[key] = '';
        }
        if(!originalConfig.value[key]) {
          // new key, add full entry to updatedDoc
          updateDoc[key] = editorValue.value[key];
        } else {
          getAdvancedSettingChangesHelper(updateDoc, key, editorValue.value[key], originalConfig.value[key]);
        }
      }
      return updateDoc;
    }

    function getAdvancedSettingChangesHelper(update, key, editorValue, originalConfig) {
      /**
       * Recursion helper.
       *
       * @param {Object} update             - updateDoc
       * @param {String} key                - Current key
       * @param          editorValue        - editorValue object value for given key
       * @param          originalConfig     - originalConfig object value for given key
       *
       * @return {Boolean}      - Return whether children have at least one delta.
       */

      if (typeof(editorValue) !== typeof({})) {
        if (editorValue !== originalConfig) {
          update[key] = editorValue;
          return true;
        } else {
          return false;
        }
      }

      update[key] = {};

      let hasDelta = [];
      for (let [nextKey, nextValue] of Object.entries(editorValue)) {
        let result = getAdvancedSettingChangesHelper(update[key], nextKey, editorValue[nextKey], originalConfig[nextKey]);
        hasDelta.push(result);
      }
      if (hasDelta.some(e=>e)) {    // If at least one delta (one true) among children
        return true;
      } else {
        delete update[key];
        return false;               // If not at least one delta, delete key
      }
    }


    async function postSave() {
      isLoading.value = true;
      const changes = getAdvancedSettingChanges();
      const payload = {id: props.watchlist._id, update: changes};
      try {
        await store.dispatch('watchlists/updateWatchlist', payload);
        context.emit('save');
        context.emit('close');
      } catch {
        console.log("failed to save changes")
      }
      isLoading.value = false;
    }

    const darkMode = computed(() => store.getters['settings/getDarkMode']);

    return {
      editorValue,
      isLoading,
      postSave,
      darkMode
    }
  }
};
</script>

<style scoped lang="scss">

.saveSettingsButton {
  background: var(--overwatch-primary);

  @include overwatch-body-small;
}

.json-editor :deep(.json-editor-vue),
.json-editor :deep(.json-editor-vue) * {
  font-family: consolas, menlo, monaco, "Ubuntu Mono", source-code-pro, monospace;
}

@media (max-width: 480px) {
  .container {
    height: 90%;
  }
}

</style>