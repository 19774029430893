<template>
  <div class="wrapper">
    <div style="display: flex;" v-if="!routeName.includes('clustervideopopup') && !dialogMode">
      <RocIcon
        color="primary"
        icon="outsideLink"
        size="sm"
        style="margin-left: auto; cursor: pointer;"
        @click="openChildWindow"
      />
    </div>

    <div class="overwatch-title-med">
      {{ cluster?.name }}
    </div>

    <div class="camera-name">
      <RocIcon
        color="primary"
        icon="cameraOn"
        size="sm"
      />
      <div>
        {{ videoCameraName }}
      </div>
      <RocIcon
        v-if="!routeName.includes('clustervideopopup') && !dialogMode"
        color="black"
        icon="expand"
        size="sm"
        style="
          margin-left: auto;
          cursor: pointer;
        "
        @click="$emit('show-dialog')"
      />
    </div>

    <div class="video-player">
      <roc-spinner v-if="!isVideoPlayable" style="position: absolute; margin: 0;"/>
      <video ref="videoRef"
        autoplay
        :src="videoStreamEndpoint"
        :key="globalVideoPlaybackEncounter._id"

        @canplay="isVideoPlayable=true"

        @play="isVideoPlaying = true"
        @pause="isVideoPlaying = false;"

        @error="handleError"
        @timeupdate="handleTimeUpdate"
        style="margin-left: auto; margin-right: auto;"
      >
      </video>
      <div v-if="errorMessage.length > 0">
        {{ errorMessage }}
      </div>
      <div v-if="isVideoPlayable">
        <VideoSeekerBar class="seeker-bar"
          :currentTime="currentTime"
          :duration="duration"
          :intervals="encounterIntervals"
          :video-start="videoObject?.startMS"
          :video-end="videoObject?.stopMS"
          @pause="pause"
          @seek="updateCurrentTime"
        />
        <div style="padding: var(--spacing-base) var(--spacing-xs);">
          <VideoControls
            @play="play"
            @pause="pause"
            @forward10="skip10(1)"
            @rewind10="skip10(-1)"
            @fastForward="fastPlayback()"
            @rewind="rewind()"
            @next="skipToNextEncounter"
            @previous="skipToPreviousEncounter"
            :isVideoPlaying="isVideoPlaying"
            :currentTime="currentTime"
            :duration="duration"
          />
        </div>
        <ClusterVideoRoulette
          v-if="!dialogMode"
          ref="rouletteRef"
          :encounters="encountersInVideo"
          :encounterClusterLUT="encounterClusterDictionary"
          :currentTimestamp="currentTimeInTimestamp"
          :videoStart="videoObject?.startMS"
          @seek="rouletteSeek"
          @find-cluster="findCluster"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import VideoControls from '@/components/ui/VideoControls.vue';
import VideoSeekerBar from '@/components/ui/VideoSeekerBar.vue';
import ClusterVideoRoulette from '@/components/clusters/ClusterVideoRoulette.vue';
import RocIcon from '@/components/ui/RocIcon';

const props = defineProps({
  caseId: String,
  dialogMode: Boolean
});
const emit = defineEmits(['find-cluster', 'show-dialog']);

const store = useStore();
const router = useRouter();
const routeName = ref(router.currentRoute.value.path);

const videoRef = ref(null);
const isVideoPlayable = ref(false);

const rouletteRef = ref(null);

const cluster = computed(() => {
  const cluster = store.getters['clusters/clusters'].find(c => c.encounters.map(e=>e._id).includes(globalVideoPlaybackEncounter.value._id));
  return cluster;
});

const globalVideoPlaybackEncounter = computed(() => {
  return store.getters['clusters/videoPlaybackEncounter'];
});
watch(globalVideoPlaybackEncounter, nv => {
  reset();
  isVideoPlayable.value = false;

  if (childWindow.value) {
    const payload = {type: 'encounterChange', data: nv}
    childWindow.value.postMessage(JSON.stringify(payload));
  }
})

const videoStreamEndpoint = computed(() => {
  const videoId = globalVideoPlaybackEncounter.value.track_info.videoMediaId;
  return `/rest/v1/media/video/${videoId}/stream`;
});

const videoCameraName = computed(() => {
  if (videoObject.value && cameraObjects.value?.length > 0) {
    const cameraGUID = videoObject.value.cameraGUID;
    const camera = cameraObjects.value.find(c => c.GUID === cameraGUID);
    return camera.name;
  }
  return '';
})

const isVideoPlaying = ref(false);

 // Step to reset playback rate, rewind, etc.
 // Calling before every video action for consistency
function reset() {
  videoRef.value.playbackRate = 1.0;
  if (rewindInterval.value) {
    clearInterval(rewindInterval.value);
  }
}

function play() {
  reset();
  videoRef.value.playbackRate = 1.0;
  videoRef.value.play();
}

function pause() {
  reset();
  videoRef.value.pause();
}

function skip10(direction) {
  reset();
  videoRef.value.currentTime = videoRef.value.currentTime + (direction * 10);
}

function fastPlayback() {
  reset();
  videoRef.value.play();
  videoRef.value.playbackRate = 3.0;
}

const rewindInterval = ref();
function rewind() {
  // HTML5 doesn't technically support this. Must implement a custom rewind function.
  pause();

  videoRef.value.playbackRate = 1.0;

  rewindInterval.value = setInterval(() => {
    videoRef.value.currentTime = videoRef.value.currentTime - .1;

    // Valid JS I swear.
    if (videoRef.value.currentTime <= 0) {
      pause();
    }
  }, 100)
}

function updateCurrentTime(time) {
  videoRef.value.currentTime = time;
}

const errorMessage = ref('');
function handleError(e) {
  const errorCode = parseInt(e.target.error.message.split(":")[0]);
  switch (errorCode) {
    case 404:
      errorMessage.value = 'Video playback error. Context video does not exist.';
      break;
    default:
      errorMessage.value = 'Video playback error. Unknown error.';
  }
}

const currentTime = ref(0);
const duration = ref();
function handleTimeUpdate(event) {
  currentTime.value = event.target.currentTime;
  if (!duration.value) {
    duration.value = event.target.duration;
  }
}

/**
 * Find encounters in video
 */
const clusters = ref([]);
const videoObject = ref();
const cameraObjects = ref([]);
const encountersInVideo = ref([]);

const isLoadingVideoClusters = ref(false);
const isErrorLoadingVideoClusters = ref(false);

const encounterClusterDictionary = ref({});

watch(globalVideoPlaybackEncounter, async nv => {
  try {
    isLoadingVideoClusters.value = true;

    // Get Video
    const videoResponse = await store.dispatch('clusters/getVideoObjectByMediaId', nv.track_info.videoMediaId);
    if (videoResponse.status === 'success') {
      videoObject.value = videoResponse.result;
    } else {
      throw new Error('Error getVideoObjectbyMediaId');
    }

    // Get camera data
    const cameraResponse = await store.dispatch('cases/getCamerasByCaseId', {caseId: props.caseId});
    if (cameraResponse.status === 'success') {
      cameraObjects.value = cameraResponse.result;
    } else {
      throw new Error('Error getCamerasByCaseId');
    }

    // Get Clusters in Video
    const clusterResponse = await store.dispatch('clusters/getClustersByVideoMediaId', {
      caseId: props.caseId,
      mediaId: nv.track_info.videoMediaId
    });
    if (clusterResponse.status === 'success') {
      clusters.value = clusterResponse.result;
    } else {
      throw new Error('Error getClustersByVideoMediaId');
    }

    // Get Encounters of those clusters and filter so that we only get the ones in the video.
    let allEncountersOfClusters = [];
    for (let cluster of clusters.value) {
      allEncountersOfClusters.push(...cluster.encounters);

      // encounter:cluster look up table
      cluster.encounters.forEach(e => {
        encounterClusterDictionary.value[e._id] = cluster;
      });
    }
    allEncountersOfClusters = allEncountersOfClusters.filter(e => e.track_info.start_timestamp >= videoObject.value.startMS && e.track_info.start_timestamp <= videoObject.value.stopMS);

    encountersInVideo.value = allEncountersOfClusters;
    encountersInVideo.value.sort((a,b) => a.track_info.start_timestamp - b.track_info.start_timestamp);
  } catch(e){
    console.log(e)
    isErrorLoadingVideoClusters.value = true;
  }
  isLoadingVideoClusters.value = false;
}, {immediate: true});

// Calculating intervals to show on seeker bar from encounters
const encounterIntervals = computed(() => {
  if (encountersInVideo.value?.length > 0) {
    const mergedIntervals = [];
    const encounterTimestamps = encountersInVideo.value.map(e => {
      return [e.track_info.start_timestamp, e.track_info.stop_timestamp];
    });

    // Merging intervals
    for (let range of encounterTimestamps) {
      let length = mergedIntervals.length;

      // If there is a last interval in the mergedIntervals list
      // and if the current range start time is less than the previous range end time
      if (length > 0 && range[0] <= mergedIntervals[length - 1][1]) {
        // Update the previous range end time with the max of current range endtime or previous range endtime
        mergedIntervals[length - 1][1] = Math.max(range[1], mergedIntervals[length - 1][1]);
      } else {
        // If no previous range, just push current range
        mergedIntervals.push(range);
      }
    }

    return mergedIntervals;
  }
  return [];
});

/**
 * Encounter skipping code
 */

// Video's currentTime translated to unix timestamp
const currentTimeInTimestamp = computed(() => {
  const currentTimeInMilliseconds = currentTime.value * 1000;
  return currentTimeInMilliseconds + videoObject.value?.startMS;
});

// Find first encounter whose start_timestamp is after the currentTime
const nextEncounter = computed(() => {
  return encountersInVideo.value.find(e => e.track_info.start_timestamp > currentTimeInTimestamp.value);
});

// Find first encounter whose stop_timestamp is before the currentTime
const previousEncounter = computed(() => {
  return encountersInVideo.value.findLast(e => e.track_info.start_timestamp < currentTimeInTimestamp.value);
});

function skipToNextEncounter() {
  reset();
  if (nextEncounter.value) {
    updateCurrentTimeByTimestamp(nextEncounter.value.track_info.start_timestamp);
  }
}

function skipToPreviousEncounter() {
  reset();
  if (previousEncounter.value) {
    updateCurrentTimeByTimestamp(previousEncounter.value.track_info.start_timestamp);
  }
}

function rouletteSeek(timestamp) {
  pause();
  updateCurrentTimeByTimestamp(timestamp);
}

function updateCurrentTimeByTimestamp(timestamp) {
  const timestampInSeconds = timestamp / 1000;
  const videoStartTimeInSeconds = videoObject.value.startMS / 1000;
  videoRef.value.currentTime = timestampInSeconds - videoStartTimeInSeconds;
}

function findCluster(cluster) {
  emit('find-cluster', cluster);
}

/**
 * Window popup for videos
 */
const childWindow = ref();

function openChildWindow() {
  const routeData = router.resolve({name: 'ClusterEncounterVideoPopup'});
  childWindow.value = window.open(routeData.href, 'Context video', 'width=1200,height=700,left=300,top=200, resizable=yes');

  store.commit('clusters/setIsVideoPopupVisible', true);

  // Add an event listener to the parent window so when it closes it will close the child window
  window.addEventListener('beforeunload', closeChildWindow);

  // On logout
  window.addEventListener('logout', closeChildWindow);

  // Listen for messages from the child window
  window.addEventListener('message', messageEventHandler);
}

function closeChildWindow() {
  // Check if the child window is open before trying to close it
  if (childWindow.value && !childWindow.value.closed) {
      store.commit("clusters/setIsVideoPopupVisible", false);
      window.removeEventListener('message', messageEventHandler);
      childWindow.value.close();
  }
}

function messageEventHandler(event) {
  try {
    const eventObject = JSON.parse(event.data);

    if (eventObject.type === 'childReady') {
      const payload = {
        type: 'initialData',
        encounter: globalVideoPlaybackEncounter.value,
        clusters: clusters.value
      }
      childWindow.value.postMessage(JSON.stringify(payload));
    }

    if (eventObject.type === 'videoWindowClosed') {
      store.commit('clusters/setIsVideoPopupVisible', false);
    }

    if (eventObject.type === 'findCluster') {
      findCluster(eventObject.data);
    }
  } catch (e) {}
}

</script>

<style scoped lang="scss">

video {
  display: block;
}

.video-player {
  height: fit-content;
  width: 100%;
  border: solid 1px var(--overwatch-accent);

  position: relative;
}

.seeker-bar {
  width: 100%;
}

.camera-name {
  @include overwatch-body-med;
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-base);

  gap: var(--spacing-base);
}

</style>
