<template>
    <div class="loaderContainer">
        <div
        class="loader"
        :style="spinnerStyle">
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';

export default {
    name: 'RocSpinner',
    props: {
        size: {
            type: String,
            default: 'lg',
            validator(value) {
                return ['sm', 'lg', 'match'].includes(value);
            },
            note: 'lg = spinner for loading page'
        },
        color: {
            type: String,
            default: 'primary',
            validator(value) {
                return ['primary'].includes(value);
            }
        }
    },
    setup(props) {
        const computedSize = computed(() => {
        switch (props.size) {
            case 'sm':
            return '24px'
            case 'lg':
            return '64px'
            //allows the icon to be the size of the parent container
            case 'match':
            return '0'
        }
        });

        const computedColor = computed(() => {
        switch (props.color) {
            case 'primary':
            return 'var(--overwatch-primary)'
        }
        });

        const spinnerStyle = computed(() => {
            let borderWidth;
            if(props.size === 'sm'){
                borderWidth  = '3px';
            }
            else{
                borderWidth = '5px';
            }

            return {
                width: `${computedSize.value}`,
                height: `${computedSize.value}`,
                border: `${borderWidth} solid ${computedColor.value}`,
                borderBottomColor: 'transparent'
            }
        });

        return {
        computedSize,
        spinnerStyle
        }
    }
}
</script>
<style scoped lang="scss">
.loaderContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: var(--spacing-s);
}

.loader {
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation .6s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>