<template>
  <div>
    <div v-if='isLoading'>
      <roc-spinner/>
    </div>
    <div class="setting-field">
      <MDBInput class="setting-field" v-model="name" label="Name"/>
    </div>
    <div class="setting-field">
      <MDBSelect ref='selectRef' v-model:options="myOptions" v-model:selected="selectedProtocol"/>
    </div>
    <div class="setting-field">
      <MDBInput class="setting-field" v-model="host" label="Host" type="url"/>
    </div>
    <div class="setting-field">
      <MDBInput class="setting-field" v-model="port" label="Port" type="number" :maxlength="5" :inputMask="9"/>
    </div>
    <div class="setting-field" style="color: var(--overwatch-error);">
      <ul>
        <li class="status" v-for="message in errorMessages">
          {{ message }}
        </li>
      </ul>
    </div>
    <div class="buttons">
      <MDBBtn color="link" @click="clearData">Clear</MDBBtn>
      <MDBBtn color="link" @click="closeDialog">Discard</MDBBtn>
      <MDBBtn class="submit" @click="pressSave">Save</MDBBtn>
    </div>
  </div>
</template>

<script>
import { validate } from "@babel/types";
import { MDBBtn, MDBInput, MDBSelect } from "mdb-vue-ui-kit";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "LoginSettings",
  props: ["editingConfig"],
  emits: ["close", "save"],
  components: {
    MDBBtn,
    MDBInput,
    MDBSelect,
  },
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);

    const selectRef = ref(null);

    // Time should be good enough for this use case.
    const id = ref(props.editingConfig ? props.editingConfig.id : new Date().getTime());
    const name = ref(props.editingConfig ? props.editingConfig.name : '');
    const protocol = ref(props.editingConfig ? props.editingConfig.protocol : 'http://');
    const selectedProtocol = ref('');
    const host = ref(props.editingConfig ? props.editingConfig.host : '');
    const port = ref(props.editingConfig ? props.editingConfig.port : '');


    onMounted(() => {
      selectRef.value.setValue(protocol.value);
    });

    const errorMessages = ref(new Set());

    const myOptions = ref([
      { text: 'http://', value: 'http://' },
      { text: 'https://', value: 'https://' },
    ]);

    function closeDialog() {
      context.emit("close");
    }

    function clearData() {
      name.value = "";
      protocol.value = "";
      host.value = "";
      port.value = "";
    }

    function pressSave() {
      try {
        // check if any data entered - we allow all blanks which resets to default
        if (protocol.value || host.value || port.value) {
          // check host is entered - this is required - we can default protocol and port
          if (host.value) {
            scrubHost();
            scrubProtocol();
            scrubPort();
            // this theoretically confirms the entered data is a valid URL
            const url = new URL(protocol.value + host.value + ((port.value != '') ? (":" + port.value) : ''));
          } else {
            displayError("Host required.");
            return;
          }
        }
        // save valid values, even if blank

        const updatedConfig = {
          id: id.value,
          name: name.value,
          protocol: protocol.value,
          host: host.value,
          port: port.value
        }

        context.emit("save", updatedConfig)
        context.emit("close");

      } catch (_) {
        // exception creating URL() insance, must be invalid inputs
        displayError("Invalid data");
        return;
      }
    }
    /**
     * Helper function to scrub the user entry for host.
     */
    function scrubHost() {
      let hostValue = host.value;
      if (hostValue.endsWith("/")) {
        hostValue = hostValue.substring(0, hostValue.length-1);
      }
      if (hostValue.startsWith("https://")) {
        hostValue = hostValue.substring("https://".length, hostValue.length);
      }
      if (hostValue.startsWith("http://")) {
        hostValue = hostValue.substring("http://".length, hostValue.length);
      }
      host.value = hostValue;
    }
    /**
     * Helper function to scrub the user entry for protocol.
     * Currently allows entering http or https without the ://
     * TODO: check list of allowed protocols?  fix typos when its clear the user
     * intended http:// or https://?  Better yet, provide dropdown of protocols supported?
     */
    function scrubProtocol() {
      // default to lowercase 
      let protocolValue = selectedProtocol.value.toLowerCase();
      if (!protocolValue || protocolValue === '') {
        // default to HTTP
        protocolValue = 'http://';
      }
      // do not require user to enter :// - add here if not typed in
      if (protocolValue === 'http' || protocolValue === 'https') {
        protocolValue += '://';
      }
      protocol.value = protocolValue;
    }
    /**
     * Helper function to scrub the user entry for port.
     * Currently allows entering valid port or no port
     */
    function scrubPort() {
      let portValue = port.value;
      if (!portValue || portValue === '' || portValue === 0) {
        portValue = '';
      } else {
        // impose any other range checks on port
        if (Number(portValue) < 0 || Number(portValue) > 65535) {
          displayError('Port value outside range');
          throw 'Port value outside range';
        }
      }
      port.value = portValue;
    }

    function displayError(errorText) {
      errorMessages.value.add(errorText);
      // clear error msg after a few seconds sort of like a Toast
      setTimeout(()=> {
        errorMessages.value.delete(errorText);
      }, 5000);
    }

    return {
      isLoading,
      clearData,
      closeDialog,
      pressSave,
      name,
      protocol,
      host,
      port,
      errorMessages,
      myOptions,
      selectRef,
      selectedProtocol
    }
  }
};
</script>

<style scoped>

.setting-field {
  padding: 10px;
}
.buttons {
  display: flex;
  padding-top: 1rem;
  justify-content: right;
}

</style>