<template>
  <div>
    <div v-show="isExpanded" tabindex="1" open id="mydialog" ref="mydialog" class="mydialog center-screen">
      <header class="stick-to-top" id="mydialogheader" ref="mydialogheader">
        <div class="title">
          <slot name="header"></slot>
        </div>
        <div class="roc-dialog-close-icon" @click="minimizeComponent" style="margin-left: auto;">
          <RocIcon icon='minimize' size="sm"/>
        </div>
        <div class="roc-dialog-close-icon" @click="hideComponent">
          <RocIcon icon="exit" size="sm" style="width: 18px;"/>
        </div>

        <!-- <RocButton 
        type="secondary" 
        style='box-shadow: none !important; margin-left: auto; color: var(--overwatch-neutral-100)'
        @click='minimizeComponent'>
          <MDBIcon icon='minus' />
        </RocButton>
        <RocButton style="box-shadow: none !important;" @click="hideComponent">
          <RocIcon icon="exit" size="sm"/>
        </RocButton> -->
      </header>
      <slot :close="closeSignal"></slot>
    </div>
    <div v-show="!isExpanded" class="mydialog stick-to-bottom" @click="expandComponent" style="cursor:pointer">
      <header class="stick-to-top" id="minimizedheader">
        <div class="title">
          <slot name="header"></slot>
        </div>
        <div class="roc-dialog-close-icon" @click="expandComponent" style="margin-left: auto; margin-top: 0;">
          <RocIcon icon="add" size="sm"/>
        </div>
      </header>
    </div>
  </div>
</template>

<script>

import { useStore } from "vuex";
import { MDBIcon } from "mdb-vue-ui-kit";
import { ref, computed, watch, onMounted } from "vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocButton from "@/components/ui/RocButton.vue";

export default {
  name: "MinimizableDialog",
  props: ['expand'],
  components: {
    MDBIcon,
    RocIcon,
    RocButton
  },
  setup() {
    const store = useStore();

    const mydialog = ref();
    onMounted(() => {
      dragElement(mydialog.value);
    });

    // is Boolean, but any change is sending a close signal.
    const closeSignal = ref(false);
    function hideComponent() {
      closeSignal.value = !closeSignal.value;
    }

    const isExpanded = ref(true);

    function minimizeComponent() {
      isExpanded.value = false;
    }

    function expandComponent() {
      isExpanded.value = true;
    }
    
    const isImporterVisible = computed(() => {
      store.getters['cases/importerVisible'];
    })
    
    watch(isImporterVisible, () => {
      if (isImporterVisible.value) {
        dialogSetup();
      } else {
        cleanup();
      }
    });

    function dialogSetup() {
      let body = document.querySelector('body');
      body.classList.add("dialog-open"); 
  
      // Listen for refresh. Run cleanup on refresh so that body is scrollable.
      window.addEventListener('beforeunload', cleanup);
    }

    function cleanup() {
      window.removeEventListener('beforeunload', cleanup);

      let body = document.querySelector('body');
      body.classList.remove("dialog-open");
    }

    function dragElement(elmnt) {
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }

    return {
      mydialog,
      hideComponent,
      minimizeComponent,
      expandComponent,
      isExpanded,
      closeSignal
    }
  }
}

</script>

<style>
  /* Stop body from scrolling. */
  body.dialog-open {
    overflow: hidden;
  }
</style>

<style scoped>
.title {
  /* center title in middle of flexbox */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.stick-to-top {
  position: sticky;
  top: 0;
  z-index: 50;
}

.mydialog {
  z-index: 99;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-secondary);
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 2px;
  min-width: 500px;
}

#mydialogheader {
  display: flex;
  align-items: center;
  margin: 0 5px 0 5px;
}

#minimizedheader {
  display: flex;
  align-items: center;
  margin: 0 5px 0 5px;
}

.center-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.stick-to-bottom {  
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.roc-dialog-close-icon {
  margin-right: var(--spacing-m);
  margin-top: var(--spacing-s);
  cursor: pointer;

}

</style>