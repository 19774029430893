<template>
  <div class="popper-content">
    <div class="title">
      <div class="back-section">
        <div
          v-show="page !== 'home'"
          @click="page='home'"
          style="cursor: pointer;"
        >
          <RocIcon
            class="back-icon"
            color="black"
            size="xs"
            icon="downArrow"
          />
        </div>
      </div>
      <div v-if="page === 'home'">
        Filters
      </div>
      <div v-else-if="page === 'tags'">
        Tags
      </div>
      <div v-else-if="page === 'cameras'">
        Cameras
      </div>
    </div>
    <div class="divider"></div>
    <div class="body">
      <div v-if="page === 'home'" class="home">
        <div class="filter-row">
          <div>
            Tags
          </div>
          <div class="filter-count" @click="page='tags'">
            {{ computedAttributesCountText }}
            <div>
              <RocIcon
                class="go-icon"
                color="black"
                size="xs"
                icon="downArrow"
              />
            </div>
          </div>
        </div>
        <div class="filter-row" >
          <div>
            Cameras
          </div>
          <div class="filter-count" @click="page='cameras'">
            {{ computedCameraCountText }}
            <div>
              <RocIcon
                class="go-icon"
                color="black"
                size="xs"
                icon="downArrow"
              />
            </div>
          </div>
        </div>
        <div class="filter-row" >
          <div>
            Watchlist Matches Only
          </div>
          <div class="filter-count">
            <RocCheckbox v-model="isMatchFilterSelected"/>
          </div>
        </div>
        <div class="divider"></div>
        <div class="d-flex">
          <RocButton type="secondary" size="normal" style="margin-left: auto;" @click="$emit('clear')">
            Reset
          </RocButton>
        </div>
      </div>
      <!-- Tags Page -->
      <div v-else-if="page === 'tags'">
        <div class="input-rectangle">
          <filterMultiSelect encounterTagsMode class="select"
            mode="tags"
            placeholder-text="Select tags"
            :available-items="availableAttributes"
            @selection-changed="updateSelectedAttributes"
            :currently-selected="selectedAttributeFilters"
          />
          <RocButton @click="toggleTagsFilterType()" style="width: 100px;">
            {{ computedTagsFilterTypeText }}
          </RocButton>
        </div>
      </div>
      <!-- Cameras page -->
      <div v-else-if="page === 'cameras'">
        <div class="input-rectangle">
          <filterMultiSelect encounterTagsMode class="select"
            mode="tags"
            placeholder-text="Select cameras"
            :available-items="availableCameras"
            @selection-changed="updateSelectedCameras"
            :currently-selected="selectedCameraFilters"
          />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import RocIcon from '@/components/ui/RocIcon';
import filterMultiSelect from "@/components/ui/filterMultiSelect";
import RocCheckbox from '@/components/ui/RocCheckbox';
import RocButton from '@/components/ui/RocButton';
import {MDBBtn, MDBIcon} from 'mdb-vue-ui-kit';

export default {
  name: 'ClusteringFiltersPopperContent',
  components: {
    filterMultiSelect,
    RocIcon,
    MDBBtn,
    MDBIcon,
    RocCheckbox,
    RocButton
  },
  props: ['isOpen'],
  emits: ['tags-filter-change', 'camera-filter-change', 'watchlist-filter-change'],
  setup(props, context) {
    const store = useStore();

    const page = ref('home');

    onMounted(async () => {
      await store.dispatch('clusters/getFaceAttributes');
    })

    const mission = computed(() => {
      return store.getters['clusters/mission'];
    });

    const selectedCameraFilters = computed(() => {
      return store.getters['clusters/cameraFilters'];
    });
    const availableCameras = ref();
    function updateSelectedCameras(selectedCams) {
      store.commit("clusters/setCameraFilters", selectedCams.value);
      context.emit("camera-filter-change", selectedCams);
    }
    const computedCameraCountText = computed(() => {
      const length = selectedCameraFilters.value.length;
      if (length > 0) {
        return `${length} selected`;
      } else {
        return 'None';
      }
    });

    watch(mission, async nv => {
      if (nv) {
        let success = false;
        let retries = 0;

        // Adding this while loop here due to report that
        // cameras don't load in select sometimes.
        while (!success && retries < 3) {
          let response = await store.dispatch("cases/getCamerasByCaseId", {caseId: nv._id});
          if (response && response.status === 'success') {
            availableCameras.value = response.result;
            availableCameras.value.forEach(c => {
              c.value = c.GUID;
              c.label = c.name;
            })
            success = true;
          } else {
            retries++;
          }
        }
      }
    });

    const tagsFilterType = ref('and');
    function toggleTagsFilterType() {
      if (tagsFilterType.value === 'or') {
        tagsFilterType.value = 'and';
      } else {
        tagsFilterType.value = 'or';
      }
      updateSelectedAttributes(selectedAttributeFilters.value);
    }
    const computedTagsFilterTypeText = computed(() => {
      switch (tagsFilterType.value) {
        case 'and':
          return 'All';
        case 'or':
          return 'Any';
        default:
          return 'Any';
      }
    })

    const selectedAttributeFilters = computed(() => {
      if (store.getters["clusters/attributeFilters"]) {
        if (store.getters["clusters/attributeFilters"].andTags) {
          tagsFilterType.value = 'and';
          return store.getters["clusters/attributeFilters"].andTags;
        } else if (store.getters["clusters/attributeFilters"].orTags) {
          tagsFilterType.value = 'or';
          return store.getters["clusters/attributeFilters"].orTags;
        }
      }
      return [];
    })

    const availableAttributes = computed(() => {
      return store.getters['clusters/allFaceAttributes'].map(t => {
        return {
          label: t,
          value: t
        }
      })
    });

    function updateSelectedAttributes(selectedAttributes) {
      if (tagsFilterType.value === 'and') {
        store.commit("clusters/setAttributeFilters", { andTags: selectedAttributes });
      } else if (tagsFilterType.value === 'or') {
        store.commit("clusters/setAttributeFilters", { orTags: selectedAttributes });
      }
      context.emit('tags-filter-change');
    }

    const computedAttributesCountText = computed(() => {
      const length = selectedAttributeFilters.value.length;
      if (length > 0) {
        return `${length} selected`;
      } else {
        return 'None';
      }
    });

    const isMatchFilterSelected = ref(store.getters['clusters/watchlistMatchFilter']);
    watch(isMatchFilterSelected, nv => {
      store.commit('clusters/setWatchlistMatchFilter', nv);
      context.emit('watchlist-filter-change');
    });
    watch(() => store.getters['clusters/watchlistMatchFilter'], nv => {
      isMatchFilterSelected.value = nv;
    });

    watch(() => props.isOpen, nv => {
      if (nv) {
        page.value = 'home';
      }
    })

    return {
      page,
      availableCameras,
      availableAttributes,
      updateSelectedAttributes,
      updateSelectedCameras,
      selectedCameraFilters,
      selectedAttributeFilters,
      computedAttributesCountText,
      computedCameraCountText,
      tagsFilterType,
      toggleTagsFilterType,
      isMatchFilterSelected,
      computedTagsFilterTypeText

    }
  }
}
</script>

<style scoped lang="scss">

.popper-content {
  height: 320px;
  width: 450px;
}

.title {
  @include overwatch-title-med;
  padding: $--spacing-l $--spacing-xl;
  padding-left: 0;
  padding-bottom: $--spacing-s;

  display: flex;
}

.back-section {
  height: 100%;
  width: $--spacing-xl;

  display: flex;
  align-items: center;
  justify-content: center;

}

.back-icon {
  cursor: pointer;
  transform: rotate(-270deg);
}

.go-icon {
  cursor: pointer;
  transform: rotate(270deg);

}

.divider {
  margin: 0;
  margin-top: var(--spacing-base);
  line-height: 0;
  border-top: 1px solid var(--overwatch-accent);
}

.body {
  height: 100%;
  width: 100%;

  padding: $--spacing-l $--spacing-xl;
  padding-top: $--spacing-s;
}

.home {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.filter-row {
  @include overwatch-body-large;

  display: flex;
  justify-content: space-between;
}

.filter-count {
  @include overwatch-body-med;
  display: flex;
  align-items: center;
  gap: $--spacing-s;
  cursor: pointer;
}

.input-rectangle {
  box-sizing: border-box;
  height: auto;
  width: 100%;
  border-radius: 5px;
  border: solid 1px var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);

  display: flex;
  align-items: center;
  padding: $--spacing-base;
  gap: 10px;
}

.select {
  height: 100%;
  width: 100%;
}

.select :deep(svg) {
  color: var(--overwatch-neutral-100);
}


</style>
