<template>
  <div class="rectangle fade-in" @click="emitTimestamp(detection.timestamp)">
    <div v-if="detection.type === 'face' || detection.type === 'object'" style="min-width: 90px;">
      <div class="colorcode-rect" style="background-color: var(--overwatch-neutral-300) !important;"></div>
      <div style="padding: 4px;">
        <div class="time">{{ formattedEncounterTime(detection.timestamp) }}</div>
        <auth-img class="bestshot" :src="detection.tn"/>
        <div v-if="detection.type === 'object'">
          <div v-if="detection.label === 'license_plate'" style="margin-top: 14px;" class="d-flex justify-content-start">
            <div style=" font-size: 20px;">
              {{ detection.md.Text }}
            </div>
          </div>
          <div v-else class="match-name-text">
            {{ detection.label }} <br>
            <span class="overwatch-body-xxsmall">Detected</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="detection.type === 'face_match'" style="min-width: 136px;">
      <div class="colorcode-rect" style="background-color: var(--overwatch-error) !important;"></div>
      <div style="position: relative; padding: 4px;">
        <div class="time">{{ formattedEncounterTime(detection.timestamp) }}</div>
        <auth-img class="bestshot" :src="detection.probeFaceTN" style="margin-right: 4px;"/>
        <auth-img class="bestshot" :src="detection.tn"/>
        <div>
          <div :style='setBackgroundColorByScore()' class='numberCircle'>{{ formatMatchScore(detection.similarity) }}</div>
        </div>
        <div class="match-name-text">
          {{ detection.firstname }} {{ detection.lastname }}
        </div>
        <div class="match-watchlist-text">
         <span>from</span> {{ detection.watchlistName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthImg from "@/components/ui/AuthImg";

export default {
  name: "Detection",
  components: { AuthImg },
  emits: ["timestamp-clicked"],
  props: {
    detection: {
      type: Object,
      required: true,
    }
  },
  setup(props, context) {
    //console.log("detection: ", props.detection);
    function emitTimestamp(timestmap) {
      context.emit("timestamp-clicked", timestmap);
    }

    function formattedEncounterTime(time) {
      const eventDate = new Date(time);
      return eventDate.toLocaleTimeString([], { hour12: true }).split(' ')[0] + '.' + ('0' + eventDate.getMilliseconds()).slice(-3) + ' ' + ((eventDate.getHours() >= 12) ? 'PM' : 'AM');// .toString() + ':' + ('0' + eventDate.getMilliseconds()).slice(-3) + ' ' + (eventDate.getHours() >= 12) ? 'PM' : 'AM'; //+ ':' + eventDate.getMilliseconds();
    }

    function setBackgroundColorByScore(similarity) {
      let bgStyle = 'background: var(--roc-watch-similarity-90);';
      if (similarity) {
        if (similarity < 90 && similarity >= 70) {
          bgStyle = 'background: var(--roc-watch-similarity-70);';
        }
        else if (similarity < 70) {
          bgStyle = 'background: var(--roc-watch-similarity-70);';
        }
      }

      return bgStyle;
    }

    function formatMatchScore(score) {
      if (score != null) {
        return ((score) * 100).toFixed(1);
      }
      else return null;
    }

    return {
      emitTimestamp,
      formattedEncounterTime,
      setBackgroundColorByScore,
      formatMatchScore
    }
  }
};
</script>

<style scoped lang="scss">
.time {
  color: #000000;
  @include overwatch-body-xsmall;
  margin-top: 2px;
  margin-bottom: 2px;
}

.bestshot {
  height: 80px;
  width: auto;
}

.rectangle {
  box-sizing: border-box;
  height: 180px;
  background-color: var(--overwatch-secondary);
  overflow: hidden;
}

.colorcode-rect {
  width: 100%;
  height: 10px;
}

.numberCircle {
  width: 30px;
  line-height: 28px;
  border-radius: 50%;
  text-align: center;
  font-size: 11px;
  background: var(--overwatch-success);
  position: absolute;
  top: 90px;
  left: 51px;
}

.match-name-text {
  color: #000000;
@include overwatch-body-small;
  margin-top: 18px;
  max-width: 140px;
  overflow: hidden;
}

.match-watchlist-text {
  color: #000000;
  @include overwatch-body-xxsmall;
  margin-top: 0px;
  max-width: 140px;
  overflow: hidden;
}

.lpr-text {
  color: #000000;
 @include overwatch-body-large;
  margin-top: 18px;
  max-width: 140px;
  overflow: hidden;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: .2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>