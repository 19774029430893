import { apiCall } from '../../utils/utils';

export default {
  async search(context, payload) {
    const url = '/rest/v1/investigation/search';

    const formData = new FormData();

    formData.append('caseId', payload.caseId);
    formData.append('probe', payload.probeFile);
    formData.append('mediaIds', JSON.stringify(payload.mediaIds));
    formData.append('watchlists', JSON.stringify(payload.watchlists));
    const responseData = await apiCall(context, url, 'POST', formData);

    return responseData;
  },
  async createCase(context, payload) {
    payload.type = 'investigation';

    payload.investigation = {
      isOpen: true,
      priority: payload.priority,
      precinct: payload.precinct
    }

    delete payload.priority;
    delete payload.precinct;

    const responseData = await apiCall(context, `/rest/v1/case`, 'POST', JSON.stringify(payload));
    if (responseData.status === "success") {
      context.commit('addNewCase', responseData.result);
    }
    return responseData;
  },
  async getCases(context) {
    const responseData = await apiCall(context, '/rest/v1/investigation/cases', 'GET');
    if (responseData.status === "success") {
      context.commit('setCases', responseData.result);
    }
    return responseData;
  },

  /** TODO: For now, we pass priority in with the payloads here.
   * Should fix to only change the isOpen / priority properties at a time.
   * Usually you'd target "investigation.isOpen", but some package on node is blocking
   * setting key names with periods ('.')
   */
  async closeCase(context, payload) {
    const responseData = await apiCall(context, `/rest/v1/case/${payload.caseId}`, 'PUT', {
      "investigation": {
        isOpen: false,
        priority: payload.priority
      }

    });

    if (responseData.status === 'success') {
      const c = context.getters['cases'].find(c => c._id === payload.caseId);
      c.investigation.isOpen = false;
      context.commit('replaceCase', c);
    }
    return responseData;
  },
  async openCase(context, payload) {
    const responseData = await apiCall(context, `/rest/v1/case/${payload.caseId}`, 'PUT', {
      "investigation": {
        isOpen: true,
        priority: payload.priority
      }
    });

    if (responseData.status === 'success') {
      const c = context.getters['cases'].find(c => c._id === payload.caseId);
      c.investigation.isOpen = true;
      context.commit('replaceCase', c);
    }

    return responseData;
  },
  async setCasePriority(context, payload) {
    const responseData = await apiCall(context, `/rest/v1/case/${payload.caseId}`, 'PUT', {
      'investigation': {
        isOpen: payload.isOpen,
        priority: payload.priority
      }
    });

    if (responseData.status === 'success') {
      const c = context.getters['cases'].find(c => c._id === payload.caseId);
      c.investigation.priority = payload.priority;
      context.commit('replaceCase', c);
    }

    return responseData;
  },
  /******/
  async deleteCase(context, payload) {
    const responseData = await apiCall(context, `/rest/v1/investigation/${payload.caseId}`, 'DELETE');

    if (responseData.status === 'success') {
      context.commit('deleteCase', {caseId: payload.caseId});
    }
    return responseData;
  },
  async getRecords(context, payload) {
    const responseData = await apiCall(context, `/rest/v1/investigation/${payload.caseId}/records`, 'GET');
    return responseData;
  },
  async deleteRecord(context, payload) {
    const responseData = await apiCall(context, `/rest/v1/investigation/${payload.caseId}/record/${payload.recordId}`, 'DELETE');
    return responseData;
  },
  async deleteMedia(context, payload) {
    const responseData = await apiCall(context, `/rest/v1/investigation/media/${payload.mediaId}`, 'DELETE');

    return responseData;
  },
  async getAssociatedWatchlistedFaces(context, payload) {
    const responseData = await apiCall(context, `/rest/v1/watchlistedFace/${payload.id}/associated`)

    return responseData;
  },
  async getCaseStatus(context, payload) {
    const responseData = await apiCall(context,
      `/rest/v1/case/${payload.caseId}/status`,
      'GET'
    );

    return responseData;
  },
  async getCamerasByCaseId(context, payload) {
    const responseData = await apiCall(context,
      `/rest/v1/case/${payload.caseId}/cameras`,
      'GET'
    );
    return responseData;
  },
  async getCaseMediaByCaseId(context, payload) {
    const responseData = await apiCall(context,
      `/rest/v1/case/${payload.caseId}/media`,
      'GET'
    );
    return responseData;
  }

}
