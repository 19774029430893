
const BASE64_MARKER = ";base64,";
const BASE64_MARKER_JPG = "data:image/jpeg;base64,";
/*
* Helper function to modify base64 uri string to remove prefix and return base64 data only
*/
export function convertDataUriToString(base64Uri) {
  const markerIndex = base64Uri ? base64Uri.indexOf(BASE64_MARKER) : -1;
  if (markerIndex != -1) {
    const base64Index = markerIndex + BASE64_MARKER.length;
    return base64Uri.substring(base64Index);
  } else {
    return base64Uri;
  }
}
/**
 * Convert base64 String into valid URI by appending prefix if needed
 * @param {String} base64String 
 * @returns 
 */
export function convertStringToDataUri(base64String) {
  const markerIndex = base64String ? base64String.indexOf(BASE64_MARKER) : -1;
  if (markerIndex != -1) {
    // already URI formatted
    return base64String;
  } else {
    // prepend marker
    return (BASE64_MARKER_JPG + base64String);
  }
}
/*
* Helper function to check string for base64 URI prefix
*/
export function isBase64DataUri(base64Uri) {
  return base64Uri.indexOf(BASE64_MARKER) != -1;
}
/*
* Helper function to use native browser functionality to load image blob and build base64 URI
*/
export function blobToBase64Uri(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}
/*
* Helper function to calculate size in bytes of a given object
*/
export function memorySizeOf(obj, print=true) {
  let bytes = 0;
  function sizeOf(obj) {
    if(obj !== null && obj !== undefined) {
      switch(typeof obj) {
        case 'number':
          bytes += 8;
          break;
        case 'string':
          bytes += obj.length * 2;
          break;
        case 'boolean':
          bytes += 4;
          break;
        case 'object':
          let objClass = Object.prototype.toString.call(obj).slice(8, -1);
          if(objClass === 'Object' || objClass === 'Array') {
            for(let key in obj) {
              if(!obj.hasOwnProperty(key)) {
                continue;
              }
              sizeOf(obj[key]);
            }
          } else {
            bytes += obj.toString().length * 2;
          }
          break;
      }
    }
    return bytes;
  };
  function formatByteSize(bytes) {
    if(bytes < 1024) return bytes + " bytes";
    else if(bytes < 1048576) return(bytes / 1024).toFixed(3) + " KiB";
    else if(bytes < 1073741824) return(bytes / 1048576).toFixed(3) + " MiB";
    else return(bytes / 1073741824).toFixed(3) + " GiB";
  };
  return print ? formatByteSize(sizeOf(obj)) : sizeOf(obj);
}

export function printSizeOf(bytes) {
  if(bytes < 1024) return bytes + " bytes";
  else if(bytes < 1048576) return(bytes / 1024).toFixed(3) + " KiB";
  else if(bytes < 1073741824) return(bytes / 1048576).toFixed(3) + " MiB";
  else return(bytes / 1073741824).toFixed(3) + " GiB";
}