<template>
  <div class="case-card d-flex justify-content-between">
    <div>
      <MDBDropdown v-model="isShowingPriorityDropdown">
        <MDBDropdownToggle style="-webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;"
          tag='a'
          @click="isShowingPriorityDropdown = !isShowingPriorityDropdown"
        >
          <RocIcon v-if="priority === 'low'" icon="priority" color="none" style="color: var(--roc-watch-similarity-90)" />
          <RocIcon v-if="priority === 'medium'" icon="priority" color="none" style="color: var(--roc-watch-similarity-70)" />
          <RocIcon v-if="priority === 'high'" icon="priority" color="none" style="color: var(--roc-watch-similarity-0)" />
        </MDBDropdownToggle>
        <RocDropdownMenu aria-labelledby='dropdownMenuButton'>
          <MDBDropdownItem href="" @click.prevent="setCasePriority('low')" :active="priority === 'low'">
            <div class="overwatch-body-small d-flex align-items-center" :class="{'prioritySelected': priority==='low'}">
              <RocIcon icon="priority" color="none" style="color: var(--roc-watch-similarity-90); margin-right: var(--spacing-s)"/>
              Low
            </div>
          </MDBDropdownItem>
          <MDBDropdownItem href="" @click.prevent="setCasePriority('medium')" :active="priority === 'medium'">
            <div class="overwatch-body-small d-flex align-items-center" :class="{'prioritySelected': priority==='medium'}">
              <RocIcon icon="priority" color="none" style="color: var(--roc-watch-similarity-70); margin-right: var(--spacing-s)"/>
              Medium
            </div>
          </MDBDropdownItem>
          <MDBDropdownItem href="" @click.prevent="setCasePriority('high')" :active="priority === 'high'">
            <div class="overwatch-body-small d-flex align-items-center" :class="{'prioritySelected': priority==='high'}">
              <RocIcon icon="priority" color="none" style="color: var(--roc-watch-similarity-0); margin-right: var(--spacing-s)"/>
              High
            </div>
          </MDBDropdownItem>

        </RocDropdownMenu>
      </MDBDropdown>
    </div>
    <div>
      <div v-if="selectAll"><RocCheckbox></RocCheckbox></div>
      {{caseName}}
    </div>
    <div>
      {{crimeClassification}}
    </div>
    <div>
      {{dateCreatedFormatted}}
    </div>
    <div>
      {{ dateEditedFormatted }}
    </div>
    <div>
      {{`${user.lastname}, ${user.firstname}`}}
    </div>
    <div>
      {{ precinct }}
    </div>
    <div>
      {{ recordCount }}
    </div>
    <div>
      <div :style="isOpenStyle(isOpen)" class="isOpenBadge">
        {{ isOpen ? "Open" : "Closed" }}
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center; margin-left: auto; width: fit-content; flex: 0;">
      <RocButton @click="goToCaseRecords()" style="padding: var(--spacing-s) var(--spacing-s)">
        <div style="display: flex; align-items: center;">
          <RocIcon icon="view" size="sm" color="white"/>
        </div>
      </RocButton>
      <div class="dropdown">
        <MDBDropdown v-model='dropdownOptions' align='end'>
          <MDBDropdownToggle
          style='color:black;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;'
          tag='a'
          @click="dropdownOptions = !dropdownOptions">
          <div>
            <span class="Card-Options"><RocIcon icon="kebab" size="sm" color="black"/></span>
          </div>
          </MDBDropdownToggle>
          <RocDropdownMenu aria-labelledby='dropdownMenuButton' style="min-width: unset; width: 140px;">
            <MDBDropdownItem v-if="isOpen" href="" @click.prevent="openCloseDialog">
              <div class="overwatch-body-small d-flex align-items-center">
                Close Case
                <RocIcon icon="nomatch" size="sm" style="margin-left: auto;"/>
              </div>
            </MDBDropdownItem>
            <MDBDropdownItem v-else href="" @click.prevent="openCase">
              <div class="overwatch-body-small d-flex align-items-center">
                Open Case
              </div>
            </MDBDropdownItem>
            <MDBDropdownItem href="" @click.prevent="openDeleteDialog" v-if="isAdminOrPowerUser">
              <div class="overwatch-body-small d-flex align-items-center" style="color:var(--overwatch-error);">
                Delete Case
                <RocIcon icon="trash" size="sm" color="red" style="margin-left: auto"/>
              </div>
            </MDBDropdownItem>
          </RocDropdownMenu>
        </MDBDropdown>
      </div>
    </div>
    <BaseDialog v-if="isShowingCloseDialog" :hideCloseBtn="true" show @close="isShowingCloseDialog=false;">
      <div>
        <div class="d-flex flex-row" style="margin-top: 30px">
          <div style="margin-right: 12px">
            <RocIcon icon="trash" size="md" color="red"/>
          </div>
          <div class="overwatch-body-large" style="width:300px; flex-wrap: wrap">
            <div>
              Are you sure you want to close Case <span style="color: var(--overwatch-error)">{{ caseName }}</span>?
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: flex-end; gap: var(--spacing-s); margin-top: var(--spacing-m);">
          <RocButton class="ButtonSecondaryDefault" @click="closeCloseDialog"><span class="Save">No</span></RocButton>
          <RocButton class="ButtonPrimaryNormal" @click="closeCase"><span class="Save">Yes</span></RocButton>
        </div>
      </div>
    </BaseDialog>
    <BaseDialog v-if="isShowingDeleteDialog" :hideCloseBtn="true" show @close="isShowingDeleteDialog=false;">
      <div>
        <div class="d-flex flex-row" style="margin-top:30px">
          <div style="margin-right: 12px">
            <RocIcon icon="trash" size="sm" color="red"/>
          </div>
          <div class="overwatch-body-large" style="width:300px; flex-wrap: wrap">
            <div>
              Are you sure you want to delete Case <span style="color: var(--overwatch-error)">{{ caseName }}</span>?
            </div>
            <div style="color: var(--overwatch-error)">
              This action cannot be undone.
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: flex-end; gap: var(--spacing-s); margin-top: var(--spacing-s);">
          <RocButton class="ButtonSecondaryDefault" @click="closeDeleteDialog"><span class="Save">No</span></RocButton>
          <RocButton class="ButtonPrimaryNormal" @click="deleteCase" style="background-color: var(--overwatch-error)"><span class="Save">Delete</span></RocButton>
        </div>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import RocButton from '@/components/ui/RocButton.vue';
import RocCheckbox from '@/components/ui/RocCheckbox.vue';
import BaseDialog from '@/components/ui/BaseDialog.vue';
import { MDBDropdown, MDBDropdownItem, MDBDropdownToggle } from "mdb-vue-ui-kit";
import RocIcon from '@/components/ui/RocIcon.vue';
import RocDropdownMenu from '../ui/RocDropdownMenu.vue';

import userAccessHelper from "@/js/userAccessHelper";

export default {
  name: 'InvestigationCard',
  components:{
    RocButton,
    RocCheckbox,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    BaseDialog,
    RocIcon,
    RocDropdownMenu
  },
  props: {
    case:{
      type: Object,
      default: null
    },
    caseId: {
      type: String,
      default: ''
    },
    caseName: {
      type: String,
      default: ''
    },
    crimeClassification:{
      type: String,
      default: ''
    },
    dateCreated:{
      type: String,
      default: ''
    },
    dateEdited:{
      type: String,
      default: ''
    },
    user: {
      type: Object,
      default: ''
    },
    precinct: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: true
    },
    priority: {
      type: String,
      default: 'low'
    },
    selectAll: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeCase', 'deleteCase', 'openCase', 'changePriority'],
  setup(props, context){
    const store = useStore();
    const router = useRouter();

    const dropdownOptions = ref(false);

    const isShowingCloseDialog = ref(false);
    const isShowingDeleteDialog = ref(false);

    const dateCreatedFormatted = computed(function () {
      if (props.dateCreated) {
        let x = new Date(props.dateCreated);
        return x.toLocaleString().split(',')[0];
      }
      else {
        return '';
      }
    });

    const dateEditedFormatted = computed(() => {
      if (props.dateEdited) {
        let x = new Date(props.dateEdited);
        return x.toLocaleString().split(',')[0];
      }
      else {
        return '';
      }
    })

    function openCloseDialog() {
      isShowingCloseDialog.value = true;
      dropdownOptions.value = false;
    }

    function closeCloseDialog() {
      isShowingCloseDialog.value = false;
    }

    function openDeleteDialog() {
      isShowingDeleteDialog.value = true;
      dropdownOptions.value = false;
    }

    function closeDeleteDialog() {
      isShowingDeleteDialog.value = false;
    }

    async function closeCase() {
      context.emit('closeCase')
      isShowingCloseDialog.value = false;
      dropdownOptions.value = false;
    }

    async function deleteCase() {
      context.emit('deleteCase');
      isShowingDeleteDialog.value = false;
      dropdownOptions.value = false;
    }

    async function openCase() {
      context.emit('openCase');
      dropdownOptions.value = false;
    }

    function goToCaseRecords() {
      router.push( {name: 'InvestigativeCaseRecords', params: {caseId: props.caseId}});
    }

    const recordCount = ref(0);
    async function getRecords() {
      const response = await store.dispatch('investigations/getRecords', {caseId: props.caseId});

      if (response.status === 'success') {
        recordCount.value = response.result.length;
      }
    }
    onMounted(async () => {
      await getRecords();
    })

    const isShowingPriorityDropdown = ref(false);

    function setCasePriority(priority) {
      context.emit('changePriority', priority);
      isShowingPriorityDropdown.value = false;
    }

    const isAdminOrPowerUser = computed(() => {
      const helper = new userAccessHelper();
      return helper.isAdminOrPowerUser();
    });

    function isOpenStyle(isOpen) {
      if (isOpen) {
        return "border: solid 1px var(--overwatch-button-primary); background-color: var(--overwatch-button-primary-20); color: var(--overwatch-button-primary);"
      } else {
        return "border: solid 1px var(--overwatch-neutral-300); background-color: var(--overwatch-secondary); color: var(--overwatch-neutral-300); "
      }
    }

    return {
      dropdownOptions,
      isShowingCloseDialog,
      openCloseDialog,
      closeCloseDialog,
      closeCase,
      dateCreatedFormatted,
      dateEditedFormatted,
      goToCaseRecords,
      recordCount,
      isShowingDeleteDialog,
      openDeleteDialog,
      closeDeleteDialog,
      openCase,
      deleteCase,
      isShowingPriorityDropdown,
      setCasePriority,
      isAdminOrPowerUser,
      isOpenStyle
    }
  }
}
</script>

<style scoped lang="scss">
.case-card{
  height: 72px;
  padding: 0px 24px;
  /* first card */
  /* background-color: rgba(63, 63, 217, 0.1); */
  /* second card */
  border-bottom: 1px solid var(--overwatch-accent);
  align-items: center;
}

.case-card > div {
  flex: 1;
}
.dropdown {
  align-items: center;
}

.Card-Options {
  width: 16px;
  height: 16px;
  margin: 12px 0 14px 12px;
  padding: 0 0 0 12px;
}

/* TODO - below 3 items are from saved views for speed of coding - replace style with RocButton later */

.Save {
  height: 21px;
  flex-grow: 1;
  @include overwatch-body-small;
  text-align: center;
  color: var(--overwatch-button-text);
}

.ButtonSecondaryDefault {
  width: 115px;
  gap: 10px;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--overwatch-neutral-300);
}
.ButtonPrimaryNormal {
  width: 115px;
  gap: 10px;
  padding: 12px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--overwatch-button-primary);
}
.prioritySelected {
  color: var(--overwatch-button-primary);
}
.isOpenBadge {
  border-radius: 50px;
  padding: var(--spacing-base) var(--spacing-s);
  width: fit-content;
}
</style>