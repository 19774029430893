import { apiCall, readAsArrayBuffer } from "../../utils/utils"

export default {
  async loadCases(context) {
    const responseData = await apiCall(context, `/rest/v1/cases`, 'GET');
    if (responseData.status === 'success') {
      context.commit('setCases', responseData.result);
      context.commit('setLoadedOnce', true);
      // when we reload cases, be sure to update active mission to latest
      const activeMission = context.getters['activeMission'];
      if (activeMission) {
        const updatedActiveMission = responseData.result.find(c => c._id === activeMission._id);
        if (updatedActiveMission) {
          context.commit('setActiveMission', updatedActiveMission);
        } else {
          // activeMission no longer exists.
          context.commit('setActiveMission', null);
        }
      }
    }
  },
  async getCaseById(context, payload) {
    const responseData = await apiCall(context, `/rest/v1/case/${payload}`, 'GET');
    return responseData;
  },
  async createCase(context, payload) {
    const responseData = await apiCall(context, `/rest/v1/case`, 'POST', JSON.stringify(payload));
    if (responseData.status === "success") {
      context.commit('addNewCase', responseData.result);
      // if we don't have an active mission, set it to the newly created one
      if (!context.getters['activeMission']) {
        context.commit('setActiveMission', responseData.result);
      }
    }
    return responseData;
  },
  async updateCase(context, payload) {
    const id = payload.id;
    delete payload.id;

    const responseData = await apiCall(context, `/rest/v1/case/${id}`, 'PUT', JSON.stringify(payload));

    if (responseData.status === "success") {
      payload._id = id;
      context.commit('replaceCase', payload);
    }
    return responseData;
  },
  async deleteCase(context, payload) {
    const responseData = await apiCall(context, `/rest/v1/case/${payload.id}`, 'DELETE');
    context.commit('setIsCaseProcessing', false);
    if (responseData.status === 'success') {
      context.commit('deleteCase', {id: payload.id});

      if (context.getters['activeMission']?._id === payload.id) {
        context.commit('setActiveMission', null);
      }
    }
  },
  async startProcessingCase(context, payload) {
    const responseData = await apiCall(context,
      `/rest/v1/case/${payload.caseId}/start`,
      'POST'
    );
    if (responseData.status === 'success') {
      context.commit('setIsCaseProcessing', true);
    }
    return responseData;
  },
  async stopProcessingCase(context, payload) {
    const responseData = await apiCall(context,
      `/rest/v1/case/${payload.caseId}/stop`,
      'POST'
    );
    if (responseData.status === 'success') {
      context.commit('setIsCaseProcessing', false);
    }
    return responseData;
  },
  async getCaseStatus(context, payload) {
    const index = context.getters.cases.findIndex(c => {
      return c._id === payload.caseId
    });

    //only send request if caseId is valid
    //index will be -1 if caseId is invalid
    if(payload.caseId && index >=0){
      const responseData = await apiCall(context,
        `/rest/v1/case/${payload.caseId}/status`,
        'GET'
      );
      return responseData;
    }
    return [];
  },
  async getCamerasByCaseId(context, payload) {
    const index = context.getters.cases.findIndex(c => {
      return c._id === payload.caseId
    });

    //only send request if caseId is valid
    //index will be -1 if caseId is invalid
    if(payload.caseId && index >=0){
      const responseData = await apiCall(context,
        `/rest/v1/case/${payload.caseId}/cameras`,
        'GET'
      );
      return responseData;
    }
    return [];
  },
  async createCamera(context, payload) {
    const url = `/rest/v1/case/${payload.caseId}/camera`;

    // Not used in Camera creation.
    delete payload.caseId;

    const responseData = await apiCall(context,
      url,
      'POST',
      JSON.stringify(payload)
    );
    return responseData;
  },
  async updateCamera(context, payload) {
    const url = `/rest/v1/case/${payload.caseId}/camera`;

    // Not used in Camera creation.
    delete payload.caseId;

    const responseData = await apiCall(context,
        url,
        'PUT',
        JSON.stringify(payload)
    );
    return responseData;
  },
  async uploadMedia(context, payload) {
    const url = `/rest/v1/case/${payload.caseId}/camera/${payload.cameraId}/media`;

    const formDataBody = new FormData();
    formDataBody.append('fileName', payload.fileName)
    formDataBody.append('startTime', payload.startTime)
    formDataBody.append('caseMedia', payload.file);

    const responseData = await apiCall(context,
      url,
      'POST',
      formDataBody
    )
    return responseData;
  },
  async deleteCamera(context, payload) {
    const url = `/rest/v1/case/${payload.caseId}/camera/${payload.cameraId}`;

    const responseData = await apiCall(context, url, 'DELETE');

    return responseData;
  }
}
