<template>
  <div class="user-card">
    <div class=" d-flex space-between flex-row" style="padding-bottom: var(--spacing-base);">
      <div class="user-name">
        {{group.label}}
      </div>
      <MDBDropdown v-model='dropdownOptions' align='end'>
        <MDBDropdownToggle
          style="color:black;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;'"
          tag='a'
          @click="dropdownOptions = !dropdownOptions">
          <RocIcon color="black" size="sm" icon="kebab"/>
        </MDBDropdownToggle>
        <RocDropdownMenu aria-labelledby='dropdownMenuButton'>
          <MDBDropdownItem href="" @click.prevent="editGroup">
            <div class="d-flex justify-content-between">
              Edit
              <RocIcon color="black" size="sm" icon="edit"/>
            </div>
          </MDBDropdownItem>
          <span v-if="!isLoggedIn && !group.isSysAdmin">
            <hr class='dropdown-divider' />
            <MDBDropdownItem href="" @click.prevent="deleteGroup">
              <div class="delete d-flex justify-content-between">
                Delete
                <RocIcon icon="trash" color="red" size="sm"/>
              </div>
            </MDBDropdownItem>
          </span>
        </RocDropdownMenu>
      </MDBDropdown>
    </div>
    <div>
      <div class="notes-text">{{group.notes}}</div>
    </div>
    <div class="d-flex group-users" style="gap: var(--spacing-base);">
      <RocIcon icon="user" color="primary" size="sm" style="margin-right: var(--spacing-s);"/>
      <div class="first-users">
         {{ getGroupUsers(group?.users?.length)}}
      </div>
        <span v-if="group?.users?.length > 2" class="overwatch-neutral-300"> + {{ group?.users?.length -2 }} others</span>
    </div>
    <div class="disabled align-self-center" :class="{'hide-visibility': group.enabled}" style="margin-left: 16px; flex: 1;" >
      <RocIcon class="disabled-mobile" icon="exit" size='md' color="red"/>
      <span class="disabled-desktop">DISABLED</span>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from 'vuex';
import { MDBDropdown, MDBDropdownItem, MDBDropdownToggle } from "mdb-vue-ui-kit";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";

export default {
  name: "UserGroupCard",
  components: {
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    RocIcon,
    RocDropdownMenu,
  },
  emit: ['toggleState', 'edit', 'delete'],
  props: {
    group: Object,
  },
  setup(props, context) {
    const store = useStore();
    const dropdownOptions = ref(false);

    const isLoggedIn = computed(function() {
      if (!props.group) {
        return false;
      }
      return props.group.email === store.getters['auth/email'];
    });

    // Should I be able to delete my own userGroup? Probably not. 
    function toggleEnabled() {
      context.emit('toggleState');
      dropdownOptions.value = !dropdownOptions.value;
    }

    const stateText = computed(function () {
      if(props.group.enabled) {
        return "Disable"
      }
      else
      {
        return "Enable";
      }
    });

    function editGroup() {
      context.emit("edit", props.group);
      dropdownOptions.value = !dropdownOptions.value;
    }

    function deleteGroup() {
      context.emit("delete", props.group);
      dropdownOptions.value = !dropdownOptions.value;
    }

    function getGroupUsers(length){
      switch(length){
        case undefined:
        case -1:
        case 0:
          return 'No Users In Group';
        case 1: 
          return props.group.users[0].firstname + props.group.users[0].lastname;
        default:
          return (
             props.group.users[0].firstname + props.group.users[0].lastname + ',' + 
            props.group.users[1].firstname + props.group.users[1].lastname
          );
      }
    }

    return {
      dropdownOptions,
      toggleEnabled,
      stateText,
      editGroup,
      deleteGroup,
      isLoggedIn,
      getGroupUsers,
    };
  }
};
</script>

<style scoped lang="scss">
.user-card {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  margin-bottom: 4px !important;
  padding: 16px 20px 16px !important;
  margin: 0;
  background: var(--overwatch-secondary);
  border-radius: 5px;
  min-height: 216px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
}
.space-between{
  justify-content: space-between;
}

.group-users{
  position: absolute;
  bottom: 0;
  align-items: center;
  padding-bottom: var(--spacing-m);
  @include overwatch-body-small;
  width: 90%;
}

.first-users{
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-name {
  @include overwatch-title-small;
  line-height: 21px;
}

.notes-text {
  @include overwatch-body-small;
  line-height: 21px;
  height: 100px;
  word-wrap: break-word;
}

.user-email {
  @include overwatch-body-small;
  line-height: 18px;
}

.disabled {
  color: var(--overwatch-error);
  @include overwatch-body-small;
  line-height: 21px;
}

.dropdown-toggle:after {
  display: none;
}

hr {
  margin: 0;
}

.delete {
  color: var(--overwtch-error);
}

.disabled-mobile {
  display: none;
}

.hide-visibility {
  visibility: hidden;
}

@media (max-width: 480px) {
  .user-card {
    min-width: 0; 
    width: 100%;
    margin-bottom: 0 !important;
  }

  .disabled-mobile {
    display: block;
    margin-top: 3px;
  }

  .disabled-desktop {
    display: none;
  }
}

</style>