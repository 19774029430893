<template>
  <MDBCard>
    <MDBCardBody>
      <div class="result">
        <AuthImg :src="getImgSrc(result)" :class="{watchlisted: result.watchlistedFace}" />
        <div class="result-info">
          <div class="overwatch-body-small gray-font" style="display: flex; margin-top: 0;">
            Similarity Score
            <MDBDropdown dropstart style="margin-left: auto;" v-model="isDropdownOpen">
              <MDBDropdownToggle
                style='color:black;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;'
                tag='a'
                @click="isDropdownOpen = !isDropdownOpen"
              >
              <RocIcon color="black" size="sm" icon="kebab"/>
              </MDBDropdownToggle>
              <RocDropdownMenu style="z-index: 10;">
                <MDBDropdownItem tag='button' @click="$emit('compare'); isDropdownOpen = false;">
                  <div style="display: flex; justify-content: space-between; align-items: center; gap: var(--spacing-s); color: var(--overwatch-neutral-100)">
                    Compare Images <RocIcon icon="search" color="black" size="sm" />
                  </div>
                </MDBDropdownItem>
                <MDBDropdownItem tag='button' @click="$emit('view-linked'); isDropdownOpen = false;" v-if="result.watchlistedFace">
                  <div style="display: flex; justify-content: space-between; align-items: center; gap: var(--spacing-s); color: var(--overwatch-neutral-100)">
                    Associated Images <RocIcon icon="group" color="black" size="sm" />
                  </div>
                </MDBDropdownItem>
              </RocDropdownMenu>
            </MDBDropdown>
          </div>
          <div class="overwatch-title-med">{{ result.similarity.toFixed(2) }}</div>
          <div v-if="result.watchlistedFace">
            <div class="overwatch-body-small gray-font">Name</div>
            <div>{{`${result.watchlistedFace.firstname} ${result.watchlistedFace.lastname}`}}</div>
          </div>
          <div>
            <div class="overwatch-body-small gray-font">Source</div>
            <div style="">
              <RocIcon icon="watchlist" size="sm"/>
              {{ result.watchlistedFace ? result.watchlistedFace.source.name : result.faceTemplate.source.fileName }}
            </div>
          </div>
          <div style="color: var(--overwatch-primary); cursor: pointer; margin-top: auto;" @click="$emit('download')">
            <RocIcon icon="download" color="primary" size="sm"/>
            Download Report
          </div>
        </div>
      </div>
    </MDBCardBody>
  </MDBCard>
</template>

<script>
import { ref } from 'vue';
import {
  MDBCard,
  MDBCardBody,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem
} from 'mdb-vue-ui-kit';
import AuthImg from "@/components/ui/AuthImg";
import RocIcon from "@/components/ui/RocIcon";
import RocDropdownMenu from '../ui/RocDropdownMenu.vue';

export default {
  name: 'CandidateCard',
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  emits: ['download', 'compare', 'view-linked'],
  components: {
    MDBCard,
    MDBCardBody,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownItem,
    AuthImg,
    RocIcon,
    RocDropdownMenu
  },
  setup(props, context) {

    function getImgSrc(result) {
      if (result.faceTemplate) {
        return result.faceTemplate.tn;
      } else {
        return result.watchlistedFace.tn;
      }
    }

    const isDropdownOpen = ref(false);
    
    return {
      getImgSrc,
      isDropdownOpen
    }
  }
  
}
</script>

<style scoped>

.result {
  display: flex;
  width: 100%;
  gap: var(--spacing-s);
}

.card-body{
  background-color: var(--overwatch-secondary);
  border-radius: 5px;
}

.result img {
  width: 150px;
}

.result img.watchlisted {
  width: 170px;
}

.result-info {
  flex: 1; 
  
  display: flex; 
  flex-direction: column;
}

.gray-font {
  color: var(--overwatch-neutral-200);
  margin-top: var(--spacing-base);
}

.dropdown-toggle::before {
  visibility: hidden;
}

</style>