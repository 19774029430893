<template>
  <div class="wrapper">
    <RocButton type='binary' @click="toggleActive(btn)" v-for="btn in buttons" :key='btn' :isActive="isActive(btn)">
      {{ btn.label }}
    </RocButton>
  </div>
</template>

<script>
import RocButton from '@/components/ui/RocButton';
import { reactive } from "vue";

export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: null
    },
    list: {
      type: Array,
      required: true,
    },
    activeColor: {
      type: String,
      required: false,
      default: 'black',
    },
    inactiveColor: {
      type: String,
      required: false,
      default: 'white',
    },
    title: {
      type: String
    }
  },
  emits: ["state-change"],
  components: {
    RocButton
  },
  setup(props, context) {
    const buttons = reactive(props.list)

    function isActive(btn) {
      return btn.active;
    }

    function toggleActive(btn) {
      let active = false;
      if(btn.active) {
        active = false;
      } else {
        active = true;
      }
      context.emit("state-change", [btn, active]);
    }

    return {
      buttons,
      isActive,
      toggleActive,
    };
  }
};
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: $--spacing-base;
}

.wrapper button {
  text-transform: capitalize;
  padding-left: 0;
  padding-right: 0;
  flex: 1;
}


</style>