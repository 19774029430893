const base64 = require('@/js/base64Helper');
const Buffer = require('buffer/').Buffer;
import { Capacitor } from "@capacitor/core";

let isMobile;
try {
  isMobile = Capacitor ? Capacitor.getPlatform() != 'web' : false;
} catch(err) {
  console.log("error fetching platform. this should not happen ", err);
}

export default {
  async postRepresent(context, payload) {
    const url = `/rest/v1/face/represent`;
    const algId = payload.params.algId ? payload.params.algId : 0;
    const minSize = payload.params.minSize ? payload.params.minSize : 20; // 20 suggested minimum minSize
    const faceCount = payload.params.faceCount ? payload.params.faceCount : 1;
    const falseDetectionRate = payload.params.falseDetectionRate
      ? payload.params.falseDetectionRate
      : 0.02; // ROC_SUGGESTED_FALSE_DETECTION_RATE
    const minQuality = payload.params.minQuality
      ? payload.params.minQuality
      : -4; // ROC_SUGGESTED_MIN_QUALITY
    if (isMobile) {
      // mobile currently uses JSON
      let bodyJson;
      if (payload.bufferedImage) {
        // image buffer provided
        bodyJson = JSON.stringify({
          imageDataBuffer: payload.bufferedImage,
          params: {
            algorithmId: algId,
            minSize: minSize,
            maxFaces: faceCount,
            falseDetectionRate: falseDetectionRate,
            minQuality: minQuality
          }
        });
      } else {
        // base64 image provided
        bodyJson = JSON.stringify({
          imageDataUri: base64.convertStringToDataUri(payload.base64Image),
          params: {
            algorithmId: algId,
            minSize: minSize,
            maxFaces: faceCount,
            falseDetectionRate: falseDetectionRate,
            minQuality: minQuality
          }
        });
      }
      const httpPayload = {
        method: 'POST',
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        },
        body: bodyJson,
      };
      return await context.dispatch(
        'auth/fetchJSON',
        { url: url, payload: httpPayload },
        { root: true }
        );
    }
    // web uses direct image/ POST with represent params in header
    const headerParams = {
      algorithmId: algId,
      minSize: minSize,
      maxFaces: faceCount,
      falseDetectionRate: falseDetectionRate,
      minQuality: minQuality
    }
    let httpPayload;
    if (payload.bufferedImage) {
      httpPayload = {
        method: 'POST',
        headers: {
          'Accept': 'application/json, image/jpeg',
          'Content-Type': 'image/jpeg',
          'x-roc-represent-params': JSON.stringify(headerParams),
        },
        body: payload.bufferedImage,
        responseType: 'blob'
      };
    } else {
      // base64 image provided
      const base64ImageOnly = base64.convertDataUriToString(payload.base64Image);
      const arrayBuffer = Buffer.from(base64ImageOnly, 'base64');
      httpPayload = {
        method: 'POST',
        headers: {
          'Accept': 'application/json, image/jpeg',
          'Content-Type': 'image/jpeg',
          'x-roc-represent-params': JSON.stringify(headerParams),
        },
        body: arrayBuffer,
        responseType: 'blob'
      };
    }
    // console.debug(`postRepresent::Payload Size: ${base64.memorySizeOf(httpPayload)}`);
    const response = await context.dispatch(
      'auth/rawFetch',
      { url: url, payload: httpPayload },
      { root: true }
    );
    if (response) {
      if (response.status === 200) {
        // if the response is image data, then its formatted as the thumbnail in the body and the metadata in the headers
        if (response.headers['content-type'] ===  'image/jpeg' || response.headers['content-type'] ===  'image/png') {
          const representResult = JSON.parse(response.headers['x-roc-represent-results']);
          const base64uri = await base64.blobToBase64Uri(response.data);
          representResult.tn = base64.convertDataUriToString(base64uri);
          return representResult;
        } else {
          // otherwise assume this is structured JSON response payload
          return response;
        }
      } else {
        // return the http error
        return response;
      }
    } else {
      return null;
    }
  },

  async postCompare(context, payload) {
    const url = `/rest/v1/face/compare`;
    if (isMobile) {
      // mobile currently uses JSON
      let bodyJson;
      if (payload.imageDataUri1 && payload.imageDataUri2) {
        bodyJson = JSON.stringify({
          imageDataUri1: base64.convertStringToDataUri(payload.imageDataUri1),
          imageDataUri2: base64.convertStringToDataUri(payload.imageDataUri2)
        });
      } else {
        bodyJson = JSON.stringify({
          imageDataBuffer1: payload.bufferedImage1,
          imageDataBuffer2: payload.bufferedImage2
        });
      }
      const httpPayload = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: bodyJson
      };
      return await context.dispatch(
        'auth/fetchJSON',
        { url: url, payload: httpPayload },
        { root: true }
      );
    }
    // web uses multipart/form-data, replace images with blob buffers in formdata
    let image1Buffer;
    if (payload.imageDataUri1) {
      const base64image1 = base64.convertDataUriToString(payload.imageDataUri1);
      image1Buffer = Buffer.from(base64image1, 'base64');
    } else {
      image1Buffer = payload.bufferedImage1;
    }
    let image2Buffer;
    if (payload.imageDataUri2) {
      const base64image2 = base64.convertDataUriToString(payload.imageDataUri2);
      image2Buffer = Buffer.from(base64image2, 'base64');
    } else {
      image2Buffer = payload.bufferedImage2;
    }
    const probeBlob = new Blob([image1Buffer]);
    const galleryBlob = new Blob([image2Buffer]);
    let bodyFormData = new FormData();
    bodyFormData.append('probe', probeBlob); 
    bodyFormData.append('gallery', galleryBlob);
    const httpPayload = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
      },
      body: bodyFormData
    };
    // console.debug(`postVerify::Payload Size: ` + base64.printSizeOf((+base64.memorySizeOf(httpPayload, false)) + probeBlob.size + galleryBlob.size));
    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
  },

  async getFlavorConfig(context, flavorTag) {
    const url = `/rest/v1/objectstore/rocenroll_flavor_${flavorTag}`;
    return new Promise((resolve, reject) => {
      context.commit('setAppFlavorTag', '');
      context.dispatch("auth/fetchJSON", {url: url, payload: {method: 'GET'}}, {root: true})
      .then(async (response) => {
        if(response) {
          if (response.status == 'success') {
            context.commit("setAppFlavorConfig", response.value);
            context.commit('setAppFlavorTag', flavorTag);
            resolve(response.value);
          } else {
            console.error("getFlavorConfig::Failure querying flavor config: " + JSON.stringify(response));
            reject(response);
          }
        } else {
          console.error("getFlavorConfig::Failure querying flavor config");
          reject();
        }
      })
      .catch(err => {
        console.error("getFlavorConfig::Error querying flavor config, " + err);
        reject(err);
      });
    });
  },
  async loadCaptureConfigList(context) {
    const url = `/rest/v1/objectstore/rocenroll_captureconfigs`;
    let configList = [];
    const response = await context.dispatch("auth/fetchJSON", {url: url, payload: {method: 'GET'}}, {root: true});
    if (response && response.status === 'success') {
      configList = response.value;
    } else {
      console.error("getFlavorConfig::Failure querying capture config, response: " + JSON.stringify(response));
    }
    const rocConfigs = require("../../../js/rocenroll/rocEnrollCaptureConfig");
    const captureConfigs = new rocConfigs();
    captureConfigs.initConfigList(configList);
    const userSelectedCaptureConfig = context.getters['userSelectedCaptureConfig'];
    if (!userSelectedCaptureConfig) {
      try {
        // use default value set in flavor config
        const flavorDefault = context.getters['defaultCaptureConfig'];
        captureConfigs.setSelectedConfig(flavorDefault);
        context.commit('setSelectedCaptureConfig', flavorDefault);
        console.debug(`Set capture config as flavor default: ${flavorDefault}`);
      } catch {
        captureConfigs.setSelectedConfig('Verify');
        context.commit('setSelectedCaptureConfig', 'Verify');
        console.debug(`Set capture config as default: ${flavorDefault}`);
      }
    } else {
      // set the user selected config
      captureConfigs.setSelectedConfig(userSelectedCaptureConfig);
      context.commit('setSelectedCaptureConfig', userSelectedCaptureConfig);
      console.debug(`Set capture config as user selected: ${userSelectedCaptureConfig}`);
    }
    context.commit('setCaptureConfigs', captureConfigs)
  },
  async postDocumentValidation(context, payload) {
    let url = `/rest/v1/enroll/document`;
    if (payload.mode) {
      switch (payload.mode) {
        case 'credential': {
          if (context.getters.regionIdCredential) {
            url += `/${context.getters.regionIdCredential}`;
          }
          break;
        }
        case 'barcode': {
          if (context.getters.regionIdBarcode) {
            url += `/${context.getters.regionIdBarcode}`;
          }
          break;
        }
        case 'creditcard': {
          break;
        }
        case 'generic': {
          break;
        }
      }
      delete payload.mode;
    }
    if (isMobile) {
      // mobile currently uses JSON
      const httpPayload = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload),
      };
      return await context.dispatch(
        'auth/fetchJSON',
        { url: url, payload: httpPayload },
        { root: true }
      );
    }
    // web uses multipart/form-data, replace base64 images with buffers in formdata
    let imageSize = 0;
    let bodyFormData = new FormData();
    for (let i=0; i<payload.images.length; i++) {
      const imageBlob = new Blob([Buffer.from(payload.images[i].ImageData, 'base64')]);
      bodyFormData.append('id_images', imageBlob);
      imageSize += imageBlob.size;
      delete payload.images[i].ImageData;
    }
    bodyFormData.append('payload', JSON.stringify(payload));
    const httpPayload = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
      },
      body: bodyFormData,
    };
    // console.debug(`postDocumentValidation::${payload.processParam.scenario} Payload Size: ${base64.printSizeOf(+base64.memorySizeOf(httpPayload, false) + imageSize)}`);
    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async postPayloadToParentWindow(context, payload) {
    const obj = JSON.parse(JSON.stringify(payload.result));
    const originArray = context.getters.iframeExportOrigins;
    // the origin value here drives browser security - the origin must match the parent's origin and that context will receive this payload
    // this is configured via the backend in the flavor profile to ensure the origin is set appropriately without blindly posting a web message in the browser
    for (let i=0; i<originArray.length; i++) {
      try {
        window.parent.postMessage(obj, originArray[i]);
      } catch (err) {
        console.error(err);
      }
    }
  },
  async initEnrollWatchlistDefault(context, watchlistLabelFromUrl) {
    let watchlistList;
    const loadWatchlists = async () => {
      await context.dispatch('watchlists/loadWatchlists',{}, { root: true });
      watchlistList = context.rootGetters['watchlists/watchlists'];
    }
    let eventList;
    const loadEvents = async () => {
      const eventResp = await context.dispatch('events/getEvents',{}, { root: true });
      if (eventResp && eventResp.events) {
        eventList = eventResp.events;
      } else {
        eventList = [];
      }
    }
    await loadWatchlists();
    await loadEvents();

    const flavor = context.getters['appFlavorConfig'];
    // if flavor drives a default event, this is our selected destination
    if (flavor.workflowConfig.defaultEnrollEvent && context.getters['enrollConfig'].mode === 'standard') {
      const defaultEvent = flavor.workflowConfig.defaultEnrollEvent;
      context.commit('setRwwSelectedWatchlist', {});
      let existingEvent = eventList.find(item => item.eventName === defaultEvent.eventName);
      if (!existingEvent) {
        if (!defaultEvent.startDate) {
          // default start date to today, time 0
          defaultEvent.startDate = new Date(new Date().toISOString().substring(0, 10));
        }
        if (!defaultEvent.endDate) {
          // default end date to 1 year from today, time 0
          defaultEvent.endDate = new Date(new Date(new Date().toISOString().substring(0, 10)).setFullYear(new Date().getFullYear() + 1));
        }
        const response = await context.dispatch('events/createEvent', defaultEvent, { root: true });
        if (response && response.event) {
          existingEvent = response.event;
        }
      }
      if (existingEvent) {
        await loadWatchlists();
        let existingWl = watchlistList.find(item => item._id === existingEvent._watchlistId);
        existingWl._eventId = existingEvent._id;
        context.commit('setRwwSelectedWatchlist', existingWl);
      }
      return;
    }
    // if flavor drives a default watchlist, this is our selected destination
    if (flavor.workflowConfig.enrollWatchlist && context.getters['enrollConfig'].mode === 'standard') {
      const defaultWatchlist = flavor.workflowConfig.enrollWatchlist;
      context.commit('setRwwSelectedWatchlist', {});
      let existingWl = watchlistList.find(item => item.label.localeCompare(defaultWatchlist.name, undefined, { sensitivity: 'base' }) === 0);
      if (!existingWl) {
        const createResponse = await context.dispatch('watchlists/createWatchlist', { info: defaultWatchlist }, { root: true });
        if (createResponse && createResponse.watchlist) {
          existingWl = createResponse.watchlist;
        }
      }
      if (existingWl) {
        context.commit('setRwwSelectedWatchlist', existingWl);
        console.log(`flavor setting watchlist as ${existingWl.label}`);
        return;
      }
    }
    let selectedWl;
    // first, attempt to select watchlist from URL param
    if (watchlistLabelFromUrl) {
      selectedWl = watchlistList.find(item => item.label.localeCompare(watchlistLabelFromUrl, undefined, { sensitivity: 'base' }) === 0);
      if (selectedWl) {
        context.commit('setRwwSelectedWatchlist', selectedWl);
        console.log(`url setting watchlist as ${selectedWl.label}`);
        return;
      }
    }
    // next, check cached selection
    selectedWl = context.getters['rwwSelectedWatchlist'];
    if (selectedWl) {
      // confirm/update selected watchlist from current list of watchlists
      selectedWl = watchlistList.find(item => item._id === selectedWl.value);
      if (selectedWl) {
        context.commit('setRwwSelectedWatchlist', selectedWl);
        console.log(`cache setting watchlist as ${selectedWl.label}`);
        return;
      }
    }
    if (!selectedWl) {
      let defaultWatchlistName = null;
      // no WL selected, default it if we can
      if (flavor.workflowFlags.enrollIntoRww && flavor.workflowConfig.defaultEnrollWatchlist) {
        defaultWatchlistName = flavor.workflowConfig.defaultEnrollWatchlist;
      }
      // flavor config for iframe includes a watchlist for RF submission
      else if (flavor.iframeConfig?.submitRF?.watchlist) {
        defaultWatchlistName = flavor.iframeConfig.submitRF.watchlist;
      }
      if (defaultWatchlistName) {
        selectedWl = watchlistList.find(item => item.label.localeCompare(defaultWatchlistName, undefined, { sensitivity: 'base' }) === 0);
        if (!selectedWl) {
          // auto create based on configured default
          const createResponse = await context.dispatch('watchlists/createWatchlist', {
            info: {
              name: defaultWatchlistName,
              description: 'ROC Enroll Auto Created',
              enabled: true,
              type: 'face',
            }
          }, { root: true });
          if (createResponse && createResponse.status === 'success') {
            selectedWl = createResponse.watchlist;
          }
        }
        if (selectedWl) {
          context.commit('setRwwSelectedWatchlist', selectedWl);
          console.log(`default setting watchlist as ${selectedWl.label}`);
          return;
        }
      }
    }
  },
  async enrollIdentity(context, payload) {
    const url = `/rest/v1/enroll/identity`;
    if (isMobile || context.getters.isFingerprintMode) {
      // mobile currently uses JSON
      const httpPayload = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      };
      // console.debug(`enrollFaceImage::Payload Size: ` + base64.printSizeOf((+base64.memorySizeOf(httpPayload, false))));
      return await context.dispatch(
        'auth/fetchJSON',
        { url: url, payload: httpPayload },
        { root: true }
      );
    }
    // web uses multipart/form-data, repackage JSON accordingly
    let bodyFormData = new FormData();
    const faceImageBlob = new Blob([Buffer.from(payload.enrollment_image, 'base64')]);
    delete payload.enrollment_image;
    bodyFormData.append('enrollment_image', faceImageBlob);
    let imageSize = faceImageBlob.size;
    
    if (payload.identityData && payload.identityData.idDoc && payload.identityData.idDoc.images) {
      for (let i=0; i<payload.identityData.idDoc.images.length; i++) {
        const idImageBlob = new Blob([Buffer.from(payload.identityData.idDoc.images[i].data, "base64")]);
        imageSize += idImageBlob.size;
        bodyFormData.append('id_images', idImageBlob, payload.identityData.idDoc.images[i].label);
      }
      delete payload.identityData.idDoc.images;
    }
    bodyFormData.append('enroll_payload', JSON.stringify(payload));

    const httpPayload = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
      },
      body: bodyFormData
    };
    // console.debug(`enrollFaceImage::Payload Size: ` + base64.printSizeOf((+base64.memorySizeOf(httpPayload, false)) + imageSize));
    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async loadWebcamList(context) {
    const webcamList = [];
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      if (devices) {
        for (let d=0; d < devices.length; d++) {
          if (devices[d].kind === "videoinput") {
            // console.debug(devices[d]);
            webcamList.push(devices[d]);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
    context.commit('setWebcamList', webcamList);
  },
  async loadEnrollConfig(context) {
    const url = `/rest/v1/config/enroll`;
    const httpPayload = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    const response = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    if (response && response.status === 'success') {
      context.commit('setEnrollConfig', response.enrollConfig);
      context.commit('setEventWorkflowEnabled', response.enrollConfig.enabled);
    } else {
      context.commit('setEnrollConfig', {});
      context.commit('setEventWorkflowEnabled', false);
    }
  },
  async loadEnrollmentStatus(context, payload) {
    context.commit('setCustomerId', payload.customerId);

    const url = `/rest/v1/enroll/identity/status/${payload.customerId}`;
    const httpPayload = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ agentId: context.rootGetters['auth/email'], channelId: 'ROC Enroll', internalOnly: payload.internalOnly})
    };
    const statusResponse = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    console.log(statusResponse);
    return statusResponse;
  },
  async postVerify(context, payload) {
    const url = `/rest/v1/enroll/verify`;
    if (isMobile || context.getters.isFingerprintMode) {
      // mobile currently uses JSON
      const httpPayload = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      };
      // console.debug(`enrollFaceImage::Payload Size: ` + base64.printSizeOf((+base64.memorySizeOf(httpPayload, false))));
      return await context.dispatch(
        'auth/fetchJSON',
        { url: url, payload: httpPayload },
        { root: true }
      );
    }
    // web uses multipart/form-data, repackage JSON accordingly
    let bodyFormData = new FormData();
    const faceImageBlob = new Blob([Buffer.from(payload.enrollment_image, 'base64')]);
    delete payload.enrollment_image;
    bodyFormData.append('enrollment_image', faceImageBlob);
    bodyFormData.append('enroll_payload', JSON.stringify(payload));

    const httpPayload = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
      },
      body: bodyFormData
    };
    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async postRepresentFinger(context, payload) {
    const url = `/rest/v1/fingerprint/represent`;
    const httpPayload = {
      method: 'POST',
      headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload),
    };
    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async fetchRegulaLicense(context) {
    const url = `/rest/v1/objectstore/regula_license`;
    const response = await context.dispatch("auth/fetchJSON", {url: url, payload: {method: 'GET'}}, {root: true});
    if (response && response.status === 'success') {
      return response.value;
    } else {
      console.error("fetchRegulaLicense::Failure querying regula_license, response: " + JSON.stringify(response));
      return null;
    }
  },
  async postPaymentSession(context, payload) {
    const url = `/rest/v1/enroll/payment/session`;
    const httpPayload = {
      method: 'POST',
      headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload),
    };
    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async updatePaymentSession(context, payload) {
    const url = `/rest/v1/enroll/payment/session/${payload.id}`;
    const updatePayload = payload.update;
    updatePayload.provider = payload.provider;
    const httpPayload = {
      method: 'PUT',
      headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatePayload),
    };
    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async getPaymentSession(context, payload) {
    const url = `/rest/v1/enroll/payment/session/${payload.id}?provider=${payload.provider}`;
    const httpPayload = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async getPaymentTransaction(context, payload) {
    const url = `/rest/v1/enroll/payment/transaction?provider=${payload.provider}&transactionId=${payload.transactionId}&orderId=${payload.orderId}`;
    const httpPayload = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async verifyPaymentSession(context, payload) {
    const url = `/rest/v1/enroll/payment/session/verify`;
    const httpPayload = {
      method: 'POST',
      headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload),
    };
    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
};
