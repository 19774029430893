import router from "@/router";
import { io } from "socket.io-client";
import { Capacitor, CapacitorHttp } from "@capacitor/core";
import { cloneDeep, get as lodashGet } from "lodash";
import axios from "axios";
import oidcAuthHelper from "@/js/oidcAuthHelper";

let authSSO;

const publicPath = process.env.BASE_URL;

axios.defaults.withCredentials = true

let refreshTokenTimeout;
let isMobile;

try {
  isMobile = Capacitor ? Capacitor.getPlatform() != 'web' : false;
}

catch(err) {
  console.log("error fetching platform. this should not happen ", err);
}

axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//serves as backup, should never be used based on scheduled calls to refreshToken see: setAutoInvokeRefreshTokenTimeout
axios.interceptors.response.use((res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          let baseUrl = '';
          if(publicPath !== '/') {
            baseUrl += publicPath;
          }
          const refreshResult = await axios({
            url: `${baseUrl}/rest/v1/auth/refreshtoken`,
            method: 'POST',
            headers: {
               Accept: 'application/json',
               'Content-Type': 'application/json',
            },
            data: {},
          });
          if(refreshResult.data.status === 'failed') {
            return Promise.reject(err.response);
          }
        }

        catch(err) {
          console.error("axios.interceptors.response.use exception: ", err);
          if (err.response && err.response.data) {
            return Promise.reject(err.response.data);
          }
          return Promise.reject(err);
        }

        let retryResult
        try {
          retryResult = await axios(originalConfig);
        }

        catch(err) {
          return Promise.reject(err);
        }

        return Promise.resolve(retryResult);
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
);

export default {
  async loginSSO(context) {
    await authSSO.login();
  },
  async login(context, payload) {
    const email = payload.email;
    //attach deviceInfo to payload for mobile device token, etc.
    payload.deviceInfo = context.rootGetters["settings/getDeviceInfo"];
    const httpPayload = {
      url: '/rest/v1/auth/login',
      payload: {
        method: 'POST',
        mode: 'same-origin',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: payload,
      },
    };

    const jsonData = await context.dispatch('fetchJSON', httpPayload);
    // console.debug("login result: ", jsonData);
    if (jsonData.status === 'success') {
      context.commit('setIsAuthenticated', true);
      context.commit('setFirstName', jsonData.firstname);
      context.commit('setLastName', jsonData.lastname);
      context.commit('setEmail', email);
      context.commit('setUserConfig', jsonData.userConfig);
      // context.commit('setUserAccess', jsonData.userAccess);

      // apply user configs to encounter filter/live cam settings
      await context.dispatch("applyUserConfigs");

      //automatically update token via refreshToken api
      const delay = (jsonData.recommended_token_refresh_delay >= 30000) ? jsonData.recommended_token_refresh_delay : 30000;
      await context.dispatch("setAutoRefreshToken", {refresh_delay: delay});

      // fetch configs and usergroups
      await context.dispatch('rocenroll/loadEnrollConfig', {}, {root: true});
      await context.dispatch('settings/loadUserGroups', {}, {root: true});
    }
    else {
      context.commit('setIsAuthenticated', false);
      context.commit('setEmail', '');
      context.commit('setFirstName', '');
      context.commit('setLastName', '');
      // context.commit('setUserAccess', {});
      throw(new Error(jsonData));
    }
    return jsonData;
  },
  async applyUserConfigs(context, payload) {

    if (!context.getters.userConfig) {
      return;
    }

    const userConfig = context.getters.userConfig;

    if (!userConfig.rwwSettings) {
      return;
    }

    const settings = userConfig.rwwSettings;

    context.commit('cases/setEncounterFilterSelectedCase', settings.selectedCase, {root: true});
    context.commit('encounters/setSearchText', settings.searchText, {root: true});
    context.commit('watchlists/setEncounterFilterSelectedWatchlists', settings.watchlists, {root: true});
    context.commit('encounters/setAttributesFilter', settings.tags, {root: true});
    context.commit('encounters/setEncounterDateRangeFilter', settings.dateRangeFilter, {root: true});
    context.commit('encounters/setAdjudicationFilter', settings.adjudication, {root: true});
    context.commit('encounters/setMatchesOnly', settings.matchesOnly, {root: true});
    context.commit('cameras/setEncounterFilterSelectedCameras', settings.selectedFilterCameras, {root: true});
    context.commit('encounters/setLiveFeedCameras', settings.selectedLiveCameras, {root: true});
    context.commit('encounters/setCameraLiveFeedMode', settings.displayType, {root: true});
    context.commit('encounters/setCameraLiveFeedState', settings.toggleState, {root: true});
    context.commit('encounters/setAnalyticsFilter', settings.analytics, {root: true});
    context.commit('cases/setDateTimeParser', settings.newCaseDateTimeParser, {root: true});
    context.commit('settings/setDarkMode', settings.darkMode, {root: true});
    context.commit('settings/setCaptureZonesInstructionsShown', settings.captureZonesInstructionsShown, {root: true});
  },
  async applySavedView(context, payload){

    if(payload.faceImage){
      context.commit('encounters/setSearchTemplateId', -1, {root: true});
      context.commit('encounters/setShowImageSearchBox', true, {root:true});
    }
    else{
      context.commit('encounters/setSearchTemplateId', null, {root: true});
      context.commit('encounters/setShowImageSearchBox', false, {root:true});
    }

    context.commit('encounters/setSearchImageData', payload.faceImage, {root: true});
    context.commit('encounters/setSearchText', payload.searchText, {root: true});
    context.commit('cameras/setEncounterFilterSelectedCameras', payload.cams, {root: true});
    context.commit('encounters/setEncounterDateRangeFilter', payload.dateRange, {root: true});
    context.commit('encounters/setMatchesOnly', payload.matchesOnly, {root: true});
    context.commit('watchlists/setEncounterFilterSelectedWatchlists', payload.watchlists, {root: true});
    context.commit('encounters/setAnalyticsFilter', payload.analytics, {root: true});
    context.commit('encounters/setAttributesFilter', payload.tags, {root: true});
    context.commit('encounters/setAdjudicationFilter', payload.adjudication, {root: true});

  },
  async applySavedClusterFilter(context, payload) {
    context.commit('clusters/setAttributeFilters', payload.tags, {root: true})
    context.commit('clusters/setCameraFilters', payload.cams , {root: true})
    context.commit('clusters/setDtRangeFilter', payload.dateRange , {root: true})
    context.commit('clusters/setWatchlistMatchFilter', payload.matchesOnly , {root: true})
  },
  async setAutoRefreshToken(context, payload) {
    //Auth Token Refresh Logic - This will not persist across a refresh, will need some adaption by either persisting in this store
    //or initializing the logic at the App.vue level - MSP
    function setAutoInvokeRefreshTokenTimeout(payload) {
      return setTimeout(async() => {
        try {
          const refeshTokenResponse = await context.dispatch('refreshToken', {});
          console.debug("refreshTokenResponse: ", refeshTokenResponse);
          if(refeshTokenResponse.status === 'success') {
            console.debug("token refresh succeeded.");
            const delay = (refeshTokenResponse.recommended_token_refresh_delay >= 30000) ? refeshTokenResponse.recommended_token_refresh_delay : 30000;
            refreshTokenTimeout = setAutoInvokeRefreshTokenTimeout({ refresh_delay: delay });
            context.commit("setLastRefreshTimeout", Date.now());
            context.commit("setLastRefreshDelay", delay);
          }
          else {
            console.debug("token refresh failed."); //invalidate access
            await router.push('/logout');
          }
        }

        catch(err) {
          console.log("auth refresh error: ", err);
        }
      }, payload.refresh_delay);
    }

    clearInterval(refreshTokenTimeout);
    refreshTokenTimeout = setAutoInvokeRefreshTokenTimeout({refresh_delay: payload.refresh_delay});
    context.commit("setLastRefreshTimeout", Date.now());
    context.commit("setLastRefreshDelay", payload.refresh_delay);
    return refreshTokenTimeout;
  },
  async isLoggedIn(context) {
    if (!authSSO) {
      const oidcConfig = context.getters.preAuthConfig?.jwtExternalConfig;
      authSSO = new oidcAuthHelper(oidcConfig);
    }
    const userSSO = await authSSO.getUser();
    if (userSSO) {
      context.commit('setIsSSO', true);
      context.commit('setFirstName', userSSO.profile.given_name ?? userSSO.profile.name);
      context.commit('setLastName', userSSO.profile.family_name ?? '');
      context.commit('setEmail', userSSO.profile.email);
    }

    const gotAuthorizedReply = await context.dispatch("pingServer");
    if(gotAuthorizedReply) {
      console.debug("authorized");
      context.commit('setToken', null);
      context.commit('setRefreshToken', null);
      context.commit('setIsAuthenticated', true);
      return true;
    }
    else {
      console.debug("not authorized");
      context.commit('setToken', null);
      context.commit('setRefreshToken', null);
      context.commit('setIsAuthenticated', false);
      context.commit('setOneTimeToken', null);
      return false;
    }
  },
  async fetchJSON(context, payload) {
    const response = await context.dispatch('rawFetch', payload);
    if(response && response.data) {
      return response.data;
    } else if (response.response && response.response.data) {
      // when an error code is returned, axios wraps the response object
      return response.response.data;
    }

    return {};
  },
  async fetchBinaryResourceAsBase64(context, payload) {
    let base64Image;
    try {
      payload.payload.responseType = "blob";
      const response = await context.dispatch('rawFetch', payload);
      if(response.status == 200) {
        function blobToBase64(blob) {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.readAsDataURL(blob);
          });
        }

        if(!isMobile) {
          base64Image = await blobToBase64(response.data);
        }
        else {
          //native mobile, at least iOS returns base64 instead of blob, this needs to be validated for android
          base64Image = `data:${response.headers['Content-Type']};base64,${response.data}`;
        }
      }
    }

    catch(err) {
      console.log("unhandled exception fetchBinaryResourceAsBase64: ", err);
    }

    return base64Image;
  },
  async rawFetch(context, payload) {
    try {
      if (!payload.payload.headers) {
        payload.payload.headers = {};
      }

      if(!payload.payload.body && lodashGet(payload, 'payload.method', '').toUpperCase() != 'GET' ) {
        payload.payload.body = {};
      }

      let baseURL = context.getters.backendUrl; // will be blank if not configured by frontend, and use current server
      if(isMobile && baseURL.length === 0) {
        throw( new Error("invalid url, missing endpoint config or not set yet") );
      }

      //transform payload for backwards compatibility
      if(publicPath !== '/') {
          baseURL += publicPath;
      }
      if(baseURL.substr(baseURL.length-1) == '/' && payload.url.substr(0,1) == '/') {
        baseURL = baseURL.substr(0,baseURL.length-1);
      }

      const oneTimeToken = context.getters.oneTimeToken;
      if (oneTimeToken) {
        payload.payload.headers['x-one-time-token'] = oneTimeToken;
      }

      // make sure we are always using the latest token since it auto-refreshes in the background
      const userSSO = await authSSO?.getUser();
      if (userSSO) {
        payload.payload.headers['Authorization'] = `Bearer ${userSSO.access_token}`;
      }

      if(!isMobile) {
        const axiosPayload = {
          method: payload.payload.method,
          url: baseURL + payload.url,
          headers: payload.payload.headers,
          data: payload.payload.body,
          onUploadProgress: payload.onUploadProgress,
          signal: payload.abortController
        };

        if(payload.payload.responseType) {
          axiosPayload.responseType = payload.payload.responseType;
        }

        try {
          return await axios(axiosPayload);
        }

        catch(err) {
          if(err.status == 401 && context.getters.isAuthenticated) {
            await router.push("/logout");
          }
          return err;
        }
      }
      else {
        let cleanBody;
        cleanBody = cloneDeep(payload.payload.body);
        const capacitorPayload = {
          method: payload.payload.method,
          url: encodeURI(baseURL + payload.url),
          headers: payload.payload.headers,
          data: cleanBody,
          webFetchExtra: {
            credentials: "include",
          },
        };

        try {
          if(payload.payload.responseType) {
            capacitorPayload.responseType = payload.payload.responseType;
          }

          return await CapacitorHttp.request(capacitorPayload);
        }

        catch(err) {
          // TODO do we need a logout redirect in this case also?  see above catch block
          console.debug("CapacitorHttp exception: ", err);
          console.debug("associated payload: ", capacitorPayload);
          return err;
        }
      }
    }

    catch(err) {
      console.log(err);
    }
  },
  async refreshToken(context) {
      const httpRefreshPayload = {
        url: `/rest/v1/auth/refreshtoken`,
        payload: {
          method: 'POST',
          mode: 'same-origin',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
          }),
        },
      };

      const response = await context.dispatch('rawFetch', httpRefreshPayload);
      let jsonResponse = await response.data;

      //reattempt original request
      if (jsonResponse.status === 'success') {
        context.commit('setIsAuthenticated', true);
      }
      else {
        context.commit('setIsAuthenticated', false);
      }

      return jsonResponse;
  },
  async logout(context) {
    console.debug("logout invoked");
    clearTimeout(refreshTokenTimeout);

    try {
      console.debug('IsAuth: ', context.getters.isAuthenticated);

      let payload = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({})
      };

      const logoutResult = await context.dispatch('fetchJSON', {
        url: '/rest/v1/auth/logout',
        payload
      });

      const userSSO = await authSSO.getUser();
      if (userSSO) {
        await authSSO.logout();
      }
    }
    catch(err) {
      console.error("logout error: ", err);
    }
  },
  async pingServer(context) {
    const httpPayload = {
      url: '/rest/v1/ping',
      payload: {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };

    try {
      const jsonData = await context.dispatch('fetchJSON', httpPayload);
      if(jsonData.status === 'success') {
        return true;
      }
      else {
        return false;
      }
    }

    catch(err) {
      return false;
    }
  },
  async getSocketIO(context, payload) {
    // use configured socket endpoint if available
    let endpoint = context.getters.socketUrl;
    if (!endpoint || endpoint === '') {
      // default to window.location for full stack deployments
      const loc = window.location;
      if(loc.protocol === 'http:') {
        endpoint = 'ws://' + loc.host;
      }
      else {
        endpoint = loc.protocol + "//" + loc.host;
      }
    }

    const userSSO = await authSSO?.getUser();

    // console.log("socket endpoint: ", endpoint);
    const socket = io(endpoint, {
      auth: {
        token: userSSO ? userSSO.access_token : null,
      },
      query: payload,
      //path: `$socket.io`,
      transports: [ "websocket" ],
   });

   socket.io.on("reconnect_attempt", async (attempt) => {
     console.debug("attempting reconnect: ", attempt);
     try {
       const pingSuccess = await context.dispatch("pingServer");
       if(pingSuccess) {
         console.debug("pingSuccess.");
       }
     }
     catch(err) {
       console.debug("ping failed: ", err);
     }
   });

    socket.on('disconnect', async (reason) => {
      if (reason === "io server disconnect" || reason === "ping timeout") {
      }
    });

   return socket;
 },
  async changePassword(context, payload) {
    const httpPayload = {
      url: '/rest/v1/auth/setpassword',
      payload: {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      },
    };

    try{
      const jsonData = await context.dispatch('fetchJSON', httpPayload);
      if (jsonData.status === 'success') {
        return true;
      }
    }

    catch(err) {
      console.error(err);
    }

    return false;
  },
  async updateUserConfig(context, payload){
    let url = '/rest/v1/auth/user/userConfig';

    const httpPayload = {
      url: url,
      payload: {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
    };

    const jsonData = await context.dispatch('fetchJSON', httpPayload);

    if (jsonData.status === 'success') {
      context.commit('setUserConfig', jsonData.config);
    }
    else {
      console.warn('Unable to get userConfig')
    }
    return jsonData;
  },
  async loadPreAuthConfig(context) {
    context.commit('setPreAuthConfig', null);
    const httpPayload = {
      url: '/rest/v1/preauth/config',
      payload: {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };
    try {
      const jsonData = await context.dispatch('fetchJSON', httpPayload);
      if(jsonData && jsonData.status === 'success') {
        context.commit('setPreAuthConfig', jsonData.result);
      }
    } catch (err) {
      console.log(err);
    }
  }
};
