import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

const getDefaultState = () => {
  return {
    // saved state variables under userConfig are persisted on the client's local storage
    userConfig: {
      appFlavorTag: '',
      userSelectedCaptureConfig: null,
      devMode: false,
      icaoFallback: true,
      selectedCameraIndexFace: 0,
      selectedCameraIndexID: 0,
      selectedCameraIndexBarcode: 0,
      flagDeleteEnrollment: false,
      rwwSelectedWatchlist: {},
      privacyPolicyAccepted: false,
      userReadyFlag: false
    },
    // saved state variables under sessionConfig are persisted on the client's session storage - cleared when browser closes
    sessionConfig: {
      appFlavorConfig: null,
      fingerprintMode: false
    },
    // Runtime state variables - these are used during runtime only and not persisted
    platform: false,
    webcamList: [],
    capturedFaceData: {
      faceImage: null,
      faceThumbnail: null,
      faceMetrics: {}
    },
    capturedFaceUploadFlag: false,
    capturedIdentityDocData: {
      identityDocPageOne: null,
      identityDocPageTwo: null,
      identityDocFaceThumbnail: null,
      identityDocImageSegments: [],
      identityDocFaceMetrics: {},
      identityDocMetrics: {},
      identityDocBarcodeMrzImage: null,
      identityDocBarcodeMrz: {},
      identityDocBarcodeMrzSkipped: false,
      identityDocType: null,
      identityDocPage1Timeout: false,
    },

    scannedCacData: null,
    scannedDlData: null,
    verifyScore: 0,

    identityData: {},
    vehicleData: {},
    prevIdentityFound: false,
    prevIdentityData: {},

    rwwEnrolled: false,

    deviceSettings: {},
    captureConfigs: null,
    capturedDocs: [],
    startTime: 0,
    captureMetrics: {
      browserInfo: {},
      cameraInfo: {},
      faceFrameCount: 0,
      docTimedOut: false,
      docPage1FrameCount: 0,
      docPage2FrameCount: 0,
    },
    isEntitlement: false,
    entitlementData: null,
    enrollConfig: null,
    eventWorkflowEnabled: false,
    customerId: null,
    regionIdCredential: null,
    regionIdBarcode: null,
    verifyOverride: false,
    rwwEnrollResult: null,
    capturedFingerprintData: {
      data: null,
      position: null,
      format: 'wsq',
      resolution: 500,
      metrics: {},
      tn: ''
    },
    selectedCaptureConfig: 'Verify',
    isCitizen: false,
    paymentAmount: null,
    customerPin: null,
    returnUrl: null
  }
}

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  getDefaultState
};