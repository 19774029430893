<template>
  <div>
  <form class="d-flex flex-column justify-content-between" style="margin-top: var(--spacing-xs); gap: var(--spacing-xs);">
    <div v-if="isEditMode" style="width: 100%;">
      <RocInput v-model='passwords.current' class='inputPassword' placeholder='Current Password' :type='inputTypes.current' autocomplete="off">
        <template #customIcon>
          <RocIcon class="icon" :icon='icons.current' @click="togglePasswordVisibility('current')"/>
        </template>
      </RocInput>
    </div>
    <div style="width: 100%;">
      <span class="inputTitle">Password</span>
      <RocInput v-model='passwords.new' :errorMessage=invalidPassMessage  @blur="validateFields($event,'newPass')" placeholder='Enter a password' :type='inputTypes.new' autocomplete="off">
      <template #customIcon>
        <RocIcon class="icon" size="md" :icon="icons.new" @click="togglePasswordVisibility('new')"/>
      </template>
      </RocInput>
    </div>
    <div style="width: 100%;">
      <span class="inputTitle">Confirm Password</span>
      <RocInput v-model='passwords.confirm' :errorMessage=invalidConfirmPassMessage @blur="validateFields($event,'confirmPass')" placeholder='Confirm entered password' :type='inputTypes.confirm' autocomplete="off">
      <template #customIcon>
        <RocIcon class="icon" size="md" :icon="icons.confirm" @click="togglePasswordVisibility('confirm')"/>
      </template>
      </RocInput>
    </div>
  </form>
  <div class="justify-content-bottom align-self-center" style="margin-top: 4px;">
      <ul>
        <li class="status" v-for="status in statuses" :key="status">
            {{ status }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import {ref, reactive, watch, toRefs} from "vue";
import RocIcon from '@/components/ui/RocIcon.vue';
import RocInput from '@/components/ui/RocInput.vue'

export default {
  components: {
    RocIcon,
    RocInput,
  },
  props: {
    isEditMode: Boolean,
    isSubmitting: Boolean,
    status: String
  },

  setup(props, context) {
    const passwords = reactive({
      current: '',
      new: '',
      confirm: ''
    });
    const icons = reactive({
      current: 'invisible',
      new: 'invisible',
      confirm: 'invisible'
    });
    const inputTypes = reactive({
      current: 'password',
      new: 'password',
      confirm: 'password'
    })
    const invalidPassMessage = ref(undefined);
    const invalidConfirmPassMessage = ref(undefined);

    const statuses = ref(new Set());

    function togglePasswordVisibility(passwordType) {
      if (inputTypes[passwordType] === 'password') {
        inputTypes[passwordType] = 'text';
        icons[passwordType] = 'visible'
      } else if (inputTypes[passwordType] === 'text') {
        inputTypes[passwordType] = 'password';
        icons[passwordType] = 'invisible'
      }
    }

    function validateFields(event,field){
      if(field === 'newPass')
      {
        invalidPassMessage.value = ((event.trim() == '') ? 'Enter a password' : undefined);
      } else if(field === 'confirmPass'){
        invalidConfirmPassMessage.value = ((event.trim() == '') ? 'Confirm password.' : undefined);
      }
    }

    function verifyAndEmitPasswords() {
      /**
       * Basic verification of password fields and return passwords.
       *
       * @emit {object}     Object containing current and new passwords
       */
      statuses.value.clear()

      let verified = true;

      if (props.isEditMode) {
        if (passwords.current === '') {
          pushStatus("Current password cannot be empty.");
          verified = false;
        }
      }

      if (passwords.new === '') {
        pushStatus("New password cannot be empty.");
        verified = false;
      }

      if (passwords.new !== passwords.confirm) {
        pushStatus("Passwords must match");
        verified = false;
      }

      if (verified) {
        context.emit("verify", {verified: true, currentPassword: passwords.current, newPassword: passwords.new});
      } else {
        context.emit("verify", {verified: false, currentPassword: passwords.current, newPassword: passwords.new})
      }
    }


    watch(toRefs(props).isSubmitting, (newValue, oldValue)=>{
      if (newValue) {
        verifyAndEmitPasswords();
      }
    });

    watch(toRefs(props).status, (newValue, oldValue) => {
      if (newValue) {
        pushStatus(newValue);
      }
    })

    function pushStatus(status) {
      /**
       * Push custom status.
       *
       * @param {String} status Status string.
       */
      statuses.value.add(status);
    }

    function removeStatus(status) {
      /**
       * Remove specified status.
       *
       * @param {String} status Status string.
       */
      statuses.value.delete(status);
    }

    return {
      passwords,
      icons,
      inputTypes,
      togglePasswordVisibility,
      verifyAndEmitPasswords,
      statuses,
      invalidConfirmPassMessage,
      invalidPassMessage,
      validateFields
    };
  }
};
</script>

<style scoped lang="scss">

.icon {
  float: right;
  margin-right: 15px;
  opacity: .5;
  margin-left: var(--spacing-s);
}

.rectangle {
  display: flex;
  width: 100%;
  border: 1px solid var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  border-radius: 0.25rem;
  padding: var(--spacing-xs);
}

.inputPassword {
  border: 0px;
  width: 100%;
  @include overwatch-body-med;
}

ul {
  list-style-type: none;
}

.status {
  color: var(--overwatch-error);
}
</style>
