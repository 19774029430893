<template>
  <div class="encounter-filter">
    <form ref="form">
    <input type="file" ref="file" style="display: none" @change="selectFile($event)">
    </form>

    <div class="d-flex flex-column">
      <div class="close-row">
      <div>
        Filters
      </div>
      <MDBDropdown v-model="actionsOptionsToggle" align="end" class="actions-button-menu-container">
      <RocDropdownToggle @click="actionsOptionsToggle = !actionsOptionsToggle" >
        <div class="actions-dropdown-button">
          <RocButton :size="'tiny'">
            Actions
            <RocIcon
            icon="inputArrow"
            size="xxs"
            color="white"
            style="margin: 0 0 2px 10px; transition: transform 0.1s ease;"
            :style="{transform: actionsOptionsToggle ? 'rotate(0deg)' : 'rotate(180deg)'}"/>
          </RocButton>
         </div>
      </RocDropdownToggle>
      <RocDropdownMenu aria-labelledby='dropdownMenuButton' class="actions-dropdown-menu" style="margin-top: 10px; ">
        <MDBDropdownItem href="#" @click.prevent="showSavedViewPopup('load')">
          <div class="d-flex flex-row">
            <div class="overwatch-body-small">Load Saved View</div>
            <RocIcon
            style="margin-left: auto"
            color="none"
            size="sm"
            icon="upload"
            />
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem href="#" @click.prevent="goToSavedViews()">
          <div class="d-flex flex-row">
            <div class="overwatch-body-small">Go to Saved Views</div>
            <RocIcon
            style="margin-left: auto"
            color="none"
            size="sm"
            icon="outsideLink"
            />
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem v-if="areAnyFiltersExpanded" href="#" @click.prevent="expandCollapseAllFilters('collapse')">
          <div class="d-flex flex-row">
            <div class="actions-dropdown-menu-text">Collapse All Filters</div>
            <RocIcon
            style="margin-left: auto; transform: rotate(180deg)"
            color="none"
            size="sm"
            icon="downArrow"
            />
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem v-if="areAnyFiltersCollapsed" href="#" @click.prevent="expandCollapseAllFilters('expand')">
          <div class="d-flex flex-row">
            <div class="actions-dropdown-menu-text">Expand All Filters</div>
            <RocIcon
            style="margin-left: auto;"
            color="none"
            size="sm"
            icon="downArrow"
            />
          </div>
        </MDBDropdownItem>
      </RocDropdownMenu>
      </MDBDropdown>
      <div class="close-filter-icon">
      <RocIcon
        color="black"
        size="sm"
        icon="exit"
        @click="toggleFilter"
      />
    </div>
    </div>
    <div class="divider"></div>
    </div>

    <div class="filter-content">
      <div>
        <div class="filter-title" ref="divMatching">
          Matching
          <RocIcon
            class='collapse-button'
            color="primary"
            size="sm"
            icon="downArrow"
            :flip="isMatchingExpanded"
            @click="isMatchingExpanded = !isMatchingExpanded"
          />
        </div>
        <div class="filter-section" :class="{'expanded': isMatchingExpanded}">
          <RocInput :placeholder="'Match Name or Detected Text'" clearButton v-model="searchText" >
            <RocIcon color="gray" size="sm" icon="search" style="margin-right: var(--spacing-s)"/>
          </RocInput>

          <div v-if='showImgSearchBox || showImageSearch' class='imageSearchBox' @dragover.prevent @drop.prevent="drop($event)">
            <div class='d-flex justify-content-between align-items-center' style='width: 100%;'>
              <div class="overwatch-body-med" style="opacity: 0.8; color: var(--overwatch-neutral-100)">Search By Image</div>
              <div style="padding: 4px; cursor: pointer;">
              <RocIcon
              color="black"
              size="xs"
              icon="exit"
              @click="closeImageSearch()"/>
              </div>
            </div>
            <div class="d-flex">
              <div v-if="isSearchByImage" class="d-flex flex-column" style="width:50%; margin-top: 5px">
                <div style="color: var(--overwatch-neutral-100)">Modality</div>
                <RocSelect
                :availableOptions="reverseSearchModalities"
                :placeholder="'Select'"
                :optionLabel="'value'"
                :currentlySelected="reverseSearchModality"
                @selectionChanged="changeReverseSearchModality"
                />
              </div>
              <div class='d-flex justify-content-center' style='width:50%; margin-top: 30px'>
                <div>
                   <auth-img :src='imageSearchBytes' style='max-width: 115px; height: auto'></auth-img>
                </div>
              </div>
            </div>
          </div>

          <div class='search-by-image'>
            <div>Search By Image</div>
            <RocButton class='upload' v-if="!isSearchImageSelected" type="secondary" @click="reverseSearch()">
              <RocIcon size="sm" color="white" icon="upload"/>
            </RocButton>
            <RocButton v-else @click="reverseSearch">Search</RocButton>
          </div>
        </div>
      </div>

      <div class="divider"></div>

      <div>
        <div class="filter-title">

        <div>Tagging</div>
          <RocIcon
            class='collapse-button'
            color="primary"
            size="sm"
            icon="downArrow"
            :flip="isTaggingExpanded"
            @click="isTaggingExpanded = !isTaggingExpanded"
          />
        </div>
        <div class="filter-section" :class="{'expanded': isTaggingExpanded}">
          <FilterTaggingMultiselect @tags-filter-change="updateAttributesFilter" ref="taggingFilter"/>
        </div>
      </div>

      <div class="divider"></div>

      <div>
        <div class="filter-title">
          <div>Date Range</div>
          <RocIcon
            class='collapse-button'
            color="primary"
            size="sm"
            icon="downArrow"
            :flip="isDateExpanded"
            @click="isDateExpanded = !isDateExpanded"
          />
        </div>
        <div class="filter-section" :class="{'expanded': isDateExpanded}">
          <div style="margin-bottom: 12px;">
            <RocCalendar
              @time-filter-change="updateDateRange"
              @clear-date-selection="removeRange"
              :dateValue="dateRange"
              :quickDateButtonsShow="true">
            </RocCalendar>
          </div>
        </div>
      </div>

      <div class="divider"></div>

      <div>
        <div class="filter-title">
          <div>Cameras</div>
          <RocIcon
            class='collapse-button'
            color="primary"
            size="sm"
            icon="downArrow"
            :flip="isCamerasExpanded"
            @click="isCamerasExpanded = !isCamerasExpanded"
          />
        </div>
        <div class="filter-section" :class="{'expanded': isCamerasExpanded}">
          <LargeFilterMultiSelect
            mode="tags"
            :encounterCamMode="true"
            :groupsEnabled="true"
            :groupSelect="true"
            :selectAllOptions="selectAllCameraMultiselect"
            :clearOptions="clearCameraMultiselect"
            :close-on-select="false"
            no-results-text=""
            placeholder-text="Select cameras"
            :available-items="fetchCameras"
            :currently-selected="selectedCameras"
            @selection-changed="updateSelectedCameras">
          </LargeFilterMultiSelect>
          <div class="filter-multi-select-buttons">
            <RocButton type="secondary" @click="clearCameraMultiselect=true">
              Clear All
            </RocButton>
            <RocButton @click="selectAllCameraMultiselect=true">
              Select All
            </RocButton>
          </div>
        </div>
      </div>

      <div class="divider"></div>

      <div>
        <div class="filter-title">
          <div>Watchlists</div>
          <RocIcon
            class='collapse-button'
            color="primary"
            size="sm"
            icon="downArrow"
            :flip="isWatchlistsExpanded"
            @click="isWatchlistsExpanded = !isWatchlistsExpanded"
          />
        </div>
        <div class="filter-section" :class="{'expanded': isWatchlistsExpanded}">
          <LargeFilterMultiSelect
            mode="tags"
            :encounterWatchlistMode="true"
            :selectAllOptions="selectAllWlMultiselect"
            :clearOptions="clearWlMultiselect"
            :close-on-select="false"
            no-results-text=""
            placeholder-text="Select watchlists"
            :available-items="fetchWatchlists"
            :currently-selected="selectedWatchlists"
            @selection-changed="updateSelectedWatchlists">
          </LargeFilterMultiSelect>
          <div class="filter-multi-select-buttons">
            <RocButton type="secondary" @click="clearWlMultiselect=true">
              Clear All
            </RocButton>
            <RocButton @click="selectAllWlMultiselect=true">
              Select All
            </RocButton>
          </div>
        </div>
      </div>

      <div class="divider"></div>

      <div>
        <div class="filter-title">
          <div>Analytics</div>
          <RocIcon
            class='collapse-button'
            color="primary"
            size="sm"
            icon="downArrow"
            :flip="isAnalyticsExpanded"
            @click="isAnalyticsExpanded = !isAnalyticsExpanded"
          />
        </div>
        <div class="filter-section" :class="{'expanded': isAnalyticsExpanded}">
          <LargeFilterMultiSelect mode="tags"
            :encounterWatchlistMode="true"
            :close-on-select="false"
            no-results-text=""
            placeholder-text="Select analytics"
            :available-items="availableAnalytics"
            :currently-selected="selectedAnalytics"
            @selection-changed="updateSelectedAnalytics"
            :selectAllOptions="selectAllAnalyticsMultiselect"
            :clearOptions="clearAnalyticsMultiselect"
          />
          <div class="filter-multi-select-buttons">
            <RocButton type="secondary" @click="clearAnalyticsMultiselect=true">
              Clear All
            </RocButton>
            <RocButton @click="selectAllAnalyticsMultiselect=true">
              Select All
            </RocButton>
          </div>
        </div>
      </div>

      <div class="divider"></div>

      <div>
        <div class="filter-title">
          <div>Adjudication</div>
          <RocIcon
            class='collapse-button'
            color="primary"
            size="sm"
            icon="downArrow"
            :flip="isAdjudicationExpanded"
            @click="isAdjudicationExpanded = !isAdjudicationExpanded"
          />
        </div>
        <div class="filter-section" :class="{'expanded': isAdjudicationExpanded}">
          <base-button-group
            :list="adjudicationFilter"
            @state-change="adjudicationStateChange">
          </base-button-group>
        </div>
      </div>

      <div class="divider"></div>

      <div class="alarm-only-switch">
        <div style="color: var(--overwatch-neutral-100)" >Show Me Matches Only</div>
        <RocSwitch :isActive='alarmOnlySwitch' @switch-toggled="updateAlarmOnlySwitch"/>
      </div>

      <div class="d-flex justify-content-end" style="margin-top: var(--spacing-m);">
        <RocButton @click="showSavedViewPopup('save')">
          Save to Views
        </RocButton>
      </div>

      <div
        v-if="userConfig && userConfig.savedViews && userConfig.savedViews.length > 0"
        class="d-flex justify-content-end" style="margin-top: var(--spacing-s);">
        <a class='update-existing-view overwatch-body-med' @click="showSavedViewPopup('filterUpdate')">
          Update Existing View
        </a>
      </div>
    </div>

    <base-dialog :title="popupTitle" :show="showSavedView" :style="savedViewPopupStyle" @close="showSavedView=false">
      <SavedViewPopup :filter-values="savedViewObject" :popup-type="popupType"
       @load-view="loadSavedView" @close="closeSavedViewPopup"></SavedViewPopup>
    </base-dialog>
    <base-dialog :show="showUpdateConfirmation" @close="showUpdateConfirmation=false" :hideCloseBtn="true" :style="updateConfirmationStyle">
      <div style="width: 100%;" class="d-flex flex-row justify-content-center align-items-center">
          <RocIcon color="primary" size="lg" icon="circleCheck" style="margin-right:var(--spacing-s); margin-top: var(--spacing-s)"/>
        <div style="flex-wrap: wrap;">
          <div style="color: var(--overwatch-neutral-100); margin-top: var(--spacing-m)" class="overwatch-title-small">Your changes have been saved!</div>
          <div v-if="popupType === 'save'" @click="goToSavedViews()" class="overwatch-title-small"
           style="color: var(--overwatch-button-primary); margin-top: var(--spacing-s); text-decoration: underline; cursor: pointer;" >
            Go to Saved Views
          </div>
        </div>
      </div>
    </base-dialog>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch, watchEffect, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {  MDBDropdown, MDBDropdownItem } from "mdb-vue-ui-kit";
import BaseButtonGroup from "@/components/ui/BaseButtonGroup";
import SavedViewPopup from "@/components/encounters/SavedViewPopup";
import RocIcon from "@/components/ui/RocIcon";
import RocButton from "@/components/ui/RocButton";
import RocSwitch from "@/components/ui/RocSwitch";
import RocInput from "@/components/ui/RocInput.vue";
import LargeFilterMultiSelect from "@/components/encounters/LargeFilterMultiSelect";
import RocCalendar from "@/components/ui/RocCalendar.vue";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocDropdownToggle from "@/components/ui/RocDropdownToggle.vue";
import RocSelect from "@/components/ui/RocSelect.vue";
import FilterTaggingMultiselect from "@/components/ui/FilterTaggingMultiselect.vue";
import { get as lodashGet } from "lodash";

const props = defineProps(['filterState']);
const emit = defineEmits([
  'change-filter',
  'reverse-search',
  'face-filter-remove',
  'search-text-change',
  'alerts-only-change',
  'time-filter-change',
  'clear',
  'camera-filter-change',
  'watchlist-filter-change',
  'adjudication-filter-change',
  'tags-filter-change',
  'analytics-filter-change',
  'case-change'
]);

const store = useStore();
const router = useRouter();

const dateRange = computed(() => {
  return store.getters['encounters/getDTFilter'];
});

function updateDateRange(filterRange) {
  store.commit('auth/setUserSettingsChanged', Date.now());
  emit('time-filter-change', filterRange);
};

const searchText = ref(store.getters['encounters/getSearchText']);

const alarmOnlySwitch = ref(store.getters["encounters/matchesOnly"]);

const file = ref(null);
const form = ref(null);

const adjudicationFilter = reactive(store.getters["encounters/adjudicationFilter"]);
watch(adjudicationFilter, () => {
  if (adjudicationFilter.some(element=>element.active)) {
    alarmOnlySwitch.value = true;
  }
  emit('adjudication-filter-change', adjudicationFilter);
});

const fetchCameras = computed(function() {
  const cameras = store.getters['cameras/cameras'];
  const cameraGroups = Array.from(store.getters['cameras/getAllCameraGroups']) ?? [];
  const arrOfOptions = [{label: 'Uncategorized Camera', options: []}];
  cameraGroups.forEach((group) => arrOfOptions.push({label: group, options: []}));

  cameras?.forEach((camera) => {
    if(camera.cameraGroups?.length == 0)
    {
      arrOfOptions[0].options.push({label:camera.name, value: camera.GUID});
    }
    else {
      camera.cameraGroups?.forEach((group) => {
        arrOfOptions.forEach((option) => {
          if(option.label === group)
          {
            option.options.push({label:camera.name, value: camera.GUID});
          }
        });
      });
    }
  });
  return arrOfOptions;
});

const fetchWatchlists = computed(function() {
  return store.getters["watchlists/watchlists"];
});

const availableAnalytics = computed(() => {
  const array = [];

  const options = {
    Face: 'face',
    'License Plate': 'license_plate',
    'OCR In-The-Wild': 'Text',
    Vehicle: 'vehicle',
    Pedestrian: 'person',
    Gun: 'gun',
    Tattoo: 'tattoo'
  }

  for(let [key, value] of Object.entries(options)) {
    array.push({
      label: key,
      value: value
    })
  }

  return array;
}) ;

const selectedCameras = computed(function () {
  return store.getters["cameras/encounterFilterSelectedCameras"];
});

const selectedWatchlists = computed(function () {
  return store.getters["watchlists/encounterFilterSelectedWatchlists"];
});

const selectedAnalytics = computed(function () {
  return store.getters["encounters/analyticsFilter"];
});

const availableCases = computed(() => {
  return store.getters['cases/cases'];
});
const taggingFilter = ref(null);

const activeMission = computed(() => {
  return store.getters['cases/activeMission'];
})


function selectAllCameras() {
  const selected = [];
  const fetchCameras = store.getters['cameras/cameras'];
  fetchCameras.forEach(cam => {
    selected.push(cam.GUID);
  });
  store.commit(
    'cameras/setEncounterFilterSelectedCameras',
    selected
  );
  emit("camera-filter-change", selected);
}

function updateSelectedCameras(selectedCams) {
  store.commit("cameras/setEncounterFilterSelectedCameras", selectedCams.value);
  //reset clear all/select all flags
  selectAllCameraMultiselect.value = false;
  clearCameraMultiselect.value = false;
  emit("camera-filter-change", selectedCams.value);
}

function updateSelectedWatchlists(selectedWL) {
  store.commit("watchlists/setEncounterFilterSelectedWatchlists", selectedWL.value);
  store.commit('auth/setUserSettingsChanged', Date.now());
  //reset clear all/select all flags for multiselect
  selectAllWlMultiselect.value = false;
  clearWlMultiselect.value = false;
  emit("watchlist-filter-change", selectedWL);
}

//update store and emit event when tags or andOr selection changes
function updateAttributesFilter() {
  store.commit('auth/setUserSettingsChanged', Date.now());
  emit("tags-filter-change");
}

function updateSelectedAnalytics(selectedAnalytics) {
  store.commit('encounters/setAnalyticsFilter', selectedAnalytics.value);
  selectAllAnalyticsMultiselect.value = false;
  clearAnalyticsMultiselect.value = false;
  emit("analytics-filter-change", selectedAnalytics.value);
}

watch(searchText, (newValue, oldValue) => {
  if (newValue != oldValue) {
    updateSearchText(newValue);
  }
});

watch(() => store.getters['encounters/getSearchText'], (newValue, oldValue) => {
  if (newValue != searchText.value) {
    searchText.value = newValue;
    scrollToMatching();
  }
});

function updateSearchText(value) {
  store.commit("encounters/setSearchText", value);
  store.commit('auth/setUserSettingsChanged', Date.now());
  emit('search-text-change', value);
}

//manual way of opening image search, only used in load saved view popup
const showImageSearch = ref(false);

const showImgSearchBox = computed(function() {
  return store.getters['encounters/showImageSearchBox'];
});

const imageSearchBytes = computed(function() {
  let imgsrc = require('@/assets/liveview-placeholder.png');
  if (store.getters['encounters/searchImageData']) {
    imgsrc = store.getters['encounters/searchImageData'];
    nextTick(() => {
      if(availableCases.value && availableCases.value.length > 0){
        //scroll to cases since it's the first filter
        scrollToCases();
      } else {
        scrollToMatching();
      }
    });
  }
  return imgsrc;
});

const divCases = ref(null);
const divMatching = ref(null);
function scrollToCases() {
  if (divCases.value) {
    divCases.value.scrollIntoView({ behavior: 'smooth' });
  }
}
function scrollToMatching() {
  if (divMatching.value) {
    divMatching.value.scrollIntoView({ behavior: 'smooth' });
  }
}

const isSearchImageSelected = computed(() => {
  return !!store.getters['encounters/searchImageData']
})

function closeImageSearch() {
  showImageSearch.value = false;
  store.commit('encounters/setSearchImageData', null);
  store.commit('encounters/setSearchTemplateId', null);
  store.commit('encounters/setSearchModality', 'face');
  emit('face-filter-remove');
  form.value.reset();
  //file.value.splice(0, 1);
}

const isSearchByImage = computed(() => {
  return !store.getters["encounters/searchTemplateId"] || store.getters["encounters/searchTemplateId"] === -1;
})

function reverseSearch() {
  if(!store.getters["encounters/searchTemplateId"] && !store.getters["encounters/searchImageData"]) {
    file.value.click();
  }
  else {
    if (isSearchByImage.value) {
      store.commit('encounters/setSearchModality', reverseSearchModality.value);
    }
    emit('reverse-search');
  }
}

const pageFirstLoad = ref(true);
watchEffect(() => {
  //on page load, make sure time-filter-change gets called so that encounter list gets updated
  //TODO - maybe this isn't necessary anymore with how much date filter logic has changed
  if (pageFirstLoad.value === true) {
    let x = store.getters['encounters/getDTFilter'];
    emit('time-filter-change', x);
    pageFirstLoad.value = false; // reset one time flag
    return;
  }
});

function updateAlarmOnlySwitch(switchValue) {
  alarmOnlySwitch.value = switchValue;
}

watch(alarmOnlySwitch, (newValue) => {
  // If the alarm only switch is turned off, turn off all of the adjudication filters.
  if (!newValue) {
    for (var key in adjudicationFilter) {
      adjudicationFilter[key].active = false;
    }
  }
  store.commit("encounters/setMatchesOnly", alarmOnlySwitch.value);
  store.commit('auth/setUserSettingsChanged', Date.now())
  if(!store.getters["encounters/searchTemplateId"] && !store.getters["encounters/searchImageData"]) {
    emit("alerts-only-change", alarmOnlySwitch.value);
  }
});

function toggleFilter() {
  emit('change-filter', !props.filterState);
}

function removeRange() {
  emit('clear');
}

function drop(e) {
  const file = e.dataTransfer.files[0];
  loadImage(file);
}

function readAsDataURL(file) {
  return new Promise(function (resolve, reject) {
    let fr = new FileReader();
    fr.onload = function () {
      resolve(fr.result);
    };
    fr.onerror = function () {
      reject(fr);
    };
    fr.readAsDataURL(file);
  });
}

function selectFile(e) {
  const file = e.target.files[0];
  loadImage(file);
}

function loadImage(file) {
  if (file) {
    if (file.type.startsWith("image") === true) {
      readAsDataURL(file).then((bytes) => {
        store.commit('encounters/setSearchImageData', bytes);
        store.commit('encounters/setSearchTemplateId', -1);
        store.commit('encounters/setSearchModality', reverseSearchModality.value);
      });
    }
  }
}

onMounted(async () => {
  await store.dispatch('settings/loadReverseSearchObjModalities');
  initReverseSearch();
  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth;
  });
  if (searchText.value) {
    updateSearchText(searchText.value);
  }
});

const adjudicationSelected = ref('');

function adjudicationStateChange([btn, active]) {
  adjudicationSelected.value = btn.label
  adjudicationFilter.forEach((element, index) => {
    if(element.label === btn.label) {
      adjudicationFilter[index].active = active;
    }
  });
}

const reverseSearchModalities = ref([]);
const reverseSearchModality = ref('face');

function changeReverseSearchModality(modality) {
  reverseSearchModality.value = modality;
}

function initReverseSearch() {
  const objects = store.getters['settings/reverseSearchObjModalities'];
  reverseSearchModalities.value = ['face'];
  reverseSearchModalities.value.push(...objects);
}

const userConfig = computed(()=> {
  return store.getters['auth/userConfig']
});

const popupType = ref('');
function showSavedViewPopup(type) {
  actionsOptionsToggle.value = false;
  popupType.value = type;
  showSavedView.value = true;
}

//put all filters in an object to send to saved views
const savedViewObject = computed(function () {
  let x = new Date()
  let filterDateTime = x.toLocaleString().split(',')[0] + " " + x.toLocaleString().split(',')[1]

  let img = imageSearchBytes.value;
  if(imageSearchBytes.value.includes("liveview-placeholder")){
    img = "";
  }

  let savedFilters = {
    searchText: searchText.value,
    faceImage: img,
    tags: store.getters["encounters/attributesFilter"],
    dateRange: store.getters['encounters/getDTFilter'],
    cams: selectedCameras.value,
    watchlists: selectedWatchlists.value,
    analytics: selectedAnalytics.value,
    adjudication: adjudicationFilter,
    matchesOnly: alarmOnlySwitch.value,
    filterCreatedTime: filterDateTime
  };

  return savedFilters;
});

const selectAllWlMultiselect = ref(false);
const selectAllCameraMultiselect = ref(false);
const selectAllAnalyticsMultiselect = ref(false);

const clearWlMultiselect = ref(false);
const clearCameraMultiselect = ref(false);
const clearAnalyticsMultiselect = ref(false);
const clearTagsFilter = ref(false);

const windowWidth = ref(window.innerWidth);
const savedViewPopupStyle = computed(() => {
  if (windowWidth.value <= 480) {
    // Mobile style
    return {
      width: '90%'
    };
  }
  else{
    return{
      'max-width': '450px'
    }
  }
});

const showUpdateConfirmation = ref(false);
function closeSavedViewPopup(payload){
  popupType.value = payload;
  showSavedView.value = false;
  if(payload ==='save'){
    showUpdateConfirmation.value = true;
    setTimeout(() => {
      showUpdateConfirmation.value = false;
  }, 3000)
  }
  if(payload ==='filterUpdate'){
    showUpdateConfirmation.value = true;
    setTimeout(() => {
      showUpdateConfirmation.value = false;
  }, 800)
  }
}

const updateConfirmationStyle = computed(()=>{
  return{
    'background-color': '#000000',
    'border-radius': '4px'
  }
})

const actionsOptionsToggle = ref(false);
const popupTitle = computed(function () {
  if (popupType.value === "save") {
    return "Create New View"
  }
  else if (popupType.value === "load") {
    return "Load Saved View"
  }
  else if (popupType.value === "filterUpdate") {
    return "Update Saved View"
  }
  else {
    return ""
  }
})

function goToSavedViews() {
  actionsOptionsToggle.value = false;
  router.push({name: 'SavedViews'});
}

const showSavedView = ref(false);
async function loadSavedView(payload){
  await store.dispatch('auth/applySavedView', payload);

  //manually setting non-reactive values from saved view
  searchText.value = lodashGet(payload, 'searchText', null);

  if(payload.faceImage){
    showImageSearch.value = true;
  }

  let adjudication = lodashGet(payload, 'adjudication', null);
  for (var key in adjudication) {
    let x = adjudication[key];
    adjudicationStateChange([x, x.active]);
  }

  alarmOnlySwitch.value = payload.matchesOnly;

  showSavedView.value = !showSavedView.value;
  // this will re-apply tag groups per saved view we just loaded
  taggingFilter.value.initTagGroups();
}

/** Collapsible code */
const isCasesExpanded = ref(true);
const isMatchingExpanded = ref(true);
const isTaggingExpanded = ref(true);
const isDateExpanded = ref(true);
const isCamerasExpanded = ref(true);
const isWatchlistsExpanded = ref(true);
const isAnalyticsExpanded = ref(true);
const isAdjudicationExpanded = ref(true);

function expandCollapseAllFilters(expandCollapse) {
  if(expandCollapse === 'expand') {
    isCasesExpanded.value = true;
    isMatchingExpanded.value = true;
    isTaggingExpanded.value = true;
    isDateExpanded.value = true;
    isCamerasExpanded.value = true;
    isWatchlistsExpanded.value = true;
    isAnalyticsExpanded.value = true;
    isAdjudicationExpanded.value = true;
  }
  else if(expandCollapse === 'collapse') {
    isCasesExpanded.value = false;
    isMatchingExpanded.value = false;
    isTaggingExpanded.value = false;
    isDateExpanded.value = false;
    isCamerasExpanded.value = false;
    isWatchlistsExpanded.value = false;
    isAnalyticsExpanded.value = false;
    isAdjudicationExpanded.value = false;
  }
}

const areAnyFiltersExpanded = computed(() => {
  return isCasesExpanded.value || isMatchingExpanded.value || isTaggingExpanded.value || isDateExpanded.value || isCamerasExpanded.value || isWatchlistsExpanded.value || isAnalyticsExpanded.value || isAdjudicationExpanded.value;
});

const areAnyFiltersCollapsed = computed(() => {
  return !isCasesExpanded.value || !isMatchingExpanded.value || !isTaggingExpanded.value || !isDateExpanded.value || !isCamerasExpanded.value || !isWatchlistsExpanded.value || !isAnalyticsExpanded.value || !isAdjudicationExpanded.value;
});
</script>

<style scoped lang="scss">
.encounter-filter {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 380px;
  background-color: var(--overwatch-secondary);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.26);
}

.filter-content {
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  margin: 0;
  padding: $--spacing-l;
  padding-top: 0px;
}

.divider {
  margin: 0;
  line-height: 0;
  border-top: 1px solid var(--overwatch-accent) !important;
}

.close-row {
  @include overwatch-title-small;
  display: flex;
  align-items: center;
  color: var(--overwatch-neutral-100);
  width: 100%;
  padding-left: $--spacing-m;
  padding-right: $--spacing-m;
}

.close-filter-icon {
  cursor: pointer;
  margin-left: auto;
  padding: 4px;
}

.actions-dropdown-button {
  @include overwatch-title-med;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  margin-bottom: $--spacing-m;
}

.actions-dropdown-button button {
  padding: 8px 12px;
}

.actions-dropdown-button button div {
  @include overwatch-body-small;
  display: flex;
  align-items: center;
  gap: $--spacing-s;
}


.actions-button-menu-container{
  margin-left: 16px;
  margin-top: 16px;
}

.actions-dropdown-menu{
  width: 15rem;
}

.actions-dropdown-menu-text{
  @include overwatch-body-small;
}

.search-by-image {
  @include overwatch-body-med;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: $--spacing-s;
  gap: $--spacing-s;
  color: var(--overwatch-neutral-100);
}

.search-by-image .upload {
  height: fit-content;
  width: fit-content;
  padding: $--spacing-s;
  font-size: 0;
}

/* make button text not all caps */
.btn {
  text-transform: unset !important;
}

.ButtonSecondaryDefault {
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 5px 0 4px;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--overwatch-neutral-300);
}

h2 {
  margin: 0.5rem 0;
}

.filter-title {
  @include overwatch-body-large;
  color: var(--overwatch-neutral-100);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $--spacing-m 0;
}

.filter-title .collapse-button {
  cursor: pointer;
}
.filter-section {
  height: 0;
  width: 100%;
  overflow: hidden;
}

.filter-section.expanded {
  height: fit-content;
  padding-bottom: $--spacing-m;
  overflow: visible;
}

.filter-multi-select-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: $--spacing-base;
  gap: $--spacing-base;
}

.filter-multi-select-buttons button {
  flex: 1;
}

.case-select-container{
  display:flex;
  justify-content: center;
  position: relative;
  min-width: 100%;
  color: var(--overwatch-neutral-100) !important;
  background-color: var(--overwatch-neutral-500);
  border: solid 1px var(--overwatch-neutral-300);
  height:45px;
  border-radius: 5px;
  overflow: hidden;
}

.select-box{
  border: none;
  appearance: none;
  padding: 8px 25px 8px 10px;
  width: 100%;
  color: var(--overwatch-neutral-100) !important;
  background-color: var(--overwatch-neutral-500);
  font-size: 16px;
  cursor: pointer;
}

.case-select-container .icon-container{
  margin-top: 2px;
  margin-right: 8px;
  width: 20px;
  height: 90%;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--overwatch-neutral-500);
  pointer-events: none;
}

.icon-container img{
  width: 16px;
  height: 16px;
}
/* make button text not all caps */
.btn {
  text-transform: unset !important;
}

h2 {
  margin: 0.5rem 0;
}

.Check-Circle-Icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  border: 1px solid #000000;
  display: inline-block;
  background-color: var(--overwatch-secondary);
}

.ButtonSecondaryDefault {
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 5px 0 4px;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--overwatch-neutral-300);
}

.filter-option {
  margin-right: 1rem !important;
}

.filter-option label,
.filter-option input {
  vertical-align: middle;
}

.filter-option label {
  margin-left: 0.25rem;
}

.w3-border {
  border: 1px solid red !important;
}

.w3-container, .w3-panel {
  padding: 0.01em 16px;
  background: red;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26) !important;
}

.w3-round-xlarge {
  border-radius: 16px;
}

.filter-enter-from {
  opacity: 0;
  transform: translateY(-60px);
}

.imageSearchBox {
  box-sizing: border-box;
  width: 100%;
  border: solid 1px var(--overwatch-accent);
  border-radius: 5px;
  background-color: var(--overwatch-secondary);
  padding: var(--spacing-base) var(--spacing-m);
  margin-top: 5px !important;
  align-content: center;
  padding-bottom: 10px !important;
}

.container {
  width: 100%;
  height: 100%;
  background: var(--overwatch-secondary);
  border-radius: 10px;
  padding: 30px;
  position: relative;
}

.line-breaker {
  box-sizing: border-box;
  height: 1px;
  width: 320px;
  border: 1px solid var(--overwatch-accent);
  margin-top: 8px;
  margin-bottom: 8px;
}

.base-card {
  width: 22.5rem !important;
  padding: .0rem !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.base-card.top {
  border-radius: 0 0 0px 0px !important;
  box-shadow: 0 0 0 0 !important;
  margin-top: 1px !important;
}

.base-card.bottom {
  padding: .0rem !important;
  border-radius: 0px 0px 0px 0px;
  margin-top: 0px !important;

  box-shadow: 0 0 0 0 !important;
}

.filter-title {
  width:20rem;
}

.Update-Existing-View {
 @include overwatch-body-med;
  text-align: left;
  color: var(--overwatch-primary);
  cursor: pointer;
  gap: 10px;
}

.alarm-only-switch {
  @include overwatch-body-med;
  display: flex;
  align-items: center;
  gap: $--spacing-s;
  margin-top: $--spacing-s;
}

.update-existing-view {
  @include overwatch-body-med;
  text-decoration: underline;
  color: var(--overwatch-primary);
  cursor: pointer;
}

@media (max-width: 480px) {
  .encounter-filter {
    width: 100%;
    height: 100%;
    border-radius: 12px 12px 0px 0px !important;
    box-shadow: 4px 0 15px 0 rgba(0,19,58,0.3);
  }
  .imageSearchBox {
    width: 100%;
  }

  .base-card {
    /* Reduce the margins on the base-card components */
    width: 100% !important;
    margin: 0px;
  }

  .filter-title {
    width: 100%;
  }

  input {
    width: 100%;
  }
}

</style>
