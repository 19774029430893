<template>
  <div class="wrapper">
    <div class="wizard-header">
      <div class="overwatch-title-large">
        <RocIcon v-show="currentStep === 0" style="cursor: pointer; margin-right: 24px; transform: rotate(90deg)" icon="downArrow" size="sm" @click="$router.back()"/>
        {{ caseName }}
      </div>
      <!-- galleries could include caseMedia or watchlists -->
      <div v-for="gallery of galleries" style="color: var(--overwatch-button-primary); margin-left: var(--spacing-m)">
        <RocIcon icon="image" size="sm"/>
        {{ gallery.name ? gallery.name : gallery.fileName }}
      </div>
      <MDBStepper ref="wizardStepperRef" style="width: 400px; margin-left: auto; border-radius: 5px" v-if="currentStep <= 2">
        <MDBStepperStep active>
          <MDBStepperHead icon="1" @click="currentStep = 0">
            Select Galleries
          </MDBStepperHead>
        </MDBStepperStep>
        <MDBStepperStep>
          <MDBStepperHead icon="2" @click="currentStep = 1">
            Upload Probe
          </MDBStepperHead>
        </MDBStepperStep>
      </MDBStepper>
    </div>
    <div class="wizard-body">
      <InvestigativeCaseGallerySelection v-if="currentStep === 0"
        :case="currentCase"
        @select-media="handleSelectedVideos"
        @select-watchlist="handleSelectedWatchlists"
      />
      <InvestigativeCaseProbeSelection v-else-if="currentStep === 1"
        @upload="getProbeFile"
      />
      <div v-else-if="currentStep === 2" style="height: 100%; width: 100%;">
        <div style="display: flex; flex-direction: column; margin: auto; width: 700px;">
          <div style="display: flex; align-items: center; justify-content: center; margin-bottom: var(--spacing-m)">
            <roc-spinner style="margin-right: var(--spacing-m);"/>
            Processing newly uploaded media for the first time...
          </div>
        
          <!-- Case statuses -->
          <div class="panel" v-for="m in caseMediaStatuses">
            <div style="min-width: 60%">
              {{ m.fileName }}
            </div>
            <div v-if="m.status === 'processing'" style="min-width: 20%;">
              {{ liveProgressForFilename[m.fileName] ? 
                liveProgressForFilename[m.fileName].toFixed(1) :
                0
              }}% 
            </div>
            <div v-else style="min-width: 20%;">
              {{m.statusDescription ? m.status + ' ' + '-' + ' ' + m.statusDescription : m.status }}
            </div>

            <div style="min-width: 20%; display: flex; flex-direction: row-reverse;">
              <roc-spinner size="sm" v-if="m.status === 'uploading'"/>
              <roc-spinner size="sm" v-if="m.status === 'processing'"/>
              <RocIcon icon="check" size="lg" v-else-if="m.status === 'completed'" color="primary"/> 
              <RocIcon icon="exit" size="lg" v-else-if="m.status === 'error'" color="red"/> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wizard-footer" :class="{hide: currentStep >= 2}">
      <RocButton style="margin-left: auto;" @click="goNextStep()" :disabled="isNextButtonDisabled">
        Continue
      </RocButton>
    </div>
    
    <div v-if="isLoading">
      Loading...
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import RocButton from '@/components/ui/RocButton';
import InvestigativeCaseGallerySelection from '@/components/investigations/InvestigativeCaseGallerySelection';
import InvestigativeCaseProbeSelection from '@/components/investigations/InvestigativeCaseProbeSelection';
import RocIcon from '@/components/ui/RocIcon';

import {
  MDBStepper,
  MDBStepperStep,
  MDBStepperHead,
} from 'mdb-vue-ui-kit';

export default {
  name: 'InvestigativeCaseImportWizard',
  components: {
    RocButton,
    MDBStepper,
    MDBStepperStep,
    MDBStepperHead,
    InvestigativeCaseGallerySelection,
    InvestigativeCaseProbeSelection,
    RocIcon
  },
  props: ['id'],
  setup(props, context) {
    const store = useStore();
    const router = useRouter();

    const wizardStepperRef = ref(null);
    const currentStep = ref(0);

    const probeRef = ref();
    const videoRef = ref();

    const probeFile = ref();
    const probeBase64 = ref();
    function getProbeFile(payload) {
      probeFile.value = payload;

      const fr = new FileReader();
      fr.readAsDataURL(probeFile.value);
      fr.onloadend = () => {
        probeBase64.value = fr.result;
      };
    }

    const selectedMedia = ref([]);
    const selectedWatchlists = ref([]);

    const selectedMediaIds = ref([]);

    const galleries = ref([]);

    watch([selectedMedia, selectedWatchlists], (nv) => {
      galleries.value = [...nv[0], ... nv[1]];
    }, {deep:true});

    function handleSelectedVideos(payload) {
      
      selectedMedia.value = payload;
      getMediaIds(payload.map(e=>e._id));
    }

    function handleSelectedWatchlists(payload) {
      
      selectedWatchlists.value = payload;
      getWatchlists(payload.map(e => ({ id: e._id, type: e.type })));
    }

    function getMediaIds(payload) {
      selectedMediaIds.value = payload;
    }

    function getWatchlists(payload) {
      selectedWatchlists.value = payload;
    }

    const isLoading = ref(false);

    async function search() {
      return await store.dispatch('investigations/search', {
        caseId: caseId.value,
        probeFile: probeFile.value,
        mediaIds: selectedMediaIds.value,
        watchlists: selectedWatchlists.value
      });
    }

    const isFinishedProcessing = ref(false);
    watch(isFinishedProcessing, async nv => {
      if (nv) {
        // If finished processing, conduct a search.
        // Records will be populated in backend, then we can navigate to case records page.
        const response = await search();

        router.push({
          name: 'InvestigativeCaseRecords',
          params: {
            caseId: props.id
          }
        })
      }
    });
    
    const currentCase = ref();
    const isOpen = ref(true);
    onMounted(async () => {
      const response = await store.dispatch("cases/getCaseById", props.id);
      if (response.status === 'success') {
        currentCase.value = response.result;
      }
    });

    const caseName = computed(() => {
      if (currentCase.value) {
        return currentCase.value.name;
      }
    });
    const caseDescription = computed(() => {
      if (currentCase.value) {
        return currentCase.value.description;
      }
    });    

    const caseId = ref(props.id);

    const caseMediaStatuses = ref([]);
    const sockets = ref([]);
    const liveProgressForFilename = ref({});

    async function startProcessingCase() { 
      await store.dispatch("cases/startProcessingCase", {caseId: caseId.value});

      var caseUpdateInterval = setInterval(async () => {
        var response = await getCaseStatus();

        caseMediaStatuses.value = response.result;

        const isFinished = caseMediaStatuses.value.every(m => {
          return m.status === 'completed' || m.status === 'error';
        });

        if (isFinished) {
          isFinishedProcessing.value = true;
          clearInterval(caseUpdateInterval);
        }
      }, 1000);

      // livestats
      const camResponse = await store.dispatch("investigations/getCamerasByCaseId", {caseId: caseId.value});
      const cameras = camResponse.result; 

      for (let camera of cameras) {
        let socket = await store.dispatch("auth/getSocketIO", `feed=livestats&topic=${camera.GUID}`);
        
        socket.on(camera.GUID, (payload) => {
          const cameraStats = payload.cameraStats;
          const parts = cameraStats.filename.split('/');
          const filename = parts[parts.length - 1];
          const progress = cameraStats.progress;

          liveProgressForFilename.value[filename] = progress;
        });
        
        sockets.value.push(socket);
      }
    }

    async function getCaseStatus() {
      return await store.dispatch("investigations/getCaseStatus", {caseId: caseId.value});
    }

    async function goNextStep() {
      currentStep.value++;
      wizardStepperRef.value.nextStep();

      if (currentStep.value === 2) {
        if (selectedMediaIds.value.length > 0) {
          await startProcessingCase();
        } else {
          isFinishedProcessing.value = true;
        }
      }  
    }

    const caseProgress = ref(0);
    watch(liveProgressForFilename, nv => {
      // Skip if liveProgress object is reset
      if (Object.keys(nv).length === 0) {
        return 
      }

      const alreadyFinishedMedia = caseMediaStatuses.value.filter(s => 
        s.status === 'completed' || s.status === 'error'
      ) 
      const numberOfFiles = caseMediaStatuses.value.length;

      var totalPercentage = alreadyFinishedMedia.length * 100;

      for (var [key, value] of Object.entries(nv)) {
        if (alreadyFinishedMedia.map(m => m.fileName).includes(key)) continue;
        totalPercentage += Number(value);
      }

      caseProgress.value = (totalPercentage / (numberOfFiles * 100)) * 100;
      caseProgress.value = Number(caseProgress.value.toFixed(1));
    }, {deep:true});

    function disconnectAndResetSockets() {
      sockets.value.forEach(s => {
        s.disconnect();
        s.removeAllListeners();
      });
    }

    onUnmounted(() => {
      disconnectAndResetSockets();
    })

    const isNextButtonDisabled = computed(() => {
      return currentStep.value >= 2 ||
        (currentStep.value === 0 && selectedMedia.value.length === 0 && selectedWatchlists.value.length === 0);
    })

    return {
      wizardStepperRef,
      currentStep,
      currentCase,
      caseName,
      caseDescription,
      probeRef,
      videoRef,
      probeFile,
      probeBase64,
      getProbeFile,
      handleSelectedVideos,
      handleSelectedWatchlists,
      isLoading,
      goNextStep,
      caseId,
      galleries,
      isOpen,
      caseMediaStatuses,
      liveProgressForFilename,
      isNextButtonDisabled
    };
  }
}

</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
}

.wizard-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: var(--spacing-l);


  background-color: var(--overwatch-secondary);
}

.wizard-body {
  padding: var(--spacing-xl);
  flex: 1;
  background-color: var(--overwatch-background);
  overflow-y: auto;
}

.wizard-footer {
  margin-top: auto;

  padding: 16px 32px 16px 32px;

  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.25);
  
  background: var(--overwatch-secondary);

  display: flex;
}

.hide {
  display: none;
}

.panel {
  width: 100%;
  height: 3em;
  margin-bottom: 2px;
  padding: 4px !important;
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 5px;

  display: flex;
  align-items: center;
  @include overwatch-body-small;
}

</style>
