<template>
  <div class="wrapper">
    <div class="overwatch-title-med title">
      Associates Settings
    </div>

    <div class="same-frame-only">
      Same Frame Only
      <div class="icon">
        <RocPopper arrow hover popperType="tooltip" placement="top">
          <RocIcon
            icon="tooltip"
            color="primary"
            size="sm"
          />
          <template #content>
            Only show associates that appear in the same frame of the target.
          </template>
        </RocPopper>
      </div>
      <RocSwitch
        class="switch"
        v-model="isSameFrameOnly"
      />
    </div>

    <div class="divider" v-if="!isSameFrameOnly"></div>

    <div class="time-range" v-if="!isSameFrameOnly">
      <div class="overwatch-body-med" style="margin-bottom: var(--spacing-s)">
        Associate Sighting
      </div>

      <div class="number-input-row">
        <div class="checkbox">
          <RocCheckbox v-model="isBeforeTargetEnabled"/>
        </div>
        <RocNumberInput
          class="number-input"
          :disabled="!isBeforeTargetEnabled"
          v-model="beforeTarget"
        />
        <RocSelect
          class="time-range-unit"
          :disabled="!isBeforeTargetEnabled"
          :availableOptions="timeOptions"
          :currentlySelected="beforeTargetUnit"
          @selection-changed="beforeTargetUnit=$event"
          optionLabel="name"
          optionValue="value"
        />
        <div :class="{'disabled-text': !isBeforeTargetEnabled}" class="target-text">
          Before Target
        </div>
      </div>
      <div class="number-input-row">
        <div class="checkbox">
          <RocCheckbox v-model="isAfterTargetEnabled"/>
        </div>
        <RocNumberInput
          class="number-input"
          :disabled="!isAfterTargetEnabled"
          v-model="afterTarget"
        />
        <RocSelect
          class="time-range-unit"
          :disabled="!isAfterTargetEnabled"
          :availableOptions="timeOptions"
          :currentlySelected="afterTargetUnit"
          @selection-changed="afterTargetUnit=$event"
          optionLabel="name"
          optionValue="value"
        />
        <div :class="{'disabled-text': !isAfterTargetEnabled}" class="target-text">
          After Target
        </div>
      </div>
    </div>

    <div class="save-buttons">
      <RocButton type="secondary" @click="$emit('close')">
        Cancel
      </RocButton>
      <RocButton @click="save" :disabled="isSaveDisabled">
        Save
      </RocButton>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import RocIcon from '@/components/ui/RocIcon';
import RocSwitch from '@/components/ui/RocSwitch';
import RocNumberInput from '@/components/ui/RocNumberInput';
import RocSelect from '@/components/ui/RocSelect';
import RocSingleSelect from '@/components/ui/RocSingleSelect';
import RocCheckbox from '@/components/ui/RocCheckbox.vue';
import RocButton from '@/components/ui/RocButton';
import RocPopper from '@/components/ui/RocPopper.vue';

export default {
  name: 'AssociatesFilterPopup',
  components: {
    RocIcon,
    RocSwitch,
    RocNumberInput,
    RocSingleSelect,
    RocSelect,
    RocCheckbox,
    RocButton,
    RocPopper
  },
  emits: ['close', 'save-time-range', 'save-same-frame'],
  props: {
    cluster: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const store = useStore();

    const initialFields = ref(store.getters['clusters/associatesFilterPopupFields']);

    const isSameFrameOnly = ref(initialFields.value.isSameFrameOnly);

    const timeOptions = ref(
      [
        {
          name: 'Secs',
          value: 'secs',
        },
        {
          name: 'Mins',
          value: 'mins',
        },
        {
          name: 'Hrs',
          value: 'hrs'
        },
        {
          name: 'Days',
          value: 'days',
        },
      ]
    );

    const isBeforeTargetEnabled = ref(initialFields.value.isBeforeTargetEnabled);
    const isAfterTargetEnabled = ref(initialFields.value.isAfterTargetEnabled);

    const beforeTargetUnit = ref(initialFields.value.beforeTargetUnit);
    const afterTargetUnit = ref(initialFields.value.afterTargetUnit);

    const beforeTarget = ref(initialFields.value.beforeTarget);
    const afterTarget = ref(initialFields.value.afterTarget);

    const second = 1000;
    const minute = 60 * second;
    const hour = 60 * minute;
    const day = 24 * hour;

    function constructSameFrameFilter() {
      const filter = [];

      for (let encounter of props.cluster.encounters) {
        const cameraId = encounter.track_info.cameraId;
        // const timestamp = encounter.timestamp;
        const startTimestamp = encounter.track_info.start_timestamp;
        const stopTimestamp = encounter.track_info.stop_timestamp;

        filter.push(
          {
            cameraId: cameraId,
            timestamp: {
              start: startTimestamp,
              stop: stopTimestamp
            }
          }
        )
      }

      return filter;
    }

    function constructTimeRangeFilter() {
      const filter = [];

      for (let encounter of props.cluster.encounters) {
        const timestamp = encounter.timestamp;

        let beforeTimestamp = timestamp;
        let afterTimestamp  = timestamp;

        if (isBeforeTargetEnabled.value) {
          switch(beforeTargetUnit.value) {
            case 'secs':
              beforeTimestamp = beforeTimestamp - second * beforeTarget.value;
              break;
            case 'mins':
              beforeTimestamp = beforeTimestamp - minute * beforeTarget.value;
              break;
            case 'hrs':
              beforeTimestamp = beforeTimestamp - hour * beforeTarget.value;
              break;
            case 'days':
              beforeTimestamp = beforeTimestamp - day * beforeTarget.value;
              break;
          }
        }
        if (isAfterTargetEnabled.value) {

          switch(afterTargetUnit.value) {
            case 'secs':
              afterTimestamp = afterTimestamp + second * afterTarget.value;
              break;
            case 'mins':
              afterTimestamp = afterTimestamp + minute * afterTarget.value;
              break;
            case 'hrs':
              afterTimestamp = afterTimestamp + hour * afterTarget.value;
              break;
            case 'days':
              afterTimestamp = afterTimestamp + day * afterTarget.value;
              break;
          }
        }
        filter.push({start: beforeTimestamp, end: afterTimestamp})
      }

      return filter;
    }

    function saveFilterStateToStore() {
      store.commit('clusters/setAssociatesFilterPopupFields', {
        isSameFrameOnly: isSameFrameOnly.value,
        isBeforeTargetEnabled: isBeforeTargetEnabled.value,
        isAfterTargetEnabled: isAfterTargetEnabled.value,
        beforeTargetUnit: beforeTargetUnit.value,
        afterTargetUnit: afterTargetUnit.value,
        beforeTarget: beforeTarget.value,
        afterTarget: afterTarget.value
      })
    }

    const isSaveDisabled = computed(() => {
      return !(isSameFrameOnly.value || isBeforeTargetEnabled.value || isAfterTargetEnabled.value);
    })

    function save() {
      if (isSameFrameOnly.value) {
        const filter = constructSameFrameFilter();
        saveFilterStateToStore();
        context.emit('save-same-frame', filter);
      } else {
        const filter = constructTimeRangeFilter();
        saveFilterStateToStore();
        context.emit('save-time-range', filter);
      }

    }

    return {
      save,
      isSameFrameOnly,
      timeOptions,
      isBeforeTargetEnabled,
      isAfterTargetEnabled,
      beforeTargetUnit,
      afterTargetUnit,
      beforeTarget,
      afterTarget,
      isSaveDisabled
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 300px;
  padding-top: var(--spacing-xl);
}

.title {
  margin-bottom: var(--spacing-m);
}

.checkbox{
  margin-right: var(--spacing-s);
}

.same-frame-only {
  @include overwatch-body-large;
  width: 100%;
  display: flex;
  align-items: center;

  .icon {
    margin-left: var(--spacing-s);
    display: flex;
    align-items: center;
  }

  .switch {
    margin-left: auto;
  }
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-m);
  background-color: var(--overwatch-light-accent);
}

.number-input-row {
  display: flex;
  align-items: center;
  gap: var(--spacing-base);
  margin-top: var(--spacing-base);
}

.number-input {
  width: 80px;
}

.time-range-unit {
  width: 80px;
}

.save-buttons {
  margin-top: var(--spacing-m);
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-xs);
}

.target-text {
  @include overwatch-body-small;
  margin-left: var(--spacing-base);
}

.disabled-text {
  color: var(--overwatch-neutral-300);
}

</style>