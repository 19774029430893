<template>
  <div class="page">
    <!-- Nav bar -->
    <nav class="stick-to-top">
      <RocIcon
        color="black"
        size="sm"
        icon="backArrow"
        style="cursor: pointer;"
        @click="handleBackClick"
      />
      <span class='case-title'>{{ computedMissionName }}</span>
      <span class='case-datetime'> {{ computedDaterangeString }} </span>
    </nav>
    <div class="body">
      <div class="clustering-box">
        <div v-if="associatesTargetCluster" class="target-cluster-section">
          <!-- Target cluster section -->
          <div class="title">
            {{ associatesTargetCluster.name }}
          </div>
          <div class="body">
            <auth-img
              :src="associatesTargetClusterThumbnail"
              class="best-thumbnail"
              draggable="false"
            />
            <ClusterEncountersGrid
              associatesViewMode
              :encounters="associatesTargetCluster.encounters"
            />
          </div>

        </div>
        <div class="title-and-filters">
          <span class="title" v-if="associatesTargetCluster">
            Associates
            <RocIcon
              color="black"
              size="sm"
              icon="settings"
              style="cursor: pointer; margin-left: var(--spacing-s);"
              @click="isShowingAssociatesFilterPopup=true"
            />
          </span>
          <span class="title" v-else>
            Clustering
          </span>

          <MDBDropdown v-model="isSavedFilterMenuOpen">
            <RocDropdownToggle @click="isSavedFilterMenuOpen = !isSavedFilterMenuOpen" style="margin-left: var(--spacing-xl)">
              <RocButton
                class="filter-button"
                :type="selectedFilter ? 'primary' : 'white'"
              >
                <div style="display: flex; align-items: center; color: var(--overwatch-neutral-100);" v-if="!selectedFilter">
                  <RocIcon
                    color="black"
                    size="sm"
                    icon="filter"
                    style="margin-right: var(--spacing-s)"
                  />
                  Saved Filters
                </div>
                <div style="display: flex; align-items: center;" v-else>
                  <RocIcon
                    color="white"
                    size="sm"
                    icon="filter"
                    style="margin-right: var(--spacing-s)"
                  />
                  {{ selectedFilter.name }}
                  <RocIcon
                    color="white"
                    size="xs"
                    icon="exit"
                    style="cursor: pointer; margin-left: var(--spacing-s)"

                    @click.stop="clearSelectedFilter"
                  />
                </div>
              </RocButton>
            </RocDropdownToggle>
            <RocDropdownMenu>
              <RocDropdownItem v-if="savedFilters.length === 0" @click.stop="isSavedFilterMenuOpen = false">
                No items.
              </RocDropdownItem>
              <RocDropdownItem
                v-for="sf of savedFilters"
                style="cursor: default;"
                @click.stop="setSavedFilter(sf)"
              >
                <div style="display: flex; align-items: center; width: 230px; justify-content: space-between; height: 40px;">
                  {{ sf.name }}
                  <RocIcon
                    icon="edit"
                    color="primary"
                    size="sm"
                    style="cursor: pointer;"
                    @click.stop="editSavedFilter(sf)"
                  />
                </div>
              </RocDropdownItem>
            </RocDropdownMenu>
          </MDBDropdown>

          <ClusteringFilters
            class="dashboard-filters"
            @sort-change="loadClusters"
            @filter-change="handleFilterChange"
            @text-filter-change="loadClusters"
          />
        </div>
        <div class="clustering-grid" :class="{nogrid: clusters.length === 0}">
          <div class="cluster-card-wrapper" v-for="c in clusters">
            <ClusterCard
              :cluster="c"
              :key="c._id"
              @show-associates="showAssociates(c)"
            />
          </div>
          <div v-if="!isLoadingMoreClusters && clusters.length === 0" class="no-clusters-found">
            <RocLogo height="300px"/>
            There are no clusters to display, try adjusting your search filters.
          </div>
          <div class='spinner-holder' v-observe-visibility="loadMoreClusters">
            <MDBSpinner v-if="isLoadingMoreClusters"/>
          </div>
        </div>
      </div>
      <div class="video-playback-box" v-show="!isVideoPopupVisible">
        <ClusterEncounterVideo v-if="globalVideoPlaybackEncounter"
          :caseId="caseId"
          @find-cluster="showAssociates"
          @show-dialog="isVideoDialogVisible = true"
        />
        <div style="text-align: center;" v-else>
          Select an encounter to view context video.
        </div>
      </div>
    </div>
    <BaseDialog :show="isShowingSaveFilterPopup" @close="isShowingSaveFilterPopup=false" hideCloseBtn>
      <SavedClusterFiltersPopup
        @close="isShowingSaveFilterPopup=false"
        :editingFilter="editingFilter"
      />
    </BaseDialog>
    <BaseDialog :show="isShowingAssociatesFilterPopup" @close="isShowingAssociatesFilterPopup=false" hideCloseBtn>
      <AssociatesFilterPopup
        :cluster="associatesTargetCluster"
        @close="isShowingAssociatesFilterPopup=false"
        @save-time-range="handleTimeRangeFilterSave"
        @save-same-frame="handleSameFrameFilterSave"
      />
    </BaseDialog>
    <BaseDialog :show="isVideoDialogVisible" @close="isVideoDialogVisible = false">
      <ClusterEncounterVideo
        :caseId="caseId"
        dialogMode
      />
    </BaseDialog>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import RocIcon from '@/components/ui/RocIcon';
import RocButton from '@/components/ui/RocButton';
import { MDBSpinner, MDBDropdown} from 'mdb-vue-ui-kit';
import RocDropdownMenu from '@/components/ui/RocDropdownMenu.vue';
import RocDropdownToggle from '@/components/ui/RocDropdownToggle.vue';
import RocDropdownItem from '@/components/ui/RocDropdownItem.vue';

import ClusterCard from '@/components/clusters/ClusterCard';
import ClusteringFilters from '@/components/clusters/ClusteringFilters';
import ClusterEncounterVideo from '@/components/clusters/ClusterEncounterVideo.vue';
import ClusterEncountersGrid from '@/components/clusters/ClusterEncountersGrid.vue';

import BaseDialog from '@/components/ui/BaseDialog';
import SavedClusterFiltersPopup from '@/components/clusters/SavedClusterFiltersPopup.vue';

import AssociatesFilterPopup from '@/components/clusters/AssociatesFilterPopup';

import moment from 'moment';

import RocLogo from "@/components/ui/RocLogo.vue";


export default {
  name: 'ClusteringHome',
  components: {
    RocIcon,
    ClusterCard,
    ClusteringFilters,
    ClusterEncounterVideo,
    RocButton,
    MDBSpinner,
    MDBDropdown,
    RocDropdownItem,
    RocDropdownMenu,
    RocDropdownToggle,
    BaseDialog,
    SavedClusterFiltersPopup,
    RocLogo,
    ClusterEncountersGrid,
    AssociatesFilterPopup
},
  props: {
    caseId: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();

    // TODO: For now, mission is a post-event case.
    // We need to fix when we've gotten clarification on how
    // we want to implement missions.
    const mission = ref();
    const clusters = computed(() => {
      return store.getters['clusters/clusters'];
    })

    const computedDaterangeString = computed(() => {
      if (mission.value) {
        const start = moment(new Date(mission.value.startTimestamp));
        const end = moment(new Date(mission.value.endTimestamp));

        const formattedStart = start.format("MMM DD, yyyy HH:mm:ss");
        const formattedEnd = end.format("MMM DD, yyyy HH:mm:ss");

        return `${formattedStart} - ${formattedEnd}`;
      } else {
        return '';
      }
    })

    onMounted(async () => {
      // Load mission
      isLoadingMoreClusters.value = true
      var response = await store.dispatch("cases/getCaseById", props.caseId);
      if (response.status === 'success') {
        mission.value = response.result;
        store.commit('clusters/setMission', mission.value);
      }

      // Load mission's clusters, stored in vuex as clusters/clusters
      await store.dispatch('clusters/loadClusters', props.caseId);
      isLoadingMoreClusters.value = false;
    });

    async function loadClusters() {
      // Load mission's clusters, stored in vuex as clusters/clusters
      isLoadingMoreClusters.value = true;
      store.commit('clusters/setClusters', []);
      await store.dispatch('clusters/loadClusters', props.caseId);
      isLoadingMoreClusters.value = false;
    }

    const computedMissionName = computed(() => {
      return mission.value ? mission.value.name : 'Loading.'
    });

    const computedMissionDatetime = computed(() => {
      return mission.value ? mission.value.createdAt : 'Loading.'
    });

    const globalVideoPlaybackEncounter = computed(() => store.getters['clusters/videoPlaybackEncounter']);

    const isLoadingMoreClusters = ref(false);
    async function loadMoreClusters() {
      if (store.getters['clusters/moreToFetch']) {
        isLoadingMoreClusters.value = true;
        await store.dispatch('clusters/loadMoreClusters', props.caseId);
        isLoadingMoreClusters.value = false;
      }
    }

    /** Saved Filters */
    const isSavedFilterMenuOpen = ref(false);

    const userConfig = computed(() => {
      return store.getters['auth/userConfig'] ? store.getters['auth/userConfig'] : {};
    });
    const savedFilters = computed(() => {
      return userConfig.value.savedClusterFilters ? userConfig.value.savedClusterFilters : [];
    });

    const selectedFilter = ref();
    async function setSavedFilter(sf) {
      store.dispatch('auth/applySavedClusterFilter', sf);
      selectedFilter.value = sf;
      isSavedFilterMenuOpen.value = false;
      await loadClusters();
    }

    const isShowingSaveFilterPopup = ref(false);
    const editingFilter = ref();
    function editSavedFilter(sf) {
      isSavedFilterMenuOpen.value = false;
      isShowingSaveFilterPopup.value = true;
      editingFilter.value = sf;
    }

    async function clearSelectedFilter() {
      selectedFilter.value = null;
      store.dispatch('clusters/resetAllFilters');
      await loadClusters();
    }

    async function handleFilterChange() {
      selectedFilter.value = null;
      await loadClusters();
    }

    const associatesTargetCluster = ref();    // The selected cluster for which we show associates.
    function showAssociates(cluster) {
      associatesTargetCluster.value = cluster;
    }
    const associatesTargetClusterThumbnail = computed(() => {
      if (associatesTargetCluster.value) {
        const faceTemplateId = associatesTargetCluster.value.referenceEncounter.facetemplateId;
        return `/rest/v1/image/face/${faceTemplateId}/tn`;
      } else {
        return '';
      }
    });

    watch(associatesTargetCluster, nv => {
      store.commit('clusters/setAssociatesTargetCluster', nv);
    });

    const isShowingAssociatesFilterPopup = ref(false);

    async function handleTimeRangeFilterSave(filter) {
      isShowingAssociatesFilterPopup.value = false;
      store.commit('clusters/setAssociatesTimeRangeFilter', filter);
      store.commit('clusters/setAssociatesSameFrameFilter', []);
      await loadClusters();
    }
    async function handleSameFrameFilterSave(filter) {
      isShowingAssociatesFilterPopup.value = false;
      store.commit('clusters/setAssociatesSameFrameFilter', filter);
      store.commit('clusters/setAssociatesTimeRangeFilter', []);
      await loadClusters();
    }

    function resetAllFilters() {
      store.dispatch('clusters/resetAllFilters');
    }

    function resetState() {
      // Empty clusters must be set here so that page doesn't load any clusters from previous case
      store.commit('clusters/setClusters', []);
      store.commit('clusters/setSorting', '');
      resetAllFilters();
      store.commit('clusters/setVideoPlaybackEncounter', null);
      store.commit('clusters/setAssociatesTargetCluster', null);
      store.commit('clusters/setAssociatesSameFrameFilter', []);
      store.commit('clusters/setAssociatesTimeRangeFilter', []);

      // Abort any existing requests in flight
      const abortController = store.getters['clusters/abortController'];
      if (abortController) {
        abortController.abort();
      }
    }

    onUnmounted(() => {
      resetState();
    })

    async function handleBackClick() {
      if (associatesTargetCluster.value) {
        associatesTargetCluster.value = null;

        store.commit('clusters/setAssociatesTimeRangeFilter', []);
        store.commit('clusters/setAssociatesSameFrameFilter', []);

        await loadClusters();
      } else {
        router.push('/missions');
      }
    }

    const isVideoPopupVisible = computed(() => store.getters['clusters/isVideoPopupVisible']);
    const isVideoDialogVisible = ref(false);

    return {
      handleBackClick,
      clusters,
      mission,
      computedMissionName,
      computedMissionDatetime,
      globalVideoPlaybackEncounter,
      loadClusters,
      loadMoreClusters,
      isLoadingMoreClusters,
      isSavedFilterMenuOpen,
      savedFilters,
      setSavedFilter,
      isSavedFilterMenuOpen,
      isShowingSaveFilterPopup,
      editingFilter,
      editSavedFilter,
      clearSelectedFilter,
      selectedFilter,
      handleFilterChange,
      associatesTargetCluster,
      showAssociates,
      associatesTargetClusterThumbnail,
      isShowingAssociatesFilterPopup,
      handleTimeRangeFilterSave,
      handleSameFrameFilterSave,
      isVideoPopupVisible,
      isVideoDialogVisible,
      computedDaterangeString
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
}

nav {
  display: flex;
  align-items: center;
  padding: $--spacing-m;
  background-color: var(--overwatch-secondary);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

  .case-title {
    color: var(--overwatch-neutral-100);
    margin: 0px var(--spacing-m) 0px var(--spacing-l);
    @include overwatch-title-med;
  }
  .case-datetime {
    @include overwatch-body-med;
  }
}

.body {
  flex: 1;
  display: flex;
  min-height: 0;
}

.clustering-box {
  flex: 2.5;
  padding-left: $--spacing-xxl;
  padding-top: $--spacing-l;
  margin-right: var(--spacing-m);
  display: flex;
  flex-direction: column;
  height: 100%;

  .title-and-filters {
    display: flex;

    margin-bottom: $--spacing-s;
    align-items: center;

    .dashboard-filters {
      margin-left: auto;
    }

    .title {
      @include overwatch-title-large;
    }
  }

  .clustering-grid {
    //changed grid display to flex to keep spacing consistent and to create responsive sizing - Brittany
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    gap: $--spacing-s;
    justify-items: flex-start;
    height: 100%;
    overflow-y: auto;
  }

  .clustering-grid.nogrid {
    display: block;
    height: 300px;
    .no-clusters-found {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: var(--spacing-l);
    }
  }
}

.video-playback-box {
  flex: 1;
  background-color: var(--overwatch-secondary);
  margin-top: $--spacing-s;
  margin-right: $--spacing-m;
  position: relative;
  padding: $--spacing-m;
}

.spinner-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
}

.cluster-card-wrapper {
  // extra space, 2 pixels extra for each border side, so that cards have room to grow when highlighted.
  height: 324px;
  width: 204px;

  // centralize card
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-button {
  @include overwatch-body-small;
  height: 48px;
  text-transform: capitalize;
  padding-left: $--spacing-s;
  padding-right: $--spacing-s;
}

.target-cluster-section {
  .title {
    @include overwatch-title-med;
    margin-bottom: var(--spacing-s);
  }

  .body{
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-s);
    margin-bottom: var(--spacing-m);
  }

  img {
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 var(--overwatch-overlay);
  }
}

</style>
