<template>
  <div>
    <RocInput
        :placeholder="placeholder" 
        v-model="inputData"
        :disabled="disabled" 
        :errorMessage="errorMessage"
        type="tel"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, defineProps } from "vue";
import { useStore } from "vuex";
import RocInput from "./RocInput.vue";

const props = defineProps({
    modelValue: {
        type: String
    },
    placeholder: {
        type: String,
        required: false,
        default: 'phone number.',
    },
    disabled: {
        type: Boolean,
        default: false
    },
    errorMessage: {
      type: String,
    }
});
  const emits = defineEmits(['update:modelValue']);
  const store = useStore();
    const inputData = ref(props.modelValue ?? '');
    const errorMessage = ref(undefined);

    const regex = computed(() => {
      const enrollConfig = store.getters['rocenroll/enrollConfig'];
        return _.get(enrollConfig, 'entitlements.phoneRegex', '');
    })

    const isValid = computed(() => {
        return new RegExp(regex.value).test(inputData.value);
    });

    watch(inputData, () => {
      if (isValid.value) {
        emits("update:modelValue", inputData.value);
        errorMessage.value = undefined;
      } else {
        emits("update:modelValue", ''); 
        errorMessage.value =  props.errorMessage ? props.errorMessage : 'ex. 204-812-2322';
      }
    });

</script>

<style scoped lang="scss">
</style>