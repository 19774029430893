import getWorkstation from '../../../js/getWorkstation';

export default {
  async getTrackAnalysis(context, payload) {
    const url = `/rest/v1/${payload.encounterType}/trackanalysis/${payload.personId}`;
    const responseData  = await context.dispatch("auth/fetchJSON", {url: url, payload: {method: 'GET'}}, {root: true});
    const facetemplates = [];
    if (responseData.status === 'success') {
      for (const key in responseData.templates) {
        const facetemplate = responseData.templates[key];
        facetemplate.timestamp = context.getters.formattedTime(facetemplate.timestamp);
        facetemplates.push(facetemplate);
      }
    }
    //context.commit('setTrackAnalysisFacetemplates', facetemplates); deprecated
    return facetemplates;
  },

  async getContextImage(context, payload) {
    // if contextImageMediaId is defined, use that - otherwise query by personId
    const mediaId = payload.contextImageMediaId ?? payload.personId;
      const url = `/rest/v1/camera/contextimage/${payload.objectType}/${mediaId}`;
      const responseData  = await context.dispatch("auth/fetchJSON", {url: url, payload: {method: 'GET'}}, {root: true});
      if(responseData.status === 'success') {
          return responseData.imgUri;
      }
      else {
          return null;
      }
  },
    
  async compare(context, payload) {
    const algId = payload.params.algId ? payload.params.algId : 0;
    const minSize = payload.params.minSize ? payload.params.minSize : 20; // 20 suggested minimum minSize
    const faceCount = payload.params.faceCount ? payload.params.faceCount : 1;
    const falseDetectionRate = payload.params.falseDetectionRate
      ? payload.params.falseDetectionRate
      : 0.02; // ROC_SUGGESTED_FALSE_DETECTION_RATE
    const minQuality = payload.params.minQuality
      ? payload.params.minQuality
      : -4; // ROC_SUGGESTED_MIN_QUALITY

    const url = `/rest/v1/face/represent`;
    const headerParams = {
      algorithmId: algId,
      minSize: minSize,
      maxFaces: faceCount,
      falseDetectionRate: falseDetectionRate,
      minQuality: minQuality
    }

    let httpPayload = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: { imageDataUri: payload.base64Image, params: headerParams },
      responseType: 'json'
    };
  
    const response = await context.dispatch(
      'auth/rawFetch',
      { url: url, payload: httpPayload },
      { root: true }
    );
    if (response) {
      if (response.status === 200) {
        // if the response is image data, then its formatted as the thumbnail in the body and the metadata in the headers
        if (response.headers['content-type'] ===  'image/jpeg' || response.headers['content-type'] ===  'image/png') {
          const representResult = JSON.parse(response.headers['x-roc-represent-results']);
          const base64tools = require('@/js/base64Helper');
          const base64uri = await base64tools.blobToBase64Uri(response.data);
          representResult.tn = base64tools.convertDataUriToString(base64uri);
          return representResult;
        } else {
          // otherwise assume this is structured JSON response payload
          return response;
        }
      } else {
        // return the http error
        return response;
      }
    } else {
      return null;
    }
  },


  async createSettingsProfile(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/createsettingsprofileV1`;
    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    };

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },


  async deleteSettingsProfile(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/deletesettingsprofileV1`;
    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    };

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },

  async setActiveSettingsProfile(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/setactivesettingsprofileV1`;
    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    };

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },

  async getSettingsProfiles(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/getsettingsprofilesV1`;
    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    };

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },
  async getSettings(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/getsettingsV1`;
    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    };

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },

  async saveSettings(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/savesettingsV1`;

    const httpPayload = {
      method: 'PUT',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    };

    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
  },

  async getDictionary(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/getdictionaryV1/`

    const dictName = payload.dictName;

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ dictName: dictName, })
    };

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );

    return data;

  },

  async updateAllInvalidSegments(context, payload) {
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/updateAllInvalidSegmentsV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },

  async updateImage(context, payload) {
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/updateimageV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        caseName: payload.caseName,
        imgId: payload.imgId,
        image: payload.image,
        componentName: payload.componentName,
        transformationMatrix: payload.transformationMatrix,
        chin: payload.chin,
      })
    }

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },

  async clearQueue(context, payload){

    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/clearQueueV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },

  async getQueue(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/mpf/${payload.status}`

    const httpPayload = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data.items;
  },

  /**
   * @async
   * @function openRepo
   * @param {*} context
   * @returns {Promise<ExamineCase[]>}
   *
   */
  async openRepo(context, _){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/getcasesV1/`

    const httpPayload = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    }

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data.cases;
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload = { 
   *  caseName: String,
   *  examId: String, 
   * } 
   * @returns 
   */
  async getDash(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/getdashV1/`;

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        caseName: payload.caseName, examId: payload.examId,
      })
    }

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );

    return data;
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload = { 
   *  examId: String, 
   *  feature: String, 
   *  unKnownIndex: Number, 
   *  knownIndex: Number, 
   * } 
   * @returns 
   */
  async updateDash(context, payload){
      const baseUrl = context.getters['getBaseUrl']; 
      const url = `${baseUrl}/rest/examine/v1/updatedashV1/`;

      const httpPayload = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      }

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
      
      return data;
  },
  
  /**
   * 
   * @param {*} context 
   * @param {*} payload = {
   *  examId: String, 
   *  unKnownChecklist: Object,
   *  knownChecklist: Object
   * } 
   * @returns 
   */
  async updateQualityChecklist(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/updateimagequalitychecklistV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload = {
   *  examId: String, 
   *  userNotes: String, 
   *  userScore: String
   * } 
   * @returns 
   */
  async updateExaminationOpinion(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/updateexaminationopinionV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload =  { 
   *  examId: String, 
   *  activity: 
   *  {
   *    type: String, 
   *    datetime: new Date(), 
   *    which: 'known' | 'unknown', 
   *    name: String, 
   *    toggled: Boolean, 
   *    window: String
   *  }
   * } 
   * @returns 
   */
  async updateActivity(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/updateactivityV1`;

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },
  
  async getActivity(context, payload){

    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/getactivityV1`;

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );

    return data;
  },
  

  /**
   * 
   * @param {*} context 
   * @param {*} payload {
   *  annotationId: String,
   * } 
   * @returns 
   */
  async getAnnotationContent(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/getannotationcontentV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }
    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },
  


  /**
   * 
   * @param {*} context 
   * @param {*} payload {
   *    name: String,
   *    examId: String,
   *    annotationType: 'Manual' | 'Automatic' | 'Adjusted',
   *    annotationId: String,
   *    feature: String,
   *    knownImageId: String,
   *    knownIndex: Number,
   *    knownImageCrop: Object,
   *    adjustedKnownImageCrop: Object,
   *    unKnownIndex: Number,
   *    unKnownImageId: String,
   *    unKnownImageCrop: Object,
   *    adjustedUnKnownImageCrop: Object,
   *    annotationImage: base64,
   *    manualScore: Numer,
   *    shapes: Object,
   *    userNotes: String,
   *    orientation: String,
   *    componentChecklist: Object,
   *    checklistEntered: Boolean,
   * } 
   * @returns 
   */
  async updateAnnotation(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/updateannotationV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }
    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },
  
  /**
   * 
   * @param {*} context 
   * @param {*} payload {
   *    name: String,
   *    examId: String,
   *    annotationType: 'Manual' | 'Automatic' | 'Adjusted',
   *    annotationId: String,
   *    feature: String,
   *    knownImageId: String,
   *    knownIndex: Number,
   *    knownImageCrop: Object,
   *    adjustedKnownImageCrop: Object,
   *    unKnownIndex: Number,
   *    unKnownImageId: String,
   *    unKnownImageCrop: Object,
   *    adjustedUnKnownImageCrop: Object,
   *    annotationImage: base64,
   *    manualScore: Numer,
   *    shapes: Object,
   *    userNotes: String,
   *    orientation: String,
   *    componentChecklist: Object,
   *    checklistEntered: Boolean,
   * } 
   * @returns 
   */
  async addAnnotation(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/addannotationV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }
    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload {
   *  name: String
   *  examId: String,
   *  annotationId: String,
   * } 
   * @returns 
   */
  async deleteAnnotation(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/deleteannotationV1/`;
    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    };
    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    return data;
  },


  /**
   * 
   * @param {*} context 
   * @param {{name: String, examId: String}} payload
   * @returns {Promise<Object>}
   */
  async getCanExamComplete(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/getcanexamcompleteV1/`;

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    };

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );

    return data;

  },
  

  /**
   * 
   * @param {*} context 
   * @param {*} payload {
   *  name: String
   *  examId: String,
   * } 
   * @returns 
   */
  async getAnnotations(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/getannotationsV1/`;

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    };

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );

    return data;

  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns {Promise<ExamineCase>} 
   */
  async openCase(context, payload) {
    const name = payload?.caseName ?? context.getters['getCase'];
    const examId = payload?.examId ?? context.getters['getExamId'];
    
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/getcaseV1/`;

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(
          { 
              name: name, 
              examId: examId, 
          })
    };


    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );

    /** @type {Promise<{ examineCase: ExamineCase }>} */
    return data;
 },



  /**
   * 
   * @param {*} context 
   * @param {*} payload = {
   *  examiner: String,
   *  name: String,
   *  workflow: 'A' | 'B' | 'C',
   *  images: [],
   * } 
   * @returns 
   */
async dequeueCase(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/dequeuecaseV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...payload, 
        monitorSize: { width: window.screen.width, height: window.screen.height }, 
        screenDimensions: { width: window.innerWidth, height: window.innerHeight, },
        workStation: getWorkstation(),      
      })
    };
    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    context.commit('setCase', { currentCase: data.caseName });
    context.commit('setExamId', { examId: data.examId });
    return data;
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload = {
   *  examiner: String,
   *  name: String,
   *  workflow: 'A' | 'B' | 'C',
   *  images: [],
   * } 
   * @returns 
   */
async createCase(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/createcaseV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...payload, 
        monitorSize: { width: window.screen.width, height: window.screen.height }, 
        screenDimensions: { width: window.innerWidth, height: window.innerHeight, },
        workStation: getWorkstation(),
        
      })
    };

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    context.commit('setCase', { currentCase: data.caseName });
    context.commit('setExamId', { examId: data.examId });
    return data;
  },


  /**
   * 
   * @param {*} context 
   * @param {*} payload {
   *  name: String
   * } 
   * @returns 
   */
  async deleteAndExport(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/deleteandexportV1/`
    
    const httpPayload = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(payload),
      responseType: "blob"
    };


    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );

    return data;

  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload {
   *  name: String
   * } 
   * @returns 
   */
  async deleteCase(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/deletecaseV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    };

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );

    return data;
  },

/**
 * 
 * @param {*} context 
 * @param {*} payload {
 *  name: String,
 *  examId: String,
 * } 
 * @returns 
 */
  async completeExam(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/completeexamV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    };

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );

    return data;
  },


  /**
   * 
   * @param {*} context 
   * @param {*} payload = {
   *  examiner: String,
   *  name: String,
   *  workflow: 'A' | 'B' | 'C',
   * } 
   * @returns 
   */
  async createExam(context, payload){

    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/createexamV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...payload, 
        monitorSize: { width: window.screen.width, height: window.screen.height }, 
        screenDimensions: { width: window.innerWidth, height: window.innerHeight, },
        workStation: getWorkstation(),      
      })
    };

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );

    return data;
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload = {
   *  name: String
   * } 
   * @returns blob 
   */
  async exportImages(context, payload){

    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/exportimagesV1/`

    const httpPayload = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify(payload),
      responseType: "blob"
    };


    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );

    return data;
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload = {
   *  name: String,
   *  examId: String,
   *  reportType: exam | actitivty | case
   * } 
   * @returns blob 
   */
  async createReport(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/createreportV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
      responseType: "blob"
    };


    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );

    return data;
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload  = {
   *            name: String,
   *            image: base64,
   *            examId: String,
   *            fileName: String,
   *            processingPresent: String
   *       }
   * @returns 
   */
  async saveScreenshot(context, payload){
    const baseUrl = context.getters['getBaseUrl'];
    const url = `${baseUrl}/rest/examine/v1/savescreenshotV1/`

    const httpPayload = {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    };

    const data = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );

    return data;
  },
};
