<template>
  <div>
    <div class="left-right">
      <nav>
        <div>
          <span v-if="editingCase" class="overwatch-title-small">
            {{ editingCase.name }}
          </span>
          <span class="overwatch-body-med" style="margin-left: var(--spacing-s); color: var(--overwatch-neutral-300)">
            {{ editingCase ? currentStep - 1 : currentStep }}/{{ editingCase ? 3 : 4 }}
          </span>
        </div>
        <a v-if="!editingCase"
          :class="{ active: currentStep === 1 }"
        >
          1. Case Information
        </a>
        <a
          :class="{ active: currentStep === 2 }"
        >
          {{ editingCase ? 1 : 2 }}. Select Files
        </a>
        <a
          :class="{ active: currentStep === 3 }"
        >
          {{ editingCase ? 2 : 3 }}. Configure Cameras
        </a>
        <a
          :class="{ active: currentStep === 4 }"
        >
          {{ editingCase ? 3 : 4 }}. Upload Files
        </a>
      </nav>

      <div class="component">
        <!-- Step 1 -->
        <div v-if="currentStep === 1">
          <div class="input-row">
            <div>Case Name</div>
            <input v-model="caseName" />
          </div>
          <div class="input-row">
            <div>Case Description</div>
            <textarea
              rows="5"
              type="text"
              style="height:100%"
              v-model="caseDescription"
            ></textarea>
          </div>
          <div class="input-row">
            <div>Watchlists</div>
            <filter-multi-select style="margin-top: 4px;" class="overwatch-body-med"
              mode="tags"
              :close-on-select="false"
              no-results-text=""
              placeholder-text="Select Watchlists"
              :available-items="availableWatchlists"
              :currently-selected="selectedWatchlists"
              @selection-changed="handleWatchlistsSelection"
            />
          </div>
          <div class="input-row">
          <div>User Groups</div>
            <UserGroupFilterMultiSelect
              @selection-changed="updateSelectedUserGroups"
            />
          </div>
        </div>

        <!-- Step 2 -->
        <div v-else-if="currentStep === 2" class="d-flex h-100" style="flex-direction: column;">
          <div class="input-row">
            <div>Analytics</div>
            <filterMultiSelect
              style="height: 100% !important"
              mode="tags"
              :close-on-select="false"
              no-results-text=""
              placeholder-text="Select Analytics"
              :available-items="Object.keys(availableAnalytics)"
              :currently-selected="selectedAnalytics"
              @selection-changed="handleAnalyticsSelection"
            />
          </div>

          <div>
            <!-- splitString, occurrenceSelection, dateFormatString -->
            <div class="input-row">
              Split filename into camera name and datetime by:
              <input class="inline overwatch-body-med" style="width: 150px; margin-top: 10px;padding: 12px; border-radius: 5px;" v-model="splitString" title="Split string"/>
            </div>
            <div class="input-row">
              Use its
              <select v-model="occurrenceSelection" class="overwatch-body-med occurrence" style="padding: 12px; border-radius: 5px;">
                <option v-for="o in occurrenceOptions" :value="o.value"> {{ o.text }}</option>
              </select>
              occurrence from the
              <select v-model="orderSelection" class="overwatch-body-med occurrence" style="padding: 12px; border-radius: 5px;">
                <option v-for="o in orderOptions" :value="o.value"> {{ o.text }}</option>
              </select>
              of the filename.
            </div>
            <div class="input-row" >
              The datetime format is <input class="inline" v-model="dateFormatString" /> .
            </div>
            <div class="input-row">
              The camera name is
              <select v-model="cameraNameOrder" class="overwatch-body-med occurrence" style="padding: 12px; border-radius: 5px;">
                <option v-for="o in cameraNameOrderOptions" :value="o.value"> {{ o.text }}</option>
              </select>
              the datetime.
              <RocIcon
                icon="tooltip"
                color="primary"
                size="sm"
                style="cursor: pointer; margin-left: var(--spacing-base);"
                @click="showFilenameParseExample=true"
              />
            </div>
          </div>
          <div style="flex: 1; display: flex; flex-direction: column; height: 100%; margin-left: 10px;">
            <!-- imported files view / FileSelector -->
            <div class="input-row" style="flex:1;">
              <FileSelector style="height: 100%;" @change="handleNewFiles"/>
            </div>
            <div class="input-row">
              {{ exampleCameraName }}
            </div>
            <div class="input-row">
              {{ exampleStartDatetime }}
            </div>
          </div>
        </div>

        <!-- Step 3 -->
        <div v-else-if="currentStep === 3" style="height: 100%; display: flex; flex-direction: column;">
          <!-- Camera step  -->
          <div class="d-flex cameras">
            <div style="height: 100%; width: fit-content; overflow: auto;">
              <MDBListGroup light class="camera-list-group">
                <div class="overwatch-body-med" style="margin-bottom: 1px;">
                  Detected Cameras
                </div>
                <MDBListGroupItem
                  v-for="camera in tempCameraObjects"
                  tag="button"
                  noBorder
                  spacing
                  action
                  :active="selectedCamera === camera"
                  @click="selectedCamera = camera"
                >
                  {{ camera.name }}
                </MDBListGroupItem>
              </MDBListGroup>
              <MDBListGroup light class="camera-list-group" v-if="editingCaseCameras.length > 0" style="margin-top: 16px;">
                <div class="overwatch-body-med" style="margin-bottom: 1px;">
                  Previously Processed Cameras
                </div>
                <MDBListGroupItem
                  v-for="camera in editingCaseCameras"
                  tag="button"
                  noBorder
                  spacing
                  action
                  :active="selectedCamera === camera"
                  @click="selectedCamera = camera"
                >
                  {{ camera.name }}
                </MDBListGroupItem>
              </MDBListGroup>
            </div>

            <div class="camera-crud-box">
              <div>
                Settings for {{ selectedCamera.name }}
              </div>
              <CameraSettings
                :key="settingsKey"
                :disabled="'vs_config' in selectedCamera"
                style="width: 100%"
                :vs-config="selectedCamera.vs_config"
                :analytics="selectedCamera.analytics"
                :enableThreshold="selectedCamera.matchThresholdOverride?.enabled"
                :threshold="selectedCamera.matchThresholdOverride?.threshold"
                :hideSettings="['enabled', 'record']"
                @change="handleChangedCameraSettings"
                @start="handleStartCameraSettings"
              />
            </div>
          </div>
        </div>

        <!-- Step 4 - Progress-->
        <div v-else-if="currentStep === 4" style="height: 100%; display: flex; flex-direction: column;">
          <div style="font-size: 10px;">
            Total Progress
          </div>
          <MDBProgress :height="20">
            <MDBProgressBar :value="caseProgress">
              {{ caseProgress }}%
            </MDBProgressBar>
          </MDBProgress>
          <div style="margin-top: 10px; height: 100%; overflow-y: auto;">
            <div class="panel-header">
              <div class="d-flex justify-content-between align-self-center" style="width:100%">
                <div class="flex-child" style="display: inline-block; text-align: left; min-width: 60%;">Filename</div>
                <div class="flex-child" style="display: inline-block; text-align: left; min-width: 20%">Status</div>
                <div class="flex-child" style="display: inline-block; text-align: left; min-width: 20%"></div>
              </div>
            </div>
            <div class="panel" v-for="m in caseMediaStatuses">
              <div style="min-width: 60%; text-overflow: ellipsis; overflow: hidden;" :title="m.fileName">
                {{ m.fileName }}
              </div>
              <div style="min-width: 35%">
                <div v-if="m.status === 'processing'">
                  {{ liveProgressForFilename[m.fileName] ?
                    liveProgressForFilename[m.fileName].toFixed(1) :
                    0
                  }}%
                </div>
                <div v-else>
                  {{m.statusDescription ? m.status + ' ' + '-' + ' ' + m.statusDescription : m.status }}
                </div>
              </div>
              <div style="min-width: 5%; display: flex; flex-direction: row-reverse;">
                <roc-spinner size="sm" v-if="m.status === 'uploading'"/>
                <roc-spinner size="sm" v-if="m.status === 'processing'"/>
                <RocIcon icon="check" size="sm" v-else-if="m.status === 'completed'" color="primary"/>
                <RocIcon icon="error" size="sm" v-else-if="m.status === 'error'" color="red"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="footer-btns">
        <div class="footer-message">{{ footerMessage }}</div>
        <roc-spinner style="margin-right: 10px;" v-show="isLoading"/>
        <RocButton style="padding: var(--spacing-s)"
        size="md"
        :disabled="isNextDisabled"
        @click="handleNextClick">
        {{ nextButtonText }}
        </RocButton>
      </div>
    </footer>
  </div>
  <BaseDialog v-if="isShowingCloseDialog" :show="true" title="Close Wizard?" @close="isShowingCloseDialog=false;" style="z-index:9999">
    <DeleteConfirmation
      @close="isShowingCloseDialog=false"
      @delete="closeWizard"
      message="Close Wizard"
    >
      Are you sure you want to close?
    </DeleteConfirmation>
  </BaseDialog>
  <BaseDialog :show="showUpdateConfirmation" @close="showUpdateConfirmation=false" :hideCloseBtn="true" :style="updateConfirmationStyle">
    <div style="width: 100%;" class="d-flex flex-row justify-content-center">
      <div style="display: flex; align-items: center; justify-content: center;">
        <span style="display: flex; align-items: center; justify-content: center;" class="Check-Circle-Icon"><img src="@/assets/img/icons/icons_black_32x32_match.svg"/></span>
      </div>
      <div style="color:var(--overwatch-neutral-100)" class="overwatch-title-small">Stop will occur after in progress medias are complete</div>
    </div>
  </BaseDialog>
  <BaseDialog :show="showFilenameParseExample" @close="showFilenameParseExample=false" hideCloseBtn>
    <CaseFilenameParseExample />
  </BaseDialog>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import {
  MDBSwitch,
  MDBListGroup,
  MDBListGroupItem,
  MDBProgress,
  MDBProgressBar,
  MDBSelect,
  MDBBadge
} from 'mdb-vue-ui-kit';
import filterMultiSelect from '@/components/ui/filterMultiSelect';
import FileSelector from '@/components/cases/FileSelector.vue';
import CameraSettings from '@/components/cameras/CameraSettings.vue';
import DeleteConfirmation from '@/components/settings/DeleteConfirmation';
import BaseDialog from '@/components/ui/BaseDialog';
import { parse } from 'date-format-parse';
import { get as lodashGet } from "lodash";
import RocButton from "../ui/RocButton.vue";
import RocSwitch from "../ui/RocSwitch.vue";
import RocIcon from "../ui/RocIcon.vue";
import CaseFilenameParseExample from "@/components/cases/CaseFilenameParseExample";
import UserGroupFilterMultiSelect from '@/components/ui/UserGroupFilterMultiSelect';


export default {
  name: 'CaseImportWizard',
  components: {
    MDBSwitch,
    MDBProgress,
    MDBProgressBar,
    MDBListGroup,
    MDBListGroupItem,
    MDBSelect,
    filterMultiSelect,
    FileSelector,
    CameraSettings,
    DeleteConfirmation,
    BaseDialog,
    MDBBadge,
    RocButton,
    RocSwitch,
    RocIcon,
    CaseFilenameParseExample,
    UserGroupFilterMultiSelect
  },
  emits: ['close'],
  props: ['close'],
  setup(props, context) {
    const store = useStore();

    const isImporterVisible = computed(() => {
      return store.getters['cases/importerVisible'];
    });

    //returns object of case being imported, if undefined that means import was interrupted
    const importerCaseId = computed(()=> {
      return store.getters['cases/cases'].find(c => c._id === caseId.value)
    })

    watch(isImporterVisible, async () => {
      if (isImporterVisible.value || store.getters['watchlists/watchlists'].length === 0) {
          await store.dispatch('watchlists/loadWatchlists');
      }
    })

    const currentStep = ref(1);

    /* Case Info */
    const caseName = ref('');
    const caseDescription = ref('');

    /* Watchlists */
    const availableWatchlists = computed(function() {
      return store.getters['watchlists/watchlists'];
    });
    const selectedWatchlists = ref([]);
    function handleWatchlistsSelection(newSelections) {
      selectedWatchlists.value = newSelections.value;
    }

    const selectedUserGroups = ref([]);
    function updateSelectedUserGroups(g) {
      selectedUserGroups.value = g;
    }

    /* Analytics */
    const isFaceAnalyticsSelected = ref(false);
    const selectedAnalytics = ref([]);
    const availableAnalytics = ref({
      Face: 'face',
      Pedestrian: 'pedestrian',
      Gun: 'gun',
      'License Plate': 'lpr',
      Vehicle: 'vehicle',
      'OCR In-The-Wild': 'ocr',
      Tattoo: 'tattoo',
    });

    /* Edit mode, prepopulate */
    const editingCase = computed(() => {
      return store.getters['cases/editingCase'];
    });


    const editingCaseCameras = ref([]);
    const editingCasePreviousFiles = ref([]);

    watch([editingCase, isImporterVisible], async () => {
      if (editingCase.value && isImporterVisible.value) {
        resetWizard();

        currentStep.value = 2;

        let c = editingCase.value;
        caseId.value = c._id;

        const camResponse = await store.dispatch("cases/getCamerasByCaseId", {caseId: c._id});
        editingCaseCameras.value = camResponse.result;
      }
    });

    function handleAnalyticsSelection(newSelections) {
      selectedAnalytics.value = newSelections.value;
    }

    const caseProgress = ref(0);

    /* misc */

    const isNextDisabled = ref(false);
    const isLoading = ref(false);
    const footerMessage = ref('');

    async function handleNextClick() {
      var isValid = false;
      isNextDisabled.value = true;
      isLoading.value = true;

      switch (currentStep.value) {
        case 1:
          if (!caseName.value.length > 0) {
            footerMessage.value = 'Please enter a name.';
          }

          isValid = caseName.value.length > 0;
          break;
        case 2:
          let selectedDateTimeParser = {
            splitString: splitString.value,
            dateTimeParser: dateFormatString.value,
            occurrenceOptions: occurrenceSelection.value,
            orderOptions: orderSelection.value,
            cameraNameOrder: cameraNameOrder.value
          };

          //set date time parser in store to userConfig so user can keep this setting for later use
          store.commit("cases/setDateTimeParser", selectedDateTimeParser);
          store.commit('auth/setUserSettingsChanged', Date.now())

          userConfig.value.newCaseDateTimeParser = selectedDateTimeParser;
          await store.dispatch('auth/updateUserConfig', userConfig.value)

          if (!selectedAnalytics.value.length > 0) {
            footerMessage.value = 'Please select analytics.';
            break;
          }

          if (
            files.value.length > 0
          ) {
            isValid = processFiles();
          } else {
            footerMessage.value = 'Please select files.';
          }
          break;
        case 3:
          var response;

          if (!editingCase.value) {
            response = await createCase();
          } else {
            // Skipping first step during Add File, so just "validate response"
            response = 'success';
          }

          if (validateCreationResponses(response)) {
            response = await createCameras();
          }

          if (validateCreationResponses(response)) {
            footerMessage.value = '';
            isValid = true;
          } else {
            isValid = false;
            footerMessage.value = 'Unexpected error when uploading media or creating cases.';
          }
          break;
        case 4:
          if (isProcessingCase.value) {
            await stopProcessingCase();
            isProcessingCase.value = false;
          } else {
            resetWizard();
            context.emit('close');
            // Finish and exit.
          }
          break;
      }

      if (isValid) {
        footerMessage.value = '';
        currentStep.value++;
      }
      isLoading.value = false;
      isNextDisabled.value = false;
      isValid = false;
    }

    const caseId = ref('');
    async function createCase() {
      const payload = {
        name: caseName.value,
        description: caseDescription.value,
        watchlistIds: selectedWatchlists.value,
        userGroups: selectedUserGroups.value
      }
      var response = await store.dispatch("cases/createCase", payload);

      if (response.status === 'success') {
        caseId.value = response.result._id;
      }
      return response.status;
    }

    const files = ref([]);
    function handleNewFiles(newFiles) {
      files.value = newFiles;
    }

    const tempCameraObjects = ref([]);
    const selectedCamera = ref();
    const tempMediaObjects = ref([]);

    watch(tempCameraObjects, newVal => {
      if (!selectedCamera.value) {
        selectedCamera.value = tempCameraObjects.value[0];
      }
    }, {deep:true})

    // A small hack to force reload the CameraSettings child component when a camera is selected.
    // Doesn't reload for some reason without this.
    const settingsKey = ref(0);
    watch(selectedCamera, newVal => {
      settingsKey.value++;
    })

    const userConfig = ref(store.getters['auth/userConfig'] ? store.getters['auth/userConfig'] : {})
    watch(() => store.getters['auth/userConfig'], nv => {
      if (nv) {
        userConfig.value = nv;
        if (nv.newCaseDateTimeParser) {
          // Sean (04-01-2024): Incorrect naming on occurenceOptions and orderOptions
          // as they're supposed to be the name for the options on dropdowns rather than
          // the actual values, but running with it for now. TODO: Fix names

          const parseObject = nv.newCaseDateTimeParser;
          splitString.value = parseObject.splitString,
          dateFormatString.value = parseObject.dateTimeParser;
          occurrenceSelection.value = parseObject.occurrenceOptions;
          orderSelection.value = parseObject.orderOptions;
          cameraNameOrder.value = parseObject.cameraNameOrder;
        }
      }
    })

    const splitString = ref(
      userConfig.value && userConfig.value.newCaseDateTimeParser?.splitString ?
      userConfig.value.newCaseDateTimeParser.splitString :
      '.video.'
    );
    const dateFormatString = ref(
      userConfig.value && userConfig.value.newCaseDateTimeParser?.dateTimeParser ?
      userConfig.value.newCaseDateTimeParser.dateTimeParser :
      'YYYYMMDD-HH.mm.ss'
    );

    const occurrenceSelection = ref(
      userConfig.value && userConfig.value.newCaseDateTimeParser?.occurrenceOptions ?
      userConfig.value.newCaseDateTimeParser.occurrenceOptions :
      1
    );
    const occurrenceOptions = ref([
      {text: 'first', value: 1},
      {text: 'second', value: 2},
      {text: 'third', value: 3}
    ]);

    const orderSelection = ref(userConfig.value && userConfig.value.newCaseDateTimeParser?.orderOptions ?
      userConfig.value.newCaseDateTimeParser.orderOptions :
      'start'
    );


    const orderOptions = ref([
      {text: 'start', value: 'start'},
      {text: 'end', value: 'end'}
    ]);

    const cameraNameOrder = ref(userConfig.value && userConfig.value.newCaseDateTimeParser?.cameraNameOrder ?
      userConfig.value.newCaseDateTimeParser.cameraNameOrder :
      'before'
    );

    const cameraNameOrderOptions = ref([
      {text: 'before', value: 'before'},
      {text: 'after', value: 'after'}
    ])

    watch([occurrenceSelection, splitString, orderSelection, dateFormatString, cameraNameOrder], () => {
      if (footerMessage.value !== '') {
        footerMessage.value = '';
      }
    })


    const exampleCameraName = ref('Upload a file to see example camera name.');
    const exampleStartDatetime = ref(' ');

    function parseFilePath(filepath) {
      var nameNoEx = filepath.substring(0, filepath.lastIndexOf("."));

      var occurrenceSelectionIndices = [...nameNoEx.matchAll(new RegExp(splitString.value, 'gi'))].map(a => a.index);

      var splitIndex;
      if (orderSelection.value === 'start') {
        splitIndex = occurrenceSelectionIndices[occurrenceSelection.value-1];
      } else {
        splitIndex = occurrenceSelectionIndices[occurrenceSelectionIndices.length - occurrenceSelection.value];
      }

      var cameraName;
      var restOfString;
      if (cameraNameOrder.value === 'before') {
        cameraName = nameNoEx.substring(0, splitIndex);
        restOfString = nameNoEx.substring(splitIndex+splitString.value.length, nameNoEx.length);
      } else {
        cameraName = nameNoEx.substring(splitIndex+splitString.value.length, nameNoEx.length);
        restOfString = nameNoEx.substring(0, splitIndex);
      }

      var startDatetime = parse(restOfString, dateFormatString.value);

      return {
        cameraName: cameraName,
        startDatetime: startDatetime,
      }
    }

    function processFiles() {
      const seenNames = new Set();

      for (let f of files.value) {
        var {cameraName, startDatetime} = parseFilePath(f.path);

        if (isNaN(startDatetime.getTime())) {
          footerMessage.value = 'Datetime incorrectly parsed.';
          return false;
        }

        if (cameraName.length === 0) {
          footerMessage.value = 'Camera name missing.'
          return false;
        }

        if (!seenNames.has(cameraName)) {
          tempCameraObjects.value.push({
            name: cameraName,
            analytics: selectedAnalytics.value
          })
          seenNames.add(cameraName);
        }

        tempMediaObjects.value.push({
          cameraName: cameraName,   // Temporary to associate object to a camera
          fileName: f.fileHandle.name,
          file: f.fileHandle,
          startTime: startDatetime,
        })
      }
      return true;
    }

    function handleChangedCameraSettings(newSettings) {
      const idx = tempCameraObjects.value.findIndex(x => x.name === selectedCamera.value.name);
      if (idx > -1) {
        for (let setting in newSettings) {
          tempCameraObjects.value[idx][setting] = newSettings[setting];
        }
      }
    }

    // CameraSettings emits settings to all objects upon start.
    const started = ref(false);
    function handleStartCameraSettings(newSettings) {
      if (!started.value) {
        for (let i = 0; i < tempCameraObjects.value.length; i++) {
          for (let setting in newSettings) {
            tempCameraObjects.value[i][setting] = newSettings[setting];
          }
        }
      }
      started.value = true;
    }

    const cameraNameIdMap = {};
    async function createCameras() {
      const responses = [];
      for (let tempCameraObject of tempCameraObjects.value) {
        let payload = await createCameraPayload(tempCameraObject);
        var response;
        if (editingCaseCameras.value.map(m => m.name).includes(payload.name)) {
          response = await store.dispatch("cases/updateCamera", payload);
        } else {
          response = await store.dispatch("cases/createCamera", payload);
        }
        responses.push(response.status);
        cameraNameIdMap[response.result.name] = response.result._id;
      }
      return responses;
    }

    watch(currentStep, async () => {
      // Execute when current step reaches 4
      if (currentStep.value === 4) {
        await uploadMedia();
        await startProcessingCase();
      }
    })

    const caseMediaStatuses = ref([]);
    async function uploadMedia() {
      isNextDisabled.value = true
      isProcessingCase.value = true;
      // Set statuses to loading
      // {fileName: , status: }
      for (let ob of tempMediaObjects.value) {
        caseMediaStatuses.value.push({
          fileName: ob.fileName,
          status: 'uploading'
        })
      }

      for (let i = 0; i < tempMediaObjects.value.length; i++) {
        let tempMediaObject = tempMediaObjects.value[i];
        tempMediaObject.cameraId = cameraNameIdMap[tempMediaObject.cameraName];
        tempMediaObject.caseId = caseId.value;

        delete tempMediaObject.cameraName

        var response = await store.dispatch("cases/uploadMedia", tempMediaObject);
        if (response.status === 'success') {
          caseMediaStatuses.value[i].status = 'uploaded'
        } else {
          caseMediaStatuses.value[i].status = 'upload failed'
        }
      }
    }

    function validateCreationResponses(res) {
      if (typeof res === 'string') {
        return res === 'success';
      } else {
        return res.every(r => r === 'success')
      }
    }

    async function createCameraPayload(tempCameraObject) {
      let newDoc = {};
      let vsConfig;
      newDoc.name = tempCameraObject.name;
      newDoc.enabled = tempCameraObject.enabled;

      for (let analytic of tempCameraObject.analytics) {
        if (!vsConfig) {
          const result = await store.dispatch("cameras/getVSConfigDefaultByModality", availableAnalytics.value[analytic]);
          if(result.status === 'success') {
            vsConfig = result.value;
          }
        } else {
          const result = await store.dispatch("cameras/getVSConfigDefaultByModality", availableAnalytics.value[analytic]);
          // get just the analytics backend of this result.
          const analyticsBackend = result.value.roc.tracker['analytics-backends'][0];

          vsConfig.roc.tracker['analytics-backends'].push(
            analyticsBackend
          );
        }
      }

      newDoc.vs_config = vsConfig;

      newDoc.matchThresholdOverride = {
        enabled: tempCameraObject.matchThresholdOverride.enabled,
        threshold: tempCameraObject.matchThresholdOverride.threshold
      }

      newDoc.caseId = caseId.value;

      return newDoc;
    }

    const nextButtonText = computed(() => {
      switch(currentStep.value) {
        case 3:
          return "Start Import";
          break;
        case 4:
          if (isProcessingCase.value) {
            return "Stop Processing";
          } else {
            return "Finish";
          }
          break;
        default:
          return "Next >";
      }

    })

    function validateNumber(e) {
      const exclude = [8, 37, 39]   // Backspace, arrow keys
      if ((e.keyCode < 48 || e.keyCode > 57) && !exclude.includes(e.keyCode)) {
        e.preventDefault();
      }
    }

    const isProcessingCase = ref(false);
    const sockets = ref([]);
    const liveProgressForFilename = ref({});

    async function startProcessingCase() {
      isProcessingCase.value = true;

      // Add a 'processing' tag for CaseManagement screen reactivity
      const payload = {
        id: caseId.value,
        ... store.getters['cases/cases'].find(c => c._id === caseId.value),
        processing: true
      }
      store.commit('cases/replaceCase', payload);

      const result = await store.dispatch("cases/startProcessingCase", {caseId: caseId.value});

      var caseUpdateInterval = setInterval(async () => {

        //Evan - note: if a case is suddenly deleted, this will throw an error in the console
        //it's due to how often this gets called, and the case is deleted before the next call
        var response = await getCaseStatus();

        if (response && isNextDisabled.value) {
          // It looks better when the Next button is still disabled after media upload
          // until we can visually confirm that the case is processing.
          isNextDisabled.value = false;
        }

        caseMediaStatuses.value = lodashGet(response, 'result', []);
        var finishedCount = 0;

        caseMediaStatuses.value.forEach(m => {
          if (m.status === 'completed' || m.status === 'error') {
            finishedCount++;
          }
        });

        if (caseMediaStatuses.value && (finishedCount === caseMediaStatuses.value.length)) {
          caseProgress.value = 100;
          clearInterval(caseUpdateInterval);
          isProcessingCase.value = false;
          disconnectAndResetSockets();

          //may want to add this back in if there are issues with videos sticking around for next upload
          //caseMediaStatuses.value = [];
        }
      }, 1000);


      // livestats
      const camResponse = await store.dispatch("cases/getCamerasByCaseId", {caseId: caseId.value});
      const cameras = camResponse.result;

      if (cameras.length > 0) {
        for (let camera of cameras) {
          let socket = await store.dispatch("auth/getSocketIO", `feed=livestats&topic=${camera.GUID}`);

          socket.on(camera.GUID, (payload) => {
            const cameraStats = payload.cameraStats;
            const parts = cameraStats.filename.split('/');
            const filename = parts[parts.length - 1];
            const progress = cameraStats.progress;

            liveProgressForFilename.value[filename] = progress;
          });

          sockets.value.push(socket);
        }
      }
    }

    watch(liveProgressForFilename, nv => {
      // Skip if liveProgress object is reset
      if (Object.keys(nv).length === 0) {
        return
      }

      const alreadyFinishedMedia = caseMediaStatuses.value.filter(s =>
        s.status === 'completed' || s.status === 'error'
      )
      const numberOfFiles = caseMediaStatuses.value.length;

      var totalPercentage = alreadyFinishedMedia.length * 100;

      for (var [key, value] of Object.entries(nv)) {
        if (alreadyFinishedMedia.map(m => m.fileName).includes(key)) continue;
        totalPercentage += Number(value);
      }

      caseProgress.value = (totalPercentage / (numberOfFiles * 100)) * 100;
      caseProgress.value = Number(caseProgress.value.toFixed(1));
    }, {deep:true})


    async function getCaseStatus() {
      if(importerCaseId.value && importerCaseId.value.id && caseId.value){
        return await store.dispatch("cases/getCaseStatus", {caseId: importerCaseId.value.id});
      }
    }

    const windowWidth = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });
    });

    const updateConfirmationStyle = computed(() => {
      if (windowWidth.value <= 480) {
        // Mobile style
        return {
          width: '90%',
        };
      }
    });

    const showUpdateConfirmation = ref(false);
    async function stopProcessingCase() {
      let result = await store.dispatch("cases/stopProcessingCase", {caseId: caseId.value});
      closeWizard();
      showUpdateConfirmation.value = true;
        setTimeout(() => {
          showUpdateConfirmation.value = false;
      }, 5000)
    }

    /**
     * Reset wizard when it's closed / finished.
     */
    function resetWizard() {
      currentStep.value = 1;
      caseName.value = '';
      caseDescription.value = '';
      selectedWatchlists.value = [];
      isFaceAnalyticsSelected.value = false;
      selectedAnalytics.value = [];
      selectedUserGroups.value = [];
      caseProgress.value = 0;

      isNextDisabled.value = false;
      isLoading.value = false;
      footerMessage.value = '';

      caseId.value = '';
      files.value = [];
      tempCameraObjects.value = [];
      selectedCamera.value = null;
      tempMediaObjects.value = [];

      exampleCameraName.value = 'Upload a file to see example camera name.';
      exampleStartDatetime.value = ' ';

      started.value = false;

      caseMediaStatuses.value = [];
      isProcessingCase.value = false;

      liveProgressForFilename.value = {};
      caseProgress.value = 0;


      disconnectAndResetSockets();
    }

    const isResetButtonEnabled = computed(() => {
      if (
        isLoading.value  ||
        isProcessingCase.value
      ) {
        return false;
      }
      else if (
        currentStep.value === 1 ||
        currentStep.value === 2 ||
        currentStep.value === 3
      ) {
        return true;
      } else {
        return false;
      }
    })

    const isShowingCloseDialog = ref(false);

    watch([files, splitString, occurrenceSelection, orderSelection, dateFormatString, cameraNameOrder], () => {
      if (files.value.length === 0) {
        exampleCameraName.value = 'Upload a file to see example camera name.';
        exampleStartDatetime.value = '';
      } else {
        try {
          var {cameraName, startDatetime} = parseFilePath(files.value[0].path);
          exampleCameraName.value = "Example camera name: " + cameraName;
          exampleStartDatetime.value = "Example start datetime: " + startDatetime;
        } catch {
          exampleCameraName.value = 'There was an error with parsing. Please review instructions.';
          exampleStartDatetime.value = '';
        }
      }
    }, {deep: true});

    watch(() => props.close, newVal => {
      isShowingCloseDialog.value = true;
    });

    function disconnectAndResetSockets() {
      sockets.value.forEach(s => {
        s.disconnect();
        s.removeAllListeners();
      });
      sockets.value = [];
    }

    function closeWizard() {
      resetWizard();
      context.emit('close');
    }

    const showFilenameParseExample = ref(false);

    return {
      editingCase,
      currentStep,
      caseName,
      caseDescription,
      availableWatchlists,
      selectedWatchlists,
      handleWatchlistsSelection,
      isFaceAnalyticsSelected,
      selectedAnalytics,
      availableAnalytics,
      handleAnalyticsSelection,
      splitString,
      dateFormatString,
      tempCameraObjects,
      selectedCamera,
      caseProgress,
      createCase,
      isLoading,
      isNextDisabled,
      handleNextClick,
      files,
      handleNewFiles,
      footerMessage,
      handleChangedCameraSettings,
      handleStartCameraSettings,
      settingsKey,
      nextButtonText,
      exampleCameraName,
      exampleStartDatetime,
      occurrenceSelection,
      occurrenceOptions,
      orderSelection,
      orderOptions,
      cameraNameOrder,
      cameraNameOrderOptions,
      validateNumber,
      caseMediaStatuses,
      isProcessingCase,
      isResetButtonEnabled,
      isShowingCloseDialog,
      resetWizard,
      closeWizard,
      editingCaseCameras,
      liveProgressForFilename,
      showUpdateConfirmation,
      updateConfirmationStyle,
      showFilenameParseExample,
      updateSelectedUserGroups
    };
  }
};
</script>

<style lang="scss">

input{
  background-color: var(--overwatch-neutral-500);
  color: var(--overwatch-neutral-100);
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 5px;
}

.camera-list-group {
  @include overwatch-body-med;
  width: 200px;
  border-radius: 0;
  flex:1;
}

.camera-list-group button {
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid var(--overwatch-neutral-300) !important;
  border-radius: 5px;
  background-color: var(--overwatch-neutral-200);
  color:var(--overwatch-button-text);
}

.camera-list-group button:last-child {
  border-radius: 5px;
}

//Custom arrow (until refresh)
.occurrence{
  -webkit-appearance: none;
         -moz-appearance: none;
          appearance: none;

  background-image:
    linear-gradient(45deg, transparent 50%, var(--overwatch-neutral-400) 50%),
    linear-gradient(135deg,var(--overwatch-neutral-400) 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

.occurrence:focus{
  background-image:
    linear-gradient(45deg,var(--overwatch-neutral-400) 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, var(--overwatch-neutral-400) 50%),
    linear-gradient(to right,transparent,transparent);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

.camera-list-group button.active {
  color: var(--overwatch-button-text);
  background-color: var(--overwatch-primary) !important;
  border: 1px solid var(--overwatch-neutral-300) !important;
  border-radius: 5px;
}

.multiselect-tag {
  @include overwatch-body-small;
  color: var(--overwatch-button-primary);
  border: solid 1px var(--overwatch-button-primary);
}

.multiselect-dropdown {
  /* Magic that fixes blurry dropdown text on Chrome, on a 1440p monitor */
  border-radius: 4px;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-secondary);
  border: none;
  filter: drop-shadow(0px 4px 8px rgba(0,0,0,.25));
}

.multiselect .multiselect-no-results{
  color: var(--overwatch-neutral-200) !important;
}


</style>

<style scoped lang="scss">
nav {
  display: flex;
  flex-direction: column;

  padding: 20px 10px 0 10px;
  gap: 10px;

  @include overwatch-body-small;
  border-right: 1px solid var(--overwatch-neutral-300);
}

a {
  margin: 0 var(--spacing-s);
  color: var(--overwatch-neutral-300) !important;
}

a.active {
  color: var(--overwatch-neutral-100) !important;
  cursor: default;
}

a:hover {
  color: var(--overwatch-button-hover);
}

.left-right {
  display: flex;
}
.component {
  margin: 10px 10px 10px 10px;
  width: 700px;
  height: 600px;
  @include overwatch-body-large;
}
.input-row {
  width: 100%;
  margin-bottom: var(--spacing-base);
}

.analytics-row {
  display: flex;
  gap: 30px;

  margin-bottom: 10px;
}

input {
  width: 100%;
  padding: 12px;
  margin-top: 4px;
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 4px;
  @include overwatch-body-med;
}

.inline {
  width: revert;
  display: inline;
}

input:disabled {
  background-color: var(--overwatch-neutral-300);
}

textarea {
  margin-top: 4px;
  width: 100%;
  padding: 5px;
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 4px;
  resize: none;
  padding: 12px;
  @include overwatch-body-med;
}

div {
  user-select: none;
}

footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  margin: 0 10px 10px 10px;
}

.footer-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.cameras {
  flex:1;

  display: flex;
  height: 100%;
}
.camera-crud-box {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px dashed var(--overwatch-neutral-100);
  flex: 2;
}

.panel-header {
  width: 100%;
  height: 24px;
}
.panel {
  width: 100%;
  height: 3em;
  margin-bottom: 2px;
  padding: 4px !important;
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 5px;

  display: flex;
  align-items: center;

  @include overwatch-body-med;
}

.footer-message {
  display: inline-block;
  color: var(--overwatch-error);
}

.black {
  color: black;
}

select {
  display: inline-block;
  border: 1px solid var(--overwatch-neutral-100);
  width: 100px;
}

.Check-Circle-Icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  border: 1px solid #000000;
  display: inline-block;
  background-color: var(--overwatch-secondary);
}


</style>
