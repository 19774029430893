import { apiCall } from "../../utils/utils"

export default {
  async loadWorkflows(context, payload) {
    const url = `/rest/v1/workflows`;
    const responseData = await apiCall(context, url, 'GET');

    if (responseData.status === 'success') {
      context.commit('setWorkflows', responseData.result);
    }
    return responseData;
  },
  async createWorkflow(context, payload) {
    const url = `/rest/v1/workflow`;
    const responseData = await apiCall(context, url, 'POST', JSON.stringify(payload));

    if (responseData.status === 'success') {
      context.commit('addWorkflow', responseData.result);
    }

    return responseData;
  },
  async deleteWorkflow(context, payload) {
    const id = payload._id;
    const url = `/rest/v1/workflow/${id}`;

    const responseData = await apiCall(context, url, 'DELETE');

    if (responseData.status === 'success') {
      context.commit('deleteWorkflow', {id: id})
    }
    return responseData;
  },
  async updateWorkflow(context, payload) {
    const id = payload.id;
    delete payload.id;

    const url = `/rest/v1/workflow/${id}`;

    const responseData = await apiCall(context, url, 'PUT', JSON.stringify(payload));

    if (responseData.status === 'success') { 
      const relevantObject = context.getters['workflows'].find(w => w._id === id);
      for (let key of Object.keys(payload)) {
        relevantObject[key] = payload[key]
      }
    }

    return responseData;
  },
  async getWorkflowTypes(context, payload) {
    const url = `/rest/v1/workflow/types`;
    const responseData = await apiCall(context, url, 'GET');

    return responseData;
  },
  async getDefaultActionValue(context, payload) {
    const action = payload;
    const url = `/rest/v1/objectstore/workflowActionDefault.${action}`

    const responseData = await apiCall(context, url, 'GET');

    return responseData;
  }


}