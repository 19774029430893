<template>
  <div class="d-flex flex-column" :class="selected ? 'selected' : 'user-card'"> 
    <div class="d-flex justify-content-between" style="height: 100%;">
      <div class="align-self-center" style="padding-right: 70px;"> 
        <RocCheckbox v-model="selected" @click="selectedElement"></RocCheckbox>
      </div>
      <div class="align-self-center" style="flex: 1; padding-left: 16px;">
        <div class="user-name">{{user.firstname}} {{user.lastname}}</div>
      </div>
      <div class="align-self-center" style="flex: 1;">
        <div class="user-email">{{user.email}}</div>
      </div>
      <div class="group-badges" style="flex: 2;">
        <MDBBadge class="group-badge" v-for="group in associatedGroups" :key="group" badge="success" pill>{{ group.label }}</MDBBadge>
      </div>
      <div class="align-self-center" style="flex: 1.5;">
        <div class="d-flex justify-content-between align-items-center">
          <div class="user-name">{{role}}</div>
          <div>
            <MDBDropdown v-model='dropdownOptions' align='end'>
              <MDBDropdownToggle
                style='color:black;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  padding: 8px 12px 8px;'
                tag='a'
                @click="dropdownOptions = !dropdownOptions">
                <RocIcon color="black" size="sm" icon="kebab"/>
              </MDBDropdownToggle>
              <RocDropdownMenu aria-labelledby='dropdownMenuButton'>
                <MDBDropdownItem href="" @click.prevent="editUser">
                  <div class="d-flex justify-content-between">
                    Edit
                    <RocIcon color="black" size="sm" icon="edit"/>
                  </div>
                </MDBDropdownItem>
                <span v-if="!isLoggedIn">
                  <hr class='dropdown-divider' />
                  <MDBDropdownItem href="" @click.prevent="deleteUser">
                    <div class="d-flex justify-content-between">
                      Delete
                      <RocIcon icon="trash" color="red" @click="() => {}" size="sm"/>
                    </div>
                  </MDBDropdownItem>
                </span>
              </RocDropdownMenu>
            </MDBDropdown>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobile && user.notes" class="d-flex align-items-end" style="margin-top: 8px;">
      <div class="user-notes">{{user.notes}}</div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from 'vuex';
import { MDBDropdown, MDBDropdownItem, MDBDropdownToggle, MDBBadge } from "mdb-vue-ui-kit";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocCheckbox from '@/components/ui/RocCheckbox.vue';

export default {
  name: "UserCard",
  components: {
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    MDBBadge,
    RocIcon,
    RocDropdownMenu,
    RocCheckbox
  },
  emit: ['toggleUserState', 'editUser'],
  props: {
    user: Object,
    selected: {
      type: Boolean,
      default: false
    },
    userRole: {
      group: String,
      default: '',
    }
  },
  setup(props, context) {
    const store = useStore();
    const dropdownOptions = ref(false);
    const selected = ref(props.selected);
    const role = ref(props.userRole);
    const userGroups = computed(() => {
      return store.getters['settings/userGroups'];
    });

    watch(() => props.selected, nv => {
      selected.value = nv;
    });

    const isLoggedIn = computed(function() {
      if (!props.user) {
        return false;
      }
      return props.user.email === store.getters['auth/email'];
    });

    const associatedGroups = computed(() => {
      return userGroups.value.filter(group => {
        for (let gu of group.users) {
          if (gu._id === props.user._id) {
            return true;
          }
        }
      });
    });

    function editUser() {
      context.emit("editUser", props.user);
      dropdownOptions.value = !dropdownOptions.value;
    }

    function selectedElement() {
      context.emit('selectedUser',{user: props.user,value: selected.value,});
    }

    function deleteUser() {
      context.emit("deleteUser", props.user);
      dropdownOptions.value = !dropdownOptions.value;
    }

    const isMobile = computed(() => {
      return window.innerWidth <= 480;
    });

    return {
      dropdownOptions,
      editUser,
      deleteUser,
      isLoggedIn,
      associatedGroups,
      isMobile,
      selected,
      selectedElement,
      role
    };
  }
};
</script>

<style scoped lang="scss">
.user-card {
  width: 100% !important;
  min-width: 25rem;
  display: block;
  padding: 24px 20px 24px !important;
  margin: 0;
  background: var(--overwatch-secondary);
  margin-right: 0rem !important;
  border-bottom: 1px solid var(--overwatch-accent);
}

.selected {
  width: 100% !important;
  min-width: 25rem;
  display: block;
  padding: 12px 20px 12px !important;
  margin: 0;
  background: var(--overwatch-button-primary-10);
  min-height: 5rem;
  margin-right: 0rem !important;
  border-bottom: 1px solid var(--overwatch-accent);
}

.user-name {
 @include overwatch-body-med;
 margin-bottom: 4px;
  line-height: 21px;
}

.user-email {
  @include overwatch-body-med;
  line-height: 18px;
}

.user-notes {
  @include overwatch-body-xsmall;
}

.dropdown-toggle:after {
  display: none;
}

hr {
  margin: 0;
}
.group-badges {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  height: 100%;
  gap: 0;
}


.group-badge {
  @include overwatch-body-small;
  color: var(--overwatch-button-primary) !important;
  background-color:var(--overwatch-button-primary-20);
  border: 1px solid var(--overwatch-button-primary);
}

.delete {
  color: var(--overwatch-error);
}


@media (max-width: 480px) {
  .user-card {
    min-width: 0;
    width: 100%;
    margin-bottom: 0 !important;
  }

  .user-notes {
    font-size: 10px;
  }
}

</style>