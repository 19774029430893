<template>
  <div v-if='isLoading'>
    <roc-spinner/>
  </div>
  <div v-else-if="serverConfigEditorValue" style="height:500px; overflow-y: auto; padding-top: var(--spacing-m);">
    <JSONObjectSettings v-if="serverConfigEditorValue.globals"
      :title="'Globals'"
      :path="'globals'"
      :cardJsonObject="serverConfigEditorValue.globals"
      :jsonObject="serverConfigEditorValue"
      @edited-values="updateConfigObj"
    >
      <div class="settingsSection">
        <div class="settingsSectionLabel">
          Algorithm Representation
          <RocPopper arrow hover placement="bottom" :popperType="'tooltip'" :locked="true" class="popper">
            <RocIcon size="sm" color="buttonPrimary" icon="tooltip" />
                <template #content>
                  <div style="max-width: 275px;">Balanced - recommended when running on hardware without GPU acceleration. Accurate - more precise, but requires GPU acceleration.</div>
                </template>
          </RocPopper>
        </div>
        <div class="d-flex settingsSectionWidget">
          <div>
            <MDBRadio name="serverConfigRadio" v-model="algorithmRepresentationRadioBtn" value="balanced" />
          </div>
          <div style="margin-right:15px">Balanced</div>
          <div>
            <MDBRadio name="serverConfigRadio" v-model="algorithmRepresentationRadioBtn" value="accurate" />
          </div>
          <div >Accurate</div>
        </div>
      </div>

      <div v-if="lprClassifications && lprClassifications.length" class="settingsSection">
        <div class="settingsSectionLabel">
          LPR Classification
          <RocPopper arrow hover placement="bottom" :popperType="'tooltip'" :locked="true" class="popper">
            <RocIcon size="sm" color="buttonPrimary" icon="tooltip" />
                <template #content>
                  <div style="max-width: 275px;">Set license plate recognition (LPR) regional classification. Selecting 'None' disables regional classification but does not disable LPR.</div>
                </template>
          </RocPopper>
        </div>
        <div class="d-flex flex-wrap">
          <div class="d-flex" v-for="(item, index) in lprClassifications" :key="index">
            <div>
              <MDBRadio name="lprClassificationRadio" v-model="lprClassificationRadioBtn" :value="item.detection" />
            </div>
            <div style="margin-right:15px">{{ item.label }}</div>
          </div>
        </div>
      </div>
    </JSONObjectSettings>
    <JSONObjectSettings v-if="serverConfigEditorValue.rocSDKConfig"
      :title="'ROC SDK'"
      :path="'rocSDKConfig'"
      :cardJsonObject="serverConfigEditorValue.rocSDKConfig"
      :jsonObject="serverConfigEditorValue"
      @edited-values="updateConfigObj"
    >
      <div v-if="serverConfigEditorValue.rocSDKConfig.cuda" class="settingsSection">
        <div class="settingsSectionLabel">
          Compute Unified Device Architecture (CUDA)
          <RocPopper arrow hover placement="bottom" :popperType="'tooltip'" :locked="true" class="popper">
            <RocIcon size="sm" color="buttonPrimary" icon="tooltip" />
                <template #content>
                  <div style="max-width: 275px;">CUDA provides GPU hardware acceleration (requires NVIDIA chipset).</div>
                </template>
          </RocPopper>
        </div>
        <div class="d-flex settingsSectionWidget">
          <RocSwitch :isActive="serverConfigEditorValue.rocSDKConfig.cuda.enabled" @switch-toggled="serverConfigEditorValue.rocSDKConfig.cuda.enabled = $event" />
        </div>
      </div>
    </JSONObjectSettings>
    <JSONObjectSettings v-if="serverConfigEditorValue.videoServer"
      :title="'Video Server'"
      :path="'videoServer'"
      :cardJsonObject="serverConfigEditorValue.videoServer"
      :jsonObject="serverConfigEditorValue"
      @edited-values="updateConfigObj"
    >
      <div v-if="serverConfigEditorValue.videoServer.globalSettings && serverConfigEditorValue.videoServer.globalSettings.cuda" class="settingsSection">
        <div class="settingsSectionLabel">
          Compute Unified Device Architecture (CUDA)
          <RocPopper arrow hover placement="bottom" :popperType="'tooltip'" :locked="true" class="popper">
            <RocIcon size="sm" color="buttonPrimary" icon="tooltip" />
                <template #content>
                  <div style="max-width: 275px;">CUDA provides GPU hardware acceleration (requires NVIDIA chipset).</div>
                </template>
          </RocPopper>
        </div>
        <div class="d-flex settingsSectionWidget">
          <RocSwitch :isActive="serverConfigEditorValue.videoServer.globalSettings.cuda.enabled" @switch-toggled="serverConfigEditorValue.videoServer.globalSettings.cuda.enabled = $event"/>
        </div>
      </div>
    </JSONObjectSettings>
    <JSONObjectSettings v-if="serverConfigEditorValue.workflow"
      :title="'Export (Workflow)'"
      :path="'workflow'"
      :cardJsonObject="serverConfigEditorValue.workflow"
      :jsonObject="serverConfigEditorValue"
      @edited-values="updateConfigObj"
    >
      <div class="settingsSection">
        <div class="settingsSectionLabel">Enabled</div>
        <div class="d-flex settingsSectionWidget">
          <RocSwitch :isActive="serverConfigEditorValue.workflow.enabled"
          @switch-toggled="serverConfigEditorValue.workflow.enabled = $event"/>
        </div>
      </div>
    </JSONObjectSettings>
    <JSONObjectSettings
      :title="'Camera Config'"
      :path="selectedModality.key"
      :cardJsonObject="selectedModality.value"
      :jsonObject="selectedModality.value"
      @edited-values="updateObjectStoreConfig"
    >
      <div class="settingsSection">
        <div class="settingsSectionLabel">Modality</div>
          <div class="d-flex settingsSectionWidget">
          <RocSelect
            style="width: 225px"
            :availableOptions="objectStoreConfigs"
            :optionLabel="'text'"
            :placeholder="'Select a Modality'"
            @selection-changed="selectedModality = $event"
          />
        </div>
      </div>
      <div class="settingsSection" v-if="selectedModality.key === 'face'">
        <div class="settingsSectionLabel">Algorithm Type</div>
        <div class="d-flex settingsSectionWidget">
          <MDBRadio name="objectStoreRadio" v-model="objectStoreRadioBtn" value="ROC_FACE_BALANCED_REPRESENTATION" />
          <div style="margin-right:15px">Balanced</div>
          <MDBRadio name="objectStoreRadio" v-model="objectStoreRadioBtn" value="ROC_FACE_ACCURATE_REPRESENTATION" />
          <div style="margin-right:15px">Accurate</div>
        </div>
      </div>
      <div class="settingsSection align-items-center" v-if="selectedModality.key">
        <div class="settingsSectionLabel">
          Live Preview Frames per Second (FPS)
          <RocPopper arrow hover placement="bottom" :popperType="'tooltip'" :locked="true" class="popper">
            <RocIcon size="sm" color="buttonPrimary" icon="tooltip" />
                <template #content>
                  <div style="max-width: 275px;">Adjusting the FPS for a camera live preview helps balance jittery video with user interface performance.</div>
                </template>
          </RocPopper>
        </div>
        <div class="d-flex settingsSectionWidget">
        <RocNumberInput
          :numberInput="cameraPreviewFpsInput"
          :inputType="'number'"
          :min="0"
          :max="100"
          @number-input-change="cameraPreviewFpsInput = $event"
        />
        </div>
      </div>
    </JSONObjectSettings>
    <JSONObjectSettings
      :title="'Object Store'"
      :path="selectedUxSetting.key"
      :cardJsonObject="selectedUxSetting.value"
      :jsonObject="selectedUxSetting.value"
      @edited-values="updateObjectStoreConfig"
    >
      <div class="settingsSection">
        <div class="settingsSectionLabel">Select</div>
          <div class="d-flex settingsSectionWidget">
          <RocSelect
            style="width: 225px"
            :availableOptions="uxConfigs"
            :optionLabel="'text'"
            :placeholder="'Select an Option'"
            @selection-changed="selectedUxSetting = $event"
          />
        </div>
      </div>
    </JSONObjectSettings>
    <JSONObjectSettings v-if="serverConfigEditorValue.maintenance"
      :title="'Maintenance'"
      :path="'maintenance'"
      :cardJsonObject="serverConfigEditorValue.maintenance"
      :jsonObject="serverConfigEditorValue"
      @edited-values="updateConfigObj"
    >
    </JSONObjectSettings>
    <JSONObjectSettings v-if="serverConfigEditorValue.recorder"
      :title="'Recorder'"
      :path="'recorder'"
      :cardJsonObject="serverConfigEditorValue.recorder"
      :jsonObject="serverConfigEditorValue"
      @edited-values="updateConfigObj"
    >
    </JSONObjectSettings>
    <JSONObjectSettings v-if="serverConfigEditorValue.import"
      :title="'Import'"
      :path="'import'"
      :cardJsonObject="serverConfigEditorValue.import"
      :jsonObject="serverConfigEditorValue"
      @edited-values="updateConfigObj"
    >
      <div class="settingsSection">
        <div class="settingsSectionLabel">Import Enabled</div>
        <div class="d-flex settingsSectionWidget">
          <RocSwitch :isActive="serverConfigEditorValue.import.enabled" @switch-toggled="serverConfigEditorValue.import.enabled = $event"/>
        </div>
      </div>
      <div v-if="serverConfigEditorValue.import.enabled" class="settingsSection">
        <div class="settingsSectionLabel">Reverse Search
          <RocPopper arrow hover placement="bottom" :popperType="'tooltip'" :locked="true" class="popper">
            <RocIcon size="sm" color="buttonPrimary" icon="tooltip" />
                <template #content>
                  <div style="max-width: 275px;">Allows watchlisted data to be searched against historical encounter data to generate matches.</div>
                </template>
          </RocPopper>
        </div>
        <div class="d-flex settingsSectionWidget">
          <RocSwitch :isActive="serverConfigEditorValue.import.reverseMatching.enabled" @switch-toggled="serverConfigEditorValue.import.reverseMatching.enabled = $event"/>
        </div>
      </div>
      <div v-if="serverConfigEditorValue.import.enabled" class="settingsSection">
        <div class="settingsSectionLabel">Remote Enroll
          <RocPopper arrow hover placement="bottom" :popperType="'tooltip'" :locked="true" class="popper">
            <RocIcon size="sm" color="buttonPrimary" icon="tooltip" />
                <template #content>
                  <div style="max-width: 275px;">Allows importing enrollments from ROC cloud services.</div>
                </template>
          </RocPopper>
        </div>
        <div class="d-flex settingsSectionWidget">
          <RocSwitch :isActive="serverConfigEditorValue.import.remoteEnroll.enabled" @switch-toggled="serverConfigEditorValue.import.remoteEnroll.enabled = $event"/>
        </div>
      </div>
      <div v-if="serverConfigEditorValue.import.enabled" class="settingsSection">
        <div class="settingsSectionLabel">Watchlist Import
          <RocPopper arrow hover placement="bottom" :popperType="'tooltip'" :locked="true" class="popper">
            <RocIcon size="sm" color="buttonPrimary" icon="tooltip" />
                <template #content>
                  <div style="max-width: 275px;">Allows importing watchlists and watchlist data from ROC cloud services.</div>
                </template>
          </RocPopper>
        </div>
        <div class="d-flex settingsSectionWidget">
          <RocSwitch :isActive="serverConfigEditorValue.import.watchlistImport.enabled" @switch-toggled="serverConfigEditorValue.import.watchlistImport.enabled = $event"/>
        </div>
      </div>
      <div v-if="serverConfigEditorValue.import.enabled" class="settingsSection">
        <div class="settingsSectionLabel">ROC Watch Data Import
          <RocPopper arrow hover placement="bottom" :popperType="'tooltip'" :locked="true" class="popper">
            <RocIcon size="sm" color="buttonPrimary" icon="tooltip" />
                <template #content>
                  <div style="max-width: 275px;">Allows importing data (watchlists, cameras, users, etc) into ROC Watch</div>
                </template>
          </RocPopper>
        </div>
        <div class="d-flex settingsSectionWidget">
          <RocSwitch :isActive="serverConfigEditorValue.import.batchImport.enabled" @switch-toggled="serverConfigEditorValue.import.batchImport.enabled = $event"/>
        </div>
      </div>
      <div v-if="serverConfigEditorValue.import.batchImport.enabled" class="settingsSection">
        <div class="settingsSectionLabel">
          Concurrent Enrollments
          <RocPopper arrow hover placement="bottom" :popperType="'tooltip'" :locked="true" class="popper">
            <RocIcon size="sm" color="buttonPrimary" icon="tooltip" />
                <template #content>
                  <div style="max-width: 275px;">Adjusting this value allows import service to submit parallel enrollment requests. This will speed up import of large watchlists but use more system resources.</div>
                </template>
          </RocPopper>
        </div>
        <div class="d-flex settingsSectionWidget">
          <RocNumberInput
            :numberInput="serverConfigEditorValue.import.batchImport.concurrentEnrollments"
            :inputType="'number'"
            :min="0"
            :max="100"
            @number-input-change="serverConfigEditorValue.import.batchImport.concurrentEnrollments = $event"
          />
        </div>
      </div>
    </JSONObjectSettings>
    <JSONObjectSettings v-if="serverConfigEditorValue.enroll"
      :title="'Enroll'"
      :path="'enroll'"
      :cardJsonObject="serverConfigEditorValue.enroll"
      :jsonObject="serverConfigEditorValue"
      @edited-values="updateConfigObj"
    >
    </JSONObjectSettings>

    <div class="d-flex user-card justify-content-between"
      style="cursor: pointer; border-bottom: 1px solid var(--overwatch-neutral-300)"
      @click="isEditApiKeys = !isEditApiKeys">
      <div class="overwatch-title-small" style="padding: 10px 0px;">API Keys</div>
    </div>
    <div class="d-flex user-card justify-content-between"
      style="cursor: pointer; border-bottom: 1px solid var(--overwatch-neutral-300)"
      @click="isDataMigration = !isDataMigration">
      <div class="overwatch-title-small" style="padding: 10px 0px;">Data Migration</div>
    </div>
  </div>

  <!-- Footer -->
  <div v-if='!isLoading' class="buttonGroup">
    <div class="flex-grow-1">
      <div @click="areYouSurePopup('load')" class="loadDefaults">
        LOAD DEFAULT
      </div>
    </div>
    <RocButton type="secondary" size="dialogButton" @click="close()"><span class="overwatch-body-small">Cancel</span></RocButton>
    <RocButton type="primary" size="dialogButton" @click="areYouSurePopup('save')"><span class="overwatch-body-small">Save</span></RocButton>
  </div>
  <!-- Confirmation Pop-up -->
  <base-dialog v-if="isShowing && confirmationType === 'save'" :show="true" title="Save Settings" @close="isShowing = false;" :style="confirmationStyle">
    <Confirmation @close=" isShowing = false" @yes="postSave()" >
      Are you sure you want to update settings? It will require a manual restart.
    </Confirmation>
  </base-dialog>
  <base-dialog v-if="isShowing && confirmationType === 'load'" :show="true" title="Load Default Settings" @close="isShowing = false;" :style="confirmationStyle">
    <Confirmation @close=" isShowing = false" @yes="loadDefaultSettings()" >
      Are you sure you want to reset settings to defaults? It will require a save.
    </Confirmation>
  </base-dialog>
  <base-dialog v-if="isEditApiKeys" :show="true" title="API Keys" @close="closeApiKeyEditor" :style="editApiKeysStyle">
     <ApiKeySettings @close="closeApiKeyEditor"/>
  </base-dialog>
  <base-dialog v-if="isDataMigration" :show="true" title="Data Migration" @close="closeDataMigration" :style="dataMigrationStyle">
     <DataMigration @close="closeDataMigration"/>
  </base-dialog>
</template>

<script>
import { MDBRadio } from "mdb-vue-ui-kit";
import { onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import BaseDialog from "@/components/ui/BaseDialog.vue";
import Confirmation from "./Confirmation.vue";
import { cloneDeep } from "lodash";
import JSONObjectSettings from "@/components/settings/JSONObjectSettings.vue";
import { get as lodashGet } from "lodash";
import { set as lodashSet } from "lodash";
import ApiKeySettings from "@/components/settings/ApiKeySettings.vue";
import RocPopper from "@/components/ui/RocPopper.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocSwitch from "../ui/RocSwitch.vue";
import RocButton from '../ui/RocButton.vue';
import RocNumberInput from "../ui/RocNumberInput.vue";
import RocSelect from "../ui/RocSelect.vue";
import DataMigration from "@/components/settings/DataMigration.vue";

export default {
  name: "ServerConfig",
  emits: ["close"],
  components: {
    MDBRadio,
    BaseDialog,
    Confirmation,
    cloneDeep,
    JSONObjectSettings,
    RocPopper,
    RocIcon,
    ApiKeySettings,
    RocSwitch,
    RocButton,
    RocNumberInput,
    RocSelect,
    DataMigration
  },
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const serverConfigEditorValue = ref(null);
    const isAdvancedEditorDisplayed = ref(false);
    const selectedAction = ref({});
    const selectedModality = ref('');
    const selectedUxSetting = ref('');
    const algorithmRepresentationRadioBtn = ref('');
    const lprClassificationRadioBtn = ref('');
    const objectStoreRadioBtn = ref('')
    const defaultsAppliedFlag = ref(false);
    const confirmationType = ref('');
    const serverConfigIdCache = ref('');
    const cameraPreviewFpsInput = ref(0);
    const isEditApiKeys = ref(false);
    const isDataMigration = ref(false);
    const windowWidth = ref(window.innerWidth);
    const lprClassifications = ref([]);

    //call to objectStores collection is by modality only right now so below is a hard-coded list of modalites
    const objectStoreConfigs = ref([
      { text: 'Face', key: 'face', value: {} },
      { text: 'License Plate', key: 'lpr', value: {} },
      { text: 'Optical Character', key: 'ocr', value: {} },
      { text: 'Gun', key: 'gun', value: {} },
      { text: 'Vehicle', key: 'vehicle', value: {} },
      { text: 'Pedestrian', key: 'pedestrian', value: {} },
      { text: 'Tattoo', key: 'tattoo', value: {} }
    ]);

    const uxConfigs = ref([
    { text: 'UX Settings', key: 'uxsettings', value: {} },
    { text: 'Autotag Analytics', key: 'autotag_analytics', value: {} },
    ])

    const isShowing = ref(false);

    onMounted(async () => {
      isLoading.value = true;
      await serverConfigFetch();
      await uxSettingsFetch();
      await loadObjectStore();
      await loadLprClassifications();
      isLoading.value = false;
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });
    })

    async function serverConfigFetch(){
      let result;
      try {
        result = await store.dispatch('settings/getServerConfig', {});
      } catch (error) {
        error.value = error.message || 'Something went wrong!';
      }

      if (result.status === 'success') {
        serverConfigEditorValue.value = cloneDeep(result.value);
        serverConfigIdCache.value = result.value._id;
      }
    }

    async function loadObjectStore() {
      for (let i=0; i<objectStoreConfigs.value.length; i++) {
        await objectStoreFetch(objectStoreConfigs.value[i].key, false);
      }
    }

    async function uxSettingsFetch(_key, loadDefaults){
      let result;

      if (loadDefaults === true) {
        try {
          result = await store.dispatch('settings/getUXSetting', "read_only");
        } catch (error) {
          error.value = error.message || 'Something went wrong!';
        }

        if (result.status === 'success') {
          uxConfigs.value[0].value = cloneDeep(result.value);
        }

        //autotag settings fetch
        try {
          result = await store.dispatch('settings/getObjectValueByKey', "read_only_autotag_analytics");
        } catch (error) {
          error.value = error.message || 'Something went wrong!';
        }

        if (result) {
          uxConfigs.value[1].value = cloneDeep(result);
        }
      }

      else if (defaultsAppliedFlag.value === false) {
        //uxsettings initial fetch
        try {
          result = await store.dispatch('settings/getUXSetting');
        } catch (error) {
          error.value = error.message || 'Something went wrong!';
        }

        if (result.status === 'success') {
          uxConfigs.value[0].value = cloneDeep(result.value);
        }

        //autotag settings fetch
        try {
          result = await store.dispatch('settings/getObjectValueByKey', "autotag_analytics");
        } catch (error) {
          error.value = error.message || 'Something went wrong!';
        }

        if (result) {
          uxConfigs.value[1].value = cloneDeep(result);
        }
      }
    }

    async function objectStoreFetch(key, loadDefaults) {
      let result;
      let modality;

      //load default configs, this gets called for each object config
      if (loadDefaults === true) {
        modality = "read_only_" + key;

        try {
          result = await store.dispatch("cameras/getVSConfigDefaultByModality", modality);
        } catch (error) {
          error.value = error.message || 'Something went wrong!';
        }

        if (result.status === 'success') {
          return result.value;
        }

        return {};
      }
      //if defaults have been fetched and applied, don't fetch old values from db
      else if(defaultsAppliedFlag.value === false) {
        modality = key;

        try {
          result = await store.dispatch("cameras/getVSConfigDefaultByModality", modality);
        } catch (error) {
          error.value = error.message || 'Something went wrong!';
        }

        if (result.status === 'success') {
          objectStoreConfigs.value.forEach((element) => {
            if(element.key === modality){
              element.value = cloneDeep(result.value);
            }
          });
        }
      }
    }


    const configProfileWorkflows = computed(() => {
      return serverConfigEditorValue.value ? serverConfigEditorValue.value.workflow.profiles : {};
    });

    const configGlobals = computed(() => {
      return serverConfigEditorValue.value ? serverConfigEditorValue.value.globals : {};
    });

    watch(configGlobals, () => {
      serverConfigEditorValue.value.globals = configGlobals.value;
      algorithmRepresentationRadioBtn.value = serverConfigEditorValue.value.globals.algorithm.representation;
      lprClassificationRadioBtn.value = serverConfigEditorValue.value.globals.algorithm.lpr.detection;
    });

    watch(algorithmRepresentationRadioBtn, () => {
       serverConfigEditorValue.value.globals.algorithm.representation = algorithmRepresentationRadioBtn.value;
    });

    watch(lprClassificationRadioBtn, () => {
      serverConfigEditorValue.value.globals.algorithm.lpr = lprClassifications.value.find((element) => {
        if (element.detection === lprClassificationRadioBtn.value) {
          return element;
        }
      });
    });

    watch(selectedModality, () => {
      if(selectedModality.value) {
        updateCameraCard();
      }
    });

    function updateCameraCard() {
      if (selectedModality.value.key === 'face') {
          const faceAlgo = getFaceAlgorithm();
          if(faceAlgo) {
            objectStoreRadioBtn.value = faceAlgo;
          }
        }
        const selectedIndex = getModalityIndex(selectedModality.value.key);
        const newFps = lodashGet(objectStoreConfigs.value[selectedIndex].value, 'roc.tracker.camera-preview.fps', 0);
        cameraPreviewFpsInput.value = newFps;
    }

    watch(objectStoreRadioBtn, () => {
      //only set algorithm id value if modality is "face"
      if(selectedModality.value && selectedModality.value.key === "face") {
        setFaceAlgorithm(objectStoreRadioBtn.value);
      }
    });

    watch(cameraPreviewFpsInput, () => {
      if(selectedModality.value) {
        const selectedIndex = getModalityIndex(selectedModality.value.key);
        if (selectedIndex < objectStoreConfigs.value.length) {
          lodashSet(objectStoreConfigs.value[selectedIndex].value, 'roc.tracker.camera-preview.fps', cameraPreviewFpsInput.value);
        }
      }
    });

    /**
     * Helper function to find the index of the object store entry for the provided camera modality key
     * @param {String} key
     */
    function getModalityIndex(key) {
      let selectedIndex=0;
      for (selectedIndex; selectedIndex < objectStoreConfigs.value.length; selectedIndex++) {
        if (objectStoreConfigs.value[selectedIndex].key === key) {
          break;
        }
      }
      return selectedIndex;
    }

    /**
     * Helper function to find the current algorithm id for face representation.
     * This iterates analytics-backends array in the camera.face config, looking for algorithm-id entries of
     * ROC_FACE_BALANCED_REPRESENTATION or ROC_FACE_ACCURATE_REPRESENTATION - if either of these is listed in the algorithm-id
     * array, then that is what we're looking for here
     */
    function getFaceAlgorithm() {
      const selectedIndex = getModalityIndex('face');
      let analyticsBackends = lodashGet(objectStoreConfigs.value[selectedIndex].value, 'roc.tracker.analytics-backends', null);
      if (analyticsBackends) {
        for (let i=0; i < analyticsBackends.length; i++) {
          const algoIdArray = lodashGet(analyticsBackends[i], 'algorithm-id', []);
          if (algoIdArray.includes('ROC_FACE_BALANCED_REPRESENTATION')) {
            return 'ROC_FACE_BALANCED_REPRESENTATION';
          } else if (algoIdArray.includes('ROC_FACE_ACCURATE_REPRESENTATION')) {
            return 'ROC_FACE_ACCURATE_REPRESENTATION';
          }
        }
      }
      return null;
    }

    /**
     * Helper function to set the algorithm id for face representation into the camera.face config.
     * This iterates analytics-backends array in the camera.face config, looking for algorithm-id entries of
     * ROC_FACE_BALANCED_REPRESENTATION or ROC_FACE_ACCURATE_REPRESENTATION - if either of these is listed,
     * we replace it with provided value
     * @param {String} value
     */
    function setFaceAlgorithm(value) {
      const selectedIndex = getModalityIndex('face');
      let analyticsBackends = lodashGet(objectStoreConfigs.value[selectedIndex].value, 'roc.tracker.analytics-backends', null);
      if (analyticsBackends) {
        for (let i=0; i < analyticsBackends.length; i++) {
          const algoIdArray = lodashGet(analyticsBackends[i], 'algorithm-id', []);
          let algoIdSet = false;
          for (let k=0; k<algoIdArray.length; k++) {
            if (algoIdArray[k] === 'ROC_FACE_BALANCED_REPRESENTATION' || algoIdArray[k] === 'ROC_FACE_ACCURATE_REPRESENTATION') {
              algoIdArray[k] = value;
              algoIdSet = true;
              break;
            }
          }
          if (algoIdSet) {
            break;
          }
        }
      }
    }

    function areYouSurePopup(button) {
      if(button === "save")
      {
        confirmationType.value = "save";
      }
      else if(button === "load"){
        confirmationType.value = "load";
      }
      isShowing.value = true;
    }

    function close(){
      context.emit("close")
    }

    async function postSave() {
      isLoading.value = true;
      let payload;
      let result;

      //server config update
      payload = serverConfigEditorValue.value;
      result = await store.dispatch('settings/saveServerConfig', payload);
      if (!result || result.status != 'success') {
        console.error("failed to save server config changes");
      }
      //update autotag and uxsettings config
      await updateUxConfigs();

      //camera config post
      for (const element of objectStoreConfigs.value) {
        payload = {
          key: "camera." + element.key + ".vs_config",
          value: element.value
        };
        result = await store.dispatch("cameras/updateObjectStoreConfig", payload);
        if (!result || result.status != 'success') {
          console.error("failed to save object store config changes")
        }
      }
    isLoading.value = false;
    context.emit("close");
  }

  //separating out from save function to not make things too complicated
    async function updateUxConfigs() {
      let payload;
      let result;
      //uxsettings post
      payload = {
        key: "uxsettings",
        value: uxConfigs.value[0].value
      };
      result = await store.dispatch("cameras/updateObjectStoreConfig", payload);
      if (!result || result.status != 'success') {
        console.error("failed to save object store uxsettings changes");
      }
      //autotag post
      payload = {
        key: "autotag_analytics",
        value: uxConfigs.value[1].value
      };
      result = await store.dispatch("cameras/updateObjectStoreConfig", payload);
      if (!result || result.status != 'success') {
        console.error("failed to save object store autotag analytics changes");
      }
      // reload ux settings
      await store.dispatch('settings/getUXSetting');
    }

    function updateConfigObj(editedJsonObj, propertyPath){
      //server config update
      if(propertyPath && propertyPath !== ""){
        serverConfigEditorValue.value[propertyPath] = editedJsonObj
        //dropdown options get unselected when object is updated
        if(propertyPath === "workflow"){
          selectedAction.value = "";
        }
      }
      isAdvancedEditorDisplayed.value = false;
    }

    function updateObjectStoreConfig(editedJsonObj, propertyPath){
      if (editedJsonObj.roc){
        objectStoreConfigs.value.forEach((element) => {
          if(element.key === propertyPath){
            element.value = cloneDeep(editedJsonObj);
          }
        });
        updateCameraCard();
      }
      else{
        uxConfigs.value.forEach((element) => {
          if(element.key === propertyPath){
            element.value = cloneDeep(editedJsonObj);
          }
        });
      }
      isAdvancedEditorDisplayed.value = false;
    }

    async function loadDefaultSettings(){
      isLoading.value = true;
      let result;
      selectedModality.value = "";

      //fetch defaults for object store defaults
      for (const element of objectStoreConfigs.value) {
        result = await objectStoreFetch(element.key, true);
        element.value = cloneDeep(result);
      }

      //fetch defaults for ux settings/auto tag defaults
      await uxSettingsFetch(null, true);

      //fetch server config defaults, objectstore holds default config for server
      result = await objectStoreFetch("config", true);
      serverConfigEditorValue.value = cloneDeep(result);
      //need first fetched id to update config value correctly
      serverConfigEditorValue.value._id = serverConfigIdCache.value;

      defaultsAppliedFlag.value = true;
      //deselect dropdowns
      selectedAction.value = "";
      selectedModality.value = "";
      selectedUxSetting.value = "";
      isLoading.value = false;
    }

    const confirmationStyle = computed(() => {
      if (windowWidth.value <= 480) {
        // Mobile style
        return {
          width: '100%'
        };
      }
    });

    const editApiKeysStyle = computed(() => {
      if (windowWidth.value <= 480) {
        // Mobile style
        return {
          width: '100%',
          'max-height': '100%'
        }
      } else {
        return {
          width: '750px',
          'max-height': '100%'
        }
      }
    });

    const dataMigrationStyle = computed(() => {
      if (windowWidth.value <= 480) {
        // Mobile style
        return {
          width: '100%',
          'max-height': '100%'
        }
      } else {
        return {
          width: '900px',
          'max-height': '100%'
        }
      }
    });

    function closeApiKeyEditor() {
      isEditApiKeys.value = false;
    }
    function closeDataMigration() {
      isDataMigration.value = false;
    }

    async function loadLprClassifications() {
      lprClassifications.value = await store.dispatch('settings/getObjectValueByKey', 'roc_lpr_classifications');
    }

    return {
      serverConfigEditorValue,
      isLoading,
      postSave,
      objectStoreConfigs,
      selectedModality,
      objectStoreRadioBtn,
      isShowing,
      confirmationStyle,
      areYouSurePopup,
      isAdvancedEditorDisplayed,
      algorithmRepresentationRadioBtn,
      selectedAction,
      updateConfigObj,
      updateObjectStoreConfig,
      configGlobals,
      configProfileWorkflows,
      close,
      loadDefaultSettings,
      confirmationType,
      uxConfigs,
      selectedUxSetting,
      cameraPreviewFpsInput,
      editApiKeysStyle,
      isEditApiKeys,
      closeApiKeyEditor,
      lprClassificationRadioBtn,
      lprClassifications,
      isDataMigration,
      closeDataMigration,
      dataMigrationStyle
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  height:30vw;
  display: flex;
}
.btn {
  text-transform: unset !important;
}

.popper{
  padding-left: var(--spacing-base);
}

.loadDefaults{
  align-items: start;
  text-decoration: underline;
  cursor: pointer;
  color: var(--overwatch-neutral-100);
}

.settingsSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--spacing-base);
  margin-top: var(--spacing-base);
  margin-bottom: var(--spacing-xs);
}

.settingsSectionLabel {
  flex-shrink: 0;
  margin-right: var(--spacing-l);
}

.settingsSectionWidget {
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-s);
  text-align: right;
}

.user-card {
    width: 100% !important;
    min-width: 25rem;
    margin-bottom: 5px !important;
    padding: 4px !important;
    margin: 0;
    background: var(--overwatch-secondary);
    border-bottom: 1px solid rgb(0, 0, 0);
    margin-right: 0rem !important;
  }

.buttonGroup {
  margin-top: 20px;
  gap: var(--spacing-m);
  margin-right: var(--spacing-m);
  justify-content: end;
  display: flex;
}

@media (max-width: 480px) {
  .container {
    height: 100%;
  }
  .user-card {
    min-width: 0;
    width: 100%;
    margin-bottom: 0 !important;
  }
  .settingsSectionLabel {
    width: 100%;
    margin-right: 0;
  }
  .settingsSection {
    flex-direction: column;
  }
  .buttonGroup {
    margin-right: 0px;
  }


}

</style>
