<template>
  <div class="dialog-wrapper">
    <div class="overwatch-body-large">Move to New Cluster</div>
    <div class="cluster-name">
      Name
      <RocInput
        v-model="newName"
        placeholder="Enter a name for your cluster"
      />
    </div>

    <div class="overwatch-body-small">
      {{ encounters.length }} {{ encounters.length === 1 ? 'encounter' : 'encounters' }}
      <div>
        <MyPopperWrapper arrow hover class="icon-popper">
            <RocIcon
              color="black"
              size="sm"
              icon="calendar"
            />
            <template #content>
              <div class="popper-content">
                {{ computedDateString }}
              </div>
            </template>
          </MyPopperWrapper>
          <MyPopperWrapper arrow hover class="icon-popper" style="margin-left: 0;">
            <RocIcon
              color="black"
              size="sm"
              icon="tag"
            />
            <template #content>
              <div class="popper-content">
                {{ computedTagsString }}
              </div>
            </template>
          </MyPopperWrapper>
      </div>
    </div>

    <div class="buttons">
      <RocButton type="secondary" @click="$emit('close')">
        Cancel
      </RocButton>
      <RocButton @click="moveToNewCluster()" :disabled="newName.length === 0">
        Save
      </RocButton>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import RocButton from '@/components/ui/RocButton';
import RocInput from '@/components/ui/RocInput';
import RocIcon from '@/components/ui/RocIcon';
import MyPopperWrapper from '@/components/ui/MyPopperWrapper.vue';

import moment from 'moment';

export default {
  name: 'MoveToNewCluster',
  components: {
    RocButton,
    RocInput,
    RocIcon,
    MyPopperWrapper
  },
  props: {
    encounters: {
      type: Array,
      required: true,
      default: []
    },
    cluster: {
      type: Object,
      required: true
    }
  },
  emits: ['close', 'save'],
  setup(props, context) {

    onMounted(() => {
      console.log(props.encounters);
    });
    const store = useStore();

    const newName = ref('');

    async function moveToNewCluster() {
      const fromClusterId = props.cluster._id;

      const encounterIds = props.encounters.map(e => e._id);

      await store.dispatch('clusters/moveEncountersToNewCluster',
        {
          update: {
            caseId: props.cluster.caseId,
            fromClusterId: fromClusterId,
            newName: newName.value,
            encounters: encounterIds
          }
        }
      );
      context.emit("save");
      context.emit('close');
    }

    function formattedDatetimeString(datetime) {
      const date = moment(new Date(datetime));
      const formatted = date.format("MMM DD, yyyy HH:mm:ss");
      return formatted
    }

    const computedDateString = computed(() => {
      const startTimestamps = props.encounters.map(e => e.track_info.start_timestamp);
      const stopTimestamps = props.encounters.map(e => e.track_info.stop_timestamp);

      var startTimestamp = Math.min(...startTimestamps);
      var endTimestamp = Math.max(...stopTimestamps);

      return `${formattedDatetimeString(startTimestamp)} - ${formattedDatetimeString(endTimestamp)}`;
    });

    const computedTagsString = computed(() => {
      if (props.encounters.length > 0) {
        var uniqueTags = new Set();
        for (let encounter of props.encounters) {
          for (let attribute of encounter.attributes) {
            uniqueTags.add(attribute);
          }
        }

        var output = '';
        let i = 0;
        var uniqueTagsArray = Array.from(uniqueTags);
        for (let attribute of uniqueTagsArray) {
          output += attribute;
          if (i < uniqueTagsArray.length - 1) {
            output += ', ';
          }
          i++;
        }
        return output;
      } else {
        return 'No tags'
      }
    });


    return {
      newName,
      formattedDatetimeString,
      moveToNewCluster,
      computedTagsString,
      computedDateString
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-wrapper {
  display: flex;
  flex-direction: column;
  gap: $--spacing-m;
  justify-content: flex-start;
  width: 500px;
  padding: $--spacing-xl $--spacing-xxl;
}
.cluster-name {
  @include overwatch-body-large;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}
.select-cluster {
  @include overwatch-body-large;
  .select {
    height: 48px;
    width: 100%;
  }
}
.buttons {
  display: flex;
  justify-content: flex-end;
  gap: $--spacing-s;
}

.icon-popper :deep(.popper) {
  background-color: var(--overwatch-primary);
  color: var(--overwatch-neutral-100);
  border-radius: 5px;

  padding: $--spacing-s $--spacing-m;

  width: fit-content;
  min-width: 240px;
}

.icon-popper :deep(.popper #arrow::before) {
  background-color: var(--overwatch-primary);
}

</style>