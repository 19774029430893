<template>
  <div class="edit-name-dialog">
    <span>Name</span>
    <RocInput ref='editNameInputRef' v-model="editName" @keyup.enter="saveEditName()"></RocInput>

    <div class="button-div">
      <RocButton type="secondary" @click="closeDialog()">Cancel</RocButton>
      <RocButton @click="saveEditName()">Save</RocButton>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import RocButton from '@/components/ui/RocButton';
import RocInput from '@/components/ui/RocInput';
import RocCheckbox from '@/components/ui/RocCheckbox';

export default {
  name: 'ClusterCardEditName',
  components: {
    RocButton,
    RocInput,
    RocCheckbox
  },
  props: ['name'],
  emits: ['close', 'save'],
  setup(props, context) {
    const editName = ref(props.name);

    const editNameInputRef = ref(null);
    onMounted(() => {
      // Focus on input when dialog opens.
      editNameInputRef.value.focusOnInput();
    });

    function saveEditName() {
      var name = editName.value;
      context.emit('save', name);
      context.emit('close');
    }

    function closeDialog() {
      context.emit('close');
    }

    return {
      editName,
      editNameInputRef,
      saveEditName,
      closeDialog,
    }
  }
}

</script>

<style scoped lang="scss">
.edit-name-dialog {
  @include overwatch-body-med;

  padding-top: var(--spacing-m);

  span {
    display: block;
    margin-bottom: $--spacing-base;
  }
}

.button-div {
  margin-top: $--spacing-s;

  display: flex;

  gap: $--spacing-base;

  justify-content: flex-end;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  margin-top: var(--spacing-s);
}
</style>