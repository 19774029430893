<template>
  <div class="wrapper">
    <!-- Filter input -->
    <RocInput
      class="filter-input"
      @click="isInputExpanded = true"
      @blur="collapseInputOnBlur"
      @input="changeTextFilter"
      :class="{
        'collapsed': !isInputExpanded
      }"
      v-model="inputText"
    >
      <RocIcon
        icon="search"
        color="black"
        size="sm"
      />
    </RocInput>

    <!-- Sort -->
    <MDBDropdown
      v-model="isSortOpen"
    >
      <RocDropdownToggle>
        <RocButton
          class="filter-button"
          type="white"
          @click="isSortOpen = !isSortOpen"
        >
          <div class="d-flex align-items-center">
            <RocIcon
              color="black"
              size="sm"
              icon="sort"
            />
            <span
              :class="{'sort-text': currentlySelectedSort.length > 0}"
            >
              {{ currentlySelectedSortText }}
            </span>
            <RocIcon
              v-if="currentlySelectedSort.length > 0"
              class="sort-text"
              color="black"
              size="xs"
              icon="exit"
              @click.stop="selectSort('')"
            />
          </div>
        </RocButton>
      </RocDropdownToggle>
      <RocDropdownMenu>
        <RocDropdownItem @click="selectSort('newest')" :active="currentlySelectedSort==='newest'">
          Newest to Oldest
        </RocDropdownItem>
        <RocDropdownItem @click="selectSort('oldest')" :active="currentlySelectedSort==='oldest'">
          Oldest to Newest
        </RocDropdownItem>
        <RocDropdownItem @click="selectSort('alpha')" :active="currentlySelectedSort==='alpha'">
          Name A-Z
        </RocDropdownItem>
        <RocDropdownItem @click="selectSort('reverse-alpha')" :active="currentlySelectedSort==='reverse-alpha'">
          Name Z-A
        </RocDropdownItem>
      </RocDropdownMenu>
    </MDBDropdown>

    <!-- Basic filters -->
    <MDBDropdown
      v-model="isFilterOpen"
      v-if="!hideFilter"
    >
      <RocDropdownToggle>
        <RocButton
          class="filter-button"
          type="white"
          @click="isFilterOpen = !isFilterOpen"
        >
          <div class="d-flex align-items-center">
            <span style="color: var(--overwatch-neutral-100)">Filter By {{ currentlySelectedFilter }}</span>
            <RocIcon
              class="filter-caret"
              color="black"
              size="xs"
              icon="downArrow"
            />
          </div>
        </RocButton>
      </RocDropdownToggle>
      <RocDropdownMenu>
        <RocDropdownItem @click="selectFilter('all')" :active="currentlySelectedFilter==='all'">
          All
        </RocDropdownItem>
        <RocDropdownItem @click="selectFilter('complete')" :active="currentlySelectedFilter==='complete'">
          Complete
        </RocDropdownItem>
        <RocDropdownItem @click="selectFilter('in progress')" :active="currentlySelectedFilter==='in progress'">
          In Progress
        </RocDropdownItem>
        <!-- <MDBDropdownItem href="#">
          Archived
        </MDBDropdownItem> -->
      </RocDropdownMenu>
    </MDBDropdown>


  </div>
</template>

<script>
import { ref, computed } from 'vue';
import RocButton from '@/components/ui/RocButton';
import RocInput from '@/components/ui/RocInput';
import RocIcon from '@/components/ui/RocIcon';

import { MDBDropdown } from 'mdb-vue-ui-kit';
import RocDropdownMenu from '@/components/ui/RocDropdownMenu';
import RocDropdownToggle from '@/components/ui/RocDropdownToggle';
import RocDropdownItem from '@/components/ui/RocDropdownItem';

export default {
  name: 'RocDashboardFilters',
  components: {
    RocButton,
    RocInput,
    RocIcon,
    MDBDropdown,
    RocDropdownMenu,
    RocDropdownToggle,
    RocDropdownItem
  },
  props: {
    hideFilter: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const isInputExpanded = ref(false);

    const inputText = ref('');

    function collapseInputOnBlur() {
      if (inputText.value === '') {
        isInputExpanded.value = false;
      }
    }

    var textFilterTimeout;
    const textFilterEmitTime = 300;
    function changeTextFilter() {
      clearTimeout(textFilterTimeout);

      textFilterTimeout = setTimeout(() => {
        context.emit('text-filter-changed', inputText.value);
      }, textFilterEmitTime);
    }

    const isSortOpen = ref(false);
    const currentlySelectedSort = ref('');

    function selectSort(sort) {
      currentlySelectedSort.value = sort;
      isSortOpen.value = false;
      context.emit('sort-changed', sort)
    }

    const currentlySelectedSortText = computed(() => {
      switch (currentlySelectedSort.value) {
        case 'newest':
          return 'Newest to Oldest';
        case 'oldest':
          return 'Oldest to Newest';
        case 'alpha':
          return 'Name A-Z';
        case 'reverse-alpha':
          return 'Name Z-A';
        default:
          return '';
      }
    });


    const isFilterOpen = ref(false);
    const currentlySelectedFilter = ref('all');

    function selectFilter(filter) {
      currentlySelectedFilter.value = filter;
      isFilterOpen.value = false;
      context.emit('filter-changed', filter)
    }

    return {
      isInputExpanded,
      inputText,
      collapseInputOnBlur,
      currentlySelectedSort,
      currentlySelectedSortText,
      currentlySelectedFilter,
      isSortOpen,
      isFilterOpen,
      selectSort,
      selectFilter,
      changeTextFilter
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  height: 100%;
  gap: $--spacing-s;
}

// Text filter
.filter-input {
  width: 250px;
  transition: width .3s;

  display: flex;
  justify-content: center;
}

// Expanded
.filter-input :deep(svg) {
  margin-right: $--spacing-s;
}

// Collapsed
$animation-time: .3s;
.filter-input.collapsed {
  width: 45px;
  padding: 0;
  transition: all $animation-time;

}
.filter-input.collapsed :deep(svg){
  margin-right: 0;
}
.filter-input.collapsed :deep(input) {
  width: 0;
  transition: width $animation-time;
  // Input size needs to transition with the filter-input,
  // or else the icon jumps around.
}

// buttons
.filter-button {
  @include overwatch-body-small;
  height: 45px;
  text-transform: capitalize;
  padding-left: $--spacing-s;
  padding-right: $--spacing-s;
}

.square {
  // Keep buttons square at 45px.
  height: 45px;
  width: auto;
  padding: 0;
  aspect-ratio: 1/1;
}

.filter-caret{
  margin-left: $--spacing-s;
}

.sort-text {
  margin-left: $--spacing-s;
}

span {
  color: var(--overwatch-neutral-100);
}
</style>