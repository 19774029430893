<template>
  <div class="event-card">
    <div class="d-flex justify-content-between event-header disable-select">
      <div class="d-flex" >
        <div :class="{
          upcomingBubble: status === 'Upcoming',
          activeBubble: status === 'Active',
          disabledBubble: status === 'Disabled',
          endedBubble: status === 'Ended',
          inActiveBubble: status === 'Inactive'
          }"
        ></div>
        <div style="margin-left: var(--spacing-base); display: flex; align-items: center;" class="overwatch-body-small">
       {{ status }}
      </div>
        <div class="event-members-total"> 
          {{ totalEntitlements + ' members'}}
        </div>
      </div>
      <div v-if="!isSaving">
        <MDBDropdown v-model='dropdownOptions' align='end'>
          <MDBDropdownToggle class="dropdown-toggle-custom" tag='a' @click="dropdownOptions = !dropdownOptions">
            <RocIcon icon="kebab" color="black" size="sm"/>
          </MDBDropdownToggle>
          <RocDropdownMenu aria-labelledby='dropdownMenuButton' style="box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);">
            <MDBDropdownItem href="" @click.prevent>
              <div @click="toggleEnabled" class="d-flex justify-content-between">{{ stateText }}
                 <RocIcon v-if="stateText === 'Disable'" class="Badge-Icon" icon="nomatch" size="sm" color="black"/>
                 <RocIcon v-else class="Badge-Icon" icon="circleCheck" size="sm" color="primary"/>
              </div>
            </MDBDropdownItem>
            <div v-if="isAdmin">
              <hr class='dropdown-divider' style="margin-top: 0px !important; margin-bottom: 0px !important;"/>
              <MDBDropdownItem href="" @click.prevent>
                <div @click="editEvent" class="d-flex justify-content-between">
                  Edit
                  <RocIcon color="black" size="sm" icon="edit"/>
                </div>
              </MDBDropdownItem>
            </div>
            <hr class='dropdown-divider' style="margin-top: 0px !important; margin-bottom: 0px !important;"/>
            <MDBDropdownItem href="" @click.prevent>
              <div @click="deleteEvent" class="d-flex justify-content-between">
                Delete
                <RocIcon icon="trash" color="red" size="sm"/>
              </div>
            </MDBDropdownItem>
          </RocDropdownMenu>
        </MDBDropdown>
      </div>
      <div v-else>
        <roc-spinner/>
      </div>
    </div>
    <EventViewData 
      :startDate="event.startDate" 
      :endDate="event.endDate" 
      :eventName="event.eventName"
      :eventId="event._id"
      style="flex-grow: 1;"/>
    <BaseDialog v-if="isShowingDelete" :show="true" @close="isShowingDelete=false;" :style="deleteConfirmationStyle">
      <DeleteConfirmation
        @close="isShowingDelete=false"
        @delete="deleteConfirmed"
        message="Yes"
      >
      <div style="display:flex; gap: var(--spacing-base)">
        <RocIcon icon="trash" color="red" class="camera-delete"/>
        <div style="display:grid">
          Are you sure you want to delete  
          <span style="text-decoration: underline;">{{ event.eventName }} ? </span>
        </div>
      </div>
    </DeleteConfirmation>
    </BaseDialog>
  </div>
</template>

<script>
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBSwitch,
} from "mdb-vue-ui-kit";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import BaseDialog from "@/components/ui/BaseDialog";
import EventViewData from '@/components/events/EventViewData';
import RocIcon from '@/components/ui/RocIcon';
import DeleteConfirmation from "../settings/DeleteConfirmation.vue";
import RocDropdownMenu from "../ui/RocDropdownMenu.vue";
import dateHelper from "@/js/dateHelper";

export default {
  name: "EventView",
  emits: ["edit", "delete", "toggleEnabled"],
  components: {
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    MDBSwitch,
    BaseDialog,
    EventViewData,
    RocIcon,
    DeleteConfirmation,
    RocDropdownMenu
  },
  props: {
    event: Object,
    hideTitle: {
      type: Boolean,
      default: false
    },
    isSaving: Boolean
  },
  setup(props, context) {
    const store = useStore();
    const dropdownOptions = ref(false);
    const eventActive = ref(props.event.enabled);
    const parentStyle = ref(null);
    const isShowingDelete = ref(false);
    const deleteConfirmMsg = ref('Are you sure you want to delete ' + props.event.eventName);
    const statusBackground = ref('white');
    const statusBorder = ref('none');
    const dateTool = new dateHelper();
    const totalEntitlements = ref(0);

    loadEntitlements(1);
    async function loadEntitlements(reload) {
      const payload = {
        _eventId: props.event._id,
      };

      const result = await store.dispatch('events/getEntitlements', payload);
      if (result) {
        totalEntitlements.value = result.totalEntitlements;
      }
      reload.status ? reload.status.value = false : 0;
    }

    const stateText = computed(() => {
      return props.event.enabled ? "Disable" : "Enable";
    });
    const isAdmin = computed(function() {
      return store.getters['auth/isAdmin'];
    });

    function toggleEnabled() {
      dropdownOptions.value = false;
      context.emit('toggleEnabled');
    }

    function editEvent() {
      dropdownOptions.value = false;
      context.emit('edit');
    }

    function deleteEvent() {
      dropdownOptions.value = false;
      isShowingDelete.value = true;
    }

    function deleteConfirmed() {
      isShowingDelete.value = false;
      context.emit('delete');
    }

    const deleteConfirmationStyle = computed(() => {
      if (window.innerWidth <= 480) {
        // Mobile style
        return {
          width: '90%'
        }
      } 
    });

    const status = computed(() => {
      if (!props.event.enabled) {
        statusBackground.value = 'var(--overwatch-neutral-200)';
        statusBorder.value = 'none';
        return 'Disabled';
      } else {
        if (!props.event.startDate || !props.event.endDate) {
          statusBackground.value = 'var(--overwatch-neutral-200)';
          statusBorder.value = 'none';
          return 'Inactive';
        }
        const startDate = dateTool.getDateFromDateOnlyString(props.event.startDate);
        const endDate = dateTool.getDateFromDateOnlyString(props.event.endDate);
        const today = new Date();
        today.setHours(0,0,0,0);

        if (today < startDate) {
          statusBackground.value = 'white';
          statusBorder.value = '2px solid var(--overwatch-button-primary)';
          return 'Upcoming';
        } else if (today > endDate) {
          statusBackground.value = 'var(--overwatch-neutral-500)';
          statusBorder.value = 'none';
          return 'Ended'
        } else {
          statusBackground.value = 'var(--overwatch-button-primary)';
          statusBorder.value = 'none';
          return 'Active';
        }
      }
    });

    return {
      dropdownOptions,
      eventActive,
      parentStyle,
      editEvent,
      deleteEvent,
      isAdmin,
      stateText,
      toggleEnabled,
      isShowingDelete,
      deleteConfirmed,
      deleteConfirmMsg,
      deleteConfirmationStyle,
      status,
      statusBackground,
      statusBorder,
      totalEntitlements,
    }
  }
}
</script>

<style scoped lang="scss">
.event-card {
  position:relative;
  box-sizing: border-box;
  min-height: 187px;
  height: auto;
  width: 316px;
  box-shadow: 0px 4px 8px rgba(0,0,0, .05);
  border-radius: 4px;
  background-color: var(--overwatch-secondary);
  margin-bottom: var(--spacing-m);
  margin-left: var(--spacing-m);
  display:flex;
  flex-direction: column;
}

.event-header {
  font-size: 16px;
  margin-top: 16px;
  margin-left: 21px;
  margin-right: 21px;
}

.dropdown-toggle-custom {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 5px;
}

.event-members-total{
  display: flex;
  @include overwatch-body-xsmall;
  align-items: center;
  margin-left: var(--spacing-s); 
  color: var(--overwatch-neutral-200);
}

img {
  margin: 0 2px;
  border-radius: 4px;
}

.dropdown-toggle:after {
  display: none;
}

.disabled {
  width: 83px;
  color: var(--overwatch-error);
  @include overwatch-body-med;
  letter-spacing: 1px;
  margin-left: auto;
  margin-right: 1rem;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.disabledBubble {
  width: var(--spacing-xs);
  margin-top: var(--spacing-xs);
  margin-right: 2px;
  height: var(--spacing-xs);
  border-radius: 50%;
  background-image: radial-gradient(circle at 50% 50%, #c9c9c9, #c5c5c5 0%, #b8b8b8 32%, var(--overwatch-light-neutral-300) 71%);
}

.activeBubble {
  width: var(--spacing-xs);
  height: var(--spacing-xs);
  margin-top: var(--spacing-xs);
  margin-right: 2px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 var(--overwatch-light-success);
  background-image: linear-gradient(to bottom, #e7f0e6 0%, #7ec778 39%, var(--overwatch-light-success) 100%);
}

.upcomingBubble{
  width: var(--spacing-xs);
  height: var(--spacing-xs);
  margin-top: var(--spacing-xs);
  margin-right: 2px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 #3f3fd9;
  background-image: linear-gradient(to bottom, #eef 0%, #8080f0 50%, #3f3fd9 100%);
}

.endedBubble{
  width: var(--spacing-xs);
  height: var(--spacing-xs);
  margin-top: var(--spacing-xs);
  margin-right: 2px;
  border-radius: 50%;
  border: solid 1px var(--overwatch-light-neutral-300);
  background-image: radial-gradient(circle at 50% 50%, #c9c9c9, #c5c5c5 0%, #b8b8b8 32%, var(--overwatch-light-neutral-300) 71%);
}

.inActiveBubble{
  width: var(--spacing-xs);
  height: var(--spacing-xs);
  margin-top: var(--spacing-xs);
  margin-right: 2px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 #3f3fd9;
  background-image: linear-gradient(to bottom, #eef 0%, #8080f0 50%, #3f3fd9 100%);
}

@media (max-width: 480px) {
  .event-card {
    max-width: 100vw;
    min-width: 95vw;
    margin: 0;
    margin-top: var(--spacing-m);
  }
}
</style>