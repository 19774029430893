<template>
  <div>
    <search-header class="stick-to-top" 
       button-title='Sort' 
      :use-dropdown="true" 
      :keepButtonTitle="true" 
      :transparentBackground=true
      @search-filter-change="setSearchFilter"
    >
      <template v-slot:leftnav>
        Saved Views
      </template>
      <template v-slot:dropdown-slot>
        <MDBDropdownItem href="" @click.prevent="sortViews('az')">
          <div class="d-flex flex-row">
            <span style="margin-top: 2px;">A-Z</span>
            <span style="margin-left: auto">
              <RocIcon size="sm" color="black" icon="backArrow" transform='rotate(-90)'/></span>
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem href="" @click.prevent="sortViews('za')">
          <div class="d-flex flex-row">
            <span style="margin-top: 2px;">Z-A</span>
            <span style="margin-left: auto" class="Up-Arrow">
              <RocIcon size="sm" color="black" icon="backArrow" transform='rotate(-90)'/></span>
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem href="" @click.prevent="sortViews('date')">
          <div class="d-flex flex-row">
            <span style="margin-top: 2px;">Date Added</span>
            <span style="margin-left: auto">
            <RocIcon size="sm" color="black" icon="calendar"/>
         </span>
          </div>
        </MDBDropdownItem>
      </template>
    </search-header>
    <div class="Saved-Views">
      <!-- TODO - the below header is for after encounters refresh, commenting for now -->
      <!-- <div class="d-flex flew-row">
        <div class="d-flex justify-content-start overwatch-title-med" style="color: #00000">
          Saved Views
        </div>
        <div class="d-flex justify-content-end" style="margin-left: auto;">
          <div class="input-rectangle">
            <img src="@/assets/img/icons/icons_gray_16x16_search.svg" class="iconsgray16x16search">
            <input class="search-input" type="text" v-model="searchText" placeholder="Search"/>
          </div>
          <div class="dropdown">
            <MDBDropdown v-model='dropdownOptions' align='end'>
              <MDBDropdownToggle
                style='color:black;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;'
                tag='a'
                @click="dropdownOptions = !dropdownOptions">
                <MDBBtn class="ButtonIcon-Text-Button-PrimaryTiny">
                  <span><img src="@/assets/img/icons/icons_white_16x16_sort.svg"/></span>
                  <span class="Sort-Label">Sort</span>
                </MDBBtn>
              </MDBDropdownToggle>
              <MDBDropdownMenu aria-labelledby='dropdownMenuButton' class="d-flex flex-column">
                <MDBDropdownItem href="" @click.prevent="sortViews('az')">
                  <div class="d-flex flex-row">
                    <span style="margin-top: 2px;">A-Z</span>
                    <span style="margin-left: auto"><img src="@/assets/img/icons/icons_black_16x16_back_arrow.svg"/></span>
                  </div>
                </MDBDropdownItem>
                <MDBDropdownItem href="" @click.prevent="sortViews('za')">
                  <div class="d-flex flex-row">
                    <span style="margin-top: 2px;">Z-A</span>
                    <span style="margin-left: auto" class="Up-Arrow"><img src="@/assets/img/icons/icons_black_16x16_back_arrow.svg" /></span>
                  </div>
                </MDBDropdownItem>
                <MDBDropdownItem href="" @click.prevent="sortViews('date')">
                  <div class="d-flex flex-row">
                    <span style="margin-top: 2px;">Date Added</span>
                    <span style="margin-left: auto"><img src="@/assets/img/icons/icons_black_16x16_calendar.svg"/></span>
                  </div>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </div>
        </div>
      </div> -->
      <div v-if="filterViews && filterViews.length > 0">
        <InfiniteScroll :items="filterViews"  mode="nopage">
          <template v-slot:item="{ item }">
            <SavedViewCard :rawObject="item" :name="item.name" :desc="item.desc" :filterCreatedTime="item.filterCreatedTime" :key="item.name"
            :tags="item.tags" :cameras="item.cams" :dateRange="item.dateRange" :img="item.faceImage" :watchlists="item.watchlists" :matchesOnly="item.matchesOnly"
            :adjudication="item.adjudication" :isPriority="item.priority" @delete="deleteView"></SavedViewCard>
          </template>
        </InfiniteScroll>
      </div>
      <div v-else style="flex: 1; margin-top: 100px;">
        <RocLogo />
        <div class="no-data-bold">
          No Saved Views
        </div>
      </div>
      </div>
    </div>
</template>

<script>
import { MDBBtn, MDBInput, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdb-vue-ui-kit";
import { computed, onMounted, reactive, ref, watch, watchEffect } from "vue";
import InfiniteScroll from "@/components/ui/InfiniteScroll";
import RocLogo from "@/components/ui/RocLogo";
import RocIcon from "@/components/ui/RocIcon";
import SavedViewCard from "../../components/encounters/SavedViewCard.vue";
import { useStore } from "vuex";
import BaseDialog from "../../components/ui/BaseDialog.vue";
import SearchHeader from "@/components/ui/SearchHeader";
export default {
  components: {
    MDBBtn,
    MDBInput,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    InfiniteScroll,
    RocLogo,
    SavedViewCard,
    BaseDialog,
    SearchHeader,
    RocIcon
  },

  setup(props, context) {
    const searchText = ref('');
    const store = useStore();
    const dropdownOptions = ref(false);

    const savedViewsList = computed(function() {
      //add lodash for error handling
      let userConfig = store.getters['auth/userConfig'];
      return userConfig.savedViews
    });

    function setSearchFilter(filterText) {
      searchText.value = filterText;
    }

    function sortViews(sortBy){
      if(sortBy === 'az'){
        //alphabetical
        savedViewsList.value.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      }
      if(sortBy === 'za'){
        //reverse alphabetical
        savedViewsList.value.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      }
      if(sortBy === 'date'){
        //return oldest date
        savedViewsList.value.sort((a, b) => {
          const dateA = a.filterCreatedTime;
          const dateB = b.filterCreatedTime;
          return Date.parse(dateA) - Date.parse(dateB);
        })
      }
      dropdownOptions.value = false;
    }

    const filterViews = computed(function () {
      const viewsArray = savedViewsList.value;
      if (viewsArray) {
        const sortedArray = [
          ...viewsArray.filter(({ priority }) => priority),
          ...viewsArray.filter(({ priority }) => !priority)
        ];

        return searchText.value != '' ? sortedArray.filter((view) => {
          const x = view.name.toUpperCase();
          if (x.includes(searchText.value.toUpperCase())) {
            return true;
          }
        }) : sortedArray;
      }
      else {
        return null;
      }
    });

    async function deleteView(payload){
      let userConfig = store.getters['auth/userConfig'];

      userConfig.savedViews = userConfig.savedViews.filter(el => el.name !== payload);

      await store.dispatch('auth/updateUserConfig', userConfig)
    }

    onMounted(async () => {
      await getWatchlists();

      //sortViews('isPriority');
    })

    async function getWatchlists() {
      let availableWatchlists = store.getters['watchlists/watchlists'];

      if (availableWatchlists.length === 0) {
        await store.dispatch('watchlists/loadWatchlists');
        availableWatchlists = store.getters['watchlists/watchlists'];
      }
    }

    return{
      searchText,
      savedViewsList,
      deleteView,
      filterViews,
      dropdownOptions,
      sortViews,
      setSearchFilter
    };
  },
}
</script>

<style scoped lang="scss">
.btn {
  text-transform: unset !important;
}
.Saved-Views {
  width: 100%;
  padding: 0 64px;
  background-color: var(--overwatch-background);
}
.input-rectangle {
  width: 316px;
  height: 45px;
  margin-right: 10px;
  padding: var(--spacing-s) 10px var(--spacing-s) var(--spacing-m);
  border-radius: 5px;
  border: solid 1px var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  display: flex;
  align-items: center;
}
.search-input {
  border: 0px;
  height: 100%;
  margin: auto 0;
  width: 90%;
  @include overwatch-body-small;
  letter-spacing: 0;
  line-height: 25px;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-neutral-500);
}

input::placeholder {
  width: 50px;
  height: 21px;
  flex-grow: 0;
  margin: 0 0 0 var(--spacing-s);
  @include overwatch-body-xsmall;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--overwatch-neutral-300);
}

.Up-Arrow{
  transform: rotate(-180deg);
}
img.iconsgray16x16search {
  width: var(--spacing-m);
  height: var(--spacing-m);
  flex-grow: 0;
  margin: 3px var(--spacing-s) 2px 0;
  object-fit: contain;
}
img.iconswhite16x16sort {
  width: var(--spacing-m);
  height: var(--spacing-m);
  flex-grow: 0;
  object-fit: contain;
}
.ButtonIcon-Text-Button-PrimaryTiny {
  width: 96px;
  height: 42px;
  gap: var(--spacing-xs);
  padding: var(--spacing-s);
  border-radius: 5px;
  box-shadow: 0 2px var(--spacing-base) 0 rgba(0, 0, 0, 0.25);
  background-color: var(--overwatch-primary);
}

.Sort-Label {
  width: 25px;
  height: 18px;
  flex-grow: 0;
  @include overwatch-body-xxsmall;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--overwatch-neutral-100);
  margin-left: 10px;
}

.dropdown {
    height: 45px;
    display: flex;
    align-items: center;
  }

  .no-data-bold {
  display: flex;
  justify-content: center;
  font-size: 30px;
  margin-top: 30px;
}

.stick-to-top {
  position: sticky;
  top: 0;
  z-index: 50;
}
@media (max-width: 480px) {
  .Saved-Views {
    width: 100%;
    height:auto;
    padding: var(--spacing-m) 32px;
    background-color: var(--overwatch-background);
  }
}
</style>
