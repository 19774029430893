export async function apiCall(context, url, method, body=null, contentType = 'application/json', onUploadProgress = null, abortController = null) {
  const httpPayload = {
    method: method,
    headers: {
      Accept: 'application/json',
    }
  };
  if (body) {
    httpPayload.body = body
  }
  if (body && (method === 'PUT' || method === 'POST')) {
    httpPayload.headers['Content-Type'] = contentType;
  }
  const responseData = await context.dispatch(
    'auth/fetchJSON',
    { url: url, payload: httpPayload, onUploadProgress: onUploadProgress, abortController: abortController },
    { root: true }
  );
  return responseData;
}
export async function readFile(file, base64=false) {
  return new Promise(function(resolve, reject) {
    let fr = new FileReader();
    fr.onload = function() {
      resolve(fr.result);
    };
    fr.onerror = function() {
      reject(fr);
    };
    if (base64) {
      fr.readAsDataURL(file);
    } else {
      fr.readAsArrayBuffer(file);
    }
  });
}
export function generateQueryParamString(params) {
  // Generate query param
  // ?id=id1,id2,id3
  var url = '';
  var i = 0;
  for (var param of params) {
    url += `${param}`
    if (i < params.length - 1) {
      url += ',';
    }
    i++;
  }
  return url;
}
