export default function getWorkstation(){
    return {
        'operatingSystem': navigator.platform, 
        'threads': navigator.hardwareConcurrency, 
        'memory': navigator.deviceMemory, 
        'browser': identifyBrowser(navigator.userAgent, 0),
        'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
}

/**
 * @param userAgent: userAgent string. If undefined, userAgent gets set to navigator.userAgent
 * @param versionLength: how granular of browser version number you want. 
 *  0 returns whole version number, 
 *  1 return major and minor, 
 *  2 return major, minor, and patch
 *  if undefined, defaults to 2
**/
function identifyBrowser(userAgent, versionLength) {
    let regexps = {
            'Chrome': [ /Chrome\/(\S+)/ ],
            'Firefox': [ /Firefox\/(\S+)/ ],
            'MSIE': [ /MSIE (\S+);/ ],
            'Opera': [
                /Opera\/.*?Version\/(\S+)/,     /* Opera 10 */
                /Opera\/(\S+)/                  /* Opera 9 and older */
            ],
            'Safari': [ /Version\/(\S+).*?Safari\// ]
        },
        re, m, browser, version;
    
    if (userAgent === undefined)
        userAgent = navigator.userAgent;

    if (versionLength === undefined)
        versionLength = 2;
    else if (versionLength === 0)
        versionLength = 1337; //just need to be big enough to ensure we get all the matches. There are normally only 4  
    
    for (browser in regexps)
        while (re = regexps[browser].shift())
            if (m = userAgent.match(re)) {
                const versionRegex = new RegExp('[^.]+(?:\.[^.]+){0,' + --versionLength + '}'); //versionLength is just how many times we match
                const versionMatch = m[1].match(versionRegex);
                version = versionMatch[0];
                return browser + ' ' + version;
            }
    
    return null;
}
