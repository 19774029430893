<template>
  <div class="datatable"
    role="table"
    :style="`grid-template-columns: ${templateColumns}`"
  >
    <div class="header">
      <slot name="header"></slot>
    </div>
    <div class="entries">
      <slot name="entries"></slot>    
    </div>
  </div>  
</template>

<script>

import {ref, computed} from 'vue';

export default {
  name: 'RocTable',
  props: {
    'templateColumns': {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const templateColumns = ref(props.templateColumns);

    return {
      templateColumns
    };
  }
}
</script>

<style scoped lang="scss">
.datatable { 
  display: grid;
  width: 100%;
  justify-items: stretch;
  background: transparent;

  // Template columns defined as prop by user.
}

.header > :slotted(*), .entries > :slotted(*) {
  // Display the direct divs under header and entries as contents.
  display: contents;
}

.header :slotted(div>*:first-child), .entries > :deep(div>*:first-child) {
  // Style every first column span.
  // Using :deep selector here to select children components
  padding-left: var(--spacing-l);
}


.header :slotted(div>*:last-child), .entries > :deep(div>*:last-child) {
  // Style every first column span.
  // Using :deep selector here to select children components
  padding-right: var(--spacing-l);
}

// Header styles
.header {
  display: contents;
  grid-column: 1 / -1;  
}

.header :slotted(*>*) {
  // Style each entry under header.
  @include overwatch-body-small;

  color: var(--overwatch-neutral-100);

  display: flex;
  align-items: center;

  height: 53px;
  border: solid 1px var(--overwatch-accent);
  border-left: 0;
  border-right: 0;
  background-color: var(--overwatch-button-primary-20);
  user-select: none;
}

.header > :slotted(*>*:first-child) {
  // Style first column of header.
  border-left: solid 1px var(--overwatch-accent);
  border-radius: 5px 0 0 0;
}

.header > :slotted(*>*:last-child) {
  // Style last column of header.
  border-right: solid 1px var(--overwatch-accent);
  border-radius: 0 5px 0 0;
}

// Entries styles
.entries {
  display: contents;
}

.entries > :deep(*>*) {
  // Style every cell of entries
  @include overwatch-body-small;

  color: var(--overwatch-neutral-100);

  display: flex;
  align-items: center;
  height: 70px;

  border-bottom: solid 1px var(--overwatch-accent);
  background-color: var(--overwatch-secondary);
}

</style>