<template>
  <div>
    <div class='camera-card' style="position:relative;">
      <div v-if='camState ^ camOnline' class='loading-spinner'>
        <roc-spinner/>
      </div>
      <div style="position: relative; opacity: 1.0;">
        <div style="display:flex; align-items: center; justify-content: center; border-radius: 6px 6px 0 0; overflow: hidden;">
          <img :src='cameraPreviewSrc' @error="missingPreview" :style='activeStyle()'/>
        </div>
        <div v-if="camState && camOnline && (procFPS || procDropped || procTimeDelta)" class="overwatch-body-xsmall" style="position: absolute; bottom: 5px; right: 5px; color: white; background-color: black; padding: 4px;">
          <div>FPS: {{procFPS}}</div>
          <div v-if="procDropped" style="color: var(--overwatch-error);">Frames Dropped: {{procDropped}}%</div>
          <div v-if="procTimeDelta" style="color: var(--overwatch-error);">Time Discrepancy: {{procTimeDelta}} minutes</div>
        </div>
      </div>
      <div class='camera-info' style="position: relative;">
        <div class="camera-info-child">
          <RocSwitch :isActive="camState" @switch-toggled="handleSwitchToggle" style=""/>
        </div>
        <div class="camera-info-child camera-name-field" :title="cameraName">{{ cameraName }} </div>
        <div class="camera-info-child">
          <MDBDropdown v-model='dropdownOptions' align='end'>
            <MDBDropdownToggle
                style='color:black;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    padding: 8px;'
                tag='a'
                @click="dropdownOptions = !dropdownOptions">
                <RocIcon color="black" size="sm" icon="kebab"/>
            </MDBDropdownToggle>
            <RocDropdownMenu aria-labelledby='dropdownMenuButton'>
               <MDBDropdownItem href="" @click.prevent>
                <div class="dropDownMenuItem" @click="moveClicked()">
                  Move to...
                  <RocIcon icon="annotation" color="black" size='sm'/>
                </div>
              </MDBDropdownItem>
              <MDBDropdownItem href="" @click.prevent>
                <div class="dropDownMenuItem" @click="editClicked()">
                  Edit
                <RocIcon icon="edit" color="black" size='sm'/>
                </div>
              </MDBDropdownItem>
              <hr class='dropdown-divider' v-if="isAdmin"/>
              <MDBDropdownItem href="" @click.prevent v-if="isAdmin" :disabled="camState">
                <div :style='deleteStyle()' class="dropDownMenuItem"
                 @click="isShowingDelete=true; dropdownOptions = !dropdownOptions">
                  Delete
                  <RocIcon icon="trash" color="red" size="sm"/>
                </div>
              </MDBDropdownItem>
            </RocDropdownMenu>
          </MDBDropdown>
        </div>
      </div>
      <div class='d-flex justify-content-center camera-url'>
        <div class="hide-text">{{ cameraUrl }}</div>
      </div>
    </div>
    <base-dialog v-if="isShowingDelete" :show="true" @close="isShowingDelete=false;" :style="deleteConfirmationStyle">
      <DeleteConfirmation
        @close="isShowingDelete=false"
        @delete="deleteClicked()"
        :message="'Yes'"
        :cancelMessage="'No'"
      >
      <div class="d-flex" style="margin-top: var(--spacing-m);">
        <RocIcon icon="trash" color="red" style="margin-right: var(--spacing-m);"/>
        <div>
          Are you sure you want to delete
          <div>
            <span style="text-decoration: underline;">{{cameraName}}</span>?
          </div>
        </div>
      </div>
      </DeleteConfirmation>
    </base-dialog>
     <RocMoveDialog
        v-if="showMoveElement"
        :itemName="cameraName"
        :moveToOptions="groupList"
        :currentlySelectedGroups="currentGroups"
        objType='Camera Name'
        @onClose="showMoveElement = !showMoveElement"
        @onSave="moveElement"
      />
      <RocToast v-if="displayToast" :key="displayToast" :message=message @autoClose="() => {displayToast = !displayToast}"/>
  </div>
</template>

<script>
import { MDBDropdown, MDBDropdownItem, MDBDropdownToggle } from "mdb-vue-ui-kit";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import BaseDialog from "@/components/ui/BaseDialog";
import DeleteConfirmation from "@/components/settings/DeleteConfirmation";
import RocSwitch from "@/components/ui/RocSwitch";
import RocIcon from "@/components/ui/RocIcon";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";
import RocMoveDialog from "@/components/ui/RocMoveDialog.vue";
import RocToast from "@/components/ui/RocToast.vue";

export default {
  name: 'CameraView',
  props: {
    cameraName: String,
    cameraUrl: String,
    cameraGroups: Array,
    cameraGuid: String,
    cameraState: Boolean, // Should the camera be running
    cameraOnline: Boolean, // Is the camera actually running
    preview: String,
    mode: String,
  },
  emits: ['cameraStateChange', 'editCamera','moveCamera'],
  components: {
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    BaseDialog,
    DeleteConfirmation,
    RocSwitch,
    RocIcon,
    RocDropdownMenu,
    RocMoveDialog,
    RocToast
  },
  setup(props, context) {
    const store = useStore();
    const procFPS = ref(0);
    const procDropped = ref(0);
    const procTimeDelta  = ref(0);
    const showMoveElement = ref(false);
    const displayToast = ref(false);
    const message = ref('');
    const currentGroups = ref(props.cameraGroups ?? []);

    const isShowingDelete = ref(false);

    const isAdmin = computed(() => {
      return store.getters['auth/isAdmin'];
    });

    let socket;
    onMounted(async () => {
      socket = await store.dispatch("auth/getSocketIO", `feed=livestream&topic=${props.cameraGuid}`);
      socket.on(props.cameraGuid, (payload) => {
        cameraPreview.value = 'data:image/png;base64, ' + payload.image;
      });

      socket.on('disconnect', async (reason) => {
        camOnline.value = false;
        if (reason === "io server disconnect" || reason === "ping timeout") {
          console.log("socket.io disconnected.");
        }
      });
    });

    let statsSocket;
    onMounted(async () => {
      statsSocket = await store.dispatch("auth/getSocketIO", `feed=livestats&topic=${props.cameraGuid}`);
      statsSocket.on(props.cameraGuid, (payload) => {
          camOnline.value = payload.cameraStats.enabled;
          procFPS.value = payload.cameraStats.fps.toFixed(1);
          const dropPercent = (payload.cameraStats.frames_processed > 0) ? (100 * payload.cameraStats.frames_dropped / (payload.cameraStats.frames_processed + payload.cameraStats.frames_dropped)) : 0;
          if ((payload.cameraStats.frames_dropped < 50) || (dropPercent < 5.0))
            procDropped.value = 0.0; // Round down if we've only dropped a few frames
          else
            procDropped.value = dropPercent.toFixed(1);

          const timeDiscrepancyMinutes = (Date.now() - payload.cameraStats.current_time) / 60000.0;
          if (timeDiscrepancyMinutes < 5)
            procTimeDelta.value = 0.0; // Round down if we're within 5 minutes
          else
            procTimeDelta.value = timeDiscrepancyMinutes.toFixed(1);
      });
    });

    onBeforeUnmount(() => {
      socket.close();
      statsSocket.close();
    });

    const windowWidth = ref(window.innerWidth);

    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });
    });

    const groupList = computed(() => {
      return Array.from(store.getters['cameras/getAllCameraGroups']);
    })

    const cameraPreview = ref(null);
    if(!props.preview) {
      cameraPreview.value = require('@/assets/liveview-placeholder.png');
    } else {
      cameraPreview.src = props.preview;
    }

    const camState = ref(props.cameraState);
    const camOnline = ref(props.cameraOnline);
    const dropdownOptions = ref(false);

    //accommodates multiple camera views of the same camera
    watch(() => props.cameraState, nv => {
      camState.value = nv;
    })

    const cameraPreviewSrc = computed(function() {
      if(cameraPreview.src !== props.preview) {
        cameraPreview.src = props.preview;
      }
      return (cameraPreview.value ? cameraPreview.value : cameraPreview.src);
    });

    function editClicked() {
      dropdownOptions.value = false;
      context.emit('editCamera', props.cameraGuid);
    }

    function moveClicked(){
      dropdownOptions.value = false;
      currentGroups.value = store.getters['cameras/findByGUID'](props.cameraGuid).cameraGroups;
      showMoveElement.value = true;
    }

    function arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    }

    function moveElement(destination) {
      showMoveElement.value = false;

      if(!arraysEqual(destination.slice(0),currentGroups.value))
      {
        let payload = {GUID: props.cameraGuid, updates: {cameraGroups : destination}};
        store.dispatch("cameras/updateCameraByUUID", payload).then((result) => {
          if(result == 0){
              message.value = props.cameraName + ' has been moved to ' + destination;
              displayToast.value = true;
          }
        });
      } else {
        console.log("No Changes detected")
      }
    }

    async function deleteClicked() {
      if(camState.value) //camera must be stopped to delete
      {
        console.log("camera must be stopped to delete.");
        return;
      }

      try {
        await store.dispatch("cameras/deleteCamera", props.cameraGuid);
      }
      catch(error) {
        error.value = error.message || 'Something went wrong!';
      }
      dropdownOptions.value = false;
    }

    function activeStyle() {
      return camState.value ? "max-width: 446px; max-height: 251px; width: auto; height: auto; overflow: hidden;"
                            : "max-width: 446px; max-height: 251px; width: auto; height: auto; opacity: 0.4; overflow: hidden;";
    }

    function deleteStyle() {
      if(camState.value) {
        return "opacity: .4;";
      }
      else {
        return "";
      }
    }

    const deleteConfirmationStyle = computed(() => {
      if (windowWidth.value <= 480) {
        // Mobile style
        return {
          width: '90%'
        }
      }
    });

    function missingPreview(e) {
      cameraPreview.value = require('@/assets/liveview-placeholder.png');
    }

    async function handleSwitchToggle(t) {
      camState.value = t;

      try {
        const result = await store.dispatch('cameras/toggleCameraState', {
          enabled: t,
          GUID: props.cameraGuid,
        });
        if (result.status === "success") {
          camOnline.value = t;
        }
      } catch (error) {
        error.value = error.message || 'Something went wrong!';
      }
    }

    return {
      isAdmin,
      cameraPreviewSrc,
      camState,
      camOnline,
      activeStyle,
      editClicked,
      moveClicked,
      dropdownOptions,
      deleteClicked,
      deleteStyle,
      procFPS,
      procDropped,
      procTimeDelta,
      isShowingDelete,
      deleteConfirmationStyle,
      missingPreview,
      handleSwitchToggle,
      groupList,
      moveElement,
      showMoveElement,
      displayToast,
      message,
      currentGroups
    };
  }
};
</script>

<style scoped lang="scss">
.loading-spinner {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 1000;
}

.camera-card {
  box-sizing: border-box;
  height: 316px;
  width: 448px;
   box-shadow: 0 0.5px 2px 0 rgba(0,0,0,0.25);
  border-radius: 6px;
  background-color: var(--overwatch-secondary);
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
  margin-left: var(--spacing-s);
  display: flex;
  flex-direction: column;
}

.camera-info {
  flex: 1;
  display: flex;
  width: 100%;
  @include overwatch-body-med;
  color: #000000;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  align-items: center;
  padding: 0 var(--spacing-m);
}

.camera-info-child:has(.dropdown-toggle){
  margin-right: -8px; //adjust for kebab padding
}

.camera-info-child {
  justify-content: center;
  align-items: center;
}

.camera-name-field {
  flex: 2;
  text-align: center;
  @include overwatch-body-large;
  color: var(--overwatch-neutral-100);
}

.dropDownMenuItem {
  display: flex;
  justify-content: space-between;
}

.camera-url {
  font-size: 12px;
  opacity: 0.4;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
}

.lds-roller-white div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white !important;
  margin: -4px 0 0 -4px;
}

.dropdown-toggle:after {
  display: none;
}

.hide-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

div:hover {
  white-space: unset;
  text-overflow: unset;
}

@media (max-width: 480px) {
  .camera-card {
    height: auto;
    margin: 0;
    margin-left: var(--spacing-xs);
    max-width: 400px;
  }

  img {
    width: 100%;
    height: auto;
  }
}

</style>
