<template>
  <div class="encounters-grid">
    <div class="card-wrapper" v-for="encounter of encounters"
      :key="encounter._id"
      :class="{associatesViewMode: associatesViewMode}"
    >
      <ClusterEncounterCard
        :associatesViewMode="associatesViewMode"
        :encounter="encounter"
        @move-new="$emit('move-new', [encounter])"
        @move-existing="$emit('move-existing', [encounter])"
        @set-cover="$emit('set-cover', encounter)"
        @check="c => handleSelectionChanged(c, encounter)"
      />
      <!-- TODO: Refactor above functions to emit properly -->
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import ClusterEncounterCard from '@/components/clusters/ClusterEncounterCard';

export default {
  name: 'ClusterEncountersGrid',
  components: {
    ClusterEncounterCard
  },
  props: {
    associatesViewMode: {
      type: Boolean,
      default: false
    },
    encounters: {
      type: Array,
      default: []
    }
  },
  emits: ['move-new', 'move-existing', 'set-cover', 'selection-changed'],
  setup(props, context) {
    const selectedEncounters = ref([]);

    function handleSelectionChanged(isSelected, encounter) {
      if (isSelected) {
        selectedEncounters.value.push(encounter);
      } else {
        const index = selectedEncounters.value.findIndex(e => e===encounter);
        selectedEncounters.value.splice(index, 1);
      }
    }

    watch(selectedEncounters, nv => {
      context.emit('selection-changed', nv)
    }, {deep: true});

    return {
      selectedEncounters,
      handleSelectionChanged
    }
  }
}

</script>

<style scoped lang="scss">
.encounters-grid {
  max-height: 350px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: $--spacing-s;
  overflow-y: auto;
}

.card-wrapper {
  height: 144px;
  width: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-wrapper.associatesViewMode {
  height: 152px;
  width: 102px;
}

</style>