<script setup>
import { ref, onMounted, computed, defineEmits } from 'vue';
import { ValidateImage } from '../../js/fileHelper';
import RocCheckboxVue from '../ui/RocCheckbox.vue';
import RocIcon from '../ui/RocIcon.vue';
import RocButton from '../ui/RocButton.vue';
import { useStore } from 'vuex';

const store = useStore();

const emits = defineEmits(['close'])

const exts = ['png', 'gif', 'jpeg', 'jpg', 'bmp', 'svg'];

const isWhiteLabelEnabled = ref(false);
const canWhiteLabel = ref(false);
const logo = ref(null);
const logoError = ref(null);
const removeLogo = ref(false);
const isDrop = ref(false);
const loading = ref(false);
const darkMode = ref(store.getters['settings/getDarkMode']);

const isDisabled = computed(() => !canWhiteLabel.value || !isWhiteLabelEnabled.value );

onMounted(async () => {
    const preAuthConfig = store.getters['auth/preAuthConfig'];
    canWhiteLabel.value = store.getters['settings/getLicenseFlags'].whitelabel;
    if (preAuthConfig) {
      logo.value = preAuthConfig.logoImageBase64;
      removeLogo.value = preAuthConfig.hideLogo;
      isWhiteLabelEnabled.value = preAuthConfig.isWhiteLabelEnabled;
    }
});

/**
* @function handleLogo
* @param {File | DataTransferItem} file
*/
function handleLogo(file){
    logoError.value = null;
    const validator = new ValidateImage(file, {
        minSize: 0,
        maxSize: 1000,
        minWidth: 0,
        maxWidth: 1000,
        minHeight: 0,
        maxHeight: 1000,
        exts,
    })
    validator.validateBase64().then(res => {
        if(res.hasOwnProperty('isValid')){
            logoError.value = res.msg;
        } else {
            logo.value = res.src;
        }

    })
    .catch(err => {
        console.log(err.message);
        logoError.value = 'Something went wrong!';
    })
}

/**
* @function setDarkMode
* @param {DarkMode} value
*/
function setDarkMode(value) {
    darkMode.value = value;
}

/**
* @function saveSettings
*/
function saveSettings(){
    loading.value = true;
    if(isWhiteLabelEnabled.value === false || removeLogo.value === true) logo.value = null;
    const payload = {
        ...store.getters['auth/preAuthConfig'],
        isWhiteLabelEnabled: isWhiteLabelEnabled.value,
        logoImageBase64: (isWhiteLabelEnabled.value === false || removeLogo.value === true) ? null : logo.value,
        hideLogo: removeLogo.value,
        darkMode: darkMode.value
    };

    store.commit("settings/setDarkMode", darkMode.value);
    store.commit('auth/setUserSettingsChanged', Date.now());

    store.dispatch('settings/updatePreAuthConfig', payload)
    .then(() => {
        store.commit('auth/setPreAuthConfig', payload);
        loading.value = false;
        emits('close');
    });
}

async function upload(){
  if(isDisabled.value) return;
  const handles = await createFilePicker();
  if(handles.length > 0){
    const file = await handles[0].getFile();
    handleLogo(file);
  }
}

/**
* @async
* @function process
* @param {DragEvent} e
*/
async function process(e){
  const items = (e.dataTransfer.items) ? e.dataTransfer.items : e.dataTransfer.files;
  let file = items[0];
  file = (file.kind === 'file') ? file.getAsFile() : file;
  handleLogo(file);
}


/**
* @function createFilePicker
* @returns {Promise<any>}
*/
function createFilePicker(){
    return new Promise((resolve) => {
        const picker = document.createElement('input');
        picker.type = 'file';
        picker.webkitdirectory = false;
        picker.accept = 'image/*'

        picker.addEventListener('change', () => {
            resolve(
                [...picker.files].map((file) => {
                    return {
                        getFile: async () => new Promise((resolve) => {
                            resolve(file);
                        })
                    }
                })
            )
        })

        picker.click();
    })
}


/**
* @function border
* @returns {string}
*/
function border(){
      if(isDrop.value === true && !isDisabled.value){
          return '1px dashed #D6D6D6';;
      } else {
          return '1px solid #D6D6D6';
      }
}

/**
* @function dragover
* @param {Event} e
*/
function dragover(e){
  e.preventDefault();
  isDrop.value = true;
}


/**
* @function dragleave
* @param {Event} e
*/
function dragleave(e){
  e.preventDefault();
  isDrop.value = false;
}


/**
* @async
* @function drop
* @param {DragEvent} e
*/
async function drop(e){
  e.preventDefault();
  await process(e);
}

function cleanupLogo(isWhiteLabel, hideLogo){
    if(isWhiteLabel === false){
        removeLogo.value = false;
        logo.value = null;
    }
    if(hideLogo === true){
        logo.value = null;
    }
}

</script>

<template>
    <div style="display: flex; flex-direction: column; gap: var(--spacing-s)" v-if="!loading">
      <div class="d-flex flex-column">
          <div>Upload Logo</div>
        <div
            :style="{
                border: border(),
                cursor: isDisabled ? 'not-allowed' : 'pointer'
            }"
            :class="{
              'dropbox': true,
              'dropbox-disabled': isDisabled,
            }"
            @dragover.prevent="dragover"
            @dragleave.prevent="dragleave"
            @drop.prevent="drop"
            @click="upload"
        >
            <RocIcon icon="upload" size="lg" color="primary" />
            <p>Drop logo here or Click here to upload</p>
        </div>

        <div class="box" v-if="logo !== null">
          <img v-if="logo !== null && logoError === null" :src="logo" class="logo"/>
        </div>
          <p v-if="logoError !== null" style="color: var(--overwatch-error)">
            {{ logoError }}
          </p>
      </div>

      <div class="d-flex flex-column">
        <RocCheckboxVue
         :disabled="!canWhiteLabel"
          label="Enable White labeling"
          v-model="isWhiteLabelEnabled"
          @input="$event => cleanupLogo($event.target.checked, removeLogo)"
        />

        <RocCheckboxVue
         :disabled="isDisabled"
          label="Hide Branding"
          v-model="removeLogo"
          @input="$event => cleanupLogo(isWhiteLabelEnabled, $event.target.checked)"
        />
      </div>

      <div style="color: var(--overwatch-neutral-100)">
        Theme
    </div>
    <div style="display: flex; gap: var(--spacing-s)">
        <div class="d-flex flex-column">
            <img src='@/assets/Light_Mode_Select.svg'
            @click="setDarkMode(false)"
            style="cursor: pointer; border-radius: 5px;"
            :style="{ border: darkMode === false ? '2px solid var(--overwatch-button-primary)' : 'none' }"
            >
            <div class="d-flex flex-row align-items-center" style="gap: var(--spacing-base)">
                <RocIcon
                v-if="darkMode === false"
                color="primary"
                size="sm"
                icon="check"/>
                <div :style="{ color: darkMode === false ? 'var(--overwatch-button-primary)' : 'var(--overwatch-neutral-100)' }">
                    Light
                </div>
            </div>
        </div>
        <div class="d-flex flex-column">
            <img src='@/assets/Dark_Mode_Select.svg'
            @click="setDarkMode(true)"
            style="cursor: pointer; border-radius: 7px;"
            :style="{ border: darkMode === true ? '2px solid var(--overwatch-button-primary)' : 'none' }"
            >
            <div class="d-flex flex-row align-items-center" style="gap: var(--spacing-base)">
                <RocIcon
                v-if="darkMode === true"
                color="primary"
                size="sm"
                icon="check"/>
                <div :style="{ color: darkMode === true ? 'var(--overwatch-button-primary)' : 'var(--overwatch-neutral-100)' }">Dark</div>
            </div>
        </div>
    </div>
    
      <div class="d-flex justify-content-end" style="gap: var(--spacing-s)">
          <RocButton type="secondary" @click="emits('close')">Cancel</RocButton>
          <RocButton @click="saveSettings">Save</RocButton>
      </div>
    </div>
      <div v-else>
              <roc-spinner/>
      </div>
  </template>

<style scoped>
 .logo {
        width: 300px;
        max-height: 150px;
        object-fit: contain;
    }

    .cancel {
        background-color: gray;
    }

    .box {
        margin: 0.5rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .dropbox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 7rem;
        border-radius: 5px;
        background-color: var(--roc-global-accent);
        padding: 0.5rem;
    }

    .dropbox-disabled {
      opacity: 0.5;
    }
</style>
