<template>
  <roc-spinner v-if="isLoading"/>
  <div v-else>
    <div class="d-flex flex-row justify-content-between" style="margin-top: var(--spacing-m); margin-bottom: var(--spacing-m);">
      <RocPill leftText="Export" rightText="Import" :activeSide="viewingExport ? 'left': 'right'" @pill-toggled="viewingExport = !viewingExport"></RocPill>
      <div v-if="!viewingExport">
        <RocButton @click="uploadRef.click()" :type="'secondary'">
          <RocIcon icon="upload" color="white" size="sm"/>
          <span style="margin-left: var(--spacing-s);">Upload</span>
        </RocButton>
        <input
          ref="uploadRef"
          type="file"
          style="display: none"
          @change="uploadFile($event)"
          accept="*/*"
        />
      </div>
    </div>
    <div v-if="viewingExport">
      <div class="sectionTitle d-flex align-items-center">
        <span class="overwatch-title-small">Exportable Entities</span>
        <span class="overwatch-title-xsmall" style="color: var(--overwatch-neutral-200)">Select items to export</span>
      </div>
      <div class="d-flex flex-column">
        <div class="exportActions">
          <div
            v-for="entity in exportableEntities"
            class="d-flex flex-row align-items-center"
          >
            <RocCheckbox
              :model-value="selectedEntityTypes.includes(entity.entityType)"
              @update:model-value="c => toggleEntitySelected(entity.entityType)"
            />
            <span class="overwatch-body-med" style="margin-left: var(--spacing-xs)">{{ entity.label }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row align-items-end" style="margin-top: var(--spacing-l); gap: var(--spacing-l);">
        <RocButton :disabled="!selectedEntityTypes.length" @click="requestExport()">Export</RocButton>
        <div class="actionLink" @click="selectAll()">Select All</div>
        <div class="actionLink" @click="deselectAll()">Clear All</div>
      </div>
      <div class="overwatch-title-small sectionTitle" style="margin-top: var(--spacing-l);">Available Exports</div>
      <div v-if="!isDeletingAll" class="exportList">
        <div v-if="availableExports && availableExports.length" v-for="exportedEntity in availableExports" :key="exportedEntity._id">
          <div class="migrationEntry">
            <div class="overwatch-body-med exportEntryFilePath" style="flex: 1.5;">{{ getFilename(exportedEntity.filePath) }}</div>
            <div class="overwatch-body-med" style="flex: 0.5; text-align: right; color: var(--overwatch-neutral-200);">{{ getDisplayByteSize(exportedEntity.sizeInBytes) }}</div>
            <div class="d-flex flex-row flex-grow-1">
              <RocIcon v-if="exportedEntity.status === 'complete'" icon="check" color="primary" />
              <div v-else-if="exportedEntity.status === 'error'" class="d-flex flex-row align-items-center" style="gap: var(--spacing-base);">
                <RocIcon icon="error" color="red" />
                <div class="overwatch-body-med inlineErrorMsg" style="color: var(--overwatch-error);" :title="exportedEntity.errorMsg">{{ exportedEntity.errorMsg }}</div>
              </div>
              <div v-else-if="exportedEntity.status === 'processing' || exportedEntity.status === 'pending'" class="d-flex align-items-start justify-content-start">
                <roc-spinner size="sm" style="margin: 0 !important;"/>
              </div>
            </div>
            <div class="exportEntryButtons" style="flex: 0.4;">
              <div v-if="exportedEntity.status === 'complete'" style="cursor: pointer;" @click="downloadExport(exportedEntity._id)">
                <RocIcon icon="download" size="sm" color="primary"/>
              </div>
              <div v-if="exportedEntity.status === 'complete'" style="cursor: pointer;">
                <RocPopper placement="top" :popperType="'tooltip'" hover arrow :locked="true" offsetDistance="4">
                  <RocIcon icon="view" size="sm" color="black" />
                  <template #content>
                    {{ getLabelFromEntityTypes(exportedEntity.entityTypes) }}
                  </template>
                </RocPopper>
              </div>
              <div v-if="exportedEntity.status != 'processing'" style="cursor: pointer;" @click="prepareDeleteOne(exportedEntity)">
                <RocIcon icon="exit" size="sm" color="black"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <roc-spinner v-else/>
    </div>
    <div v-else>
      <div class="sectionTitle d-flex justify-content-between align-items-end">
        <span class="overwatch-title-small">Imports</span>
      </div>
      <div v-if="!isDeletingAll" class="exportList">
        <div v-if="allImports && allImports.length" v-for="importEntity in allImports" :key="importEntity._id">
          <div v-if="importEntity.uploading" class="migrationEntry">
            <div class="overwatch-body-med importEntryFilePath" style="flex: 1;">{{ getFilename(importEntity.filePath) }}</div>
            <div class="d-flex flex-column" style="gap: var(--spacing-base); flex: 1;">
              <ProgressBar :value="importEntity.progress ?? 0" style="width: 100%;"/>
              <div class="overwatch-body-small" style="color: var(--overwatch-neutral-100);">{{ getMbSize(importEntity.loaded) }} / {{ getMbSize(importEntity.total) }}MB</div>
            </div>
            <div class="exportEntryButtons flex-shrink-1">
              <div v-if="importEntity.progress < 100" @click="cancelFileUpload(importEntity)" style="cursor: pointer;">
                <RocIcon icon="exit" size="sm" color="black"></RocIcon>
              </div>
            </div>
          </div>
          <div v-else class="migrationEntry">
            <div class="overwatch-body-med importEntryFilePath" style="flex: 2">{{ getFilename(importEntity.filePath) }}</div>
            <div v-if="importEntity.status === 'processing'" class="d-flex flex-column" style="gap: var(--spacing-base);  flex: 1;">
              <ProgressBar :value="importEntity.progress ?? 0" style="width: 100%;"/>
              <div class="overwatch-body-small" style="color: var(--overwatch-neutral-100);">Processing...</div>
            </div>
            <div
              v-if="importEntity.status === 'complete' || importEntity.status === 'error' || importEntity.status === 'warning'"
              class="d-flex align-items-center justify-content-start"
              style="gap: var(--spacing-base); flex: 1;"
            >
              <RocIcon v-if="importEntity.status === 'complete'" icon="check" color="primary" />
              <RocPopper
                v-else-if="importEntity.status === 'error' || importEntity.status === 'warning'"
                placement="top"
                :popperType="'tooltip'"
                hover
                arrow
                :locked="true"
                offsetDistance="4"
              >
                <RocIcon v-if="importEntity.status === 'error'" icon="error" color="red" />
                <RocIcon v-else icon="unknown" color="gray" />
                <template #content>
                  <div class="popperErrorHolder">
                    <div v-for="errorMsg in importEntity.errorList">
                      <div class="popperErrorEntry" :title="parseErrorMsg(errorMsg)">{{ parseErrorMsg(errorMsg) }}</div>
                    </div>
                  </div>
                </template>
              </RocPopper>
              <div
                class="overwatch-body-med inlineErrorMsg"
                :style="'color: ' + importEntity.status === 'error' ? 'var(--overwatch-error);' : null"
                :title="importEntity.statusMsg"
              >
                {{ importEntity.statusMsg }}
              </div>
            </div>
            <div
              v-if="importEntity.status === 'complete' || importEntity.status === 'error' || importEntity.status === 'warning'"
              class="exportEntryButtons flex-shrink-1"
            >
              <div style="cursor: pointer;">
                <RocPopper
                  placement="top"
                  :popperType="'tooltip'"
                  hover
                  arrow
                  :locked="true"
                  offsetDistance="4"
                >
                  <RocIcon icon="view" size="sm" color="black" />
                  <template #content>
                    <div class="popperMetricsHolder">
                      <div v-for="metricMsg in getDisplayMetrics(importEntity)">
                        <div class="popperMetricsEntry">{{ metricMsg }}</div>
                      </div>
                    </div>
                  </template>
                </RocPopper>
              </div>
              <div @click="prepareDeleteOneImport(importEntity)" style="cursor: pointer;">
                <RocIcon icon="exit" size="sm" color="black"></RocIcon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <roc-spinner v-else/>
    </div>
    <div v-if='!isLoading' class="buttonGroup">
      <div class="flex-grow-1">
        <div v-if="viewingExport ? (availableExports && availableExports.length) : (availableImports && availableImports.length)" @click="isConfirmingDeleteAll = true;" class="actionLink">
          Clear All
        </div>
      </div>
    </div>
  </div>
  <base-dialog v-if="isConfirmingDeleteAll" :show="true" title="Confirm Deletion" @close="isConfirmingDeleteAll = false;" :style="confirmationStyle">
    <Confirmation @close="isConfirmingDeleteAll = false" @yes="deleteAll()" >
      Are you sure you want to delete all {{viewingExport ? 'exports' : 'imports'}}?  This action cannot be undone.
    </Confirmation>
  </base-dialog>
  <base-dialog v-if="isConfirmingDeleteOne" :show="true" title="Confirm Deletion" @close="isConfirmingDeleteOne = false;" :style="confirmationStyle">
    <Confirmation @close="isConfirmingDeleteOne = false" @yes="deleteExport(deletingExport._id)" >
      <span class="dialogMessage">Are you sure you want to delete this export from [{{ moment(deletingExport.createdAt).format('MMMM Do YYYY, h:mm:ss a') }}]?<br>This action cannot be undone.</span>
    </Confirmation>
  </base-dialog>
  <base-dialog v-if="isConfirmingDeleteOneImport" :show="true" title="Confirm Deletion" @close="isConfirmingDeleteOneImport = false;" :style="confirmationStyle">
    <Confirmation @close="isConfirmingDeleteOneImport = false" @yes="deleteImport(deletingImport._id)" >
      <span class="dialogMessage">Are you sure you want to delete this import from [{{ moment(deletingImport.createdAt).format('MMMM Do YYYY, h:mm:ss a') }}]?<br>This action cannot be undone.</span>
    </Confirmation>
  </base-dialog>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, defineEmits } from 'vue';
import RocButton from '@/components/ui/RocButton.vue';
import RocIcon from '@/components/ui/RocIcon.vue';
import Confirmation from "@/components/settings/Confirmation.vue";
import RocCheckbox from "@/components/ui/RocCheckbox.vue";
import RocPill from "@/components/ui/RocPill.vue";
import ProgressBar from '@/components/ui/ProgressBar';
import RocPopper from "@/components/ui/RocPopper";
import { useStore } from 'vuex';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';

const store = useStore();

const emits = defineEmits(['close'])

const exportableEntities = ref([]);
const selectedEntityTypes = ref([]);
const availableExports = ref([]);
const isConfirmingDeleteOne = ref(false);
const isConfirmingDeleteOneImport = ref(false);
const deletingExport = ref(null);
const deletingImport = ref(null);
const isConfirmingDeleteAll = ref(false);
const windowWidth = ref(window.innerWidth);
const isLoading = ref(false);
const uploadRef = ref(null);
const availableImports = ref([]);
const viewingExport = ref(true);
const isDeletingAll = ref(false);
const activeUploads = ref([]);
const allImports = computed(() => {
  return activeUploads.value.concat(availableImports.value);
});
let reloadTimer;

onMounted(async () => {
  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth;
  });
  isLoading.value = true;
  exportableEntities.value = await store.dispatch('settings/getObjectValueByKey', 'export_entities');
  await reloadData();
  reloadTimer = setInterval(() => reloadData(), 1000);
  isLoading.value = false;
});

onUnmounted(() => {
  if (reloadTimer) {
    clearInterval(reloadTimer);
  }
});

async function reloadData() {
  await loadExports();
  await loadImports();
}

async function loadExports() {
  availableExports.value = await store.dispatch('settings/getEntityExports');
}

async function loadImports() {
  availableImports.value = await store.dispatch('settings/getEntityImports');
}

async function requestExport() {
  isLoading.value = true;
  if (selectedEntityTypes.value.length) {
    const response = await store.dispatch('settings/requestEntityExport', {
      entityTypes: selectedEntityTypes.value
    });
  }
  selectedEntityTypes.value = [];
  await loadExports();
  isLoading.value = false;
}

async function downloadExport(id) {
  window.location.href = `/rest/v1/migration/export/${id}`;
}
function prepareDeleteOne(exportedEntity) {
  deletingExport.value = exportedEntity;
  isConfirmingDeleteOne.value = true;
}
function prepareDeleteOneImport(importedEntity) {
  deletingImport.value = importedEntity;
  isConfirmingDeleteOneImport.value = true;
}
async function deleteExport(id) {
  const response = await store.dispatch('settings/deleteEntityExport', {id: id});
  await loadExports();
}
async function deleteImport(id) {
  const response = await store.dispatch('settings/deleteEntityImport', {id: id});
  await loadImports();
}
async function deleteAll() {
  isDeletingAll.value = true;
  if (viewingExport.value) {
    const response = await store.dispatch('settings/deleteEntityExport', {id: null, deleteAll: true});
    await loadExports();
  } else {
    const response = await store.dispatch('settings/deleteEntityImport', {id: null, deleteAll: true});
    await loadImports();
  }
  isDeletingAll.value = false;
}

const confirmationStyle = computed(() => {
  if (windowWidth.value <= 480) {
    // Mobile style
    return {
      width: '100%'
    };
  }
});

function getLabelFromEntityTypes(entityTypes) {
  return entityTypes.join(", ");
}

function getFilename(filePath) {
  return filePath ? filePath.replace(/^.*[\\/]/, '') : '';
}

function getDisplayByteSize(bytes) {
  if (!bytes) {
    return "";
  }
  const sizeInKb = bytes / 1024;
  if (sizeInKb > 1024) {
    return `${(sizeInKb / 1024).toFixed(2)}MB`;
  } else {
    return `${sizeInKb.toFixed(2)}KB`;
  }
}

function getMbSize(bytes) {
  if (!bytes) {
    return '0';
  }
  const sizeInMb = ((bytes / 1024) / 1024);
  return `${sizeInMb.toFixed(2)}`;
}

function toggleEntitySelected(entityType) {
  if (!selectedEntityTypes.value.includes(entityType)) {
    selectedEntityTypes.value.push(entityType);
  } else {
    const entityToDelete = selectedEntityTypes.value.findIndex(entry => entry === entityType)
    selectedEntityTypes.value.splice(entityToDelete, 1);
  }
}

async function uploadFile(e) {
  const chosenFile = e.target.files[0];
  const controller = new AbortController();
  activeUploads.value.push({
    _id: uniqueId(),
    uploading: true,
    filePath: chosenFile.name,
    progress: 0,
    abortController: controller
  });
  const index = (activeUploads.value.length-1);
  const uploadProgress = (progress) => {
    const { total, loaded } = progress;
    activeUploads.value[index].total = total;
    activeUploads.value[index].loaded = loaded;
    activeUploads.value[index].progress = Math.round((loaded / total) * 100);
  }
  const response = await store.dispatch(
    "settings/requestEntityImport",
    {
      file: chosenFile,
      onUploadProgress: uploadProgress,
      abortController: controller.signal
    }
  );
  activeUploads.value.splice(index, 1);
}

function cancelFileUpload(importUpload) {
  importUpload.abortController.abort();
  const index = activeUploads.value.findIndex((entry) => entry._id === importUpload._id);
  if (index >= 0) {
    activeUploads.value.splice(index, 1);
  }
}

function parseErrorMsg(error) {
  return `${error.entityType} [${error.entry}]: ${error.errorMsg}`
}

function selectAll() {
  exportableEntities.value.forEach((entity) => {
    if (!selectedEntityTypes.value.includes(entity.entityType)) {
      selectedEntityTypes.value.push(entity.entityType);
    }
  });
}

function deselectAll() {
  selectedEntityTypes.value = [];
}

function getDisplayMetrics(importEntity) {
  const metrics = [];
  if (importEntity.metrics) {
    for (let metricKey of Object.keys(importEntity.metrics)) {
      if (importEntity.metrics[metricKey].count) {
        metrics.push(`${metricKey}: ${importEntity.metrics[metricKey].count}`);
      }
    }
    if (importEntity.metrics.enrollment) {
      for (let metricKey of Object.keys(importEntity.metrics.enrollment)) {
      if (importEntity.metrics.enrollment[metricKey].face?.count) {
        metrics.push(`Watchlist [${metricKey}] faces: ${importEntity.metrics.enrollment[metricKey].face.count}`);
      } else if (importEntity.metrics.enrollment[metricKey].identity?.count) {
        metrics.push(`Watchlist [${metricKey}] identities: ${importEntity.metrics.enrollment[metricKey].identity.count}`);
      } else if (importEntity.metrics.enrollment[metricKey].licenseplate?.count) {
        metrics.push(`Watchlist [${metricKey}] license plates: ${importEntity.metrics.enrollment[metricKey].licenseplate.count}`);
      }
    }
    }
  }
  return metrics;
}

</script>

<style scoped>

.exportActions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--spacing-s);
  width: 100%;
  max-height: 150px;
}
.exportList {
  display: flex;
  flex-direction: column;
  max-height: 256px;
  overflow-y: auto;
  gap: var(--spacing-base);
}
.migrationEntry {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-s);
  padding-left: var(--spacing-m);
  padding-right: var(--spacing-m);
  padding-top: var(--spacing-l);
  padding-bottom: var(--spacing-l);
  border: 1px solid var(--overwatch-neutral-400);
  border-radius: 4px
}
.exportEntryButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-s);
  justify-content: end;
}
.migrationEntry:hover {
  background-color: var(--overwatch-neutral-400);
}
.exportEntryFilePath {
  text-align: left;
  padding-right: var(--spacing-s);
}
.importEntryFilePath {
  text-align: left;
  padding-right: var(--spacing-s);
}
.sectionTitle {
  padding-bottom: var(--spacing-m);
  gap: var(--spacing-s);
}
.buttonGroup {
  margin-top: var(--spacing-s);
  justify-content: start;
  display: flex;
}
.actionLink {
  align-items: start;
  text-decoration: underline;
  cursor: pointer;
  color: var(--overwatch-button-primary);
  width: fit-content;
}
.entityTypeList {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.popperErrorHolder {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  gap: var(--spacing-base);
}
.popperErrorEntry {
  width: 50vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.popperMetricsHolder {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  gap: var(--spacing-base);
}
.popperMetricsEntry {
  max-width: 50vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.inlineErrorMsg {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 22ch;
}

@media (max-width: 480px) {
  .migrationEntry {
    flex-direction: column;
  }
  .popperErrorEntry {
    width: 90vw;
  }
}
</style>
