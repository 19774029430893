<template>
  <div class="wrapper">
    <header>
      <div style="font-size: 24px; margin-bottom: 16px;">
        {{ workflow.name }}<br>
        <span class="overwatch-body-med">Created {{ formattedDatetimeString }}</span>
      </div>
      <div style="display: flex; gap: var(--spacing-xl);">
        <div>
          <div class="overwatch-title-small">
            Type
          </div>
          <div class="overwatch-body-med">
            {{ type }}
          </div> 
        </div>
        <div>
          <div class="overwatch-title-small">
            Event
          </div>
          <div class="overwatch-body-med">
            {{ category.value }}
          </div>  
        </div>
      </div>
    </header>
    <div class="condition-group" v-for="conditionGroup of conditionGroups">
      <div class="row-div labels overwatch-title-small">
        <div style="margin-bottom: var(--spacing-base);flex:.8">
          Fact
        </div>
        <div style="margin-bottom: var(--spacing-base);flex:.5">
          Operator
        </div>
        <div style="margin-bottom: var(--spacing-base);flex:1">
          Value
        </div>
        <div style="position: absolute; top: 12px; right:12px;">
          {{ capitalize(Object.keys(conditionGroup)[0]) }}
        </div>
      </div>
      <div class="row-div values" v-for="condition of conditionsOfGroup(conditionGroup)" :key="condition.fact.property + condition.operator + condition.value">
        <div style="flex:.8">
          {{ condition.fact + condition.path.substring(1) }}
        </div>
        <div style="flex:.5">
          {{ condition.operator }}
        </div>
        <div style="flex:1">
          {{ condition.value }}
        </div>
      </div>
    </div>
    <div class="events">
      <div style="margin-top: var(--spacing-m); margin-bottom: var(--spacing-base);" class="overwatch-title-small">Actions</div>
      <div class="row-div">
      <div class="overwatch-body-med action-item" v-for="event of events" :key="event.name + event.config.value">
        {{ event.name }} {{ event.config.value }}
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {  
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownToggle,
} from 'mdb-vue-ui-kit';

export default {
  name: 'WorkflowDetail',
  components: {
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle
  },
  props: {
    workflow: Object
  },
  setup(props, context) {
    const store = useStore();

    const isMenuShowing = ref(false);

    const workflowConditionsObject = props.workflow.rule.conditions;

    // Sean: Fun fact, Vue won't know to update a prop's nested properties 
    // if they're not wrapped in a computed(). 
    const type = computed(() => { return props.workflow.type.name; })
    const category = computed(() => { return workflowConditionsObject.all[0]; });
    const conditionGroups = computed(() => {
      return workflowConditionsObject.all[1]?.any
    });
    const events = computed(() => { return props.workflow.rule.event.params; });

    const formattedDatetimeString = computed(() => {
      const datetime = new Date(Date.parse(props.workflow.createdUTC));
      return datetime.toLocaleString();
    })


    /**  */

    function conditionsOfGroup(conditionGroup) {
      if (Object.keys(conditionGroup).includes('any')) {
        return conditionGroup.any;
      } else {
        return conditionGroup.all;
      }
    }
    
    function capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

    function editWorkflow() {
      isMenuShowing.value = false;
      context.emit('edit');
    }

    function deleteWorkflow() {
      isMenuShowing.value = false;
      context.emit('delete');
    }

    return {
      type,
      category,
      conditionGroups,
      isMenuShowing,
      conditionsOfGroup,
      capitalize,
      events,
      deleteWorkflow,
      editWorkflow,
      formattedDatetimeString
    }
  }
}

</script>

<style scoped lang="scss">
.wrapper {
  margin: 12px;
  width: 60rem;
}
header {
  @include overwatch-title-small;
}
.row-div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;
}

.action-item{
  background-color: var(--overwatch-button-primary-20);
  color: var(--overwatch-button-primary);
  padding: 12px;
  border-radius: 5px;
  border: 1px solid var(--overwatch-button-primary);
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.condition-group {
  position: relative;
  border: 1px solid var(--overwatch-accent);
  border-radius: 5px;
  background: var(--overwatch-button-primary-20);
  margin-top: var(--spacing-s);
  padding: var(--spacing-s);
}

.label {
  @include overwatch-title-med;
}

.value {
  @include overwatch-body-med;
}

.condition-group .labels {
  @include overwatch-title-small;
}

.condition-group .values {
  @include overwatch-body-med;
}

</style>