<template>
  <div class="overwatch-body-large">
    <div style="margin-bottom: 16px;">
      <p style="margin-bottom: 4px">Label</p>
      <RocInput 
        :disabled="group ? group.isSysAdmin : false" 
        :errorMessage="validLabel" 
        :maxLength="60" 
        v-model="label" 
        @blur="validateFields($event,'label')"
      />
    </div>
    <!-- Site ID not fully implemented in a user friendly way to justify having UX input.  Currently each server shares a Site ID for import -->
    <!-- <div>
      <p>Site ID</p>
      <MDBInput v-model="siteId" />
    </div> -->
      <p style="margin-bottom: 4px">Notes</p>
      <RocTextArea rows="3" v-model="notes" :maxLength="150"/>
    <div style="margin-top: 10px;">
      <p>Add Users</p>
      <filterMultiSelect
        mode="tags" 
        :close-on-select="false"
        placeholder-text="Start typing to add users..." 
        :available-items="usersData"
        :currently-selected="currSelectedUsers" 
        @selection-changed="userGroupChange"
        :enableClear="false"
        :showError="userGroupError != undefined"
      />
      <p v-if="userGroupError" class="status">
         {{ userGroupError }}
      </p>
    </div>
    <div style="margin-top: 10px;">
      <div class="buttons">
        <div class="status">{{statusText}}</div>
        <RocButton type="secondary" @click="closeDialog">Cancel</RocButton>
        <RocButton @click="pressSave">Save</RocButton>
      </div>
    </div>
  </div>
  <!-- Users List -->

</template>

<script>
import { ref, computed, onMounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import { MDBCheckbox, MDBDatatable } from "mdb-vue-ui-kit";
import AddUsersToGroup from "@/components/settings/AddUsersToGroup.vue";
import filterMultiSelect from "@/components/ui/filterMultiSelect";
import RocButton from "@/components/ui/RocButton.vue";
import RocInput from "@/components/ui/RocInput.vue";
import RocTextArea from "@/components/ui/RocInput.vue";

export default {
  components: {
    MDBCheckbox,
    MDBDatatable,
    AddUsersToGroup,
    RocButton,
    RocInput,
    RocTextArea,
    filterMultiSelect
},
  props: {
    // is the user being edited. If null, consider it to be in "add user" mode.
    group: {
      type: Object,
    }
  },
  emits: ["close", "save"],

  setup(props, context) {

    const store = useStore();

    onMounted(async () => {
      await loadUsers();
      nextTick(() => {
        // Once page is loaded select the checkbox of current loggedin user and disable it.

        const currentAccountEmail = store.getters['auth/email'];
        const currentAccountCheckbox = getElementByXpath(`//tr[td[contains(text(), '${currentAccountEmail}')]]//input`);

        if (currentAccountCheckbox) {
          currentAccountCheckbox.setAttribute('disabled', 'true');
        }
      });
    });

    const isShowingAddUsersDialog = ref(false);
    const validLabel = ref(undefined);
    const userGroupError = ref(undefined);
    const label = ref(props.group ? props.group.label : '');
    const siteId = ref(props.group ? props.group.properties?.siteId : '');
    const users = ref(props.group ? props.group.users : []);
    const notes = ref(props.group ? props.group.notes : '');
    const currSelectedUsers = ref(props.group ? props.group.users.map((user) => user.firstname + ' ' + user.lastname) : []); //usernames of curr selected. 

    // this function loads all users, which returns users accessible by currently signed in user
    // we can then filter a userGroup's user list accordingly
    async function loadUsers() {
      try {
        const userResponse = await store.dispatch('settings/getUsers');
        if (userResponse?.status === 'success') {
          users.value = userResponse.users;
        }
      } catch(err) {
        console.log(err);
      }
    }

    //populates dropdown with list of all users available to select
    const usersData = computed(() => {
      return users.value.map((user) => {return user.firstname + ' ' +  user.lastname});
    });

    function userGroupChange(selectedItems){
      currSelectedUsers.value = selectedItems.value;
      userGroupError.value = ((selectedItems.value.length === 0) ? 'Select a User.' : undefined);
    }

    function closeDialog() {
      context.emit("close");
    }

    function validateFields(event,field){
      validLabel.value = ((event.trim() == '') ? 'Field must be filled.' : undefined);
    }

    const isSubmitting = ref(false);
    function pressSave() {
      if (props.group) {
        updateGroup();
      } else {
        createGroup();
      }
    }

    const statusText = ref("");
    async function createGroup() {
      try {
        const payload = {
          label: label.value,
          enabled: true,
          users: getPayloadUsers(),
          properties: {
            siteId: siteId.value
          },
          notes: notes.value
        }

        const responseData = await store.dispatch('settings/createUserGroup', payload);
        if (responseData && responseData.status === "success") {
          context.emit("save");
          context.emit("close");
        } else {
          statusText.value = responseData ? responseData.message : 'Unable to create group';
          // clear status text after a few seconds sort of like a Toast
          setTimeout(()=> {
            statusText.value = "";
          }, 3000);
        }
      } catch(err) {
        console.log(err);
      }
    }

    function getPayloadUsers(){
      let payloadUsers = users.value.filter((user) =>{
          if(currSelectedUsers.value?.includes(user.firstname + ' ' + user.lastname)){
            return user;
          }
      });
      return payloadUsers.map(x => x._id);
    }

    async function updateGroup() {
      try {
        const payload = {
          _id: props.group._id,
          label: label.value,
          users: getPayloadUsers(),
          properties: {
            siteId: siteId.value
          },
          notes: notes.value
        }

        const responseData = await store.dispatch('settings/updateUserGroup', payload);
        if (responseData && responseData.status === "success") {
          context.emit("save");
          context.emit("close");
        } else {
          statusText.value = responseData ? responseData.message : 'Unable to create group';
          // clear status text after a few seconds sort of like a Toast
          setTimeout(()=> {
            statusText.value = "";
          }, 3000);
        }
      } catch(err) {
        console.log(err);
      }
    }

    const rowsToDelete = ref([]);
    function updateRowsToDelete(rows) {
      rowsToDelete.value = rows;
    }

    function deleteRows() {
      const idArray = rowsToDelete.value.map(row => row._id);
      users.value = users.value.filter(u => {
        return !idArray.includes(u._id);
      })
      console.log('final value', users.value)
    }

    function getElementByXpath(path) {
      return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    }

    return {
      label,
      siteId,
      closeDialog,
      pressSave,
      isSubmitting,
      usersData,
      isShowingAddUsersDialog,
      statusText,
      updateRowsToDelete,
      deleteRows,
      users,
      notes,
      currSelectedUsers,
      userGroupChange,
      validLabel,
      userGroupError,
      validateFields
    };
  }
};
</script>

<style scoped lang="scss">
p {
  margin: 0 0 0 0;
}
.buttons {
  display: flex;
  padding-top: 1rem;
  justify-content: right;
  gap: var(--spacing-s);
}

.status {
  color: var(--overwatch-error);
}

.remove-button {
  width:40px; 
  margin-right: var(--spacing-s); 
  padding-left: var(--spacing-base); 
  padding-right: var(--spacing-base);
}

.usergroup-users-table {
  border-radius: 5px;
  overflow: hidden;
  background-color: transparent;
}


.usergroup-users-table :deep(input):checked {
  background-color: var(--overwatch-primary);
}

.usergroup-users-table :deep(input):checked:focus {
  background-color: var(--overwatch-primary);
}


@media (max-width: 480px) {
  select {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>

<style>

table.table.datatable-table{
  background-color: var(--overwatch-neutral-500);
}

.usergroup-users-table td, .usergroup-users-table th, .usergroup-users-table input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: var(--overwatch-neutral-100);
  border-bottom: 1px solid var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
}

.usergroup-users-table tr:last-child {
  height: auto !important;
}

.usergroup-users-table th.false {
  visibility: hidden;
}

/* style to hide user _id column */
.usergroup-users-table th:nth-child(2) {
  display: none;
}

.usergroup-users-table td:nth-child(2) {
  display: none;
}
</style>