<template>
    <div class="pill">
        <div class="inner-pill-container">
            <div class="inner-pill" :class="{ 'left': activePill === 'left', 'right': activePill === 'right' }"></div>
                <div class="inner-pill-text-container" :class="{ 'active': activePill === 'left' }"
                    @click="togglePill('left')">{{ leftText }}
                </div>
                <div class="inner-pill-text-container" :class="{ 'active': activePill === 'right' }"
                    @click="togglePill('right')">{{ rightText }}
                </div>
            </div>
        </div>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'RocPill', 
  components: {},
  emits:['pill-toggled'],
  props: {
    rightText: {
      type: String,
      default: 'Right Text'
    },
    leftText: {
      type: String,
      default: 'Left Text'
    },
    activeSide: {
        type: String,
        default: 'left'
    },
  },
  setup(props, context) {
    const leftText = ref(props.leftText);
    const rightText = ref(props.rightText);
    const activePill = ref(props.activeSide);

    function togglePill(value){
        if(value === 'left'){
            activePill.value = 'left';
            context.emit("pill-toggled", activePill.value);
        } else {
            activePill.value = 'right';
            context.emit("pill-toggled", activePill.value);
        }
    }

    return {
        togglePill,
        activePill,
        leftText,
        rightText
    }
  }
}

</script>
<style scoped lang="scss">
.pill{
    display: flex;
    padding: var(--spacing-base);
    background-color: var(--overwatch-background);
    border-radius: 50px;
    border: 1px solid var(--overwatch-neutral-300);
    @include overwatch-body-med;
    position: relative;
    width:300px;
}

    /* because of the absolute positioning, a container is necessary to maintain padding */
.inner-pill-container {
    height: 40px;
    border-radius: 50px;
    display: flex;
    position: relative;
    width: 100%;
}

.inner-pill {
    height: 100%;
    width: 50%;
    position: absolute;
    background-color: var(--overwatch-primary);
    color: var(--overwatch-button-text);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer; 
    transition: 0.1s ease-in-out;
}

.inner-pill.left{
    left: 0;
    right: 50%;
}

.inner-pill.right{
    left: 50%;
    right: 0;
}

.inner-pill-text-container{
    z-index: 1;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
    background-color: transparent;
}

.inner-pill-text-container.active{
    color: var(--overwatch-button-text);
}

@media (max-width: 480px) {
.pill{
    width:250px;
    margin-top: 25px;
    margin-left: 0px;
  }
}
</style>