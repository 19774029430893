class versionInfo {
    constructor() {
        this.version = "v2.2";
        this.revision = "d3a54bf2031778fea96e33af72ed2306d1f62d09";
        this.commitCount = "51";
        this.buildDate = "Wed Sep  4 15:04:32 UTC 2024";
        this.isProduction = !this.version.startsWith("{{"); // The script for building the production container will substitute the above values

        if (!this.isProduction) {
            // For dev environment determine version from git
            try {
                this.version = require('child_process')
                               .execSync('git rev-parse --abbrev-ref HEAD')
                               .toString().trim();
            } catch(err) {
                this.version = "DEV";
            }

            try {
                this.revision = require('child_process')
                                .execSync('git rev-parse HEAD')
                                .toString().trim();
            } catch(err) {
                this.revision = "DEV";
            }

            try {
                this.commitCount = require('child_process')
                                   .execSync('git rev-list --count origin/master..')
                                   .toString().trim();
            } catch(err) {
                this.commitCount = "DEV";
            }

            this.buildDate = new Date().toUTCString();
        }
    }
}

module.exports = versionInfo;
