<template>
  <div>
    <div v-if='isLoading'>
      <roc-spinner/>
    </div>
    <div class="d-flex justify-content-between" style="width: 100%; padding-top: 12px;">
      <div class="headline" style="position: relative;">
        <div class="d-flex align-items-center">
        <div style="margin-right: var(--spacing-s)">Delete Encounter Data</div>
        <RocPopper
          placement="bottom"
          :popperType="'tooltip'"
          hover
          arrow
        >
          <RocIcon size="sm" icon="tooltip" color="buttonPrimary" />
          <template #content>
            <div>Detections, Images and Videos</div>
          </template>
        </RocPopper>
      </div>
        <br />
        <div v-if='enabled'>
          <span class="settings-line">
            <div class="d-flex center" style="gap: var(--spacing-base); align-items: center;">
              older than
              <RocNumberInput
                :inputType="'number'"
                :numberInput="days"
                :min="0"
                :max="365"
                @number-input-change="updateDaysInput"
              />
              day(s)
            </div>
            <div class="checkboxdiv" style="margin-bottom: var(--spacing-base);">
              <RocCheckbox v-model="ignoreMatchedEncounter" /> Exclude Watchlist
              Matches
            </div>
            <p>(including all related images and video)</p>
          </span>
          <div class="myfooter">
            Learn more about
            <a
              href="http://www.rankone.io"
              target="_blank"
              style="text-decoration: underline;color: var(--overwatch-button-primary);"
              >privacy considerations related to FR here.</a
            >
          </div>
        </div>
      </div>
      <div style="margin-top: -5px">
        <RocSwitch :isActive="enabled" @switch-toggled="enableDisableSlider"/>
      </div>
    </div>
    <div class="d-flex justify-content-end" style="gap: var(--spacing-s); margin-top: var(--spacing-m);">
      <div>
        <RocButton @click="reset" type="secondary" size="dialogButton">Reset</RocButton>
      </div>
      <div>
        <RocButton type="primary" size="dialogButton" @click="postSave">Save</RocButton>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import RocPopper from '../ui/RocPopper.vue';
import RocIcon from '@/components/ui/RocIcon';
import RocSwitch from '../ui/RocSwitch.vue';
import RocCheckbox from '../ui/RocCheckbox.vue';
import RocNumberInput from '../ui/RocNumberInput.vue';
import RocButton from '../ui/RocButton';

export default {
  name: "retentionSettings",
  emits: ["close"],
  components: {
    RocCheckbox,
    RocPopper,
    RocIcon,
    RocSwitch,
    RocNumberInput,
    RocButton
  },
  setup(props, context) {
    const enabled = ref(true);
    const days = ref(7);
    const ignoreMatchedEncounter = ref(true);
    const isLoading = ref(false);
    const store = useStore();
    const retention = ref(null);

    onMounted(async ()=> {
      const result = await fetch();
      if(result.status === 'success') {
        retention.value = result.retention;
        enabled.value = retention.value.running;
        days.value = secondsToDays(retention.value.settings.durationSeconds);
        ignoreMatchedEncounter.value = retention.value.settings.ignoreMatchedEncounter;
      }
    });

    function secondsToDays(seconds) {
      return seconds / 86400;
    }

    function daysToSeconds(days) {
      return days * 86400;
    }

    function updateDaysInput(value) {
      return (days.value = value);
    }

    function enableDisableSlider(switchValue) {
      return (enabled.value = switchValue);
    }

    function reset() {
      enabled.value = true;
      days.value = 7;
      ignoreMatchedEncounter.value = true;
    }

    async function postSave() {
      const payload = {
        "enabled": enabled.value,
        "durationSeconds": daysToSeconds(days.value),
        "ignoreMatchedEncounter": ignoreMatchedEncounter.value,
      };

      const result = await store.dispatch(
        'settings/saveRetentionSettings',
        payload
      );
      if (result.status === 'success') {
        retention.value = result.retention;
        enabled.value = retention.value.running;
        days.value = secondsToDays(retention.value.settings.durationSeconds);
        context.emit('close');
      }
    }

    async function fetch() {
      isLoading.value = true;
      let result;
      try {
        result = await store.dispatch('settings/getRetentionSettings', {});
      } catch (error) {
        error.value = error.message || 'Something went wrong!';
      }
      isLoading.value = false;
      return result;
    }

    return {
      enabled,
      days,
      ignoreMatchedEncounter,
      reset,
      fetch,
      postSave,
      isLoading,
      retention,
      updateDaysInput,
      enableDisableSlider
    };
  }
};
</script>

<style scoped lang="scss">
.headline {
  @include overwatch-body-med;
}

.subheadline {
  @include overwatch-body-small;
}

.checkboxdiv {
  display: flex; 
  align-items: center; 
  gap: var(--spacing-s); 
  margin-top: var(--spacing-m);
  @include overwatch-body-small;
}

.settings-line {
  height: 20px;
  width: 39px;
  color: var(--overwatch-neutral-100);
 @include overwatch-body-small;
}

.myfooter {
  color: var(--overwatch-neutral-100);
  white-space: nowrap;
  @include overwatch-body-xsmall;
}

.saveSettingsButton {
  background: var(--overwatch-primary);

}

.reset {
  height: 40px;
  width: 45px;
  color: var(--overwatch-neutral-100);
  @include overwatch-body-small;
  text-align: right;
  vertical-align: middle;
  margin-right: 30px;
  cursor: pointer;
}

@media (max-width: 480px) {
  .myfooter {
    padding: 10px;
    padding-bottom: 0;
    margin-bottom: 0px;
  }
}
</style>

<style>
/* MOBILE */

/* Hacky: we need to define this in the non-scoped style sheet to apply to the built-in markdown checkbox label. */
@media (max-width: 480px) {
  .retention-checkbox {
    padding: 0 !important;
  }
  .retention-checkbox div {
    display: flex;
    gap: 5px;
    padding: 0;
    width: 100%;
  }
  .retention-checkbox input {
    margin: 0 !important;
    min-width: 18px;
  }
}
</style>
