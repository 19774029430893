<template>
  <BaseDialog v-if="isShowingSettings" show title="ROC Watch Server Info" @close="isShowingSettings=false;" style="z-index: 1000; width: 90%; overflow-y: auto;">
    <LoginSettings 
      @close="isShowingSettings=false;" 
      :editingConfig="currentEditingConfig"
      @delete="deleteConfig" 
      @save="saveConfig"
    />
  </BaseDialog>
  <div class="outer">
    <div v-if="presetServerList.length===0">
      No configurations set.
    </div>

    <div v-else>
      <span>Connecting to: <span>{{ currentActiveConfig ? currentActiveConfig.name : 'None' }}</span></span>
      <MDBListGroup class="list-group">
        <MDBListGroupItem v-for="config in presetServerList" 
          class="group-item"
          type="button"
          :color="currentActiveConfig ? ( currentActiveConfig.name===config.name ? 'success' : '') : ''"
          @click="selectActiveConfig(config)"
        >
          {{config.name}}
          <div class="button-group">
            <RocButton type="secondary" @click.stop="deleteConfig(config.id)">
              <RocIcon icon="trash" size="md" color="red"/>
            </RocButton>
            <RocButton @click.stop="editConfig(config)">
              <RocIcon icon="settings" size="sm"/>
            </RocButton>
          </div>
        </MDBListGroupItem>
      </MDBListGroup>
    </div>

    <RocButton @click="addConfig" style="margin-top: 10px;">
      Add Configuration
    </RocButton>
  </div>
</template>

<script>
import { MDBListGroup, MDBListGroupItem } from "mdb-vue-ui-kit";
import { ref, computed } from "vue";
import { useStore } from "vuex";

import BaseDialog from "@/components/ui/BaseDialog"
import LoginSettings from "@/components/settings/LoginSettings";
import { is } from "@babel/types";
import RocButton from "@/components/ui/RocButton";

export default {
  name: "ConfigListGroup",
  props: ["presetServerList"],
  components: {
    MDBListGroup,
    MDBListGroupItem,
    BaseDialog,
    LoginSettings,
    RocButton
  },
  setup(props, context) {
    const store = useStore();

    // TODO: Set active config 
    const currentActiveConfig = ref(null);
    if (store.getters['auth/serverName']) {
      currentActiveConfig.value = props.presetServerList.find(obj => obj.name === store.getters['auth/serverName']);
    }

    const currentEditingConfig = ref();

    const isShowingSettings = ref(false);

    function selectActiveConfig(config) {
      currentActiveConfig.value = config;

      store.commit("auth/setServerId", config.id)
      store.commit("auth/setServerName", config.name);
      store.commit("auth/setServerProtocol", config.protocol);
      store.commit("auth/setServerHost", config.host);
      store.commit("auth/setServerPort", config.port);
    }

    function editConfig(config) {
      currentEditingConfig.value = config;
      isShowingSettings.value = true;
    }

    function addConfig() {
      currentEditingConfig.value = null;
      isShowingSettings.value = true;
    }

    function saveConfig(payload) {
      const newList = [];
      
      for (var obj of props.presetServerList) {
        if (obj.id !== payload.id) {
          newList.push(obj);
        } else {
          newList.push(payload);
        }
      }

      if (!currentEditingConfig.value) {
        newList.push(payload);      // If there's nothing we're editing, just push to end of list
      }
      
      store.commit('auth/setPresetServers', newList);
    }

    function deleteConfig(id) {
      const newList = [
        ...props.presetServerList.filter(obj => obj.id !== id)
      ];

      store.commit('auth/setPresetServers', newList);

      // If config is the current active config, reset
      if (id === store.getters['auth/serverId']) {
        store.commit("auth/setServerId", '')
        store.commit("auth/setServerName", '');
        store.commit("auth/setServerProtocol", '');
        store.commit("auth/setServerHost", '');
        store.commit("auth/setServerPort", '');
      
        currentActiveConfig.value = null;
      }
    }

    return {
      currentActiveConfig,
      currentEditingConfig,
      isShowingSettings,
      selectActiveConfig,
      editConfig,
      addConfig,
      saveConfig,
      deleteConfig
    }
  }
}

</script>

<style scoped>
.list-group {
  width: 100%;

  margin-top: 10px;
}
.button-group{
  display: flex;
  flex-direction: row;

  margin: 5px 0 5px 0;
}

.button-group button:nth-child(2){
  background-color:white;
}

.group-item {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-right: 5px;

}
</style>