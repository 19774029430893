<template>
  <div>
    <div class="input-wrapper"
      @click="focusOnInput"
      :class="{
        'input-wrapper-disabled': disabled,
        'inputError': errorMessage
      }"
    >
      <slot></slot>
      <input
        ref="inputRef"
        :type="type"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="modelValue"
        :maxLength="maxLength ?? null"
        :tabindex="tabIndex > 0 ? tabIndex : null"
        @input="$emit('update:modelValue', $event.target.value)"
        @blur="$emit('blur',$event.target.value)"
        @focus="$emit('focus')"
      >
      <RocIcon v-if="errorMessage" icon="error" size="sm" color="red"/>
      <slot name="customIcon"></slot>
      <div class='clear-button' v-if="clearButton && !disabled" @click="clearInput()">
        <RocIcon color="black" size="xs" icon="exit"/>
      </div>
    </div>
    <span v-if="errorMessage" style="color: var(--overwatch-light-error);">{{ errorMessage }}</span>
  </div>
</template>

<script>
import { ref } from 'vue';
import RocIcon from '@/components/ui/RocIcon';

export default {
  name: 'RocInput',
  components: {
    RocIcon
  },
  props: {
    modelValue: {
      type: String
    },
    placeholder: {
      type: String,
      required: false
    },
    clearButton: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number
    },
    errorMessage:{
      type: String,
      required: false
    },
    tabIndex: Number
  },
  emits: ['update:modelValue','focus','blur'],
  setup(props, context) {
    const inputRef = ref();
    const cursor = ref(props.disabled ? 'unset' : 'text');
    function focusOnInput() {
      inputRef.value.focus();
    }

    function clearInput() {
      inputRef.value.value = '';
      context.emit('update:modelValue', '');
    }

    return {
      inputRef,
      focusOnInput,
      clearInput,
      cursor,
    }
  }
}
</script>

<style scoped lang="scss">
.input-wrapper {
  border-radius: 5px;
  border: solid 1px var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  padding: var(--spacing-s) var(--spacing-s) var(--spacing-s) var(--spacing-s);
  cursor: v-bind(cursor);
  display: flex;
  align-items: center;
}

.inputError {
  border-radius: 5px;
  border: solid 1px var(--overwatch-light-error);
  background-color: var(--overwatch-neutral-500);
  padding: var(--spacing-s) var(--spacing-s) var(--spacing-s) var(--spacing-s);
  cursor: v-bind(cursor);
  display: flex;
  align-items: center;
}

.input-wrapper-disabled {
  background-color: var(--overwatch-neutral-400);
}
.input-wrapper > input:disabled {
  background-color: var(--overwatch-neutral-400);
}
input {
  all: unset;
  @include overwatch-body-med;
  width: 100%;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-neutral-500);
}

input::placeholder {
  @include overwatch-body-med;
  color: var(--overwatch-neutral-300);
}

input:focus {
  outline: none;
  border: none;
}

input:focus {
  outline: none;
  border: none;
}

.clear-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px;
}
</style>