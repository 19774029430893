import { apiCall } from "../../utils/utils"

export default {
  async loadClusters(context, payload) {
    if (context.getters['abortController']) {
      context.getters['abortController'].abort();
    }
    const ac = new AbortController();
    context.commit('setAbortController', ac);

    context.commit('setMoreToFetch', false);

    const responseData = await apiCall(
      context,
      `/rest/v1/missions/${payload}/clusters`,
      'POST',
      {
        filter: getFilters(context),
        sorting: context.getters['sorting'],
        page: 0,
        abortController: ac.signal
      },
      'application/json',
      null,
      ac.signal
    );
    context.commit('setPage', 0);

    if (responseData.status === 'success') {
      let clusters = responseData.result;

      if (context.getters['associatesTargetCluster']) {
        clusters = clusters.filter(c => c._id != context.getters['associatesTargetCluster']._id);
      }

      context.commit('setClusters', clusters);
      context.commit('setMoreToFetch', true);
    }
  },
  async loadMoreClusters(context, payload) {
    if (context.getters['abortController']) {
      context.getters['abortController'].abort();
    }
    const ac = new AbortController();
    context.commit('setAbortController', ac);

    if (context.getters['moreToFetch']) {
      context.commit('setPage', context.getters['page'] + 1)
      context.commit('setMoreToFetch', false);

      const responseData = await apiCall(
        context,
        `/rest/v1/missions/${payload}/clusters`,
        'POST',
        {
          filter: getFilters(context),
          sorting: context.getters['sorting'],
          page: context.getters['page']
        },
        'application/json',
        null,
        ac.signal
      );

      if (responseData.status === 'success') {
        if (responseData.result.length === 0) {
          context.commit('setMoreToFetch', false);
        } else {
          let clusters = responseData.result;

          if (context.getters['associatesTargetCluster']) {
            clusters = clusters.filter(c => c._id != context.getters['associatesTargetCluster']._id);
          }

          context.commit('concatClusters', clusters);
          context.commit('setMoreToFetch', true);
        }
      }
    }
  },
  async getClusterEncounters(context, payload) {
    const responseData = await apiCall(context, `/rest/v1/clusters/${payload}/encounters`, 'GET');
    return responseData;
  },
  async updateCluster(context, payload) {
    const url = `/rest/v1/clusters/${payload.id}`;

    payload.filter = getFilters(context);

    const responseData = await apiCall(context, url, 'PUT', payload);
    if (responseData.status === 'success') {
      context.commit('replaceCluster', responseData.result)
    }
  },
  async moveEncountersBetweenClusters(context, payload) {
    const url = `/rest/v1/clusters/moveEncounters`;

    payload.filter = getFilters(context);

    const responseData = await apiCall(context, url, 'POST', payload);
    if (responseData.status === 'success') {
      if (responseData.result.from.encounters.length === 0) {
        context.commit('removeClusterById', responseData.result.from._id);
      } else {
        context.commit('replaceCluster', responseData.result.from);
      }
      context.commit('replaceCluster', responseData.result.to);
    }
  },
  async moveEncountersToNewCluster(context, payload) {
    const url = `/rest/v1/clusters/moveToNewCluster`;

    payload.filter = getFilters(context);

    const responseData = await apiCall(context, url, 'POST', payload);
    if (responseData.status === 'success') {
      if (responseData.result.from.encounters.length === 0) {
        context.commit('removeClusterById', responseData.result.from._id);
      } else {
        context.commit('replaceCluster', responseData.result.from);
      }
      context.commit('concatClusters', [responseData.result.to]);
    }
  },
  async mergeClusters(context, payload) {
    const url = `/rest/v1/clusters/mergeClusters`;

    payload.filter = getFilters(context);
    const responseData = await apiCall(context, url, 'POST', payload);
    if (responseData.status === 'success') {
      context.commit('replaceCluster', responseData.result.to);
      context.commit('removeClusterById', payload.update.fromClusterId);
    }
  },
  async getFaceAttributes(context, payload) {
    const url = `/rest/v1/face/tags`;
    const responseData = await apiCall(context, url, 'GET');
    if (responseData.status === 'success') {
      context.commit('setAllFaceAttributes', responseData.tags);
    }
  },
  async getClusterCount(context, payload) {
    const responseData = await apiCall(context, `/rest/v1/missions/${payload}/clusters/count`, 'GET');
    return responseData;
  },
  async getClustersByVideoMediaId(context, payload) {
    const responseData = await apiCall(context,
      `/rest/v1/missions/${payload.caseId}/video/${payload.mediaId}/clusters`,
      'GET'
    );
    return responseData;
  },
  async getVideoObjectByMediaId(context, payload) {
    const responseData = await apiCall(context,
      `/rest/v1/media/video/${payload}/object`,
      'GET'
    );
    return responseData;
  },
  resetAllFilters(context, payload) {
    context.commit('setAttributeFilters', {});
    context.commit('setCameraFilters', []);
    context.commit('setDtRangeFilter', []);
    context.commit('setWatchlistMatchFilter', false);
  },
}

function getFilters(context) {
  let filter = {};
  filter.cameras = context.getters['cameraFilters'];
  filter.attributes = context.getters['attributeFilters'];
  filter.name = context.getters['nameFilter']
  filter.watchlistMatch = context.getters['watchlistMatchFilter'];
  if (context.getters['associatesTargetCluster']) {
    filter.associatesSameFrameFilter = context.getters['associatesSameFrameFilter'];
    filter.associatesTimeRangeFilter = context.getters['associatesTimeRangeFilter'];
  }

  if (context.getters['dtRangeFilter'] && context.getters['dtRangeFilter'].length > 0) {
    filter.dates = context.getters['dtRangeFilter'].map(dr => {
      return {
        start: new Date(dr.start).valueOf(),
        end: new Date(dr.end).valueOf()
      }
    })
  }
  return filter;
}