import csvHelper from '@/js/csvHelper';


function splitFilenameExtension(filename){
    const regex = /(.*)\.([^.]*)$/;
    const match = filename.match(regex);

    return match ? [match[1], match[2].toLowerCase()] : [filename, ""];
}

export function readFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (err) => reject(err);
    });
}


export function readImage(file){
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (err) => reject(err);
    });
}

export function readCSV(file){
    return new Promise((resolve, reject) => {
        readFile(file)
        .then(txt => {
            const [_, ext] = splitFilenameExtension(file.name);
            if(ext !== 'csv'){
                reject('File must be a CSV.');
            } else {
                const csv = new csvHelper();
                const csvObj = csv.csvToObject(txt);
                resolve(csvObj);
            }
        })
        .catch(err => reject(err))
    })
}

export class ValidateCSV {
    constructor(file, options = {}){
        this.file = file;
        this.requiredColumns = options.requiredColumns || [];
        this.maxSize = options.maxSize || 1024;
        this.columnDataTypes = options.columnDataTypes || {}; 
    }

    async validate() {
        const csvData = await this.readCsvData();

        if (this.file.size > this.maxSize) {
            throw `File size must be less than ${this.maxSize} bytes`;
        }

        if (!this.hasHeaderRow(csvData)) {
            throw `CSV file must have a header row`;
        }

        if (this.requiredColumns.length > 0 && !this.hasRequiredColumns(csvData)) {
            throw `Missing required columns: ${this.requiredColumns.join(', ')}`;
        }

        const validDataTypes = this.validateDataTypes(csvData);
        if (validDataTypes !== true) {
            throw validDataTypes;
        }

        return csvData;
    }

    async readCsvData(){
        const txt = await readFile(this.file)
        const [_, ext] = splitFilenameExtension(this.file?.name);
        if(ext !== 'csv'){
            throw 'File must be a CSV.';
        } else {
            const csv = new csvHelper();
            const csvObj = csv.csvToObject(txt);
            return csvObj;
        }
    }

    hasHeaderRow(csvData) {
        return csvData.header.length > 0;
    }

    hasRequiredColumns(csvData) {
        const headerRow = csvData.header.map(item => item.trim());
        return this.requiredColumns.every((column) => headerRow.includes(column));
    }

    validateDataTypes(csvData) {
        const headerRow = csvData.header;
        for (let i = 1; i < csvData.rows.length; i++) {
            const row = csvData.rows[i];
            for (let j = 0; j < row.length; j++) {
                const columnName = headerRow[j];
                const expectedType = this.columnDataTypes[columnName];
                if (expectedType) {
                    const actualValue = row[j];
                    if (!this.isValidDataType(actualValue, expectedType)) {
                        return `Expect column ${columnName} to be of type ${expectedType} at row ${i + 1}, column ${j + 1}`;
                    }
                }
            }
        }
        return true;
    }

    isValidDataType(value, expectedType) {
        switch (expectedType) {
            case 'string':
                return typeof value === 'string';
            case 'number':
                return !isNaN(Number(value));
            case 'boolean':
                return value === 'true' || value === 'false';
            // add additional data type checks here
        }
        return false;
    }
}

export class ValidateImage {
    
    /**
     * @typedef ImageValidation
     * @property {boolean} isValid
     * @property {string} msg
     * @property {string} name - image name
     * @property {number=} width
     * @property {number=} height
     * @property {number=} size
     * @property {string=} ext
     * @property {string=} fileName
     */

    constructor(file, options = {}, ){
        this.file = file;
        this.minWidth = options.minWidth || 0;
        this.maxWidth = options.maxWidth || 10000;
        this.minHeight = options.minHeight || 0;
        this.maxHeight = options.maxHeight || 10000;
        this.maxSize = options.maxSize || 1024 * 1024;
        this.minSize = options.minSize || 0;
        this.exts = options.exts || "*";
        this.regex = options.regex || /[ `!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;
        this.url = URL.createObjectURL(this.file);
        this.extraProps = options.extraProps || {};
    }

    /**
     * @method
     * @returns {ImageValidation[]}
     */
   getChecks(){
        const [fileName, ext] = splitFilenameExtension(this.file?.name);  
        const fileSize = this.checkSize();
        const extension = this.checkFileExtensions(ext)
        const specialChars = this.checkSpecialChars(fileName);
        const checks = [extension, specialChars, fileSize];
        return checks;
    }

    /**
     * @async
     * @method
     * @returns {Promise<object>}
     */
    async validateBase64(){
        const checks = this.getChecks();

        for(const check of checks){
            if(check.isValid !== true){
                return check;
            }
        }

        const image = await this.loadImage()
        .catch(err => err);
        if(image?.type === 'error'){
            return { isValid: false, name: this.file.name, msg: 'Could not parse image.' }
        }

        const base64 = await this.getImageAsBase64()
        .catch(err => err);
        if(base64?.type === 'error'){
            return { isValid: false, name: this.file.name, msg: 'Something went wrong.' }
        }
    
        const dimensions = this.checkImageDimensions(image);
        if(dimensions.isValid !== true) return dimensions;

        return {
            src: base64,
        }
    }

    /**
     * @async
     * @method
     * @returns {Promise<Object>}
     */
    async validate(){
        const checks = this.getChecks();
        for(const check of checks){
            if(check.isValid !== true){
                return check;
            }
        }

        const image = await this.loadImage()
        .catch(err => err);
        if(image?.type === 'error'){
            return { isValid: false, name: this.file.name, msg: 'Could not parse image.' }
        }

        const arrayBuffer = await this.getImageAsArrayBuffer()
        .catch(err => err);
        if(arrayBuffer?.type === 'error'){
            return { isValid: false, name: this.file.name, msg: 'Something went wrong.' }
        }

        const hash = await this.fileHash(arrayBuffer)
        .catch(err => err);
        if(hash?.type === 'error'){
            return { isValid: false, name: this.file.name, msg: 'Something went wrong.' }
        }


        const dimensions = this.checkImageDimensions(image);
        if(dimensions.isValid !== true) return dimensions;

        const [_, ext] = splitFilenameExtension(this.file?.name);

        return { 
            imageName: this.file?.name,
            buffer: Array.from(new Uint8Array(arrayBuffer)), 
            hash: hash, 
            src: image,
            height: image.height, 
            width: image.width, 
            fileType: ext, 
            size: this.file.size,
            ...this.extraProps,
        };
        
    }

    /**
     * @async
     * @method
     * @returns {Promise<HTMLImageElement>}
     */
    async loadImage(){
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(image);
            image.onerror = (err) => reject(err);

            image.src = this.url;
        })
    }

    /**
     * @method
     * @param {object} image
     * @returns {ImageValidation}
     */
    checkImageDimensions(image){
        return {
            isValid: (image.width >= this.minWidth && image.width <= this.maxWidth) && (image.height >= this.minHeight && image.height <= this.maxHeight), 
            width: image.width,
            height: image.height,
            name: this.file.name,
            msg: `Image must be less than ${this.maxWidth}px width and ${this.maxHeight}px high`,
        }
    }

    /**
     * @method
     * @returns {ImageValidation}
     */
    checkSize(){
        return {
            isValid: (this.file.size / 1000) <= this.maxSize && (this.file.size / 1000) >= this.minSize,
            size: this.file.size,
            name: this.file.name,
            msg: `Image must be between: ${this.minSize}kb and ${this.maxSize}kb`,
        };
    }

    /**
     * @async
     * @method
     * @param {ArrayBuffer} buffer
     * @returns {Promise<string>}
     */
    async fileHash(buffer){
        const hashAsArrayBuffer = await crypto.subtle.digest("SHA-256", buffer);

        const uint8ViewOfHash = new Uint8Array(hashAsArrayBuffer);

        const hashAsString = Array.from(uint8ViewOfHash)
            .map((b) => b.toString(16).padStart(2, "0"))
            .join("");
        return hashAsString;
    }


    /**
     * @async
     * @method
     * @returns {Promise<ArrayBuffer>}
     */
    async getImageAsArrayBuffer(){
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.readAsArrayBuffer(this.file);
            fr.onload = () => resolve(fr.result);
            fr.onerror = (err) => reject(err);
        })
    }



    /**
     * @async
     * @method
     * @returns {Promise<string>}
     */
    async getImageAsBase64(){
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.readAsDataURL(this.file);
            fr.onload = () => resolve(fr.result);
            fr.onerror = (err) => reject(err);
        })
    }

    /**
    * @method
    * @param {string} ext
    * @returns {ImageValidation} 
    */
    checkFileExtensions(ext){
        if(this.exts === "*"){
            return {
                isValid: true,
                name: this.file?.name,
                ext,
                msg: `File (${ext}) is not of types: \n ${this.exts}`,
            };
        }
        return {
            isValid: this.exts.includes(ext.toLowerCase()),
            name: this.file?.name,
            ext,
            msg: `File (${ext}) is not of types: ${this.exts.join(', ')}`,
        };
    }


    /**
    * @method
    * @param {string} fileName
    * @returns {ImageValidation} 
    */
    checkSpecialChars(fileName){
        if(this.regex === null){
            return {
                isValid: true,
                name: this.file?.name,
                fileName,
                msg: `File name cannot contain special characters.`,
            };
        } else {
            return {
                isValid: !this.regex.test(fileName),
                name: this.file?.name,
                fileName,
                msg: `File name cannot contain special characters.`,
            };
        }
    }

}
