export default {
  resetState (state, defaultState) {
    // Merge rather than replace so we don't lose observers
    Object.assign(state, defaultState);
  },
  addRequest(state, payload) {
    state.requests.push(payload);
  },
  setRequests(state, payload) {
    state.requests = payload;
  }
};