<template>
  <div class="d-flex flex-column content" style="height: 75vh;">
    <div class="d-flex justify-content-between align-items-center">
      Create Enroll Invites
      <RocButton type="tertiary" floating style='box-shadow: none !important;' @click="handleClose()">
        <RocIcon icon='minimize' size="sm"/>
      </RocButton>
    </div>
    <EntitlementFileSelector v-if="!isCreating" @csvAdded="addEntitlementsCSV" :style="(hasEntitlements ? 'flex: 0.5;' : 'flex 0.7;')"/>
    <div v-if="isCreating" class="d-flex justify-content-center">
      <div style="width: 94.5%;">
        <MDBProgress :height="20">
          <MDBProgressBar :value="createProgress" :max="createMax">
            {{ Math.round((createProgress / createMax) * 100) }}%
          </MDBProgressBar>
        </MDBProgress>
      </div>
    </div>
    <div class="entitlementList" :style="listBoxStyle">
      <RocGrid
        :columnDefs="colDefs"
        :rows="entitlements"
        :allowDelete="true"
        :allowEdit="true"
        @deleteItem="removeEntitlement"
        @deleteAll="clearImporter"
        @contentChanged="updateEntitlement"
      />
    </div>
    <div class="d-flex justify-content-end" style="font-size: 14px; margin-right:10px; margin-top: 5px;">{{ entitlements.length }} Records</div>
    <div class="d-flex flex-column justify-content-end align-items-end bottomSection">
      <div v-if="!isCreating" style="width: 100%; padding: 10px;">
        <EntitlementImportEntry mode="adder" @add="addEntitlement"/>
      </div>
      <div v-if="!isCreating" class="d-flex" style="gap: var(--spacing-xl)">
        <RocButton type="secondary" @click="clearImporter">
          Clear
        </RocButton>
        <RocButton v-if="hasEntitlements" type="primary" @click="createEntitlements">
          Create
        </RocButton>
        <RocButton v-else type="secondary" @click="$emit('close')">
          Close
        </RocButton>
      </div>
      <div v-else style="text-align: right; padding-bottom: 10px; padding-right: 10px;">
        <roc-spinner/>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { MDBProgress, MDBProgressBar } from "mdb-vue-ui-kit";
import { useStore } from 'vuex';
import EntitlementImportEntry from "@/components/events/EntitlementImportEntry";
import EntitlementFileSelector  from "@/components/events/EntitlementFileSelector";
import { get as lodashGet } from "lodash";
import RocButton from "@/components/ui/RocButton.vue";
import RocGrid from "@/components/ui/RocGrid.vue";
import RocIcon from '@/components/ui/RocIcon.vue';

export default  {
  name: "EntitlementImporter",
  emits: ["close"],
  components: {
    EntitlementImportEntry,
    EntitlementFileSelector,
    MDBProgress,
    MDBProgressBar,
    RocButton,
    RocGrid,
    RocIcon
  },
  setup(props, context) {
    const store = useStore()
    const entitlements = ref([]);
    const isCreating = ref(false);
    const eventId = computed(() => { return store.getters["watchlists/importerEventId"]; });
    const createProgress = ref(0);
    const createMax = ref(0);
    const hasEntitlements = computed(() => { return entitlements.value.length > 0; });
    const colDefs = ref([]);
    getColumnDefs();

    function addEntitlement(entitlement) {
      entitlement._eventId = eventId.value;
      entitlements.value.push(entitlement);
    }

    function addEntitlementsCSV(csvObject) {
      csvObject.rows.forEach(element => {
        if (!isEntitlementEmpty(element)) {
          element._eventId = eventId.value;
          addEntitlement(element);
        }
      });
    }

    function removeEntitlement(index) {
      entitlements.value.splice(index, 1);
    }

    function updateEntitlement(e, index) {
      if(!e.target?.id)
      {
        entitlements.value[index].notes = e;
        return 
      }
      entitlements.value[index][e.target.id] = e.target.innerText;
      
    }

    async function createEntitlements() {
      isCreating.value = true;
      createProgress.value = 0;
      createMax.value = entitlements.value.length;
      const entitlementsCopy = _.cloneDeep(entitlements.value);
      const invalidEntitlements = [];
      for (let i=0; i < entitlementsCopy.length; i++) {
        if (!isEntitlementValid(entitlementsCopy[i])) {
          invalidEntitlements.push(_.cloneDeep(entitlementsCopy[i]));
          // remove from both copy list and OG list
          entitlementsCopy.splice(i, 1);
          removeEntitlement(i);
          i--;
        }
      }
      for (let i=0; i < entitlementsCopy.length; i++) {
        const entitlement = entitlementsCopy[i];
        await store.dispatch('events/createEntitlement', entitlement);
        removeEntitlement(0);
        createProgress.value++;
      }
      clearImporter();
      createProgress.value = 0;
      createMax.value = 0;
      // push invalid entitlements back to list - they weren't created
      entitlements.value = entitlements.value.concat(invalidEntitlements);
      isCreating.value = false;
      if (entitlements.value.length == 0) {
        context.emit('close');
      }
    }

    function handleClose() {
      clearImporter();
      store.dispatch("watchlists/toggleWatchlistImporter", false);
    }

    function isEntitlementEmpty(entitlement) {
      const firstname = lodashGet(entitlement, 'firstname', null);
      const lastname = lodashGet(entitlement, 'lastname', null);
      const email = lodashGet(entitlement, 'email', null);
      const phoneNumber = lodashGet(entitlement, 'phoneNumber', null);
      const notes = lodashGet(entitlement, 'notes', null);
      return !firstname && !lastname && !email && !phoneNumber && !notes;
    }

    function isEntitlementValid(entitlement) {
      const enrollConfig = store.getters['rocenroll/enrollConfig'];
      const phoneRegex = _.get(enrollConfig, 'entitlements.phoneRegex', '');
      const emailRegex = _.get(enrollConfig, 'entitlements.emailRegex', '');
      const requiredFields = _.get(enrollConfig, 'entitlements.requiredFields', []);

      const firstnameOk = requiredFields.includes('firstname') ? (entitlement.firstname && entitlement.firstname.length > 0) : true;
      const lastnameOk = requiredFields.includes('lastname') ? (entitlement.lastname && entitlement.lastname.length > 0) : true;
      const emailOk = requiredFields.includes('email') ? 
        ((entitlement.email && entitlement.email.length > 0) && 
          (emailRegex.length > 0 ? new RegExp(emailRegex).test(entitlement.email) : true))
        : true;
      const phoneOk = requiredFields.includes('phoneNumber') ? 
        ((entitlement.phoneNumber && entitlement.phoneNumber.length > 0) && 
          (emailRegex.length > 0 ? new RegExp(phoneRegex).test(entitlement.phoneNumber) : true))
        : true;
      
      return firstnameOk && lastnameOk && emailOk && phoneOk;
    }

    function clearImporter() {
      entitlements.value = [];
    }

    const listBoxStyle = computed(() => {
      if (window.innerWidth <= 480) {
        if (hasEntitlements.value) {
          return {
            'height' : '440px',
          }
        } else {
          return {
            'height' : '400px',
          }
        }
        
      } else {
        if (hasEntitlements.value) {
          return {
            'height' : '440px',
          }
        } else {
          return {
            'height' : '400px',
          }
        }
      }
    });

    function getColumnDefs() {

      if(!isHidden('firstname')){
        colDefs.value.push({ label: 'First Name', key: 'firstname', editable: true});
      }
      if(!isHidden('lastname')) {
        colDefs.value.push({ label: 'Last Name',key: 'lastname', editable: true});
      } 
      if(!isHidden('email')){
        colDefs.value.push({ label: 'Email', key: 'email', editable: true, hide: true});
      }
      if(!isHidden('phoneNumber')){
        colDefs.value.push({ label: 'Phone Number', key: 'phoneNumber', editable: true, hide: true});
      }

    }

    function isHidden(field) {
      const enrollConfig = store.getters['rocenroll/enrollConfig'];
      const hiddenFields = _.get(enrollConfig, 'entitlements.hiddenFields', []); // default to not hidden
      return hiddenFields.includes(field);
    }


    return {
      addEntitlement,
      addEntitlementsCSV,
      removeEntitlement,
      updateEntitlement,
      entitlements,
      createEntitlements,
      isCreating,
      clearImporter,
      listBoxStyle,
      createProgress,
      createMax,
      hasEntitlements,
      isHidden,
      colDefs,
      handleClose
    }
  }
}
</script>

<style scoped>

.entitlementList {
  width: 100%;
  overflow: auto; 
  overflow-x: hidden;
  padding-top: var(--spacing-m);
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  overscroll-behavior-y: contain;
  background-color: var(--overwatch-secondary);
}

.content {
    overflow: auto;
    position: absolute;
    max-width: 100vh;
    min-width: 650px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    z-index: 999; 
    position: absolute; 
    border: 1px solid var(--overwatch-neutral-300); 
    background: var(--overwatch-secondary);
    padding: 12px;
}

.entryCard {
  background-color: var(--overwatch-secondary);
  padding: 5px;
  margin-bottom: 5px;
}

.columnHeader {
  margin-top: 10px;
  padding: 10px;
  text-align: left;
  background-color: var(--overwatch-secondary);
}

.columnHeaderText {
  opacity: 0.5;
  color: rgba(0,0,0,1);
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}

.bottomSection {
  flex: 1;
  margin-top: 10px;  
}

@media (max-width: 480px) {
  .bottomSection {
    margin-bottom: 60px;
  }
  .content {
    min-width: 480px;
  }
}

</style>