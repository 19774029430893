<template>
  <div class="dialog-wrapper">
    <div class="overwatch-title-large" v-if="mergeClusterMode">Merge With...</div>
    <div class="overwatch-title-large" v-else>Move to Existing Cluster</div>
    <div class="cluster-name">
      <span class="overwatch-title-med">Cluster Name</span>
      <span class="overwatch-body-med">{{ cluster.name }}</span>
    </div>
    <div class="select-cluster">
      Cluster
      <RocSingleSelect class="select"
        :availableItems="computedClusterOptions"
        @selection-changed="setSelectedCluster"
        valueProp="value"
      />
    </div>
    <div class="buttons">
      <RocButton type="secondary" @click="$emit('close')">
        Cancel
      </RocButton>
      <RocButton
      @click="mergeClusterMode ?
        mergeClusters() :
        moveToSelectedCluster()
      "
        :disabled="!selectedClusterId"
      >
        Save
      </RocButton>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import RocSingleSelect from '@/components/ui/RocSingleSelect';
import RocButton from '@/components/ui/RocButton';

export default {
  name: 'MoveToExistingCluster',
  components: {
    RocSingleSelect,
    RocButton
  },
  props: {
    encounters: {
      type: Array,
      required: true,
      default: []
    },
    cluster: {
      type: Object,
      required: true
    },
    mergeClusterMode: {
      type: Boolean,
      required: false
    }
  },
  emits: ['close', 'save'],
  setup(props, context) {
    const store = useStore();
    const clusters = computed(() => {
      return store.getters['clusters/clusters'];
    })
    const computedClusterOptions = computed(() => {
      const output = [];

      // Exclude prop cluster
      const filteredClusters = clusters.value.filter(c => c._id != props.cluster._id);
      for (let c of filteredClusters) {
        output.push({
          value: c._id,
          name: c.name
        })
      }
      return output;
    });

    const selectedClusterId = ref();
    function setSelectedCluster(c) {
      selectedClusterId.value = c.value;
    }

    async function moveToSelectedCluster() {
      const fromClusterId = props.cluster._id;
      const toClusterId = selectedClusterId.value;

      const encounterIds = props.encounters.map(e => e._id);

      await store.dispatch('clusters/moveEncountersBetweenClusters',
        {
          update: {
            fromClusterId: fromClusterId,
            toClusterId: toClusterId,
            encounters: encounterIds
          }
        }
      );
      context.emit('save');
      context.emit('close');
    }

    async function mergeClusters() {
      const fromClusterId = props.cluster._id;
      const toClusterId = selectedClusterId.value;

      await store.dispatch('clusters/mergeClusters',
        {
          update: {
            fromClusterId: fromClusterId,
            toClusterId: toClusterId,
          }
        }
      );
      context.emit('close');
    }

    return {
      computedClusterOptions,
      selectedClusterId,
      setSelectedCluster,
      moveToSelectedCluster,
      mergeClusters,
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-wrapper {
  display: flex;
  flex-direction: column;
  gap: $--spacing-m;
  justify-content: flex-start;
  width: 500px;
  padding: $--spacing-xl $--spacing-xxl;
}
.cluster-name {
  display: flex;
  flex-direction: column;
}
.select-cluster {
  @include overwatch-body-large;
  .select {
    height: 48px;
    width: 100%;
  }
}
.buttons {
  display: flex;
  justify-content: flex-end;
  gap: $--spacing-s;
}
</style>