export default {
    setTrackAnalysisFacetemplates(state, payload) {
        state.trackAnalysisFacetemplates = payload;
    },
    setSettings(state, payload){
        state.settings = payload;
    },
    setCase(state, payload){
        state.currentCase = payload.currentCase;
    },
    setExamId(state, payload){
        state.examId = payload.examId;
    },
    setReadOnly(state, payload){
        state.readOnly = payload.readOnly;
    },
    resetState (state, defaultState) {
        // Merge rather than replace so we don't lose observers
        Object.assign(state, defaultState);
    },
    setTrackAnalysisFacetemplates(state, payload) {
        state.trackAnalysisFacetemplates = payload;
    },
};
