<template>
  <div>
    <div class="input-row">
      <div>Name</div>
      <span> {{ propCase.name }} </span>
    </div>
    <div class="input-row">
      <div>Description</div>
      <textarea disabled v-model="propCase.description"/>
    </div>
    <div class="input-row" style="display: flex; width: 100%">
      <div style="flex:1">
        <div>Cameras</div>
        <ul style="max-height: 250px; overflow: auto;">
          <li v-for="c in cameras">{{ c.name }}</li>
        </ul>
      </div>
      <div style="flex:1" v-if="propCase.type ==='postevent'">
        <div>Watchlists</div>
        <ul style="max-height: 250px; overflow: auto;">
          <li v-for="w in watchlists">{{ w }}</li>
        </ul>
      </div>
    </div>
    <div class="input-row" style="width: 100%">
      <div>
        User Groups
      </div>
      <div>
        {{userGroupsString}}
      </div>
    </div>
    <div class="input-row" v-if="propCase.type ==='postevent'">
      <div style="font-size: 10px;">
        Total Progress
      </div>
      <MDBProgress :height="20">
        <MDBProgressBar :value="caseProgress">
          {{ caseProgress }}%
        </MDBProgressBar>
      </MDBProgress>
      <div style="margin-top: 10px;">
        <div class="panel-header">
          <div class="d-flex justify-content-between align-self-center" style="width:100%">
            <div class="flex-child" style="display: inline-block; text-align: left; min-width: 60%;">Filename</div>
            <div class="flex-child" style="display: inline-block; text-align: left; min-width: 20%">Status</div>
            <div class="flex-child" style="display: inline-block; text-align: left; min-width: 20%"></div>
          </div>
        </div>
        <div style="max-height: 250px; overflow: auto;">
          <div class="panel" v-for="m in caseMediaStatuses" >
            <div style="min-width: 60%">
              {{ m.fileName }}
            </div>
            <div style="min-width: 35%">
              {{m.statusDescription ? m.status + ' ' + '-' + ' ' + m.statusDescription : m.status }}
            </div>
            <div style="min-width: 5%; display: flex; flex-direction: row-reverse;">
              <roc-spinner size="sm" v-if="m.status === 'uploading'"/>
              <roc-spinner size="sm" v-if="m.status === 'processing'"/>
              <RocIcon icon="check" size="sm" v-else-if="m.status === 'completed'" color="primary"/>
              <RocIcon icon="error" size="sm" v-else-if="m.status === 'error'" color="red"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { MDBProgress, MDBProgressBar } from "mdb-vue-ui-kit";
import RocIcon from "@/components/ui/RocIcon.vue";

export default {
  name: 'CaseInformation',
  components: {
    MDBProgress,
    MDBProgressBar,
    RocIcon
  },
  props: ['case', 'statuses', 'cameras', 'progress'],
  setup(props, context) {
    const store = useStore();

    const propCase = ref(props.case);

    const caseMediaStatuses = computed(() => {
      return props.statuses;
    });

    const caseProgress = computed(() => props.progress);

    const watchlists = computed(() => {
      const watchlists = store.getters['watchlists/watchlists'];
      const watchlistIds = props.case.watchlistIds;

      return watchlists.filter(w => watchlistIds.includes(w._id)).map(w => w.name);
    })

    const allUserGroups = computed(() => {
      return store.getters['settings/userGroups'];
    });
    const userGroupsString = computed(() => {
      let output = [];
      for (let c of props.case.userGroups) {
        const group = allUserGroups.value.find(g => g._id===c);
        if (group) {
          output.push(group.label);
        }
      }
      return output.join(', ');
    })

    return {
      propCase,
      caseProgress,
      caseMediaStatuses,
      watchlists,
      userGroupsString
    }
  }
}
</script>

<style scoped lang="scss">

.input-row {
  width: 100%;
  margin-bottom: 16px;
}

input {
  width: 100%;
  padding: 5px;
  border: 1px solid var( --overwatch-neutral-300);
  border-radius: 4px;
}

input:disabled, textarea:disabled {
  color: var(--overwatch-neutral-300);
}

textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 4px;
  resize: none;
}


.panel-header {
  width: 100%;
  height: 24px;
  background-color: var(--overwatch-secondary);
}
.panel {
  width: 100%;
  height: 3em;
  margin-bottom: 2px;
  padding: 4px !important;
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 5px;

  display: flex;
  align-items: center;
  @include overwatch-body-small;
}



</style>