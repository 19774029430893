<template>
  <div class='camera-list' v-if="activeMission && activeMission.type === 'live'">
    <base-dialog :show='showDialog' @close='handleError' :style="cameraCRUDStyle" :hideCloseBtn="true" :preventCloseOnClickAway="true">
      <camera-crud :title="crudTitle" :mode="cameraCRUDMode" :camera-id="cameraEditGUID" @close="showDialog = false;"></camera-crud>
    </base-dialog>
    <search-header
      title='Cameras'
      @search-filter-change='setSearchFilter'
      :transparentBackground="true"
      :hideButton="true"
    >
      <template v-slot:leftnav>
        <div class="title">
          Cameras
          <RocButton
              size="dialogButton"
              type="primary"
              :disabled="!canAddCamera"
              @click="addCameraClicked"
            >
            Create
          </RocButton>
        </div>
      </template>
    </search-header>
    <div v-if='isLoading'>
      <roc-spinner/>
    </div>
    <div v-if="hasCameras">
      <div class="groupCard" v-if="unCatCameras">
        <RocIcon @click="collapseDiv('Unassigned')" :rotate270="isGroupHidden('Unassigned')" icon="downArrow" size="md" style="margin-right: var(--spacing-m);"/>
          Uncategorized Cameras
      </div>
      <div class='camera-container' v-if="!isGroupHidden('Unassigned')">
        <div v-for='camera in cameras' :key='camera.GUID'>
          <div  v-if="!camera.cameraGroups || camera.cameraGroups.length == 0">
            <camera-view
              :camera-guid='camera.GUID'
              :camera-name='camera.name'
              :camera-state='camera.enabled'
              :camera-online='camera.online'
              :preview="camera.previewImage"
              @edit-camera="editCameraClicked">
            </camera-view>
          </div>
        </div>
      </div>
      <div v-for="group in groupList" :key="group">
        <CameraGroupCard :group="group" @collapseDiv="collapseDiv" @groupEvent="groupEvent"/>
          <div class='camera-container' v-if='!isGroupHidden(group)'>
            <div v-for='camera in cameras' :key='camera.GUID'>
              <camera-view v-if="camera.cameraGroups?.includes(group)"
                :camera-guid='camera.GUID'
                :camera-groups='camera.cameraGroups'
                :camera-name='camera.name'
                :camera-state='camera.enabled'
                :camera-online='camera.online'
                :preview="camera.previewImage"
                @edit-camera="editCameraClicked">
              </camera-view>
            </div>
          </div>
      </div>
    </div>
      <RocToast v-if="displayToast" :message=message @autoClose="() => {displayToast = !displayToast}"/>
  </div>
  <div v-else>
    Please select a active live mission.
  </div>
</template>

<script>
import SearchHeader from "@/components/ui/SearchHeader";
import CameraView from "@/components/cameras/CameraView";
import CameraCrud from "@/components/cameras/CameraCRUD";
import RocIcon from "@/components/ui/RocIcon.vue";
import CameraGroupCard from "@/components/cameras/CameraGroupCard.vue";
import RocToast from "@/components/ui/RocToast.vue";
import RocButton from "@/components/ui/RocButton.vue";

import { useStore } from "vuex";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";

export default {
  name: 'CamerasList',
  components: {
    SearchHeader,
    CameraView,
    CameraCrud,
    RocIcon,
    CameraGroupCard,
    RocToast,
    RocButton
  },
  setup() {
    const store = useStore();

    const isLoading = ref(false);
    const searchFilterText = ref('');
    const error = ref(null);
    const showDialog = ref(false);
    const windowWidth = ref(window.innerWidth);
    const canAddCamera = ref(false);
    const hideCameraGroup = ref([]);
    const displayToast = ref(false);
    const message = ref('');

    const activeMission = computed(() => {
      return store.getters['cases/activeMission'];
    });

    let socket;
    onMounted(async () => {
      const payload = 'feed=cameras&topic=all';
      socket = await store.dispatch("auth/getSocketIO", payload);
      socket.on('all', (payload) => {
        store.commit('cameras/setCameraState', payload);
      });

      canAddCamera.value = await store.dispatch('settings/canAddCamera');
    });

    onMounted(() => {
      window.addEventListener('resize', ()=>{windowWidth.value = window.innerWidth;});
    });

    onBeforeUnmount(() => {
      socket.close();
    });

    const cameraCRUDMode = ref('edit');
    const cameraEditGUID = ref(null);
    const crudTitle = ref('Add Camera');

    loadCameras();

    async function loadCameras() {
      isLoading.value = true;
      try {
        await store.dispatch('cameras/loadCamerasByActiveMission');

      } catch (error) {
        error.value = error.message || 'Something went wrong!';
      }
      isLoading.value = false;
    }

    function addCameraClicked() {
      showDialog.value = !showDialog.value;
      cameraCRUDMode.value = 'add';
      crudTitle.value = 'Add Camera';
      cameraEditGUID.value = null;
    }

    function editCameraClicked(cameraGUID) {
      cameraCRUDMode.value = 'edit';
      cameraEditGUID.value = cameraGUID;
      showDialog.value = !showDialog.value;
      crudTitle.value = 'Edit Camera';
    }

    function setSearchFilter(filterText) {
      searchFilterText.value = filterText;
    }

    async function groupEvent(payload,groupDeleted){
      let updateArr = [];
      payload.forEach((load) => {
        updateArr.push(updateGroup(load));
      });

      Promise.all(updateArr).then(() => {
        if(groupDeleted){
          message.value = groupDeleted + ' has been deleted!';
          displayToast.value = true;
        }
      });
    }

    function updateGroup(payload){
      return store.dispatch("cameras/updateCameraByUUID", payload);
    }

    const groupList = computed(() => {
      return Array.from(store.getters['cameras/getAllCameraGroups']);
    })

    const cameras = computed(function() {
      let cameraList = store.getters['cameras/cameras'];

      cameraList.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if(nameA < nameB) {
          return -1
        }
        else if(nameA > nameB) {
          return 1;
        }

        return 0;
      });

      return searchFilterText.value != '' ? cameraList.filter((camera) => {
        if (camera.name.toLowerCase().includes(searchFilterText.value.toLowerCase())) {
          return true;
        }
      }) : cameraList;
    });

    const unCatCameras = computed(() => {
      return cameras?.value.some((camera) =>  camera.cameraGroups?.length == 0);
    });

    const hasCameras = computed(function() {
      return !isLoading.value && store.getters['cameras/cameras'];
    });

    async function handleError() {
      error.value = null;
      showDialog.value = false;
      canAddCamera.value = await store.dispatch('settings/canAddCamera');
    }

    const cameraCRUDStyle = computed(() => {
      if (windowWidth.value <= 480) {
        return {
          'width': '100%'
        }
      } else {
        return {
          'width': '950px',
          'max-width': '100%'
        }
      }
    });

    function collapseDiv(group){
      let index = hideCameraGroup.value.indexOf(group);
      if(index >= 0)
      {
        hideCameraGroup.value.splice(index,1);
      }
      else hideCameraGroup.value.push(group);
    }

    function isGroupHidden(group){
      if(hideCameraGroup.length == 0)
        return false;
      else
        return hideCameraGroup.value?.includes(group);
    }

    return {
      activeMission,
      canAddCamera,
      isLoading,
      cameras,
      hasCameras,
      setSearchFilter,
      error,
      handleError,
      showDialog,
      addCameraClicked,
      cameraCRUDMode,
      cameraEditGUID,
      editCameraClicked,
      crudTitle,
      cameraCRUDStyle,
      groupList,
      collapseDiv,
      hideCameraGroup,
      isGroupHidden,
      groupEvent,
      displayToast,
      message,
      unCatCameras
    };
  }
};
</script>

<style scoped lang="scss">

.camera-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 var(--spacing-xl) var(--spacing-l) var(--spacing-l);
}

.groupCard{
  min-height: 80px;
  @include overwatch-title-small;
  margin: var(--spacing-xs) var(--spacing-xl);
  padding: var(--spacing-l) var(--spacing-m);
  box-shadow: 0 0.5px 2px 0 rgba(0,0,0,0.25);
  background-color: var(--overwatch-secondary);
  display: flex;
  align-items: center;
}

.groupCard:has(.dropdown-toggle){
    padding-right: var(--spacing-base);
}

.title{
  gap: var(--spacing-l);
  padding-left: var(--spacing-base);
  display: flex;
  align-items: center;
}

/* IPAD PORTRAIT */
@media only screen and (max-width: 810px) and (orientation: portrait) {
  .camera-list {
    min-width: 0;
  }
}

/* MOBILE */
@media (max-width: 480px) {

  .camera-container {
    margin: 0;
  }

  .groupCard{
    margin: var(--spacing-s);
    max-width: 95%
  }

  .title{
    padding-left: var(--spacing-s);
    padding-bottom: var( --spacing-s);
    display: flow;
  }

}

</style>
