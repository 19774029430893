import { cloneDeep } from "lodash";
import { apiCall, readFile } from "../../utils/utils";
import { convertDataUriToString } from "../../../js/base64Helper";

export default {
  async loadWatchlists(context) {
    const url = `/rest/v1/watchlists`;
    const httpPayload = {
      method: 'GET'
    };

    const responseData = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    const watchlists = [];
    for (const key in responseData) {
      const watchlist = responseData[key];
      watchlist.value = watchlist._id; //for filter selection
      watchlist.label = watchlist.name;
      watchlists.push(watchlist);
    }
    context.commit('setWatchlists', watchlists);
  },
  async updateWatchlist(context, payload) {
    const url = `/rest/v1/watchlist/${payload.id}`;
    const httpPayload = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload.update)
    };

    const responseData = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    if (responseData.status === 'success') {
      if(payload?.update?.color)
      {
        context.commit('setWatchlistColor', responseData.watchlist);
      } else context.commit('setWatchlistState', responseData.watchlist);
    }
  },
  async createWatchlist(context, payload) {
    const url = `/rest/v1/watchlist`;
    const httpPayload = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload.info)
    };

    const responseData = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    if (responseData.status === 'success' && (payload.info.type === 'face' || payload.info.type === 'tattoo')) {
      responseData.watchlist.label = responseData.watchlist.name; //required for multiselect components
      responseData.watchlist.value = responseData.watchlist._id; //required for multiselect components
      context.commit('addNewWatchlist', responseData.watchlist);
      // if the payload came with files, enroll them, otherwise we're just creating an empty watchlist
      if (payload.files && payload.info.type === 'face') {
        payload.watchlistId = responseData.watchlist._id;
        await context.dispatch('enrollFacesToWatchlist', payload);
      }
      if (payload.files && payload.info.type === 'tattoo') {
        payload.watchlistId = responseData.watchlist._id;
        await context.dispatch('enrollTattoosToWatchlist', payload);
      }
    }
    else if (responseData.status === 'success' && (payload.info.type === 'text')) {
      responseData.watchlist.label = responseData.watchlist.name; //required for multiselect components
      responseData.watchlist.value = responseData.watchlist._id; //required for multiselect components
      context.commit('addNewWatchlist', responseData.watchlist);
      // if the payload came with licensePlates, enroll them, otherwise we're just creating an empty watchlist
      if (payload.licensePlates) {
        payload.watchlistId = responseData.watchlist._id;
       responseData.validation = await context.dispatch('enrollPlates', payload);
      }
    }
    return responseData;
  },
  async enrollPlates(context, payload) {
    const plates = payload.licensePlates;
    for (let i = 0; i < plates.length; i++) {
      const url = `/rest/v1/watchlist/${payload.watchlistId}/licenseplate`;
      const body = {
        "watchlistId": payload.watchlistId,
        "watchlistedLicensePlate": plates[i],
      };
      const httpPayload = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      };

      try {
        const responseData = await context.dispatch(
          'auth/fetchJSON',
          { url: url, payload: httpPayload },
          { root: true }
        );
        if (responseData.status === 'success') {
          if (context.getters.memberSummaryCount(payload.watchlistId) < 8) {
            context.commit('updateWatchlistMemberSummary', {
              _id: payload.watchlistId,
              enrollmentPreview: responseData.watchlistedLicensePlate
            });
          }
          plates[i].status = 'success';
        }
        else {
          plates[i].status = 'failed';
          plates[i].errorMsg = responseData.message;
        }
      }

      catch (e) {
        console.log("Error: ", e);
      }

    }
  },

  async enrollTattoosToWatchlist(context, payload) {
    const fileCopy = [...payload.files.value];
    const maxParallelEnrollments = 10;

    while (fileCopy.length) {
      let pArray = [];
      for (let i = 0; i < maxParallelEnrollments; i++) {
        const file = fileCopy.shift();
        if (file) {
          pArray.push(
            context.dispatch('enrollTattoo', {
              wl_id: payload.watchlistId,
              file: file,
              progress: payload.progress,
              preview: payload.enrollmentPreviews,
              enrollmentResults: payload.enrollmentResults,
              reverseMatch: payload.reverseMatch
            })
          );
        }
      }
      await Promise.all(pArray);
      if (fileCopy.length) {
        payload.page.value++;
      }
      pArray = [];
    }
  },
  async enrollTattoo(context, payload) {
    payload.file.enrollStatus = 'pending';
    let url;
    let httpPayload;
    let base64Image;
    try {
      base64Image = await readFile(payload.file.fileHandle, true);
      base64Image = convertDataUriToString(base64Image);
    } catch (err) {
      console.error(err);
    }

    let jsonPayload = {
      firstname: payload.file.f,
      lastname: payload.file.l,
      internalId: payload.file.internalId,
      notes: payload.file.notes,
      enrollment_image: base64Image,
      reverseMatch: payload.reverseMatch
    };

    url = `/rest/v1/watchlist/${payload.wl_id}/tattoo`;
    httpPayload = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(jsonPayload)
    };
    const responseData = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    if (responseData.status === 'success') {
      payload.file.enrollStatus = 'success';
      payload.preview.value.unshift(
        responseData.watchlistedImage.enrollmentPreview
      ); //display preview back on watchlist CRUD component
      if (payload.preview.value.length >= 50) {
        payload.preview.value.pop();
      }

      //show member in new watchlist in background
      if (context.getters.memberSummaryCount(payload.wl_id) < 8) {
        context.commit('updateWatchlistMemberSummary', {
          _id: payload.wl_id,
          enrollmentPreview: responseData.watchlistedImage.enrollmentPreview
        });
      }

      if (payload.enrollmentResults) {
        payload.enrollmentResults.value.push(responseData.watchlistedImage);
      }
      //update size
      context.commit('updateWatchlistSize', { _id: payload.wl_id });
    } else {
      payload.file.enrollStatus = 'failed';
      payload.file.enrollStatusMsg = responseData.message;
    }

    payload.progress.value += 1; //update for import progressbar
  },
  async enrollFacesToWatchlist(context, payload) {
    const fileCopy = [...payload.files.value];
    const maxParallelEnrollments = context.getters.maxParallelEnrollments;

    while (fileCopy.length) {
      let pArray = [];
      for (let i = 0; i < maxParallelEnrollments; i++) {
        const file = fileCopy.shift();
        if (file) {
          pArray.push(
            context.dispatch('enrollFace', {
              wl_id: payload.watchlistId,
              file: file,
              progress: payload.progress,
              preview: payload.enrollmentPreviews,
              enrollmentResults: payload.enrollmentResults,
              reverseMatch: payload.reverseMatch
            })
          );
        }
      }
      await Promise.all(pArray);
      if (fileCopy.length) {
        payload.page.value++;
      }
      pArray = [];
    }
  },
  async enrollFace(context, payload) {
    payload.file.enrollStatus = 'pending';
    let url;
    let httpPayload;
    if (payload.file.fileHandle['type'].split('/')[0] === 'image') {
      // read file as image data, base64
      let base64Image;
      try {
        base64Image = await readFile(payload.file.fileHandle, true);
        base64Image = convertDataUriToString(base64Image);
      } catch (err) {
        console.error(err);
      }

      let jsonPayload = {
        firstname: payload.file.f,
        lastname: payload.file.l,
        internalId: payload.file.internalId,
        notes: payload.file.notes,
        enrollment_image: base64Image,
        customProperties: {
          code: payload.file.code,
        },
        reverseMatch: payload.reverseMatch
      };

      url = `/rest/v1/watchlist/${payload.wl_id}/face`;
      httpPayload = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(jsonPayload)
      };
    } else {
      // attempt to read file as buffer and assume EFT/EBTS data
      let ebtsBuffer;
      try {
        ebtsBuffer = await readFile(payload.file.fileHandle);
      } catch (err) {
        console.error(err);
      }
      url = `/rest/v1/watchlist/${payload.wl_id}/ebts`;
      httpPayload = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/octet-stream'
        },
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        responseType: 'json',
        body: ebtsBuffer
      };
    }
    const responseData = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    if (responseData.status === 'success') {
      payload.file.enrollStatus = 'success';
      if (!payload.file.f && responseData.watchlistedFace.firstname) {
        payload.file.f = responseData.watchlistedFace.firstname;
      }
      if (!payload.file.l && responseData.watchlistedFace.lastname) {
        payload.file.l = responseData.watchlistedFace.lastname;
      }
      if (!payload.file.notes && responseData.watchlistedFace.notes) {
        payload.file.notes = responseData.watchlistedFace.notes;
      }
      if (!payload.file.internalId && responseData.watchlistedFace.internalId) {
        payload.file.internalId = responseData.watchlistedFace.internalId;
      }
      
      payload.file.url = responseData.watchlistedFace?.enrollmentPreview?.url
      payload.preview.value.unshift(
        responseData.watchlistedFace.enrollmentPreview
      ); //display preview back on watchlist CRUD component
      if (payload.preview.value.length >= 50) {
        payload.preview.value.pop();
      }

      //show member in new watchlist in background
      if (context.getters.memberSummaryCount(payload.wl_id) < 8) {
        context.commit('updateWatchlistMemberSummary', {
          _id: payload.wl_id,
          enrollmentPreview: responseData.watchlistedFace.enrollmentPreview
        });
      }

      if (payload.enrollmentResults) {
        payload.enrollmentResults.value.push(responseData.watchlistedFace);
      }
      //update size
      context.commit('updateWatchlistSize', { _id: payload.wl_id });
    } else {
      payload.file.enrollStatus = 'failed';
      payload.file.enrollStatusMsg = responseData.message;
    }

    payload.progress.value += 1; //update for import progressbar
  },
  async deleteWatchlist(context, payload) {
    const url = `/rest/v1/watchlist/${payload.id}`;
    const httpPayload = {
      method: 'DELETE'
    };

    const responseData = await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
    if (responseData.status === 'success') {
      context.commit('deleteWatchlist', responseData.watchlist);
    }
  },
  async enrollFaceToWatchlists(context, payload) {
    const url = '/rest/v1/watchlist/enrollByPersonId';

    const httpPayload = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    const responseData = await context.dispatch(
      'auth/fetchJSON',
      {
        url: url,
        payload: httpPayload
      },
      { root: true }
    );

    return responseData;
  },
  async isAudibleAlarmEnabled(context, payload) {
    const watchlist = context.getters.watchlists.find(wl => wl._id === payload._watchlistId);
    if (watchlist) {
      if (watchlist.audibleAlarmEnabled) {
        return true;
      }
    }
    return false;
  },
  async setWatchlistAdjudicationState(context, payload) {
    const url = `/rest/v1/watchlistedFaceMatch/${payload.id}`;

    const httpPayload = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    const responseData = await context.dispatch(
      'auth/fetchJSON',
      {
        url: url,
        payload: httpPayload
      },
      { root: true }
    );

    if (responseData.status === 'success') {
      return true;
    }

    return false;
  },
  async setWatchlistedFaceMatchNotes(context, payload) {
    const url = `/rest/v1/watchlistedFaceMatch/${payload.id}`;

    const httpPayload = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    const responseData = await context.dispatch(
      'auth/fetchJSON',
      {
        url: url,
        payload: httpPayload
      },
      { root: true }
    );

    if (responseData.status === 'success') {
      context.commit('encounters/updateEncounter', payload, { root: true });
    }

    return false;
  },
  async setWatchlistedLicensePlateMatchNotes(context, payload) {
    const url = `/rest/v1/watchlistedLicensePlateMatch/${payload.id}`;

    const httpPayload = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    const responseData = await context.dispatch(
      'auth/fetchJSON',
      {
        url: url,
        payload: httpPayload
      },
      { root: true }
    );

    if (responseData.status === 'success') {
      context.commit('encounters/updateEncounter', payload, { root: true });
    }
    return false;
  },
  async getWatchlistedFace(context, payload) {
    const url = `/rest/v1/watchlistedFace/${payload.id}`;
    const httpPayload = {
      method: 'GET'
    };
    return await context.dispatch('auth/fetchJSON', { url: url, payload: httpPayload }, { root: true });
  },
  async getWatchlistedFaceMatch(context, payload) {
    const url = `/rest/v1/watchlistedFaceMatch/${payload.id}`;
    const httpPayload = {
      method: 'GET'
    };
    return await context.dispatch('auth/fetchJSON', { url: url, payload: httpPayload }, { root: true });
  },
  async getWatchlistedImage(context, payload) {
    const url = `/rest/v1/watchlistedImage/${payload.id}`;
    const httpPayload = {
      method: 'GET'
    };
    return await context.dispatch('auth/fetchJSON', { url: url, payload: httpPayload }, { root: true });
  },
  async deleteWatchlistedElement(context, payload) {
    const elementId = payload.watchlistedElementId;
    const type = payload.watchlistType;
    let url;
    if (type === 'face') {
      url = `/rest/v1/watchlistedFace/${elementId}`
    }
    else if (type === 'text') {
      url = `/rest/v1/watchlistedLicensePlate/${elementId}`;
    } else {
      url = `/rest/v1/watchlistedImage/${elementId}`;
    }

    const httpPayload = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    return await context.dispatch("auth/fetchJSON", { url: url, payload: httpPayload }, { root: true });
  },
  async moveWatchlistedElement(context, payload) {
    const elementId = payload.watchlistedElementId;
    const type = payload.watchlistType;
    const watchlistDestination = payload.watchlistDestination;
    let url;
    if (type === 'face') {
      url = `/rest/v1/watchlistedFace/move`
    }
    else if (type === 'text') {
      url = `/rest/v1/watchlistedLicensePlate/move`;
    } else {
      url = `/rest/v1/watchlistedImage/move`;
    }

    const httpPayload = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: elementId, watchlistId: watchlistDestination }),
    };
    return await context.dispatch("auth/fetchJSON", { url: url, payload: httpPayload }, { root: true });
  },
  async updateWatchlistedElement(context, payload) {
    let url;
    if (payload.type === 'face') {
      url = `/rest/v1/watchlistedFace/${payload.watchlistedElement._id}`;
    }
    else if (payload.type === 'text') {
      url = `/rest/v1/watchlistedLicensePlate/${payload.watchlistedElement._id}`;
    } else{
      url = `/rest/v1/watchlistedImage/${payload.watchlistedElement._id}`;
    }
    const httpPayload = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload.watchlistedElement),
    };
    return await context.dispatch("auth/fetchJSON", { url: url, payload: httpPayload }, { root: true });
  },
  async getWatchlistElements(context, payload) {
    let url;
    const postPayload = {
      filterText: payload.filterText,
      filterImage: payload.filterImage,
      maxFaceSearchCandidates: payload.maxCandidates ?? 20,
      matchThreshold: payload.matchThreshold
    };
    if (payload.type === 'face') {
      // face endpoint uses a POST-GET to allow body parameters for filtering
      url = `/rest/v1/watchlistedFace/${payload.watchlistId}/${payload.currentPage}`;
      const httpPayload = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postPayload),
      };
      return await context.dispatch("auth/fetchJSON", { url: url, payload: httpPayload }, { root: true });
    }
    else if (payload.type === 'text') {
      // text endpoint uses a basic GET
      url = `/rest/v1/watchlist/${payload.watchlistId}/licenseplates/${payload.currentPage}?filter=${payload.filterText}`;
      const httpPayload = {
        method: 'GET'
      };
      return await context.dispatch("auth/fetchJSON", { url: url, payload: httpPayload }, { root: true });
    } else {
      // face endpoint uses a POST-GET to allow body parameters for filtering
      url = `/rest/v1/watchlistedImage/${payload.watchlistId}/${payload.currentPage}`;
      const httpPayload = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postPayload),
      };
      return await context.dispatch("auth/fetchJSON", { url: url, payload: httpPayload }, { root: true });

    }
  },
  async initWatchlistImporter(context, payload) {
    context.commit('setImporterType', payload.type);
    context.commit('setImporterMode', payload.mode);
    context.commit('setImporterWatchlistId', payload.watchlistId);
    context.commit('setImporterEventId', payload.eventId);
    context.commit('setImporterVisible', payload.visible);
  },
  async toggleWatchlistImporter(context, payload) {
    context.commit('setImporterVisible', payload);
  },
  async updateWatchlistedFaceMatchAttributes(context, payload) {
    const url = `/rest/v1/watchlistedFaceMatch/${payload.id}`;
    const httpPayload = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };
    const responseData = await context.dispatch(
      'auth/fetchJSON',
      {
        url: url,
        payload: httpPayload
      },
      { root: true }
    );
    if (responseData.status === 'success') {
      context.commit('encounters/updateEncounter', payload, { root: true });
    }
    return responseData;
  },
  async updateWatchlistedLicensePlateMatchAttributes(context, payload) {
    const url = `/rest/v1/watchlistedLicensePlateMatch/${payload.id}`;
    const httpPayload = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };
    const responseData = await context.dispatch(
      'auth/fetchJSON',
      {
        url: url,
        payload: httpPayload
      },
      { root: true }
    );
    if (responseData.status === 'success') {
      context.commit('encounters/updateEncounter', payload, { root: true });
    }
    return responseData;
  },
  async updateWatchlistedImageMatchAttributes(context, payload) {
    const url = `/rest/v1/watchlistedImageMatch/${payload.id}`;
    const httpPayload = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };
    const responseData = await context.dispatch(
      'auth/fetchJSON',
      {
        url: url,
        payload: httpPayload
      },
      { root: true }
    );
    if (responseData.status === 'success') {
      context.commit('encounters/updateEncounter', payload, { root: true });
    }
    return responseData;
  },
  async enrollLicensePlateToWatchlists(context, payload) {
    const responseData = await apiCall(
      context,
      '/rest/v1/watchlist/enrollLicensePlate',
      'POST',
      JSON.stringify(payload)
    );
    return responseData;
  },
};
